import React, { useState, useRef, useEffect } from "react";
import { Prompt, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import cuid from "cuid";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

function NavigationPrompt({ when }) {
  const [open, setOpen] = useState(false);
  const trigger = useRef(Symbol.for(`navigationPrompt_${cuid()}`));
  const callbackRef = useRef(null);
  const history = useHistory();
  const { t } = useTranslation();

  const show = (allowTransitionsCallback) => {
    allowTransitionsCallback(false);
    setOpen(true);
    callbackRef.current = allowTransitionsCallback;
  };

  const handleClose = () => {
    history.goForward();
    setOpen(false);
  };

  const handleTransition = (location) => {
    return Symbol.keyFor(trigger.current);
  };

  const handleConfirm = () => {
    callbackRef.current(true);
    setOpen(false);
  };

  useEffect(() => {
    window[trigger.current] = show;

    return () => {
      delete window[trigger.current];
    };
  }, []);

  return (
    <>
      {<Prompt when={when} message={handleTransition} />}
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{
          root: "modal__backdrop",
          paper: "finish-modal-container",
        }}
      >
        <Tooltip
          title={t("common.tooltip.close")}
          classes={{
            tooltip: "tooltip",
          }}
          placement="bottom-start"
        >
          <button className="btn__close" onClick={handleClose}>
            <CloseIcon classes={{ root: "btn__modal-icon--close" }} />
          </button>
        </Tooltip>
        <div className="modal-content">
          <div className="modal-content-container">
            <p className="modal-title">{t("navigationPrompt.title")}</p>
            <div className="btn-container">
              <Button
                className="btn-confirm"
                type="button"
                onClick={handleConfirm}
              >
                {t("navigationPrompt.btnConfirm")}
              </Button>
              <Button
                className="btn-cancel"
                type="button"
                onClick={handleClose}
              >
                {t("navigationPrompt.btnCancel")}
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}
export default NavigationPrompt;   
