import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import PolicyText from './PolicyText';

function PrivacyPolicy () {
	const { t } = useTranslation();
	useEffect(() => {
		document.title = t('privacy.pageTitle')
	},[t])
	return (
		<div className='container__terms-page'>
			<p className='terms-title'>POLÍTICAS DE PRIVACIDADE</p>
			<PolicyText/>
		</div>
	)
}

export default PrivacyPolicy