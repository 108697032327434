import { requestLogin } from '../utils/authAPI';
import { setLocalToken, getCurrentUser, getUnits, getOSS, getProfiles, getGender } from '../utils/dataAPI';
import { setErrorStatus } from './error';
import { Cookies } from 'react-cookie';

export const SET_USER = 'SET_USER';
export const SET_LOGIN_STATUS = 'SET_LOGIN_STATUS';
export const SET_UNITS = 'SET_UNITS';
export const SET_OSS = 'SET OSS';
export const SET_PROFILES = 'SET_PROFILES';
export const SET_GENDER = 'SET_GENDER';

export function setUser(userData) {
	return {
		type: SET_USER,
		userData: userData
	}
}

export function setLoginStatus(bool) {
	return {
		type: SET_LOGIN_STATUS,
		loginStatus: bool,
	}
}

export function setUnits(units) {
	return {
		type: SET_UNITS,
		units: units,
	}
}

export function setOSS(oss) {
	return {
		type: SET_OSS,
		oss: oss
	}
}

export function setProfiles(profiles) {
	return {
		type: SET_PROFILES,
		profiles: profiles
	}
}

export function setGender(gender) {
	return {
		type: SET_GENDER,
		gender: gender
	}
}

export function handleLogin(username,password,captcha) {
	const cookies = new Cookies();
	return(dispatch) => {
		return requestLogin(username,password,captcha).then((response) => {
			if (response.status === 401) {
				return Promise.reject('loginUnauthorized')
			}
			if (response.status === 200) {
				return response.json()
			}
		}).then((tokens) => {
				if (tokens.success) {
					setLocalToken(tokens);
					cookies.set('user', tokens, {path: '/', domain: window.location.hostname, maxAge: 604800})
					dispatch(setLoginStatus(true))
				} else {
					return Promise.reject('loginUnauthorized')
				}
		}).catch((error) => {
			if (error === 'loginUnauthorized') {
				dispatch(setLoginStatus(false))
				return error
			} else {
				dispatch(setLoginStatus(false))
				return 'loginError'
			}
		})
	}
}

export function handleGetUser() {
	return(dispatch) => {
		return getCurrentUser().then((userData) => {
			if (userData && userData.success) {
				let newPermissions = {}

				userData.permissions.forEach((permission) => {
					const { module, resource, ...permissionList} = permission
					if (permission.module !== permission.resource) {
						newPermissions[permission.resource] = permissionList
					} else {
						newPermissions[permission.module] = permissionList
					}
				})

				userData.permissions = newPermissions
				dispatch(setUser(userData))
			} else {
				return Promise.reject();
			}
		}).catch(error => {
			dispatch(setErrorStatus(true))
		})
	}
}

export function handleGetUnits() {
	return(dispatch) => {
		return getUnits().then((units) => {
			let idList = {};
			units.data.forEach((unit) => {
				idList[unit.id] = unit.name
			})

			let newList = {...units, idList}
			dispatch(setUnits(newList))
		}).catch(error => {
			dispatch(setErrorStatus(true))
		})
	}
}

export function handleGetOSS(id) {
	return(dispatch) => {
		return getOSS().then((oss) => {
			if (oss && oss.success) {
				let idList = {};
				oss.data.forEach((item) => {
					idList[item.id] = item.name
				})
				let newList = {...oss, idList}
				dispatch(setOSS(newList))
			} else {
				return Promise.reject();
			}
		}).catch(error => {
			dispatch(setErrorStatus(true))
		})
	}
}

export function handleGetProfiles() {
	return(dispatch) => {
		return getProfiles().then((profiles) => {
			if (profiles && profiles.success) {
				let newData = [];
				let idList = {};
				profiles.data.forEach((profile) => {
					idList[profile.id] = profile.name
					newData.push({id: profile.id, name: profile.name})
				})
				let newList = {data: newData, idList}
				dispatch(setProfiles(newList));
			} else {
				return Promise.reject();
			}
		}).catch(error => {
			dispatch(setErrorStatus(true))
		})
	}
}

export function handleGetGender() {
	return(dispatch) => {
		return getGender().then((gender) => {
			if (gender && gender.success) {
				let idList = {};
				gender.data.forEach((item) => {
					idList[item.id] = item.name
				})
				let newList = {...gender, idList}
				dispatch(setGender(newList));
			} else {
				return Promise.reject();
			}
		}).catch(error => {
			dispatch(setErrorStatus(true))
		})
	}
}