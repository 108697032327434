import { useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { ExternalLink, AlertCircle, XOctagon, Frown } from "lucide-react";
import { toast } from "react-toastify";
import { PDFObject } from "react-pdfobject";
import DataTable from "react-data-table-component";
import Tooltip from "@material-ui/core/Tooltip";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import CircularProgress from "@material-ui/core/CircularProgress";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Menu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import SearchIcon from "@material-ui/icons/Search";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Skeleton from "@material-ui/lab/Skeleton";
import ViewListOutlinedIcon from "@material-ui/icons/ViewListOutlined";
import moment from "moment";

import { getOrgsById, updateFidoValidation } from "../utils/dataAPI";
import { config, orgDetailsConfig, toastConfig } from "../data/tableConfig";
import {
  getTableItems,
  editTableItem,
  downloadFile,
  uploadFile,
} from "../utils/dataAPI";
import { cnpjValidation } from "../utils/inputValidation";
import { formatCNPJ, formatPhone, formatData } from "../utils/formatData";
import { tableCustomStyles } from "../data/tableStyles";
import IconSelector from "./IconSelector";
import FormElement from "./FormElement";
import ErrorMessage from "./ErrorMessage";
import OrgDetailsError from "./OrgDetailsError";

function OrganizationDetails() {
  const [showDetailForm, setShowDetailForm] = useState(false);
  const [showFiscalForm, setShowFiscalForm] = useState(false);
  const [orgInfo, setOrgInfo] = useState(null);
  const [fiscalDocsInfo, setFiscalDocsInfo] = useState(null);
  const [generalTabLoading, setGeneralTabLoading] = useState(true);
  const [fiscalTabLoading, setFiscalTabLoading] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [errorType, setErrorType] = useState(null);
  const [sortBy, setSortBy] = useState(config.fiscal_document.initialSort);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [order, setOrder] = useState(config.fiscal_document.initialOrder);
  const [perPage, setPerPage] = useState(10);
  const [openSearch, setOpenSearch] = useState(false);
  const [openPDFModal, setOpenPDFModal] = useState(false);
  const [pdfURL, setPDFurl] = useState(null);
  const [pdfTitle, setPDFTitle] = useState(null);
  const [pdfLoadingError, setPDFLoadingError] = useState(false);
  const [downloadFileError, setDownloadFileError] = useState("");
  const [tableError, setTableError] = useState(false);
  const [reloadPage, setReloadPage] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [changeInput, setChangeInput] = useState(false);
  const [openValidateModal, setOpenValidateModal] = useState(false);
  const [validationModalType, setValidationModal] = useState("");
  const [fidoToValidate, setFidoToValidate] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleValidateClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setFidoToValidate(row["id_issued_by"]);
  };

  useEffect(() => {
    if (validationModalType !== "") {
      setOpenValidateModal(true);
    } else {
      setOpenValidateModal(false);
    }
  }, [validationModalType]);

  useEffect(() => {
    console.log(fidoToValidate);
  }, [fidoToValidate]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModalValidateClose = () => {
    setValidationModal("");
  };

  const previousController = useRef(null);
  const requestAborted = useRef(false);
  const { orgId } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.userData);

  const handleValidation = (validationModalType) => {
    updateFidoValidation(orgId, fidoToValidate, validationModalType)
      .then(() => {
        onReloadPage();
        setOpenValidateModal(false);
      })
      .catch(() => {
        console.log("erro ao atualizar documento");
      });
  };

  let generalDataFields = [];

  orgDetailsConfig.generalData.fields.forEach((field) => {
    let formItem = {
      id: field.id,
      ...field.formProps,
    };
    generalDataFields.push(formItem);
  });

  let fiscalDocsFields = [];

  orgDetailsConfig.fiscal_document.fields.forEach((field) => {
    let formItem = {
      id: field.id,
      ...field.formProps,
    };
    fiscalDocsFields.push(formItem);
  });

  let fiscalTableColumns = [];

  config.fiscal_document.fields.forEach((field) => {
    let tableItem = {
      id: field.id,
      name: t(`orgDetails.fiscal_document.fields.${field.id}`),
      ...field.tableProps,
    };
    if (
      field.tableProps.type === "update_button" &&
      Object.keys(user).length !== 0 &&
      user.permissions.my_organization.update
    ) {
      tableItem.cell = (row) => {
        return (
          <Button
            className="export-menu-btn"
            type="button"
            onClick={() => handleFiscalDocUpdate(row)}
          >
            <IconSelector name="RotateCw" type="feather-icon" size={18} />
            <p className="table-btn-text">
              {t(`orgDetails.fiscal_document.tableBtn`)}
            </p>
          </Button>
        );
      };
    }

    if (
      field.tableProps.type === "validate_button" &&
      Object.keys(user).length !== 0 &&
      user.permissions.allow_fido_validation.update
    ) {
      tableItem.cell = (row) => {
        return (
          <>
            <Button
              className="validate-menu-btn"
              type="button"
              onClick={(event) => handleValidateClick(event, row)}
              disabled={
                row["expiration_status"] === "Pendente" ||
                row["expiration_status"] === "Em atraso"
              }
            >
              <IconSelector name="MoreVertical" type="feather-icon" size={18} />
            </Button>
            <Menu
              className="validate-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              getContentAnchorEl={null}
              PaperProps={{
                className: "validate-menu-paper",
              }}
              MenuListProps={{
                className: "validate-menu-list",
              }}
            >
              <MenuItem
                className="validate-menu-item"
                onClick={() => {
                  setValidationModal("validar");
                  handleClose();
                }}
              >
                <IconSelector
                  name="CheckCircle"
                  type="feather-icon"
                  size={18}
                />
                {t(`orgDetails.fiscal_document.validate`)}
              </MenuItem>
              <MenuItem
                className="validate-menu-item"
                onClick={() => {
                  setValidationModal("invalidar");
                  handleClose();
                }}
              >
                <IconSelector name="XCircle" type="feather-icon" size={18} />
                {t(`orgDetails.fiscal_document.invalidate`)}
              </MenuItem>
            </Menu>
          </>
        );
      };
    }

    if (field.tableProps.type === "pdf_file") {
      tableItem.cell = (row) => {
        if (row[field.id] !== null) {
          return (
            <button
              className="cell__btn"
              type="button"
              onClick={() => openPDF(row)}
            >
              <p className="btn__text">
                {t("orgDetails.fiscal_document.pdfBtn")}
              </p>
              <ExternalLink size={20} />
            </button>
          );
        }
      };
    }

    if (field.tableProps.type === "boolean") {
      tableItem.cell = (row) => {
        return row[field.tableProps.selector] !== null ? (
          <div
            className={
              row[field.tableProps.selector] ===
                t("orgDetails.fiscal_document.itemStatusTrue") ||
              row[field.tableProps.selector] ===
                t("orgDetails.fiscal_document.validationStatusTrue")
                ? "status-active"
                : row[field.tableProps.selector] ===
                    t("orgDetails.fiscal_document.itemStatusNull") ||
                  row[field.tableProps.selector] ===
                    t("orgDetails.fiscal_document.validationStatusNull")
                ? "status-pending"
                : "status-overdue"
            }
          >
            {row[field.tableProps.selector]}
          </div>
        ) : null;
      };
    }

    fiscalTableColumns[field.tableProps.order] = tableItem;
  });

  const [inputText, setInputText] = useState(() =>
    fiscalTableColumns.map((tableColumn) => "")
  );

  const onReloadPage = () => {
    setReloadPage(true);
    setTableError(false);
  };

  const getParams = (pageParams) => {
    let search = [];

    inputText.forEach((input, index) => {
      if (input !== "") {
        search.push({ [fiscalTableColumns[index].id]: input });
      }
    });

    let params = {
      page: pageParams.page,
      perPage: pageParams.perPage,
      search: inputText.every((value) => value === "") ? [] : search,
      sortBy: pageParams.sortBy,
      order: pageParams.order,
      pageType: "fiscal_document",
      id: pageParams.id,
    };

    if (pageParams.signal) {
      params = {
        ...params,
        signal: pageParams.signal,
      };
    }

    return params;
  };

  const openPDF = (row) => {
    setPDFTitle(t("common.modal.generalPDFTitle"));

    setOpenPDFModal(true);

    downloadFile(row["filename"], "fiscal_document")
      .then(async (response) => {
        if (response.success !== undefined && !response.sucess) {
          setPDFLoadingError(true);
          setDownloadFileError(t(`common.downloadError.${response.type}`));
        } else {
          if (response.status === 200) {
            const headerType = response.headers.get("Content-Type");
            if (
              headerType === "application/pdf" ||
              headerType === "binary/octet-stream"
            ) {
              const blob = await response.blob();
              const file = new Blob([blob], { type: "application/pdf" });
              const fileURL = URL.createObjectURL(file);
              setPDFurl(fileURL);
            } else {
              setPDFLoadingError(true);
              setDownloadFileError(t("common.downloadError.error.default"));
            }
          } else {
            setPDFLoadingError(true);
            setDownloadFileError(t("common.downloadError.error.default"));
          }
        }
      })
      .catch((error) => {
        setPDFLoadingError(true);
        setDownloadFileError("common.downloadError.error.default");
      });
  };

  const onClosePDFModal = () => {
    setOpenPDFModal(false);
    setPDFLoadingError(false);
    setDownloadFileError("");
    URL.revokeObjectURL(pdfURL);
    setPDFTitle(null);
    setPDFurl(null);
  };

  const toggleSearch = () => {
    //send search event to google analytics
    setOpenSearch(!openSearch);
  };

  const onFilter = (value, column, index) => {
    setChangeInput(true);
    let newState = [...inputText];
    newState[index] = value;
    setInputText(newState);
  };

  const handleStep = (index) => {
    setActiveStep(index);
  };

  const handleBackBtn = () => {
    history.push("/organizations");
  };

  const handleDetailUpdate = () => {
    setShowDetailForm(!showDetailForm);
  };

  const handleFiscalDocUpdate = (row) => {
    setFiscalDocsInfo(row);
    setShowFiscalForm(!showFiscalForm);
  };

  const handlePageChange = async (page) => {
    setFiscalTabLoading(true);
    try {
      const pageParams = {
        page: page,
        perPage: perPage,
        sortBy: sortBy,
        order: order,
        id: orgId,
      };
      const response = await getTableItems(getParams(pageParams));

      if (response && response.success) {
        const formattedResponse = formatData(
          response.data,
          fiscalTableColumns,
          "orgDetails.fiscal_document"
        );

        setCurrentPage(page);
        setTableData(formattedResponse);
        setFiscalTabLoading(false);
      } else {
        toast.error(t("common.toast.operationError"), toastConfig);
        setFiscalTabLoading(false);
      }
    } catch (error) {
      toast.error(t("common.toast.operationError"), toastConfig);
      setFiscalTabLoading(false);
    }
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setFiscalTabLoading(true);
    try {
      const pageParams = {
        page: currentPage,
        perPage: newPerPage,
        sortBy: sortBy,
        order: order,
        id: orgId,
      };
      const response = await getTableItems(getParams(pageParams));
      if (response && response.success) {
        const formattedResponse = formatData(
          response.data,
          fiscalTableColumns,
          "orgDetails.fiscal_document"
        );

        setTableData(formattedResponse);
        setPerPage(newPerPage);
        setFiscalTabLoading(false);
      } else {
        toast.error(t("common.toast.operationError"), toastConfig);
        setFiscalTabLoading(false);
      }
    } catch (error) {
      toast.error(t("common.toast.operationError"), toastConfig);
      setFiscalTabLoading(false);
    }
  };

  const handleSort = async (column, sortDirection) => {
    setFiscalTabLoading(true);
    try {
      const pageParams = {
        page: currentPage,
        perPage: perPage,
        sortBy: column.selector,
        order: sortDirection,
        id: orgId,
      };
      const response = await getTableItems(getParams(pageParams));

      if (response && response.success) {
        const formattedResponse = formatData(
          response.data,
          fiscalTableColumns,
          "orgDetails.fiscal_document"
        );

        setSortBy(column.selector);
        setOrder(sortDirection);
        setTableData(formattedResponse);
        setFiscalTabLoading(false);
      } else {
        toast.error(t("common.toast.operationError"), toastConfig);
        setFiscalTabLoading(false);
      }
    } catch (error) {
      toast.error(t("common.toast.operationError"), toastConfig);
      setFiscalTabLoading(false);
    }
  };

  const handleSaveGeneralData = (values, actions) => {
    let newValues = { ...orgInfo };

    for (let input in newValues) {
      if (values[input] === "") {
        newValues[input] = null;
      } else if (values.hasOwnProperty(input)) {
        newValues[input] = values[input];
      }
    }

    editTableItem("organizations", newValues)
      .then((response) => {
        if (response && response.success) {
          getOrgsById(orgId)
            .then((orgDetail) => {
              actions.setSubmitting(false);
              setOrgInfo(orgDetail.data[0]);
              handleDetailUpdate();
            })
            .catch((error) => {
              toast.error(t("common.toast.noAccessToOrgData"), toastConfig);
              actions.setSubmitting(false);
              handleDetailUpdate();
            });
        } else {
          toast.error(t("common.toast.dataNotSaved"), toastConfig);
          actions.setSubmitting(false);
        }
      })
      .catch((error) => {
        toast.error(t("common.toast.dataNotSaved"), toastConfig);
        actions.setSubmitting(false);
      });
  };

  const handleSaveFiscalData = (values, actions) => {
    let newValues = { ...fiscalDocsInfo };

    for (let input in newValues) {
      if (values[input] === "") {
        newValues[input] = null;
      } else if (values.hasOwnProperty(input)) {
        newValues[input] = values[input];
      }
    }

    let fileObj = {
      file: values["file"],
      filename: newValues["filename"],
      id_org: newValues["id_org"],
      id_issued_by: newValues["id_issued_by"],
    };

    uploadFile(fileObj).then((upload) => {
      if (upload.response && upload.response.status === 204) {
        if (values["file"]) {
          newValues["filename"] = upload.filename;
        }
        editTableItem("fiscal_document", newValues)
          .then((response) => {
            if (response && response.success) {
              const pageParams = {
                page: 1,
                perPage: perPage,
                sortBy: sortBy,
                order: order,
                id: orgId,
              };
              actions.setSubmitting(false);
              setFiscalTabLoading(true);
              setShowFiscalForm(false);
              let formattedResponse = null;
              getTableItems(getParams(pageParams))
                .then((tableResponse) => {
                  if (tableResponse && tableResponse.success) {
                    formattedResponse = formatData(
                      tableResponse.data,
                      fiscalTableColumns,
                      "orgDetails.fiscal_document"
                    );
                  } else {
                    setTableError(true);
                    formattedResponse = [];
                  }
                  setReloadPage(false);
                  setCurrentPage(1);
                  setTotalRows(tableResponse.total);
                  setTableData(formattedResponse);
                  setFiscalTabLoading(false);
                })
                .catch((error) => {
                  setTableError(true);
                  formattedResponse = [];
                  setReloadPage(false);
                  setTableData(formattedResponse);
                  setFiscalTabLoading(false);
                });
            } else {
              toast.error(t("common.toast.dataNotSaved"), toastConfig);
              actions.setSubmitting(false);
            }
          })
          .catch((error) => {
            toast.error(t("common.toast.dataNotSaved"), toastConfig);
            actions.setSubmitting(false);
          });
      } else {
        toast.error(t("common.toast.fileNotUpdated"), toastConfig);
        actions.setSubmitting(false);
      }
    });
  };

  const getInitialValues = (type) => {
    let initialValues = {};
    if (type === "generalData") {
      generalDataFields.forEach((field) => {
        initialValues[field.id] = orgInfo[field.id] ? orgInfo[field.id] : "";
      });
    }

    if (type === "fiscalDocs") {
      fiscalDocsFields.forEach((field) => {
        initialValues[field.id] = fiscalDocsInfo[field.id]
          ? fiscalDocsInfo[field.id]
          : "";
      });

      initialValues["filename"] = fiscalDocsInfo["filename"]
        ? fiscalDocsInfo["filename"]
        : "";
    }

    return initialValues;
  };

  const fieldsValidation = (values) => {
    const errors = {};
    const doValidations = (field) => {
      if (
        field.validation.required &&
        ((!values[field.id] && typeof values[field.id] !== "number") ||
          (field.type === "select" && values[field.id] === "none")) &&
        field.id === "file" &&
        values.hasOwnProperty("filename") &&
        !values["filename"] &&
        !values[field.id]
      ) {
        errors[field.id] = t("common.inputError.missingField");
      } else if (
        field.validation.minChar !== null &&
        values[field.id] &&
        values[field.id].length < field.validation.minChar
      ) {
        errors[field.id] = t("common.inputError.minCharValidation");
      } else if (
        field.validation.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values[field.id])
      ) {
        if (
          field.validation.required ||
          (!field.validation.required && values[field.id])
        ) {
          errors[field.id] = t("common.inputError.emailValidation");
        }
      } else if (field.validation.cnpj && cnpjValidation(values[field.id])) {
        errors[field.id] = t("common.inputError.cnpjValidation");
      } else if (field.validation.noSpace && !/^(?!\s).{1,}/) {
        errors[field.id] = t("common.inputError.noSpaceValidation");
      } else if (
        values[field.id] &&
        field.validation.phone &&
        !/^\([1-9]{1}[0-9]{1}\) [0-9]{4}[-][0-9]{4}$/.test(values[field.id])
      ) {
        if (
          field.validation.required ||
          (!field.validation.required && values[field.id])
        ) {
          errors[field.id] = t("common.inputError.phoneValidation");
        }
      } else if (
        field.validation.date &&
        !moment(values[field.id], "DD/MM/YYYY", true).isValid() ===
          !moment(values[field.id], "YYYY-MM-DD", true).isValid()
      ) {
        if (
          field.validation.required ||
          (!field.validation.required && values[field.id])
        ) {
          errors[field.id] = "Data inválida";
        }
      }
    };

    if (activeStep === 0) {
      generalDataFields.forEach((field) => {
        doValidations(field);
      });
    } else {
      fiscalDocsFields.forEach((field) => {
        doValidations(field);
      });
    }

    return errors;
  };

  useEffect(() => {
    setErrorType(null);
    if (
      Object.keys(user).length !== 0 &&
      !user.permissions.my_organization.read
    ) {
      setErrorType("unauthorizedAccess");
    }
    setGeneralTabLoading(true);
    getOrgsById(orgId)
      .then((orgDetail) => {
        if (orgDetail.success) {
          if (orgDetail.data.length === 0) {
            setErrorType("invalidOrganization");
          } else {
            setOrgInfo(orgDetail.data[0]);
          }
        } else {
          setErrorType("generalError");
        }
        setGeneralTabLoading(false);
      })
      .catch((error) => {
        setErrorType("generalError");
        setGeneralTabLoading(false);
      });
  }, [orgId, user]);

  useEffect(() => {
    document.title = t("orgDetails.pageTitle");
  }, [t]);

  useEffect(() => {
    setFiscalTabLoading(true);
    const fetchData = async () => {
      if (reloadPage) {
        let formattedResponse = null;
        try {
          const pageParams = {
            page: 1,
            perPage: perPage,
            sortBy: sortBy,
            order: order,
            id: orgId,
          };
          const response = await getTableItems(getParams(pageParams));

          if (response && response.success) {
            formattedResponse = formatData(
              response.data,
              fiscalTableColumns,
              "orgDetails.fiscal_document"
            );
          } else {
            setTableError(true);
            formattedResponse = [];
          }

          setReloadPage(false);
          setCurrentPage(1);
          setTotalRows(response.total);
          setTableData(formattedResponse);
          setFiscalTabLoading(false);
        } catch (error) {
          setTableError(true);
          formattedResponse = [];
          setReloadPage(false);
          setTableData(formattedResponse);
          setFiscalTabLoading(false);
        }
      }
    };

    fetchData();
  }, [reloadPage, orgId, user]);

  useEffect(() => {
    setFiscalTabLoading(true);
    const fetchData = async () => {
      if (changeInput) {
        let formattedResponse = null;
        if (previousController.current) {
          previousController.current.abort();
          requestAborted.current = true;
        }
        try {
          const controller = new AbortController();
          const signal = controller.signal;
          previousController.current = controller;
          const pageParams = {
            page: 1,
            perPage: perPage,
            sortBy: sortBy,
            order: order,
            id: orgId,
            signal: signal,
          };
          const response = await getTableItems(getParams(pageParams));

          if (response && response.success) {
            formattedResponse = formatData(
              response.data,
              fiscalTableColumns,
              "orgDetails.fiscal_document"
            );
            requestAborted.current = false;
            previousController.current = null;
          } else {
            //não foi possível fazer testes se a condição de erro de tabela será atendida como planejado, portanto qualquer problema reportado sobre isso verificar essa condição
            if (!requestAborted.current) {
              setTableError(true);
            }
            formattedResponse = [];
          }

          if (!requestAborted.current) {
            setReloadPage(false);
            setCurrentPage(1);
            setTotalRows(response.total);
            setTableData(formattedResponse);
            setFiscalTabLoading(false);
          } else {
            requestAborted.current = false;
          }
        } catch (error) {
          setTableError(true);
          formattedResponse = [];
          setReloadPage(false);
          setTableData(formattedResponse);
          setFiscalTabLoading(false);
        }
      }
    };

    fetchData();
  }, [inputText]);

  return (
    <div className="page-container">
      {Object.keys(user).length !== 0 && (
        <>
          {user.permissions.my_organization.read && errorType === null ? (
            <>
              <div className="title-container">
                {user.permissions.list_organizations.read && (
                  <Button
                    className="back-btn"
                    onClick={handleBackBtn}
                    type="button"
                  >
                    <ArrowBackIcon />
                  </Button>
                )}
                <p className="tablepage-title">{t("orgDetails.pageTitle")}</p>
              </div>
              <div className="page-content">
                <div className="form-controls">
                  <Stepper nonLinear activeStep={activeStep} connector={<></>}>
                    <Step>
                      <StepButton
                        icon={<span>{"1."}</span>}
                        className={
                          activeStep === 0
                            ? "button-style--active"
                            : "button-style"
                        }
                        onClick={() => handleStep(0)}
                      >
                        {t("orgDetails.stepLabels.generalData")}
                      </StepButton>
                    </Step>
                    {
                      <Step>
                        <StepButton
                          icon={<span>{"2."}</span>}
                          className={
                            activeStep === 1
                              ? "button-style--active"
                              : "button-style"
                          }
                          onClick={() => handleStep(1)}
                        >
                          {t("orgDetails.stepLabels.fiscal_document")}
                        </StepButton>
                      </Step>
                    }
                  </Stepper>
                </div>
                {activeStep === 0 ? (
                  <div className="page-form-container">
                    {!showDetailForm && activeStep === 0 ? (
                      <div className="detail-container">
                        {generalTabLoading ? (
                          <div className="detail-title-container">
                            <Skeleton
                              variant="text"
                              animation="wave"
                              classes={{ root: "skeleton-info-title" }}
                            />
                          </div>
                        ) : (
                          <div className="detail-title-container">
                            <p className="detail-title">
                              {orgInfo && orgInfo.org_name}
                            </p>
                          </div>
                        )}
                        <div className="detail-table-container">
                          <div className="detail-column">
                            <div className="detail-cell">
                              <div className="icon__wrapper">
                                <IconSelector
                                  name="Phone"
                                  type="feather-icon"
                                  size={18}
                                />
                              </div>
                              <p className="detail-subtitle">
                                {t("orgDetails.fields.contact")}:
                              </p>
                            </div>
                            <div className="detail-cell">
                              <div className="icon__wrapper">
                                <IconSelector
                                  name="Mail"
                                  type="feather-icon"
                                  size={18}
                                />
                              </div>
                              <p className="detail-subtitle">
                                {t("orgDetails.fields.email")}:
                              </p>
                            </div>
                            <div className="detail-cell">
                              <div className="icon__wrapper">
                                <IconSelector
                                  name="CreditCard"
                                  type="feather-icon"
                                  size={18}
                                />
                              </div>
                              <p className="detail-subtitle">
                                {t("orgDetails.fields.cnpj")}:
                              </p>
                            </div>
                            <div className="detail-cell">
                              <div className="icon__wrapper">
                                <IconSelector
                                  name="Smile"
                                  type="feather-icon"
                                  size={18}
                                />
                              </div>
                              <p className="detail-subtitle">
                                {t("orgDetails.fields.corporate_name")}:
                              </p>
                            </div>
                            <div className="detail-cell">
                              <div className="icon__wrapper">
                                <ViewListOutlinedIcon
                                  style={{ fontSize: 24 }}
                                />
                              </div>
                              <p className="detail-subtitle">
                                {t("orgDetails.fields.org_type")}:
                              </p>
                            </div>
                          </div>
                          <div className="detail-column">
                            {generalTabLoading ? (
                              <>
                                <div className="detail-cell">
                                  <Skeleton
                                    variant="text"
                                    animation="wave"
                                    classes={{ root: "skeleton-info-txt" }}
                                  />
                                </div>
                                <div className="detail-cell">
                                  <Skeleton
                                    variant="text"
                                    animation="wave"
                                    classes={{ root: "skeleton-info-txt" }}
                                  />
                                </div>
                                <div className="detail-cell">
                                  <Skeleton
                                    variant="text"
                                    animation="wave"
                                    classes={{ root: "skeleton-info-txt" }}
                                  />
                                </div>
                                <div className="detail-cell">
                                  <Skeleton
                                    variant="text"
                                    animation="wave"
                                    classes={{ root: "skeleton-info-txt" }}
                                  />
                                </div>
                                <div className="detail-cell">
                                  <Skeleton
                                    variant="text"
                                    animation="wave"
                                    classes={{ root: "skeleton-info-txt" }}
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="detail-cell">
                                  <p className="detail-info">
                                    {orgInfo && orgInfo.contact !== null
                                      ? formatPhone(orgInfo.contact)
                                      : "-"}
                                  </p>
                                </div>
                                <div className="detail-cell">
                                  <p className="detail-info">
                                    {orgInfo && orgInfo.email !== null
                                      ? orgInfo.email
                                      : "-"}
                                  </p>
                                </div>
                                <div className="detail-cell">
                                  <p className="detail-info">
                                    {orgInfo && orgInfo.cnpj !== null
                                      ? formatCNPJ(orgInfo.cnpj)
                                      : "-"}
                                  </p>
                                </div>
                                <div className="detail-cell">
                                  <p className="detail-info">
                                    {orgInfo && orgInfo.corporate_name !== null
                                      ? orgInfo.corporate_name
                                      : "-"}
                                  </p>
                                </div>
                                <div className="detail-cell">
                                  <p className="detail-info">
                                    {orgInfo && orgInfo.org_type !== null
                                      ? orgInfo.org_type
                                      : "-"}
                                  </p>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        {user.permissions.my_organization.update && (
                          <Button
                            className="btn-color-filled"
                            onClick={handleDetailUpdate}
                          >
                            {t("orgDetails.updateBtn")}
                          </Button>
                        )}
                      </div>
                    ) : (
                      showDetailForm &&
                      activeStep === 0 && (
                        <div className="detail-container">
                          <Formik
                            initialValues={getInitialValues("generalData")}
                            validate={(values) => fieldsValidation(values)}
                            onSubmit={(values, actions) =>
                              handleSaveGeneralData(values, actions)
                            }
                            enableReinitialize={true}
                          >
                            {({
                              values,
                              errors,
                              touched,
                              setFieldValue,
                              setFieldTouched,
                              isSubmitting,
                              handleBlur,
                              handleChange,
                              handleSubmit,
                            }) => (
                              <form className="form" onSubmit={handleSubmit}>
                                {generalDataFields.map((field) => (
                                  <div
                                    className="form__input-container"
                                    key={field.id}
                                  >
                                    <FormElement
                                      props={{
                                        field: field,
                                        values: values,
                                        handleChange: handleChange,
                                        handleBlur: handleBlur,
                                        pageType: "orgDetails",
                                        setFieldValue: setFieldValue,
                                        setFieldTouched: setFieldTouched,
                                        errors: errors,
                                        touched: touched,
                                        disabled: field.editDisabled
                                          ? true
                                          : isSubmitting,
                                      }}
                                    />
                                    {errors[field.id] && touched[field.id] && (
                                      <div className="input-feedback">
                                        <AlertCircle size={18} />
                                        <p className="error-text">
                                          {errors[field.id]}
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                ))}
                                <div className="step-control-container">
                                  <Button
                                    className="btn-color-inverted"
                                    onClick={handleDetailUpdate}
                                    disabled={isSubmitting}
                                    type="button"
                                  >
                                    {t("orgDetails.cancelBtn")}
                                  </Button>
                                  <Button
                                    className="btn-color-filled"
                                    disabled={isSubmitting}
                                    type="submit"
                                    id="generalDataForm"
                                  >
                                    {t("orgDetails.saveBtn")}
                                    {isSubmitting && (
                                      <CircularProgress
                                        classes={{ root: "btn__loading" }}
                                        size="2rem"
                                      />
                                    )}
                                  </Button>
                                </div>
                              </form>
                            )}
                          </Formik>
                        </div>
                      )
                    )}
                  </div>
                ) : (
                  <div className="fiscal-table-container">
                    {!showFiscalForm && activeStep === 1 ? (
                      <div className="tablepage-content table-no-border">
                        <DataTable
                          columns={fiscalTableColumns}
                          data={tableData}
                          customStyles={tableCustomStyles}
                          noHeader
                          subHeader
                          selectableRows={config.fiscal_document.selectableRows}
                          highlightOnHover={false}
                          sortServer
                          sortIcon={<ArrowDownwardIcon />}
                          pagination
                          paginationServer
                          paginationIconNext={
                            <NavigateNextIcon
                              classes={{ root: "icon-pagination" }}
                            />
                          }
                          paginationIconPrevious={
                            <NavigateBeforeIcon
                              classes={{ root: "icon-pagination" }}
                            />
                          }
                          paginationIconFirstPage={
                            <FirstPageIcon
                              classes={{ root: "icon-pagination" }}
                            />
                          }
                          paginationIconLastPage={
                            <LastPageIcon
                              classes={{ root: "icon-pagination" }}
                            />
                          }
                          paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                          onChangePage={handlePageChange}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onSort={handleSort}
                          paginationTotalRows={totalRows}
                          paginationComponentOptions={{
                            rowsPerPageText: t("common.rowsPerPageText"),
                            rangeSeparatorText: t("common.rangeSeparatorText"),
                            noRowsPerPage: false,
                            selectAllRowsItem: false,
                            selectAllRowsItemText: "All",
                          }}
                          progressPending={fiscalTabLoading}
                          progressComponent={
                            <div className="error-content--table">
                              <div className="message__container">
                                <CircularProgress
                                  classes={{ root: "progress-loading" }}
                                />
                                <p className="title-message">
                                  {t("common.table.loading")}
                                </p>
                              </div>
                            </div>
                          }
                          noDataComponent={
                            tableError ? (
                              <div className="error-content--table">
                                <ErrorMessage onReload={onReloadPage} />
                              </div>
                            ) : (
                              <div className="error-content--table">
                                <div className="message__container">
                                  <Avatar
                                    classes={{ root: "nodata-content-icon" }}
                                  >
                                    <XOctagon size={60} strokeWidth={0.5} />
                                  </Avatar>
                                  <p className="title-message">
                                    {t("common.table.noData")}
                                  </p>
                                </div>
                              </div>
                            )
                          }
                          subHeaderAlign="left"
                          subHeaderComponent={
                            <Accordion
                              expanded={openSearch}
                              classes={{ root: "accordion__tableHeader" }}
                            >
                              <AccordionSummary
                                classes={{ root: "accordion__summary-table" }}
                              >
                                <div className="tablepage-subheader">
                                  <Button
                                    classes={{
                                      root: openSearch
                                        ? "export-menu-btn--open search-btn-margin"
                                        : "export-menu-btn search-btn-margin",
                                    }}
                                    onClick={toggleSearch}
                                  >
                                    <SearchIcon />
                                    <p className="btn__control-text">
                                      {t("common.table.headerBtn.search")}
                                    </p>
                                  </Button>
                                </div>
                              </AccordionSummary>
                              <AccordionDetails
                                classes={{
                                  root: "accordion__details-table table-header-no-padding",
                                }}
                              >
                                {fiscalTableColumns.map((column, index) => (
                                  <div
                                    key={index}
                                    className="container-search-column table-search-width"
                                  >
                                    <div
                                      className={
                                        column.searchable
                                          ? "search-input-column"
                                          : "search-input-column--hidden"
                                      }
                                    >
                                      <SearchIcon
                                        classes={{ root: "icon__search-input" }}
                                      />
                                      <input
                                        className="search-input"
                                        id={index}
                                        value={inputText[index]}
                                        placeholder={t(
                                          `orgDetails.fiscal_document.searchPlaceholder.${column.id}`
                                        )}
                                        onChange={(e) =>
                                          onFilter(
                                            e.target.value,
                                            column.id,
                                            index
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                ))}
                              </AccordionDetails>
                            </Accordion>
                          }
                        />
                      </div>
                    ) : (
                      (showFiscalForm && activeStep) === 1 && (
                        <div className="page-form-container fiscal-form-container">
                          <div className="detail-container">
                            <Formik
                              initialValues={getInitialValues("fiscalDocs")}
                              validate={(values) => fieldsValidation(values)}
                              onSubmit={(values, actions) =>
                                handleSaveFiscalData(values, actions)
                              }
                              enableReinitialize={true}
                            >
                              {({
                                values,
                                errors,
                                touched,
                                setFieldValue,
                                setFieldTouched,
                                isSubmitting,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                              }) => (
                                <form className="form" onSubmit={handleSubmit}>
                                  {fiscalDocsFields.map((field) => (
                                    <div
                                      className="form__input-container"
                                      key={field.id}
                                    >
                                      <FormElement
                                        props={{
                                          field: field,
                                          values: values,
                                          handleChange: handleChange,
                                          handleBlur: handleBlur,
                                          pageType:
                                            "orgDetails.fiscal_document",
                                          setFieldValue: setFieldValue,
                                          setFieldTouched: setFieldTouched,
                                          errors: errors,
                                          touched: touched,
                                          disabled: field.editDisabled
                                            ? true
                                            : isSubmitting,
                                        }}
                                      />
                                      {errors[field.id] &&
                                        touched[field.id] && (
                                          <div className="input-feedback">
                                            <AlertCircle size={18} />
                                            <p className="error-text">
                                              {errors[field.id]}
                                            </p>
                                          </div>
                                        )}
                                    </div>
                                  ))}
                                  <div className="step-control-container">
                                    <Button
                                      className="btn-color-inverted"
                                      onClick={handleFiscalDocUpdate}
                                      disabled={isSubmitting}
                                      type="button"
                                    >
                                      {t("orgDetails.cancelBtn")}
                                    </Button>
                                    <Button
                                      className="btn-color-filled"
                                      disabled={isSubmitting}
                                      type="submit"
                                    >
                                      {t("orgDetails.saveBtn")}
                                      {isSubmitting && (
                                        <CircularProgress
                                          classes={{ root: "btn__loading" }}
                                          size="2rem"
                                        />
                                      )}
                                    </Button>
                                  </div>
                                </form>
                              )}
                            </Formik>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                )}
              </div>
            </>
          ) : (
            <OrgDetailsError type={errorType} />
          )}
        </>
      )}
      {
        <Dialog
          open={openPDFModal}
          onClose={onClosePDFModal}
          scroll="paper"
          classes={{ paper: "modal__body-alt" }}
        >
          <DialogTitle classes={{ root: "dialog__title" }}>
            <div className="container__modal-header">
              <Tooltip
                title={t("common.tooltip.close")}
                classes={{
                  tooltip: "tooltip",
                }}
                placement="bottom-start"
              >
                <button
                  type="button"
                  className="btn__close"
                  onClick={onClosePDFModal}
                >
                  <CloseIcon
                    classes={{
                      root: "btn__modal-icon--close",
                    }}
                  />
                </button>
              </Tooltip>
              <p className="modal__header-title">{pdfTitle}</p>
            </div>
          </DialogTitle>
          <DialogContent classes={{ root: "modal__content-alt" }}>
            {pdfURL === null && !pdfLoadingError ? (
              <div className="loading-text-container">
                <p className="loading-text">{t("common.modal.loadingPDF")}</p>
                <CircularProgress classes={{ root: "progress-loading" }} />{" "}
              </div>
            ) : pdfLoadingError ? (
              <div className="error-loading-container">
                <Avatar classes={{ root: "dashboard-content-icon" }}>
                  <Frown size={72} strokeWidth={0.5} />
                </Avatar>
                <p className="error-loading-text">{downloadFileError}</p>
              </div>
            ) : (
              <PDFObject url={pdfURL} />
            )}
          </DialogContent>
        </Dialog>
      }
      <Dialog
        open={openValidateModal}
        onClose={handleModalValidateClose}
        aria-labelledby="customized-dialog-title"
        PaperProps={{
          className: "modal-validate-paper",
        }}
      >
        <DialogTitle id="customized-dialog-title">
          <IconButton
            aria-label="close"
            onClick={handleModalValidateClose}
            style={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <p className="modal-validate-title">
            {validationModalType === "validar"
              ? t(`orgDetails.fiscal_document.validTitle`)
              : t(`orgDetails.fiscal_document.invalidTitle`)}
          </p>
          <p className="modal-validate-subtitle">
            {validationModalType === "validar"
              ? t(`orgDetails.fiscal_document.validSubTitle`)
              : t(`orgDetails.fiscal_document.invalidSubTitle`)}
          </p>
          <div className="modal-button-div">
            <Button
              autoFocus
              onClick={() => handleValidation(validationModalType)}
              className="modal-validate-btn"
            >
              {validationModalType === "validar"
                ? t(`orgDetails.fiscal_document.validate`)
                : t(`orgDetails.fiscal_document.invalidate`)}
            </Button>
            <Button
              onClick={() => {
                handleModalValidateClose();
              }}
              className="modal-cancel-btn"
            >
              {t(`orgDetails.fiscal_document.comeBack`)}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default OrganizationDetails;
