import React, { useState, useMemo, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import Avatar from "@material-ui/core/Avatar";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Checkbox from "@material-ui/core/Checkbox";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import LabelOutlinedIcon from "@material-ui/icons/LabelOutlined";
import KeyboardArrowDownOutlinedIcon from "@material-ui/icons/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@material-ui/icons/KeyboardArrowUpOutlined";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import ImportExportOutlinedIcon from "@material-ui/icons/ImportExportOutlined";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams, Redirect } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Search";
import { toast } from "react-toastify";
import { ExternalLink, User, XOctagon } from "lucide-react";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import { PDFObject } from "react-pdfobject";
import { Frown } from "lucide-react";
import ViewListOutlinedIcon from "@material-ui/icons/ViewListOutlined";
import Divider from "@material-ui/core/Divider";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import PostAddIcon from "@material-ui/icons/PostAdd";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import * as FileSaver from "file-saver";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import DatePicker from "react-datepicker";
import pt from "date-fns/locale/pt";
import { registerLocale } from "react-datepicker";
import DateRangeIcon from "@material-ui/icons/DateRange";
import Select from "@material-ui/core/Select";
import { AlertCircle } from "lucide-react";

import { exportExcel, exportCSV, exportPDF } from "../utils/exportFile";
import { formatData, convertValue } from "../utils/formatData";
import { event } from "../utils/googleAnalytics";
import {
  getTableItems,
  deleteTableItems,
  updateStatusTableItem,
  addTableItem,
  downloadFile,
  downloadCSV,
  getImportReportDetails,
  updateDeadline,
} from "../utils/dataAPI";

import {
  config,

  toastConfig,
  actionMenuConfig,

  modalTableConfig,
} from "../data/tableConfig";

import { permissionsConfig } from "../data/tableConfig";
import { tableCustomStyles } from "../data/tableStyles";

import IconSelector from "./IconSelector";
import ErrorMessage from "./ErrorMessage";
import { getDeadlineYearOptions } from "../utils/yearOptions";

function CellMenu({ props }) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [hideDivider, setHideDivider] = useState(false);

  const {
    row,
    mainOptions,
    subOptions,
    pageType,
    menuItemClick,
    permissions,
    mainTypePermissions,
    subTypePermissions,
  } = props;

  useEffect(() => {
    const testArray = [];

    subOptions.forEach((subOption, index) => {
      if (!permissions[subOption].read) {
        testArray[index] = false;
      } else {
        testArray[index] = true;
      }
    });
    if (testArray.every((value) => value === false)) {
      setHideDivider(true);
    } else {
      setHideDivider(false);
    }
  }, [subOptions, permissions]);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeCelMenu = () => {
    setAnchorEl(null);
  };
  
  return (
    <div>
      <button
        className={Boolean(anchorEl) ? "cell__btn--active" : "cell__btn--menu"}
        type="button"
        onClick={(event) => handleOpen(event)}
      >
        <ViewListOutlinedIcon />
        <KeyboardArrowDownOutlinedIcon />
      </button>
      <Menu
        id="menu"
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeCelMenu}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        classes={{ paper: "cell-menu" }}
      >
        {mainOptions &&
          mainOptions.map((option, index) => (
            <div key={option}>
              {permissions &&
                permissions[option][mainTypePermissions[index]] && (
                  <MenuItem
                    onClick={() => menuItemClick(row, pageType, option)}
                    className="cell-menu-item"
                    key={option}
                  >
                    {t(`${pageType}.menuCell.${option}`)}
                  </MenuItem>
                )}
            </div>
          ))}
        {subOptions && subOptions.length !== 0 && !row.is_addendum ? (
          <div>
            {!hideDivider && <Divider />}
            {subOptions &&
              subOptions.map((subOption, index) => (
                <div key={subOption}>
                  {permissions &&
                    permissions[subOption][subTypePermissions[index]] && (
                      <MenuItem
                        onClick={() => menuItemClick(row, pageType, subOption)}
                        className="cell-menu-item"
                        key={subOption}
                      >
                        {subOption === "saveAsNewContract" ? (
                          <SaveOutlinedIcon className="cell-menu-icon" />
                        ) : (
                          <PostAddIcon className="cell-menu-icon" />
                        )}{" "}
                        {t(`${pageType}.menuCell.${subOption}`)}
                      </MenuItem>
                    )}
                </div>
              ))}
          </div>
        ) : null}
      </Menu>
    </div>
  );
}

function TablePage({ pageType }) {
  const { t } = useTranslation();
  let history = useHistory();
  const [openCheckboxModal, setCheckboxModal] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const user = useSelector((state) => state.user.userData);
  /*Usado para que a opção de ativar e desativar no botão de Ações em massa apareça apenas nas páginas contidas nessa array.*/
  const statusWhitelist = ["users", "profiles", "set_headings"];

  const deadlineMonths = [
    t("common.months.january"),
    t("common.months.february"),
    t("common.months.march"),
    t("common.months.april"),
    t("common.months.may"),
    t("common.months.june"),
    t("common.months.july"),
    t("common.months.august"),
    t("common.months.september"),
    t("common.months.october"),
    t("common.months.november"),
    t("common.months.december"),
  ];

  const deadlineYears = getDeadlineYearOptions();

  const [deadlineDate, setDeadlineDate] = useState(new Date());
  const [deadlineInputDate, setDeadlineInputDate] = useState(
    t("common.picker.deadlineInput")
  );

  const [certDate, setCertDate] = useState(new Date());
  const [certInputDate, setCertInputDate] = useState(
    t("common.picker.certificateInput")
  );
  const [certInputError, setCertInputError] = useState(false);

  let tableColumns = [];

  registerLocale("pt", pt);

  config[pageType].fields.forEach((field) => {
    if (field.hasOwnProperty("tableProps")) {
      let tableItem = {
        id: field.id,
        name:
          pageType === "units" && field.id === "unit_id"
            ? t(`${pageType}.fields.${field.id}_table`)
            : t(`${pageType}.fields.${field.id}`),
        ...field.tableProps,
      };

      if (field.tableProps.type === "boolean") {
        tableItem.cell = (row) => {
          if (row[field.tableProps.selector] !== null) {
            return (
              <div
                className={
                  row[field.tableProps.selector] ===
                  t(`${pageType}.itemStatusTrue`)
                    ? "status-active"
                    : "status-inactive"
                }
              >
                {row[field.tableProps.selector]}
              </div>
            );
          }
        };
      }

      if (field.tableProps.type === "multiStatus") {
        tableItem.cell = (row) => {
          if (row[field.tableProps.selector] !== null) {
            return (
              <div
                className={`multiStatus multiStatus--${
                  row[field.tableProps.selector]
                }`}
              >
                {t(`common.multiStatus.${row[field.tableProps.selector]}`)}
              </div>
            );
          }
        };
      }

      if (field.tableProps.type === "checkboxOptions") {
        tableItem.cell = (row) => {
          return (
            <button
              className="cell__btn"
              type="button"
              onClick={() => onOpenCheckboxModal(row)}
            >
              <p className="btn__text">{t(`${pageType}.rolesBtn`)}</p>
              <ExternalLink size={20} />
            </button>
          );
        };
      }
      if (field.tableProps.type === "pdf_file") {
        tableItem.cell = (row) => {
          return (
            <button
              className="cell__btn"
              type="button"
              onClick={() => openPDF(row)}
            >
              <p className="btn__text">{row[field.id]}</p>
            </button>
          );
        };
      }
      if (field.tableProps.type === "csv_file") {
        tableItem.cell = (row) => {
          return (
            <button
              className="cell__btn"
              type="button"
              onClick={() => saveCSV(row)}
            >
              <p className="btn__text">{row[field.id]}</p>
            </button>
          );
        };
      }
      if (field.tableProps.type === "modal") {
        tableItem.cell = (row) => {
          if (row[field.id] === t("common.table.imported")) {
            return <div className="status-active">{row[field.id]}</div>;
          } else {
            return (
              <Button
                className="status-error"
                onClick={() => openStatusModal(row)}
              >
                {row[field.id]}
              </Button>
            );
          }
        };
      }
      if (field.tableProps.type === "table") {
        tableItem.cell = (row) => {
          return (
            <button
              className="cell__btn"
              type="button"
              onClick={() => onOpenTableModal(row)}
            >
              <p className="btn__text">Ver concorrência</p>
            </button>
          );
        };
      }
      if (field.tableProps.type === "link") {
        tableItem.cell = (row) => {
          return (
            <a className="table-link" target="_blank" href={row[field.id]} rel="noreferrer">
              Acessar link
            </a>
          );
        };
      }
      if (field.tableProps.type === "menu") {
        tableItem.cell = (row) => {
          return (
            <CellMenu
              props={{
                row: row,
                mainOptions: field.tableProps.menuMainOptions,
                subOptions: field.tableProps.menuSubOptions,
                pageType: pageType,
                menuItemClick: cellMenuItemClick,
                permissions: user.permissions,
                mainTypePermissions: field.tableProps.mainPermissions,
                subTypePermissions: field.tableProps.subPermissions,
              }}
            />
          );
        };
      }
      tableColumns[field.tableProps.order] = tableItem;
    }

    if (pageType === "users") {
      tableColumns[0] = {
        cell: (row) => (
          <Avatar
            classes={{ root: "profile-img" }}
            src={`data:image/jpeg;base64,${row.photo}`}
          >
            <User />
          </Avatar>
        ),
        width: "5.2rem",
        type: "avatar",
      };
    }
  });

  const searchColumns = tableColumns.filter(
    (column) => !(column.type === "avatar")
  );


  const [results, setResults] = useState([]);
  const [inputText, setInputText] = useState(
    pageType === "users"
      ? () => tableColumns.slice(1).map((tableColumn) => "")
      : () => tableColumns.map((tableColumn) => "")
  );
  const [hideColumn, setHideColumn] = useState(() =>
    tableColumns.map((tableColumn) => false)
  );
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [order, setOrder] = useState(config[pageType].initialOrder);
 
  const [sortBy, setSortBy] = useState(config[pageType].initialSort);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleClearRows, setToggleClearRows] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorColMenu, setAnchorColMenu] = useState(null);
  const [multiActionEl, setMultiActionEl] = useState(null);
  const [checkboxOptions, setCheckboxOptions] = useState(null);
  const [confirmMultiAction, setConfirmMultiAction] = useState(false);
  const [multiAction, setMultiAction] = useState(null);
  const [tableError, setTableError] = useState(false);
  const [reloadPage, setReloadPage] = useState(true);
  const [openPDFModal, setOpenPDFModal] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [pdfURL, setPDFurl] = useState(null);
  const [pdfContractNb, setPDFContractNb] = useState(null);
  const [certRow, setCertRow] = useState(null);
  const [pdfLoadingError, setPDFLoadingError] = useState(false);
  const { menuId } = useParams();
  const divSearch = useRef(null);
  const [logError, setLogError] = useState([]);
  const [openLogError, setOpenLogError] = useState(false);
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [openChangeDeadline, setOpenChangeDeadline] = useState(false);
  const [openDeadlinePicker, setOpenDeadlinePicker] = useState(false);
  const [openTableModal, setOpenTableModal] = useState(false);
  const [tableContents, setTableContents] = useState(null);
  const [winner, setWinner] = useState(null);
  const [downloadFileError, setDownloadFileError] = useState("");
  const [exportLoading, setExportLoading] = useState(false);
  const previousController = useRef(null);
  const [changeInput, setChangeInput] = useState(false);
  const requestAborted = useRef(false);
  const [certStatus, setCertStatus] = useState(null);
  const [openCertPicker, setOpenCertPicker] = useState(false);
  const [issuedDate, setIssuedDate] = useState(null);
  const [issuedTime, setIssuedTime] = useState(null);
  const [certLoading, setCertLoading] = useState(false);

  tableColumns.forEach((tableColumn, index) => {
    tableColumn.omit = hideColumn[index];
  });

  const onCreateItem = () => {
    //function to send the createItem event to google analytics
    event(`create_${pageType}_item`, "create_table_item", `${pageType}_item`);
    if (menuId) {
      history.push(`/contracts/${pageType}/${menuId}/associate`);
    } else {
      history.push(`/${pageType}/create`);
    }
  };

  const onFilter = (value, column, index) => {
    setChangeInput(true);
    let newState = [...inputText];
    newState[index] = value;
    setInputText(newState);
  };

  const onCloseDeadlineModal = () => {
    setOpenChangeDeadline(false);
    setOpenDeadlinePicker(false);
    setDeadlineInputDate(t("common.table.selectNewDate"));
    setDeadlineDate(new Date());
  };

  const controlDeadlinePicker = () => {
    setOpenDeadlinePicker(!openDeadlinePicker);
  };

  const controlDatePicker = () => {
    setOpenDatePicker(!openDatePicker);
  };

  const controlCertPicker = () => {
    setOpenCertPicker(!openCertPicker);
    if (!openCertPicker) {
      setCertDate(new Date());
      setCertInputDate(t("common.picker.certificateInput"));
    }
  };

  const handleDeadlineChange = (date) => {
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    const dateString = day + "/" + (month + 1) + "/" + year;

    setDeadlineDate(date);
    setDeadlineInputDate(dateString);
  };

  const handleDateChange = async (date) => {
    const options = { month: "short" };
    const tempMonth = new Intl.DateTimeFormat("pt-BR", options).format(date);
    const month = tempMonth.charAt(0).toUpperCase() + tempMonth.slice(1, 3);
    const year = date.getFullYear();
    const dateString = month + " " + year;
    setStartDate(dateString);
    setCalendarDate(date);
    controlDatePicker();
    const pageParams = {
      page: currentPage,
      perPage: perPage,
      sortBy: sortBy,
      order: order,
      menuId: menuId,
      date: date,
    };
    setLoading(true);
    try {
      const response = await getTableItems(getParams(pageParams));

      if (response && response.success) {
        const formattedResponse = formatData(
          response.data,
          tableColumns,
          pageType
        );
        setTotalRows(response.total);
        setCurrentPage(pageParams.page);
        setTotalRows(response.total);
        setResults(formattedResponse);
        setLoading(false);
      } else {
        toast.error(t("common.toast.operationError"), toastConfig);
        setLoading(false);
      }
    } catch (error) {
      toast.error(t("common.toast.operationError"), toastConfig);
      setLoading(false);
    }
  };

  const handleCertPeriodChange = (date) => {
    let month = date.getMonth() + 1;
    const year = date.getFullYear();

    if (month < 10) {
      month = "0" + month;
    }
    const dateString = month + "/" + year;
    setCertDate(date);
    setCertInputDate(dateString);
  };

  const onOpenCheckboxModal = (row) => {
    if (row.hasOwnProperty("permissions")) {
      let newPermissions = {};
      row.permissions.forEach((permission) => {
        const { module, resource, execute, ...permissionList } = permission;
        const filteredPermissions = Object.keys(permissionList)
          .filter((key) => permissionList[key] !== null)
          .reduce((obj, key) => {
            return {
              ...obj,
              [key]: permissionList[key],
            };
          }, {});

        if (permission.module !== permission.resource) {
          newPermissions[`${permission.module}-${permission.resource}`] =
            filteredPermissions;
        } else {
          newPermissions[permission.module] = filteredPermissions;
        }
      });

      setCheckboxOptions(newPermissions);
    }
    setCheckboxModal(true);
  };

  const onCloseCheckboxModal = () => {
    setCheckboxModal(false);
  };

  const onOpenTableModal = (row) => {
    setOpenTableModal(true);
    let newTable = [];
    row.competitors.forEach((competitor, index) => {
      let newCompetitor = {};
      if (competitor.winner) {
        setWinner(index);
      }
      modalTableConfig[pageType].forEach((column) => {
        newCompetitor[column] = competitor[column];
      });
      newTable.push(newCompetitor);
    });

    setTableContents(newTable);
  };

  const onCloseTableModal = () => {
    setOpenTableModal(false);
  };

  const cancelLogError = () => {
    setOpenLogError(false);
  };

  const openStatusModal = (row) => {
    getImportReportDetails(row.id).then((response) => {
      if (response.success) {
        setLogError(response.data.log_error);
        setOpenLogError(true);
      } else {
        toast.error(t("common.toast.logError"), toastConfig);
      }
    });
  };

  const saveCSV = (row) => {
    downloadCSV(row["filename"]).then((response) => {
      if (!response.includes('"success": false')) {
        let blob = new Blob([response], { type: "text/csv;charset=utf-8" });
        FileSaver.saveAs(blob, row["filename"]);
      } else {
        toast.error(t("common.toast.fileDownloadError"), toastConfig);
      }
    });
  };

  const openPDF = (row) => {
    let rowName = "";

    if (pageType === "contracts") {
      rowName = "filename";
      setPDFContractNb(
        t("common.modal.contractPDFTitle") + row["contract_number"]
      );
    } else if (pageType === "third_party_contracts") {
      rowName = "filename";
      setPDFContractNb(t("common.modal.generalPDFTitle"));
    } else if (pageType === "certificates") {
      rowName = "cert_id";
      setPDFContractNb(t("common.modal.generalPDFTitle"));
    } else {
      rowName = "document_filename";
      setPDFContractNb(t("common.modal.generalPDFTitle"));
    }

    setOpenPDFModal(true);

    downloadFile(row[rowName], pageType)
      .then(async (response) => {
        if (response.success !== undefined && !response.sucess) {
          setPDFLoadingError(true);
          setDownloadFileError(t(`common.downloadError.${response.type}`));
        } else {
          if (response.status === 200) {
            const headerType = response.headers.get("Content-Type");
            if (
              headerType === "application/pdf" ||
              headerType === "binary/octet-stream"
            ) {
              const blob = await response.blob();
              const file = new Blob([blob], { type: "application/pdf" });
              const fileURL = URL.createObjectURL(file);
              setPDFurl(fileURL);
            } else {
              setPDFLoadingError(true);
              setDownloadFileError(
                t("common.downloadError.document.error.default")
              );
            }
          } else {
            setPDFLoadingError(true);
            setDownloadFileError(
              t("common.downloadError.document.error.default")
            );
          }
        }
      })
      .catch((error) => {
        setPDFLoadingError(true);
        setDownloadFileError("common.downloadError.document.error.default");
      });
  };

  const onClosePDFModal = () => {
    setOpenPDFModal(false);
    setPDFLoadingError(false);
    setDownloadFileError("");
    URL.revokeObjectURL(pdfURL);
    setPDFContractNb(null);
    setPDFurl(null);
  };

  const onHideColumn = (index) => {
 
    //send filter event to google analytics
    event(
      `hide_${pageType}_columns`,
      "hide_table_column",
      `${pageType}_table_columns`
    );
    let newState = [...hideColumn];
    newState[index] = !hideColumn[index];
    if (pageType === "users" && index === 1) {
      newState[0] = !hideColumn[0];
    }
    setHideColumn(newState);
  };

  const newColumns = useMemo(() => [...tableColumns], [hideColumn, user]);

  const onShowMenu = (event) => {
    event.stopPropagation();
    setAnchorColMenu(event.currentTarget);
  };

  const handleCloseColMenu = (event) => {
    event.stopPropagation();
    setAnchorColMenu(null);
  };

  const onShowMultiActionMenu = (event) => {
    event.stopPropagation();
    setMultiActionEl(event.currentTarget);
  };

  const handleMultiActionMenu = (event) => {
    event.stopPropagation();
    setMultiActionEl(null);
  };

  const onShowExportMenu = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseExportMenu = () => {
    setAnchorEl(null);
  };

  const toggleSearch = () => {
    //send search event to google analytics
    event(`search_${pageType}`, "search_table", `${pageType}_table`);
    setOpenSearch(!openSearch);
  };

  const getParams = (pageParams) => {
    let search = [];

    inputText.forEach((input, index) => {
      if (input !== "") {
        search.push({ [searchColumns[index].id]: input });
      }
    });

    let params = {
      page: pageParams.page,
      perPage: pageParams.perPage,
      search: inputText.every((value) => value === "") ? [] : search,
      sortBy: pageParams.sortBy,
      order: pageParams.order,
      pageType: pageType,
      menuId: pageParams.menuId,
      date: pageParams.date,
    };

    if (pageParams.signal) {
      params = {
        ...params,
        signal: pageParams.signal,
      };
    }

    if (config[pageType].dateRef) {
      const currentMonth = pageParams.date.getMonth() + 1;
      const currentYear = pageParams.date.getFullYear();
      const options = { month: "short" };
      const tempMonth = new Intl.DateTimeFormat("pt-BR", options).format(
        pageParams.date
      );
      const month = tempMonth.charAt(0).toUpperCase() + tempMonth.slice(1, 3);
      const year = pageParams.date.getFullYear();
      const dateString = month + " " + year;
      setStartDate(dateString);
      params["month_ref"] = currentMonth;
      params["year_ref"] = currentYear;
    }

    return params;
  };

  const handlePageChange = async (page) => {
    setLoading(true);
    try {
      const pageParams = {
        page: page,
        perPage: perPage,
        sortBy: sortBy,
        order: order,
        menuId: menuId,
        date: calendarDate,
      };
      const response = await getTableItems(getParams(pageParams));

      if (response && response.success) {
        const formattedResponse = formatData(
          response.data,
          tableColumns,
          pageType
        );

        setCurrentPage(page);
        setResults(formattedResponse);
        setLoading(false);
      } else {
        toast.error(t("common.toast.operationError"), toastConfig);
        setLoading(false);
      }
    } catch (error) {
      toast.error(t("common.toast.operationError"), toastConfig);
      setLoading(false);
    }
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    try {
      const pageParams = {
        page: currentPage,
        perPage: newPerPage,
        sortBy: sortBy,
        order: order,
        menuId: menuId,
        date: calendarDate,
      };
      const response = await getTableItems(getParams(pageParams));
      if (response && response.success) {
        const formattedResponse = formatData(
          response.data,
          tableColumns,
          pageType
        );

        setResults(formattedResponse);
        setPerPage(newPerPage);
        setLoading(false);
      } else {
        toast.error(t("common.toast.operationError"), toastConfig);
        setLoading(false);
      }
    } catch (error) {
      toast.error(t("common.toast.operationError"), toastConfig);
      setLoading(false);
    }
  };

  const handleSort = async (column, sortDirection) => {
    setLoading(true);
    try {
      const pageParams = {
        page: currentPage,
        perPage: perPage,
        sortBy: column.selector,
        order: sortDirection,
        menuId: menuId,
        date: calendarDate,
      };
      const response = await getTableItems(getParams(pageParams));

      if (response && response.success) {
        const formattedResponse = formatData(
          response.data,
          tableColumns,
          pageType
        );

        setSortBy(column.selector);
        setOrder(sortDirection);
        setResults(formattedResponse);
        setLoading(false);
      } else {
        toast.error(t("common.toast.operationError"), toastConfig);
        setLoading(false);
      }
    } catch (error) {
      toast.error(t("common.toast.operationError"), toastConfig);
      setLoading(false);
    }
  };

  const handleSelectedRows = (allSelected) => {
    setSelectedRows(allSelected.selectedRows);
  };

  const onMultiAction = (event, action) => {
    if (action === "deadline") {
      setOpenChangeDeadline(true);
    } else {
      setConfirmMultiAction(true);
    }
    setMultiAction(action);
    handleMultiActionMenu(event);
  };

  const onConfirmMultiAction = async () => {
    setLoading(true);
    setConfirmMultiAction(false);
    onCloseDeadlineModal();
    let actionItems = [];
    let day = "";
    let month = "";
    let year = "";
    selectedRows.forEach((row) => {
      actionItems.push(row.id);
    });

    if (pageType === "accountability") {
      day = deadlineDate.getDate();
      month = deadlineDate.getMonth();
      year = deadlineDate.getFullYear();
    }

    let actionResponse = null;

    switch (multiAction) {
      case "delete":
        actionResponse = await deleteTableItems(pageType, actionItems);
        //send delete event to google analytics
        event(
          `delete_${pageType}_items`,
          "delete_table_items",
          `${pageType}_table_items`
        );
        break;
      case "activate":
        actionResponse = await updateStatusTableItem(pageType, {
          id: actionItems,
          status: true,
        });
        break;
      case "deactivate":
        actionResponse = await updateStatusTableItem(pageType, {
          id: actionItems,
          status: false,
        });
        break;
      case "deadline":
        actionResponse = await updateDeadline(pageType, {
          deadline: year + "-" + (month + 1) + "-" + day,
          id: actionItems,
        });
        break;
      default:
        actionResponse = null;
    }

    if (actionResponse && actionResponse.success) {
      const pageParams = {
        page: currentPage,
        perPage: perPage,
        sortBy: sortBy,
        order: order,
        menuId: menuId,
        date: calendarDate,
      };
      const response = await getTableItems(getParams(pageParams));
      if (response && response.success) {
        const formattedResponse = formatData(
          response.data,
          tableColumns,
          pageType
        );
        setTotalRows(response.total);
        setToggleClearRows(!toggleClearRows);
        setSelectedRows([]);
        setResults(formattedResponse);
        setLoading(false);
      } else {
        toast.error(t("common.toast.operationError"), toastConfig);
        setLoading(false);
      }
    } else {
      //TODO: Modificar para não ocorrer exceção para a página de plano de contas
      if (pageType === "chartOfAccounts") {
        toast.error(t("common.toast.deleteFailed"), toastConfig);
      } else {
        toast.error(t(`errors.${actionResponse.type}`), toastConfig);
      }
      setLoading(false);
    }
  };

  const onCancelMultiAction = () => {
    setConfirmMultiAction(false);
    setOpenChangeDeadline(false);
  };

  const onClickRow = (row) => {
    //send edit event to google analytics
    event(`edit_${pageType}_item`, "edit_table_item", `${pageType}_table_item`);
    if (pageType === "contracts" && row.is_addendum) {
      if (user.permissions[permissionsConfig["addendum"]].update) {
        history.push(`/contracts/addendum/edit/${row.id}`);
      }
    } else if (pageType === "organizations") {
      if (user.permissions.my_organization.read) {
        history.push(`/organizations/org-details/${row.id_org}`);
      }
    } else if (
      user.permissions[permissionsConfig[pageType]].update &&
      pageType !== "certificates"
    ) {
      history.push(`/${pageType}/edit/${row.id}`);
    }
  };

  const handleExport = async (type) => {
    //send export event to google analytics
    event(`export_${type}`, "export_table_data", `${type}`);

    const exportFunc = {
      excel: exportExcel,
      pdf: exportPDF,
      csv: exportCSV,
    };

    const newTableColumns = tableColumns.filter(
      (column) =>
        !(column.type === "avatar") && !(column.type === "checkboxOptions")
    );

    handleCloseExportMenu();
    setExportLoading(true);
    const params = {
      page: 1,
      perPage: null,
      search: [],
      sortBy: newTableColumns[0].id,
      order: "asc",
      pageType: pageType,
      menuId: menuId,
      date: calendarDate,
    };

    try {
      const response = await getTableItems(getParams(params));

      if (response.hasOwnProperty("url")) {
        fetch(response.url, {
          method: "GET",
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }).then(async (r) => {
          try {
            const altResponse = await r.json();
            const formattedResponse = formatData(
              altResponse.data,
              newTableColumns,
              pageType
            );
            let filter = [];
            newTableColumns.forEach((column) => {
              filter.push(column.id);
            });
            let tableResults = [];
            formattedResponse.forEach((item) => {
              const singleResult = filter.reduce(
                (acc, k) => ({
                  ...acc,
                  ...(item.hasOwnProperty(k) && { [k]: item[k] }),
                }),
                {}
              );
              tableResults.push(singleResult);
            });
            exportFunc[type](
              tableResults,
              newTableColumns,
              t(`${pageType}.exportTitle`)
            );
            setExportLoading(false);
          } catch (error) {
            toast.error(t("common.toast.tableExportFailed"), toastConfig);
            setExportLoading(false);
          }
        });
      } else if (response && response.success) {
        const formattedResponse = formatData(
          response.data,
          newTableColumns,
          pageType
        );
        let filter = [];
        newTableColumns.forEach((column) => {
          filter.push(column.id);
        });
        let tableResults = [];
        formattedResponse.forEach((item) => {
          const singleResult = filter.reduce(
            (acc, k) => ({
              ...acc,
              ...(item.hasOwnProperty(k) && { [k]: item[k] }),
            }),
            {}
          );
          tableResults.push(singleResult);
        });
        exportFunc[type](
          tableResults,
          newTableColumns,
          t(`${pageType}.exportTitle`)
        );
        setExportLoading(false);
      } else {
        toast.error(t("common.toast.tableExportFailed"), toastConfig);
        setExportLoading(false);
      }
    } catch (error) {
      toast.error(t("common.toast.tableExportFailed"), toastConfig);
      setExportLoading(false);
    }
  };

  const onReloadPage = () => {
    setReloadPage(true);
  };

  const openConfirmationModal = (row) => {
    setCertRow(row);
    setOpenConfirmation(true);
  };

  const closeConfirmationModal = () => {
    setOpenConfirmation(false);
    setTimeout(() => {
      setCertInputError(false);
      setCertRow(null);
      setCertStatus(null);
      setCertDate(new Date());
      setCertInputDate(t("common.picker.certificateInput"));
      setOpenCertPicker(false);
    }, 1500);
  };

  const onConfirmIssueCert = () => {
    setCertLoading(true);
    if (certInputDate === t("common.picker.certificateInput")) {
      setCertInputError(true);
    } else {
      const dataMonth = certDate.getMonth() + 1;
      const dataYear = certDate.getFullYear();
      const data = {
        contract_id: certRow.id,
        month_ref: dataMonth,
        year_ref: dataYear,
      };
      addTableItem("certificates", data)
        .then((response) => {
          if (response && response.success) {
            const operationCompleted = new Date();
            let day = operationCompleted.getDate();
            let month = operationCompleted.getMonth() + 1;
            let hour = operationCompleted.getHours();
            let minute = operationCompleted.getMinutes();

            if (day < 10) {
              day = "0" + day;
            }
            if (month < 10) {
              month = "0" + month;
            }
            if (minute < 10) {
              minute = "0" + minute;
            }
            setIssuedDate(day + "/" + month);
            setIssuedTime(hour + ":" + minute);
            setCertStatus("certificates.success");
          } else {
            if (
              response.message !== "certificates.already_exists" &&
              response.message !== "certificates.deadline_not_found"
            ) {
              setCertStatus("certificates.error");
            } else {
              setCertStatus(response.message);
            }
          }
          setCertLoading(false);
        })
        .catch((error) => {
          setCertStatus("certificates.error");
          setCertLoading(false);
        });
    }
  };

  const cellMenuItemClick = (row, pageType, option) => {
    if (option === "issue_certificate") {
      openConfirmationModal(row);
    } else {
      history.push(`${actionMenuConfig[pageType][option]}/${row.id}`);
    }
  };

  const goBack = () => {
    history.push(`/contracts`);
  };

  useEffect(() => {
    if (certInputDate !== t("common.picker.certificateInput")) {
      setCertInputError(false);
    }
  }, [certInputDate]);

  useEffect(() => {
    setLoading(true);
    document.title = t(`${pageType}.pageTitle`);
    const fetchData = async () => {
      if (reloadPage) {
        let formattedResponse = null;
        try {
          const pageParams = {
            page: 1,
            perPage: perPage,
            sortBy: sortBy,
            order: order,
            menuId: menuId,
            date: calendarDate,
          };
          const response = await getTableItems(getParams(pageParams));

          if (response && response.success) {
            formattedResponse = formatData(
              response.data,
              tableColumns,
              pageType
            );
          } else {
            setTableError(true);
            formattedResponse = [];
          }

          setReloadPage(false);
          setCurrentPage(1);
          setTotalRows(response.total);
          setResults(formattedResponse);
          setLoading(false);
        } catch (error) {
          setTableError(true);
          formattedResponse = [];
          setReloadPage(false);
          setResults(formattedResponse);
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [reloadPage, menuId, user]);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      if (changeInput) {
        let formattedResponse = null;
        if (previousController.current) {
          previousController.current.abort();
          requestAborted.current = true;
        }
        try {
          const controller = new AbortController();
          const signal = controller.signal;
          previousController.current = controller;
          const pageParams = {
            page: 1,
            perPage: perPage,
            sortBy: sortBy,
            order: order,
            menuId: menuId,
            date: calendarDate,
            signal: signal,
          };
          const response = await getTableItems(getParams(pageParams));

          if (response && response.success) {
            formattedResponse = formatData(
              response.data,
              tableColumns,
              pageType
            );
            requestAborted.current = false;
            previousController.current = null;
          } else {
            //não foi possível fazer testes se a condição de erro de tabela será atendida como planejado, portanto qualquer problema reportado sobre isso verificar essa condição
            if (!requestAborted.current) {
              setTableError(true);
            }
            formattedResponse = [];
          }

          if (!requestAborted.current) {
            setReloadPage(false);
            setCurrentPage(1);
            setTotalRows(response.total);
            setResults(formattedResponse);
            setLoading(false);
          } else {
            requestAborted.current = false;
          }
        } catch (error) {
          setTableError(true);
          formattedResponse = [];
          setReloadPage(false);
          setResults(formattedResponse);
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [inputText]);

  useEffect(() => {
    let newState = [...hideColumn];
    tableColumns.forEach((tableColumn, index) => {
      if (config[pageType].omittedColumns.includes(tableColumn.id)) {
        newState[index] = true;
      }
    });
    setHideColumn(newState);
  }, []);

  const horizontalScroll = (event) => {
    if (event.target.nodeName === "DIV") {
      if (divSearch !== null) {
        divSearch.current.scrollLeft = event.target.scrollLeft;
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", horizontalScroll, true);

    return () => {
      window.removeEventListener("scroll", horizontalScroll, true);
    };
  }, [divSearch]);


  return (
    <div className="tablepage-container">
      {Object.keys(user).length !== 0 && (
        <>
          {user.permissions[permissionsConfig[pageType]].read ? (
            <>
              <div className="tablepage-header">
                {menuId && (
                  <Button className="back-btn" onClick={goBack} type="button">
                    <ArrowBackIcon />
                  </Button>
                )}
                <div className="header-title">
                  <p className="tablepage-title">
                    {t(`${pageType}.pageTitle`)}
                  </p>
                  <p className="tablepage-subtitle">
                    {t(`${pageType}.subTitle`, { count: totalRows })}
                  </p>
                </div>
                {Object.keys(user).length !== 0 &&
                  user.permissions[permissionsConfig[pageType]].create && (
                    <Button
                      className="tablepage-create-btn"
                      onClick={() => onCreateItem()}
                    >
                      <div className="btn-icon-wrapper">
                        <IconSelector
                          name={config[pageType].newItemIcon.name}
                          type={config[pageType].newItemIcon.type}
                        />
                      </div>
                      {t(`${pageType}.btnNewItem`)}
                    </Button>
                  )}
              </div>
              <div
                className={
                  config[pageType].selectableRows
                    ? "tablepage-content"
                    : "tablepage-content table-no-highlight-hover"
                }
              >
                <DataTable
                  customStyles={tableCustomStyles}
                  noHeader
                  columns={newColumns}
                  data={results}
                  progressPending={loading}
                  progressComponent={
                    <div className="error-content--table">
                      <div className="message__container">
                        <CircularProgress
                          classes={{ root: "progress-loading" }}
                        />
                        <p className="title-message">
                          {t("common.table.loading")}
                        </p>
                      </div>
                    </div>
                  }
                  selectableRows={config[pageType].selectableRows}
                  subHeader
                  pagination
                  paginationServer
                  sortServer
                  pointerOnHover
                  highlightOnHover
                  selectableRowsHighlight
                  paginationIconNext={
                    <NavigateNextIcon classes={{ root: "icon-pagination" }} />
                  }
                  paginationIconPrevious={
                    <NavigateBeforeIcon classes={{ root: "icon-pagination" }} />
                  }
                  paginationIconFirstPage={
                    <FirstPageIcon classes={{ root: "icon-pagination" }} />
                  }
                  paginationIconLastPage={
                    <LastPageIcon classes={{ root: "icon-pagination" }} />
                  }
                  clearSelectedRows={toggleClearRows}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onSort={handleSort}
                  onSelectedRowsChange={handleSelectedRows}
                  onRowClicked={onClickRow}
                  paginationTotalRows={totalRows}
                  selectableRowsComponent={Checkbox}
                  selectableRowsComponentProps={{
                    indeterminate: (isIndeterminate) => isIndeterminate,
                  }}
                  sortIcon={<ArrowDownwardIcon />}
                  paginationComponentOptions={{
                    rowsPerPageText: t("common.rowsPerPageText"),
                    rangeSeparatorText: t("common.rangeSeparatorText"),
                    noRowsPerPage: false,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: "All",
                  }}
                  paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                  subHeaderAlign="left"
                  noDataComponent={
                    tableError ? (
                      <div className="error-content--table">
                        <ErrorMessage onReload={onReloadPage} />
                      </div>
                    ) : (
                      <div className="error-content--table">
                        <div className="message__container">
                          <Avatar classes={{ root: "nodata-content-icon" }}>
                            <XOctagon size={60} strokeWidth={0.5} />
                          </Avatar>
                          <p className="title-message">
                            {t("common.table.noData")}
                          </p>
                        </div>
                      </div>
                    )
                  }
                  subHeaderComponent={
                    <Accordion
                      expanded={openSearch}
                      classes={{ root: "accordion__tableHeader" }}
                    >
                      <AccordionSummary
                        classes={{ root: "accordion__summary-table" }}
                      >
                        <div className="tablepage-subheader">
                          {Object.keys(user).length !== 0 &&
                            (user.permissions[permissionsConfig[pageType]]
                              .delete ||
                              (user.permissions[permissionsConfig[pageType]]
                                .update &&
                                pageType !== "certificates") ||
                              (user.permissions.deadline.update &&
                                pageType === "accountability")) && (
                              <Button
                                classes={{
                                  root: Boolean(multiActionEl)
                                    ? "multiaction-menu-btn--open"
                                    : "multiaction-menu-btn",
                                }}
                                onClick={onShowMultiActionMenu}
                              >
                                <LabelOutlinedIcon />
                                {t("common.table.headerBtn.multiAction")}
                                <KeyboardArrowDownOutlinedIcon />
                              </Button>
                            )}
                          <Menu
                            keepMounted
                            anchorEl={multiActionEl}
                            open={Boolean(multiActionEl)}
                            onClose={handleMultiActionMenu}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            classes={{ paper: "export-menu-container" }}
                          >
                            {Object.keys(user).length !== 0 &&
                              user.permissions[permissionsConfig[pageType]]
                                .delete && (
                                <MenuItem
                                  classes={{ root: "export-menu-item" }}
                                  onClick={(event) =>
                                    onMultiAction(event, "delete")
                                  }
                                >
                                  {pageType === "units"
                                    ? t("common.dissociate")
                                    : t("common.delete")}
                                </MenuItem>
                              )}
                            {Object.keys(user).length !== 0 &&
                              user.permissions[permissionsConfig[pageType]]
                                .update &&
                              statusWhitelist.filter(
                                (page) => page === pageType
                              ).length !== 0 && (
                                <MenuItem
                                  classes={{ root: "export-menu-item" }}
                                  onClick={(event) =>
                                    onMultiAction(event, "activate")
                                  }
                                >
                                  {t(`${pageType}.actionItemUpdateTrue`)}
                                </MenuItem>
                              )}
                            {Object.keys(user).length !== 0 &&
                              user.permissions[permissionsConfig[pageType]]
                                .update &&
                              statusWhitelist.filter(
                                (page) => page === pageType
                              ).length !== 0 && (
                                <MenuItem
                                  classes={{ root: "export-menu-item" }}
                                  onClick={(event) =>
                                    onMultiAction(event, "deactivate")
                                  }
                                >
                                  {t(`${pageType}.actionItemUpdateFalse`)}
                                </MenuItem>
                              )}
                            {Object.keys(user).length !== 0 &&
                              user.permissions.deadline.update &&
                              pageType === "accountability" && (
                                <MenuItem
                                  classes={{ root: "export-menu-item" }}
                                  onClick={(event) =>
                                    onMultiAction(event, "deadline")
                                  }
                                >
                                  {t("common.table.changeDateLimit")}
                                </MenuItem>
                              )}
                          </Menu>
                          {config[pageType].dateRef !== undefined &&
                            config[pageType].dateRef && (
                              <div>
                                <Button
                                  classes={{
                                    root: openDatePicker
                                      ? "calendar-btn--open"
                                      : "calendar-btn",
                                  }}
                                  onClick={controlDatePicker}
                                >
                                  {" "}
                                  <DateRangeIcon /> {startDate}{" "}
                                  <KeyboardArrowDownOutlinedIcon />
                                </Button>
                                <DatePicker
                                  dateFormat="MM/yyyy"
                                  locale="pt"
                                  showMonthYearPicker
                                  showFourColumnMonthYearPicker
                                  open={openDatePicker}
                                  onClickOutside={controlDatePicker}
                                  onChange={handleDateChange}
                                  selected={calendarDate}
                                />
                              </div>
                            )}
                          <div className="table-controls-container">
                            <Button
                              disabled={exportLoading}
                              classes={{
                                root: Boolean(anchorEl)
                                  ? "export-menu-btn--open"
                                  : "export-menu-btn",
                              }}
                              onClick={onShowExportMenu}
                            >
                              {exportLoading ? (
                                <CircularProgress
                                  classes={{ root: "progress-export-loading" }}
                                  size="2rem"
                                />
                              ) : (
                                <>
                                  <ImportExportOutlinedIcon />
                                  <p className="btn__control-text">
                                    {t("common.table.headerBtn.export")}
                                  </p>
                                </>
                              )}
                            </Button>
                            <Menu
                              keepMounted
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={handleCloseExportMenu}
                              getContentAnchorEl={null}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              classes={{ paper: "export-menu-container" }}
                            >
                              <MenuItem
                                classes={{ root: "export-menu-item" }}
                                onClick={() => handleExport("excel")}
                              >
                                {t("common.exportExcel")}
                              </MenuItem>
                              <MenuItem
                                classes={{ root: "export-menu-item" }}
                                onClick={() => handleExport("csv")}
                              >
                                {t("common.exportCSV")}
                              </MenuItem>
                              <MenuItem
                                classes={{ root: "export-menu-item" }}
                                onClick={() => handleExport("pdf")}
                              >
                                {t("common.exportPDF")}
                              </MenuItem>
                            </Menu>
                            <Button
                              classes={{
                                root: Boolean(anchorColMenu)
                                  ? "export-menu-btn--open"
                                  : "export-menu-btn",
                              }}
                              onClick={onShowMenu}
                            >
                              <p className="btn__text-inverted">
                                {t("common.table.headerBtn.showColumns")}
                              </p>
                              <ExpandMoreIcon />
                            </Button>
                            <Menu
                              keepMounted
                              anchorEl={anchorColMenu}
                              open={Boolean(anchorColMenu)}
                              onClose={handleCloseColMenu}
                              getContentAnchorEl={null}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              classes={{ paper: "filter-menu-container" }}
                            >
                              {tableColumns.flatMap((column, index) =>
                                column.type === "avatar" ? null : (
                                  <ListItem
                                    classes={{ root: "export-menu-item" }}
                                    key={index}
                                    onClick={() => onHideColumn(index)}
                                  >
                                    <Checkbox
                                      classes={{ root: "checkbox" }}
                                      checked={!hideColumn[index]}
                                    />
                                    {t(`${pageType}.fields.${column.id}`)}
                                  </ListItem>
                                )
                              )}
                            </Menu>
                            <Button
                              classes={{
                                root: openSearch
                                  ? "export-menu-btn--open"
                                  : "export-menu-btn",
                              }}
                              onClick={toggleSearch}
                            >
                              <SearchIcon />
                              <p className="btn__control-text">
                                {t("common.table.headerBtn.search")}
                              </p>
                            </Button>
                          </div>
                          <Dialog
                            open={openChangeDeadline}
                            onClose={onCloseDeadlineModal}
                            classes={{ paper: "modal__body" }}
                          >
                            <DialogTitle classes={{ root: "dialog__title" }}>
                              <div className="container__modal-header">
                                <Tooltip
                                  title={t("common.tooltip.close")}
                                  classes={{
                                    tooltip: "tooltip",
                                  }}
                                  placement="bottom-start"
                                >
                                  <button
                                    type="button"
                                    className="btn__close"
                                    onClick={onCloseDeadlineModal}
                                  >
                                    <CloseIcon
                                      classes={{
                                        root: "btn__modal-icon--close",
                                      }}
                                    />
                                  </button>
                                </Tooltip>
                                <p className="modal__header-title">
                                  {t("common.table.changeDateLimit")}
                                </p>
                              </div>
                            </DialogTitle>
                            <DialogContent
                              classes={{ root: "content-container-modal" }}
                            >
                              {selectedRows.length === 0 ? (
                                <>
                                  <p className="title-modal">
                                    {t("common.noSelection")}
                                  </p>
                                  <Button
                                    type="button"
                                    className="modal-btn"
                                    onClick={onCancelMultiAction}
                                  >
                                    {t("common.okBtn")}
                                  </Button>
                                </>
                              ) : (
                                <div className="align-container">
                                  <div className="deadline-container">
                                    <p className="deadline-title">
                                      {t("common.table.dateLimit")}
                                    </p>
                                    <Button
                                      classes={{ root: "btn-deadline-input" }}
                                      onClick={controlDeadlinePicker}
                                    >
                                      {deadlineInputDate}
                                      {openDeadlinePicker ? (
                                        <KeyboardArrowUpOutlinedIcon
                                          classes={{ root: "arrow-deadline" }}
                                        />
                                      ) : (
                                        <KeyboardArrowDownOutlinedIcon
                                          classes={{ root: "arrow-deadline" }}
                                        />
                                      )}
                                    </Button>
                                    {openDeadlinePicker && (
                                      <DatePicker
                                        locale="pt"
                                        onChange={handleDeadlineChange}
                                        selected={deadlineDate}
                                        inline
                                        renderCustomHeader={({
                                          date,
                                          changeYear,
                                          changeMonth,
                                        }) => (
                                          <div className="header-deadline">
                                            <div className="deadline-select-container">
                                              <Select
                                                classes={{
                                                  root: "deadline-select-month",
                                                }}
                                                value={date.getMonth()}
                                                onChange={({
                                                  target: { value },
                                                }) => changeMonth(value)}
                                                IconComponent={
                                                  KeyboardArrowDownOutlinedIcon
                                                }
                                                MenuProps={{
                                                  anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left",
                                                  },
                                                  transformOrigin: {
                                                    vertical: "top",
                                                    horizontal: "left",
                                                  },
                                                  getContentAnchorEl: null,
                                                  PaperProps: {
                                                    style: {
                                                      maxHeight: `${
                                                        5.4 * 4
                                                      }rem`,
                                                    },
                                                  },
                                                }}
                                              >
                                                {deadlineMonths.map(
                                                  (monthOption, index) => (
                                                    <MenuItem
                                                      key={monthOption}
                                                      value={index}
                                                      className="select-menu-item"
                                                    >
                                                      {monthOption}
                                                    </MenuItem>
                                                  )
                                                )}
                                              </Select>
                                            </div>
                                            <div className="deadline-select-container">
                                              <Select
                                                classes={{
                                                  root: "deadline-select-year",
                                                }}
                                                value={date.getFullYear()}
                                                onChange={({
                                                  target: { value },
                                                }) => changeYear(value)}
                                                IconComponent={
                                                  KeyboardArrowDownOutlinedIcon
                                                }
                                                MenuProps={{
                                                  anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left",
                                                  },
                                                  transformOrigin: {
                                                    vertical: "top",
                                                    horizontal: "left",
                                                  },
                                                  getContentAnchorEl: null,
                                                  PaperProps: {
                                                    style: {
                                                      maxHeight: `${
                                                        5.4 * 4
                                                      }rem`,
                                                    },
                                                  },
                                                }}
                                              >
                                                {deadlineYears.map(
                                                  (yearOption) => (
                                                    <MenuItem
                                                      key={yearOption}
                                                      value={yearOption}
                                                      className="select-menu-item"
                                                    >
                                                      {yearOption}
                                                    </MenuItem>
                                                  )
                                                )}
                                              </Select>
                                            </div>
                                          </div>
                                        )}
                                      />
                                    )}
                                  </div>
                                  <Button
                                    type="button"
                                    className="modal-btn"
                                    onClick={onConfirmMultiAction}
                                  >
                                    {t("common.saveItem")}
                                  </Button>
                                </div>
                              )}
                            </DialogContent>
                          </Dialog>
                          <Dialog
                            open={openCheckboxModal}
                            onClose={onCloseCheckboxModal}
                            scroll="paper"
                            classes={{ paper: "modal__body" }}
                          >
                            <DialogTitle classes={{ root: "dialog__title" }}>
                              <div className="container__modal-header">
                                <Tooltip
                                  title={t("common.tooltip.close")}
                                  classes={{
                                    tooltip: "tooltip",
                                  }}
                                  placement="bottom-start"
                                >
                                  <button
                                    type="button"
                                    className="btn__close"
                                    onClick={onCloseCheckboxModal}
                                  >
                                    <CloseIcon
                                      classes={{
                                        root: "btn__modal-icon--close",
                                      }}
                                    />
                                  </button>
                                </Tooltip>
                                <p className="modal__header-title">
                                  {t("common.modal.permissions")}
                                </p>
                              </div>
                            </DialogTitle>
                            <DialogContent classes={{ root: "modal__content" }}>
                              {checkboxOptions !== null &&
                                Object.keys(checkboxOptions).map((category) => (
                                  <div key={category}>
                                    <p className="modal__category-title">
                                      {t(
                                        `${pageType}.checkboxOptions.${category}.title`
                                      )}
                                    </p>
                                    <div className="modal__item-list-container">
                                      {Object.keys(
                                        checkboxOptions[category]
                                      ).map((option) => (
                                        <div
                                          key={option}
                                          className={
                                            checkboxOptions[category][option]
                                              ? "modal__item--selected"
                                              : "modal__item"
                                          }
                                        >
                                          <p
                                            className={
                                              checkboxOptions[category][option]
                                                ? "modal__item-text--selected"
                                                : "modal__item-text"
                                            }
                                          >
                                            {t(
                                              `${pageType}.checkboxOptions.${category}.${option}`
                                            )}
                                          </p>
                                          <Checkbox
                                            checked={
                                              checkboxOptions[category][option]
                                            }
                                            classes={{
                                              root: "checkbox--right",
                                            }}
                                          />
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                ))}
                            </DialogContent>
                          </Dialog>
                          <Dialog
                            open={openPDFModal}
                            onClose={onClosePDFModal}
                            scroll="paper"
                            classes={{ paper: "modal__body-alt" }}
                          >
                            <DialogTitle classes={{ root: "dialog__title" }}>
                              <div className="container__modal-header">
                                <Tooltip
                                  title={t("common.tooltip.close")}
                                  classes={{
                                    tooltip: "tooltip",
                                  }}
                                  placement="bottom-start"
                                >
                                  <button
                                    type="button"
                                    className="btn__close"
                                    onClick={onClosePDFModal}
                                  >
                                    <CloseIcon
                                      classes={{
                                        root: "btn__modal-icon--close",
                                      }}
                                    />
                                  </button>
                                </Tooltip>
                                <p className="modal__header-title">
                                  {pdfContractNb}
                                </p>
                              </div>
                            </DialogTitle>
                            <DialogContent
                              classes={{ root: "modal__content-alt" }}
                            >
                              {pdfURL === null && !pdfLoadingError ? (
                                <div className="loading-text-container">
                                  <p className="loading-text">
                                    {t("common.modal.loadingPDF")}
                                  </p>
                                  <CircularProgress
                                    classes={{ root: "progress-loading" }}
                                  />{" "}
                                </div>
                              ) : pdfLoadingError ? (
                                <div className="error-loading-container">
                                  <Avatar
                                    classes={{ root: "dashboard-content-icon" }}
                                  >
                                    <Frown size={72} strokeWidth={0.5} />
                                  </Avatar>
                                  <p className="error-loading-text">
                                    {downloadFileError}
                                  </p>
                                </div>
                              ) : (
                                <PDFObject url={pdfURL} />
                              )}
                            </DialogContent>
                          </Dialog>
                          <Dialog
                            open={confirmMultiAction}
                            onClose={onCancelMultiAction}
                            classes={{
                              root: "modal__backdrop",
                              paper: "modal__multiAction",
                            }}
                          >
                            <Tooltip
                              title={t("common.tooltip.close")}
                              classes={{
                                tooltip: "tooltip",
                              }}
                              placement="bottom-start"
                            >
                              <button
                                className="btn__close"
                                onClick={onCancelMultiAction}
                              >
                                <CloseIcon
                                  classes={{ root: "btn__modal-icon--close" }}
                                />
                              </button>
                            </Tooltip>
                            <div className="content-modal">
                              {selectedRows.length === 0 ? (
                                <>
                                  <p className="title-modal">
                                    {t("common.noSelection")}
                                  </p>
                                  <Button
                                    type="button"
                                    className="modal-btn"
                                    onClick={onCancelMultiAction}
                                  >
                                    {t("common.okBtn")}
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <p className="title-modal">
                                    {t(
                                      `${pageType}.multiAction.${multiAction}`,
                                      { count: selectedRows.length }
                                    )}
                                  </p>
                                  <Button
                                    type="button"
                                    className="btn-confirm"
                                    onClick={onConfirmMultiAction}
                                  >
                                    {menuId
                                      ? t(`${pageType}.${multiAction}`)
                                      : t(`common.${multiAction}`)}
                                  </Button>
                                  <Button
                                    type="button"
                                    className="btn-cancel"
                                    onClick={onCancelMultiAction}
                                  >
                                    {t(`common.cancelItem`)}
                                  </Button>
                                </>
                              )}
                            </div>
                          </Dialog>
                          <Dialog
                            open={openConfirmation}
                            onClose={closeConfirmationModal}
                            classes={{
                              root: "modal__backdrop",
                              paper: "modal__multiAction",
                            }}
                          >
                            <Tooltip
                              title={t("common.tooltip.close")}
                              classes={{
                                tooltip: "tooltip",
                              }}
                              placement="bottom-start"
                            >
                              <button
                                className="btn__close"
                                onClick={closeConfirmationModal}
                              >
                                <CloseIcon
                                  classes={{ root: "btn__modal-icon--close" }}
                                />
                              </button>
                            </Tooltip>
                            {certStatus === null && (
                              <div className="content-modal content-modal--larger-content">
                                <p className="title-modal">
                                  {t("certificates.modalText", {
                                    contract: certRow
                                      ? certRow.contract_number
                                      : null,
                                  })}
                                </p>
                                <div className="align-container">
                                  <p className="deadline-title">
                                    {t("certificates.modalField")}
                                  </p>
                                  <Button
                                    classes={{ root: "btn-deadline-input" }}
                                    onClick={controlCertPicker}
                                  >
                                    {certInputDate}
                                    {openCertPicker ? (
                                      <KeyboardArrowUpOutlinedIcon
                                        classes={{ root: "arrow-deadline" }}
                                      />
                                    ) : (
                                      <KeyboardArrowDownOutlinedIcon
                                        classes={{ root: "arrow-deadline" }}
                                      />
                                    )}
                                  </Button>
                                  {certInputError && (
                                    <div className="input-feedback">
                                      <AlertCircle size={18} />
                                      <p className="error-text">
                                        {t("common.picker.certInputError")}
                                      </p>
                                    </div>
                                  )}
                                </div>
                                <div className="cert-picker-container">
                                  {openCertPicker && (
                                    <DatePicker
                                      selected={certDate}
                                      onChange={(date) =>
                                        handleCertPeriodChange(date)
                                      }
                                      locale="pt"
                                      showMonthYearPicker
                                      showFourColumnMonthYearPicker
                                      inline
                                    />
                                  )}
                                </div>
                                <Button
                                  type="button"
                                  className="btn-confirm"
                                  onClick={onConfirmIssueCert}
                                  disabled={certLoading}
                                >
                                  {t(`common.confirmItem`)}
                                  {certLoading && (
                                    <CircularProgress
                                      classes={{ root: "btn__loading" }}
                                      size="2rem"
                                    />
                                  )}
                                </Button>
                                <Button
                                  type="button"
                                  className="btn-cancel"
                                  onClick={closeConfirmationModal}
                                >
                                  {t(`common.cancelItem`)}
                                </Button>
                              </div>
                            )}
                            {(certStatus === "certificates.success" ||
                              certStatus === "certificates.error") &&
                              !certLoading && (
                                <div className="modal-content--align-left">
                                  <div
                                    className={
                                      certStatus === "certificates.error"
                                        ? "icon-wrapper--error"
                                        : "icon-wrapper"
                                    }
                                  >
                                    <IconSelector
                                      name={
                                        certStatus === "certificates.error"
                                          ? "AlertTriangle"
                                          : "CheckCircle"
                                      }
                                      type="feather-icon"
                                    />
                                  </div>
                                  <div className="modal-text-container">
                                    <p className="modal-title--align-left">
                                      {t(`${certStatus}.title`)}
                                    </p>
                                    <p>
                                      {certStatus === "certificates.success"
                                        ? t(`${certStatus}.text`, {
                                            issuedDate: issuedDate,
                                            issuedTime: issuedTime,
                                          })
                                        : t(`${certStatus}.text`)}
                                    </p>
                                  </div>
                                  <Button
                                    className="btn-confirm"
                                    type="button"
                                    onClick={closeConfirmationModal}
                                  >
                                    {t("common.okBtn")}
                                  </Button>
                                </div>
                              )}
                            {(certStatus === "certificates.already_exists" ||
                              certStatus ===
                                "certificates.deadline_not_found") &&
                              !certLoading && (
                                <div className="content-modal">
                                  <p className="modal-title">
                                    {t(`${certStatus}.text`)}
                                  </p>
                                  <Button
                                    className="btn-confirm"
                                    type="button"
                                    onClick={closeConfirmationModal}
                                  >
                                    {t("common.okBtn")}
                                  </Button>
                                </div>
                              )}
                          </Dialog>
                          <Dialog
                            open={openLogError}
                            onClose={cancelLogError}
                            scroll="paper"
                            classes={{
                              root: "modal__backdrop",
                              paper: "modal__body-alt",
                            }}
                          >
                            <DialogTitle classes={{ root: "dialog__title" }}>
                              <div className="container__modal-header">
                                <Tooltip
                                  title={t("common.tooltip.close")}
                                  classes={{ tooltip: "tooltip" }}
                                  placement="bottom-start"
                                >
                                  <button
                                    type="button"
                                    className="btn__close"
                                    onClick={cancelLogError}
                                  >
                                    <CloseIcon
                                      classes={{
                                        root: "btn__modal-icon--close",
                                      }}
                                    />
                                  </button>
                                </Tooltip>
                                <p className="modal__header-title">
                                  {t("common.modal.importingErrors")}
                                </p>
                              </div>
                            </DialogTitle>
                            <DialogContent classes={{ root: "modal__content" }}>
                              <div className="container-error-log">
                                <TableContainer
                                  classes={{ root: "log-error-table" }}
                                >
                                  <Table>
                                    <TableBody>
                                      {Array.isArray(logError)
                                        ? logError.length !== 0 &&
                                          logError.map((item, index) => (
                                            <TableRow key={index}>
                                              <TableCell
                                                classes={{
                                                  root:
                                                    index ===
                                                    logError.length - 1
                                                      ? "table-cell-last-line"
                                                      : "table-cell",
                                                }}
                                              >
                                                <p className="table-cell-text">
                                                  {t(
                                                    `importData.importErrors.${item.field}`
                                                  )}
                                                </p>
                                              </TableCell>
                                            </TableRow>
                                          ))
                                        : Object.values(logError).map(
                                            (item, index) => (
                                              <TableRow key={index}>
                                                <TableCell
                                                  classes={{
                                                    root:
                                                      index === 0
                                                        ? "first-column first-line result-first-column"
                                                        : index ===
                                                          Object.values(
                                                            logError
                                                          ).length -
                                                            1
                                                        ? "first-column last-line result-first-column"
                                                        : "first-column result-first-column",
                                                  }}
                                                >
                                                  <p className="first-column-text">
                                                    {t("common.modal.line") +
                                                      (index + 1)}
                                                  </p>
                                                </TableCell>
                                                <TableCell
                                                  classes={{
                                                    root:
                                                      index ===
                                                      Object.values(logError)
                                                        .length -
                                                        1
                                                        ? "table-cell-last-line"
                                                        : "table-cell",
                                                  }}
                                                >
                                                  {item.type.map((error, i) => (
                                                    <p
                                                      key={i}
                                                      className="importError-text"
                                                    >
                                                      {t(
                                                        `importData.importErrors.${error}`
                                                      )}
                                                    </p>
                                                  ))}
                                                </TableCell>
                                              </TableRow>
                                            )
                                          )}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </div>
                            </DialogContent>
                          </Dialog>
                          <Dialog
                            open={openTableModal}
                            onClose={onCloseTableModal}
                            scroll="paper"
                            classes={{
                              root: "modal__backdrop",
                              paper: "modal__body-alt",
                            }}
                          >
                            <DialogTitle>
                              <div className="container__modal-header">
                                <Tooltip
                                  title={t("common.tooltip.close")}
                                  classes={{
                                    tooltip: "tooltip",
                                  }}
                                  placement="bottom-start"
                                >
                                  <button
                                    type="button"
                                    className="btn__close"
                                    onClick={onCloseTableModal}
                                  >
                                    <CloseIcon
                                      classes={{
                                        root: "btn__modal-icon--close",
                                      }}
                                    />
                                  </button>
                                </Tooltip>
                                <p className="modal__header-title">
                                  {t("common.modal.competitors")}
                                </p>
                              </div>
                            </DialogTitle>
                            <DialogContent classes={{ root: "modal__content" }}>
                              {tableContents !== null &&
                              tableContents.length !== 0 ? (
                                <TableContainer>
                                  <Table>
                                    <TableBody>
                                      <TableRow>
                                        {Object.keys(tableContents[0]).map(
                                          (key, idx) => (
                                            <TableCell
                                              classes={{
                                                root: "table-cell-title",
                                              }}
                                              key={key}
                                            >
                                              <p className="table-title-text">
                                                {t(
                                                  `${pageType}.tableColumns.${key}`
                                                )}
                                              </p>
                                            </TableCell>
                                          )
                                        )}
                                      </TableRow>
                                      {tableContents.map((item, index) => (
                                        <TableRow
                                          classes={{
                                            root:
                                              winner === index
                                                ? "table-row-winner"
                                                : "",
                                          }}
                                          key={index}
                                        >
                                          {Object.values(item).map(
                                            (value, i) => (
                                              <TableCell key={value + i}>
                                                <p
                                                  className={
                                                    winner === index
                                                      ? "table-cell-winner"
                                                      : "table-modal-text"
                                                  }
                                                >
                                                  {i ===
                                                  Object.values(item).length - 1
                                                    ? convertValue(value)
                                                    : value}
                                                </p>
                                              </TableCell>
                                            )
                                          )}
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              ) : null}
                            </DialogContent>
                          </Dialog>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails
                        classes={{
                          root:
                            pageType === "users" && !hideColumn[1]
                              ? "accordion__details-table--users"
                              : config[pageType].selectableRows
                              ? "accordion__details-table"
                              : "accordion__details-table table-header-no-padding",
                        }}
                        ref={divSearch}
                      >
                        {searchColumns.map((column, index) => (
                          <div
                            key={index}
                            className={
                              (
                                pageType === "users"
                                  ? !hideColumn[index + 1]
                                  : !hideColumn[index]
                              )
                                ? "container-search-column" +
                                  (!config[pageType].selectableRows
                                    ? " table-search-width"
                                    : "")
                                : "container-search-column--hidden"
                            }
                          >
                            {(pageType === "users"
                              ? !hideColumn[index + 1]
                              : !hideColumn[index]) && (
                              <div
                                className={
                                  column.searchable
                                    ? "search-input-column"
                                    : "search-input-column--hidden"
                                }
                              >
                                <SearchIcon
                                  classes={{ root: "icon__search-input" }}
                                />
                                <input
                                  className="search-input"
                                  id={index}
                                  value={inputText[index]}
                                  placeholder={t(
                                    `${pageType}.searchPlaceholder.${column.id}`
                                  )}
                                  onChange={(e) =>
                                    onFilter(e.target.value, column.id, index)
                                  }
                                />
                              </div>
                            )}
                          </div>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  }
                />
              </div>
            </>
          ) : (
            <Redirect to="/" />
          )}
        </>
      )}
    </div>
  );
}

export default TablePage;
