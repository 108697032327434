import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';

function CheckboxItem({props}) {
	const { t } = useTranslation();
	const { field, values, pageType, handleChange, disabled, setFieldValue } = props;

	useEffect(() => {
		if (values[field.id] !== false && field.conditionalVisibility) {
			if (values[field.parentVisibility] !== field.visibilityReq) {
				setFieldValue(field.id,false)
			}
		}

	},[values[field.parentVisibility]])

	return (
		<>
		{
			field.conditionalVisibility ? 
			(
				values[field.parentVisibility] === field.visibilityReq ?
				(
				<div className='container__checkbox'>
					<Checkbox
					 	id={field.id}
						name={field.id}
						checked={values[field.id]}
						onChange={handleChange}
						classes={{root: 'checkbox'}}
						disabled={disabled}
					/>
					<p className='checkbox__text'>{t(`${pageType}.fields.${field.id}`)}</p>
				</div>	
				) :
				null
			) : 
			<div className='container__checkbox'>
				<Checkbox
				 	id={field.id}
					name={field.id}
					checked={values[field.id]}
					onChange={handleChange}
					classes={{root: 'checkbox'}}
					disabled={disabled}
				/>
				<p className='checkbox__text'>{t(`${pageType}.fields.${field.id}`)}</p>
			</div>	
		}
		</>
	)
}

export default CheckboxItem