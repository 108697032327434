import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
} from "react-router-dom";
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';

import App from './js/components/App';
import './js/i18n/i18n';
import reducer from './js/reducers';
import middleware from './js/middleware';

ReactDOM.render(
	<CookiesProvider>
		<Provider store={(createStore(reducer,middleware))}>
			<Router
				getUserConfirmation={(dialogKey, callback) => {
					const dialogTrigger = window[Symbol.for(dialogKey)]

					if (dialogTrigger) {
					 	return dialogTrigger(callback)
					}

					callback(true)
				}}
			>
				<App />
			</Router>
		</Provider>
	</CookiesProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
