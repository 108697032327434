import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { toast } from 'react-toastify';
import IconSelector from '../IconSelector';
import { toastConfig }  from '../../data/tableConfig';

function UploadImgInput({props}) {
	const { field, values, setFieldValue, disabled} = props;
	const handleImageUpload = (event, fieldId) => {
		if (event.target.files[0]) {
			if (event.target.files[0].type === 'image/jpg' || event.target.files[0].type === 'image/png' || event.target.files[0].type === 'image/jpeg') {
				let reader = new FileReader();
				reader.onloadend = function() {
				  setFieldValue(fieldId, reader.result.split(',')[1])
				}
				reader.readAsDataURL(event.target.files[0])
			} else {
				toast.error('Extensão de imagem inválida', toastConfig);
			}
		}
	}


	return (
		<div className='avatar-input-container'>
			<div className='avatar-container'>
				{
					(!values[field.id]) ? 
					(
						<div className='placeholder-container'>
							<IconSelector name='Camera' type='feather-icon'/>
						</div>
					) :
					(
						<Avatar src={`data:image/jpeg;base64,${values[field.id]}`} classes={{root: 'avatar-img'}}/>
					)
				}
			</div>
			<label htmlFor='userAvatar' className='upload-avatar-label'>Adicionar foto de perfil</label>
			<input type='file' accept='image/png, image/jpeg, image/jpg' id='userAvatar' disabled={disabled} className='visually-hidden' onChange={(event) => handleImageUpload(event,field.id)}></input>
		</div>
	)
}

export default UploadImgInput