function PurchaseProcessText() {
	return (
		<div>
			<p className='instructions-title'>Instruções para Importação de Processo de Compra</p>
			<div>
				<p className='instructions-text'>Cada linha a ser importada é referente a um concorrente em um processo de compras. Os dados que são comuns entre todos os concorrentes, como identificador do processo, objeto, modalidade, etc., devem ser replicados em todas as linhas para cada um dos fornecedores concorrentes.</p>
				<p className='instructions-text'>Os fornecedores devem estar previamente cadastrados no sistema.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: ORGANIZAÇÃO (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o código da ORGANIZAÇÃO a que se refere o Processo de Compra. Deve conter apenas números. Será verificada sua existência na tabela do sistema de ORGANIZAÇÕES, bem como se a mesma está ativa. Este código deve ser o mesmo que o do usuário que está fazendo a importação, caso contrário, haverá um erro.</p>
				<p className='instructions-text'>Exemplo: 123, 999, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: MODALIDADE (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o código da MODALIDADE do referido Processo de Compra. Deve conter apenas números. Será verificada sua existência na tabela do sistema de MODALIDADES, bem como se a mesma está ativa. </p>
				<p className='instructions-text'>Exemplo: 1, 10, 999, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: NÚMERO DO PROCESSO (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o número interno do processo do referido Processo de Compra da Organização.</p>
				<p className='instructions-text'>Exemplo: 15/2022, A2022P15, 025-2021, etc.</p>
				<p className='instructions-text'>Pode ter no máximo 255 caracteres.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: LINK DA CHAMADA (NÂO OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o link (URL) público da chamada do PROCESSO.</p>
				<p className='instructions-text'>Exemplo: http://www.organizacao.com.br/link/processo-numero-xpto, https://nome_organizacao.org.br/pregao-eletronico-no-001-2022, etc.</p>
				<p className='instructions-text'>Pode ter no máximo 255 caracteres.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: OBJETO (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o OBJETO do processo de compra.</p>
				<p className='instructions-text'>Exemplo: Contratação de empresa licenciada para a prestação de serviços de coleta, transporte, tratamento e disposição final de Resíduos dos Serviços de Saúde (resíduo hospitalar), etc.</p>
				<p className='instructions-text'>Pode ter no máximo 999 caracteres.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: DATA DE LANÇAMENTO (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É a DATA DE LANÇAMENTO do processo de compra. Deve ter o formato AAAA-MM-DD, onde AAAA é o ano, MM é o mês e DD é o dia. O separador das datas tem que ser o -.</p>
				<p className='instructions-text'>Exemplo: 2022-02-05, 2020-10-25, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: DATA DE RESULTADO (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É a DATA DE RESULTADO do processo de compra. Deve ter o formato AAAA-MM-DD, onde AAAA é o ano, MM é o mês e DD é o dia. O separador das datas tem que ser o -.</p>
				<p className='instructions-text'>Exemplo: 2022-02-05, 2020-10-25, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: DOCUMENTO DA CHAMADA (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o nome do arquivo de imagem do documento da chamada para o referido Processo de Compras. Será verificado se o mesmo já existe no cadastro de documentos do sistema. Atenção para que o nome do arquivo seja EXATAMENTE o mesmo do que foi importado.</p>
				<p className='instructions-text'>Verifique se não há espaços extras e não use caracteres especiais (*/&, etc.). </p>
				<p className='instructions-text'>Não esqueça de colocar a extensão.</p>
				<p className='instructions-text'>Pode ter no máximo 150 caracteres.</p>
				<p className='instructions-text'>Exemplo: nome_do_arquivo.pdf, nomeDoArquivo.pdf, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: DOCUMENTO DO RESULTADO (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o nome do arquivo de imagem do documento do resultado da chamada para o referido Processo de Compras. Será verificado se o mesmo já existe no cadastro de documentos do sistema. Atenção para que o nome do arquivo seja EXATAMENTE o mesmo do que foi importado.</p>
				<p className='instructions-text'>Verifique se não há espaços extras e não use caracteres especiais (*/&, etc.). </p>
				<p className='instructions-text'>Não esqueça de colocar a extensão.</p>
				<p className='instructions-text'>Pode ter no máximo 150 caracteres.</p>
				<p className='instructions-text'>Exemplo: nome_do_arquivo.pdf, nomeDoArquivo.pdf, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: CNPJ OU CPF DO FORNECEDOR (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o CNPJ ou o CPF do fornecedor que participa do processo de compra.</p>
				<p className='instructions-text'>Caso seja Pessoa Jurídica, o CNPJ tem que ser informado no formato 99.999.999/9999-99.</p>
				<p className='instructions-text'>Caso seja Pessoa Física, o CPF tem que ser informado no formato 999.999.999-99.</p>
				<p className='instructions-text'>Também será verificada sua validade.</p>
				<p className='instructions-text'>Exemplo: 99.999.999/0001-99 (CNPJ), 999.999.999-99 (CPF), etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: PREÇO DO FORNECEDOR (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o valor nominal do preço praticado pelo fornecedor no âmbito do respectivo processo de compra. Poderá ter a VÍRGULA ou PONTO como separador dos centavos. Não deve ser utilizado nenhum separador de milhar.</p>
				<p className='instructions-text'>Exemplo: 1200, 34,78, 1200.34, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: FORNECEDOR VENCEDOR (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É a sinalização se o fornecedor foi o vencedor (S) do respectivo processo de compra ou não (N). Serão apenas aceitos os caracteres S ou N. Em um processo de compra só pode haver um vencedor, do contrário haverá um erro.</p>
				<p className='instructions-text'>Exemplo: S, N</p>
			</div>
		</div>
	)
}

export default PurchaseProcessText