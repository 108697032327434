import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Avatar from "@material-ui/core/Avatar";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import { AlertCircle } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { toast } from "react-toastify";
import moment from "moment";

import IconSelector from "./IconSelector";
import { config, toastConfig } from "../data/tableConfig";
import { handleGetUser } from "../actions/user";
import { editCurrentUser } from "../utils/dataAPI";
import { cpfValidation } from "../utils/inputValidation";
import FormElement from "./FormElement";

function UserProfile(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showEdit, setShowEdit] = useState(() =>
    config.users.createEditCategories.map((category) => false)
  );
  const user = useSelector((state) => state.user.userData);
  const selectOptions = useSelector((state) => state.selectOptions);

  let formFields = {};

  config.users.createEditCategories.forEach((category) => {
    formFields[category] = [];
  });

  config.users.fields.forEach((field) => {
    if (field.hasOwnProperty("formProps")) {
      let formItem = {
        id: field.id,
        ...field.formProps,
      };
      formFields[field.formProps.category][field.formProps.order] = formItem;
    }
  });

  const getInitialValues = (category) => {
    let initialValues = {};
    formFields[category].forEach((field) => {
      if (field.type === "inputGroup") {
        field.inputs.forEach((input) => {
          initialValues[input.id] = user.data[input.id]
            ? user.data[input.id]
            : "";
        });
      } else if (field.type === "select") {
        initialValues[field.id] =
          user.data[field.id] ||
          (!user.data[field.id] && typeof user.data[field.id]) === "number"
            ? user.data[field.id]
            : "none";
      } else if (field.type === "checkboxOptions") {
        initialValues[field.category] = {};
        field.checkboxOptions.forEach((input) => {
          initialValues[field.category][input.id] = {};
          input.fields.forEach((option) => {
            initialValues[field.category][input.id][option] = false;
          });
        });
      } else {
        initialValues[field.id] = user.data[field.id]
          ? user.data[field.id]
          : "";
      }
    });

    return initialValues;
  };

  const fieldsValidation = (values, index) => {
    const errors = {};
    formFields[config.users.createEditCategories[index]].forEach((field) => {
      if (
        field.validation.required &&
        ((!values[field.id] && typeof values[field.id] !== "number") ||
          (field.type === "select" && values[field.id] === "none"))
      ) {
        errors[field.id] = t("common.inputError.missingField");
      } else if (
        field.validation.minChar !== null &&
        values[field.id] &&
        values[field.id].length < field.validation.minChar
      ) {
        errors[field.id] = t("common.inputError.minCharValidation");
      } else if (
        field.validation.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values[field.id])
      ) {
        errors[field.id] = t("common.inputError.emailValidation");
      } else if (field.validation.cpf && cpfValidation(values[field.id])) {
        errors[field.id] = t("common.inputError.cpfValidation");
      } else if (field.validation.noSpace && !/^(?!\s).{1,}/) {
        errors[field.id] = t("common.inputError.noSpaceValidation");
      } else if (
        values[field.id] &&
        field.validation.phoneNumber &&
        !/^[1-9]{1}[0-9]{10}$/.test(values[field.id])
      ) {
        errors[field.id] = t("common.inputError.cellphoneValidation");
      } else if (
        field.validation.date &&
        !moment(values[field.id], "DD/MM/YYYY", true).isValid()
      ) {
        errors[field.id] = t("common.inputError.dateValidation");
      } else if (field.validation.checkboxGroup) {
        let categoryOptions = [];
        Object.keys(values[field.category]).forEach((group) => {
          categoryOptions.push(
            Object.values(values[field.category][group]).every(
              (option) => option === false
            )
          );
        });
        if (categoryOptions.every((item) => item === true)) {
          errors[field.category] = t("common.inputError.optionValidation");
        }
      }
    });

    return errors;
  };

  const handleEdit = (event, index) => {
    event.stopPropagation();
    let newEdit = [...showEdit];
    newEdit[index] = true;
    setShowEdit(newEdit);
  };

  const handleCancel = (event, index) => {
    let newEdit = [...showEdit];
    newEdit[index] = false;
    setShowEdit(newEdit);
  };

  const handleSave = (values, actions, index) => {
    let newValues = { ...user.data };
    for (let input in newValues) {
      if (values[input] === "") {
        newValues[input] = null;
      } else if (values.hasOwnProperty(input)) {
        newValues[input] = values[input];
      }
    }

    editCurrentUser(newValues)
      .then((response) => {
        if (response && response.success) {
          dispatch(handleGetUser());

          actions.setSubmitting(false);
          let newEdit = [...showEdit];
          newEdit[index] = false;
          setShowEdit(newEdit);
        } else {
          toast.error(t("common.toast.dataNotSaved"), toastConfig);
          actions.setSubmitting(false);
        }
      })
      .catch((error) => {
        toast.error(t("common.toast.dataNotSaved"), toastConfig);
        actions.setSubmitting(false);
      });
  };

  useEffect(() => {
    document.title = t("userProfile.pageTitle");
  }, [t]);

  return (
    <div className="container__page">
      <div className="header__page">
        <p className="header__page-title">{t("userProfile.pageTitle")}</p>
      </div>
      {Object.keys(user).length !== 0 && (
        <div className="container__page-content">
          {config.users.createEditCategories.map((category, index) =>
            !showEdit[index] ? (
              <div key={category} className="accordion">
                <Accordion
                  defaultExpanded
                  classes={{ root: "accordion-material-ui" }}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon classes={{ root: "expand-icon" }} />
                    }
                    classes={{
                      root: "accordion__summary",
                      expanded: "accordion__summary--expanded",
                      content: "accordion__summary--content",
                    }}
                  >
                    <div className="accordion__summary-content">
                      <p className="accordion__title">
                        {t(`userProfile.categories.${category}`)}
                      </p>
                      <Button
                        className="btn__accordion--edit"
                        onClick={(event) => handleEdit(event, index)}
                      >
                        <IconSelector
                          name="Edit3"
                          type="feather-icon"
                          size={18}
                        />{" "}
                        <p className="btn-txt">Atualizar informações</p>
                      </Button>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails classes={{ root: "accordion__details" }}>
                    {category === "personalData" && (
                      <div className="accordion__details-content">
                        <div className="avatar-container">
                          {user.data.photo === null ? (
                            <div className="placeholder-container">
                              <IconSelector name="Camera" type="feather-icon" />
                            </div>
                          ) : (
                            <Avatar
                              src={`data:image/jpeg;base64,${user.data.photo}`}
                              classes={{ root: "avatar-img" }}
                            />
                          )}
                        </div>
                        <div className="accordion__container-column">
                          <p className="accordion__user-name">{`${user.data.first_name} ${user.data.last_name}`}</p>
                          <div className="accordion__container-column--medium">
                            <div className="accordion__container-column--small">
                              <div className="accordion__user-info">
                                <div className="icon__wrapper">
                                  <IconSelector
                                    name="Gift"
                                    type="feather-icon"
                                    size={18}
                                  />
                                </div>
                                <p className="text__user-info">
                                  <span className="text__title">
                                    {t("userProfile.fields.birthday")}
                                  </span>{" "}
                                  {user.data.birthday}
                                </p>
                              </div>
                              <div className="accordion__user-info">
                                <div className="icon__wrapper">
                                  <IconSelector
                                    name="CreditCard"
                                    type="feather-icon"
                                    size={18}
                                  />
                                </div>
                                <p className="text__user-info">
                                  <span className="text__title">
                                    {t("userProfile.fields.cpf")}
                                  </span>{" "}
                                  {user.data.cpf}
                                </p>
                              </div>
                            </div>
                            <div className="accordion__container-column--small">
                              <div className="accordion__user-info">
                                <div className="icon__wrapper">
                                  <IconSelector
                                    name="Smile"
                                    type="feather-icon"
                                    size={18}
                                  />
                                </div>
                                <p className="text__user-info">
                                  <span className="text__title">
                                    {t("userProfile.fields.socialName")}
                                  </span>{" "}
                                  {user.data.social_name !== null
                                    ? user.data.social_name
                                    : "-"}
                                </p>
                              </div>
                              <div className="accordion__user-info">
                                <div className="icon__wrapper">
                                  <IconSelector
                                    name="wc"
                                    type="material-icon"
                                    size={18}
                                  />
                                </div>
                                <p className="text__user-info">
                                  <span className="text__title">
                                    {t("userProfile.fields.gender")}
                                  </span>{" "}
                                  {user.data.gender_name}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {category === "contactData" && (
                      <div className="accordion__details-content">
                        <div className="accordion__container-column--small">
                          <div className="accordion__user-info">
                            <div className="icon__wrapper">
                              <IconSelector
                                name="MapPin"
                                type="feather-icon"
                                size={18}
                              />
                            </div>
                            <p className="text__user-info">
                              <span className="text__title">
                                {t("userProfile.fields.address")}
                              </span>{" "}
                              {user.data.address !== null
                                ? user.data.address
                                : "-"}
                            </p>
                          </div>
                          <div className="accordion__user-info">
                            <div className="icon__wrapper">
                              <IconSelector
                                name="Phone"
                                type="feather-icon"
                                size={18}
                              />
                            </div>
                            <p className="text__user-info">
                              <span className="text__title">
                                {t("userProfile.fields.mobilePhone")}
                              </span>{" "}
                              {user.data.mobile !== null
                                ? user.data.mobile
                                : "-"}
                            </p>
                          </div>
                          <div className="accordion__user-info">
                            <div className="icon__wrapper">
                              <IconSelector
                                name="Mail"
                                type="feather-icon"
                                size={18}
                              />
                            </div>
                            <p className="text__user-info">
                              <span className="text__title">
                                {t("userProfile.fields.email")}
                              </span>{" "}
                              {user.data.email}
                            </p>
                          </div>
                        </div>
                        <div className="accordion__container-column--small">
                          <div className="accordion__user-info">
                            <div className="icon__wrapper">
                              <IconSelector
                                name="Facebook"
                                type="feather-icon"
                                size={18}
                              />
                            </div>
                            <p className="text__user-info">
                              <span className="text__title">
                                {t("userProfile.fields.facebook")}
                              </span>{" "}
                              {user.data.facebook !== null
                                ? user.data.facebook
                                : "-"}
                            </p>
                          </div>
                          <div className="accordion__user-info">
                            <div className="icon__wrapper">
                              <IconSelector
                                name="Linkedin"
                                type="feather-icon"
                                size={18}
                              />
                            </div>
                            <p className="text__user-info">
                              <span className="text__title">
                                {t("userProfile.fields.linkedin")}
                              </span>{" "}
                              {user.data.linkedin !== null
                                ? user.data.linkedin
                                : "-"}
                            </p>
                          </div>
                          <div className="accordion__user-info">
                            <div className="icon__wrapper">
                              <IconSelector
                                name="Instagram"
                                type="feather-icon"
                                size={18}
                              />
                            </div>
                            <p className="text__user-info">
                              <span className="text__title">
                                {t("userProfile.fields.instagram")}
                              </span>{" "}
                              {user.data.instagram !== null
                                ? user.data.instagram
                                : "-"}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                    {category === "accountData" && (
                      <div className="accordion__details-content">
                        <div className="accordion__container-column--small">
                          <div className="accordion__user-info">
                            <div className="icon__wrapper">
                              <IconSelector
                                name="User"
                                type="feather-icon"
                                size={18}
                              />
                            </div>
                            <p className="text__user-info">
                              <span className="text__title">
                                {t("userProfile.fields.profile")}
                              </span>{" "}
                              {user.data.profile_name}
                            </p>
                          </div>
                          <div className="accordion__user-info">
                            <div className="icon__wrapper">
                              <IconSelector
                                name="Key"
                                type="feather-icon"
                                size={18}
                              />
                            </div>
                            <p className="text__user-info">
                              <span className="text__title">
                                {t("userProfile.fields.password")}
                              </span>{" "}
                              ********
                            </p>
                          </div>
                        </div>
                        <div className="accordion__container-column--small">
                          <div className="accordion__user-info">
                            <div className="icon__wrapper">
                              <IconSelector
                                name="Home"
                                type="feather-icon"
                                size={18}
                              />
                            </div>
                            <p className="text__user-info">
                              <span className="text__title">
                                {t("userProfile.fields.organization")}
                              </span>{" "}
                              {user.data.org_name === null
                                ? t("common.all")
                                : user.data.org_name}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </AccordionDetails>
                </Accordion>
              </div>
            ) : (
              <div key={category} className="container__form-block">
                <Button
                  className="btn__edit-form--cancel"
                  onClick={(event) => handleCancel(event, index)}
                >
                  <IconSelector name="XCircle" type="feather-icon" />
                  <p className="btn-txt">{t("userProfile.cancelChanges")}</p>
                </Button>
                <div className="container__form--user-profile">
                  <p className="form__title--user-profile">
                    {t(`userProfile.categories.${category}`)}
                  </p>
                  <Formik
                    initialValues={getInitialValues(category)}
                    validate={(values) => fieldsValidation(values, index)}
                    onSubmit={(values, actions) =>
                      handleSave(values, actions, index)
                    }
                    enableReinitialize={true}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                      isSubmitting,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                    }) => (
                      <form className="form" onSubmit={handleSubmit}>
                        {selectOptions &&
                          formFields[
                            config.users.createEditCategories[index]
                          ].map((field) => (
                            <div
                              className="form__input-container"
                              key={field.id}
                            >
                              <FormElement
                                props={{
                                  field: field,
                                  values: values,
                                  handleChange: handleChange,
                                  handleBlur: handleBlur,
                                  pageType: "users",
                                  setFieldValue: setFieldValue,
                                  setFieldTouched: setFieldTouched,
                                  options: selectOptions,
                                  errors: errors,
                                  touched: touched,
                                  disabled:
                                    field.id === "email" ||
                                    field.id === "category_id" ||
                                    field.id === "profile_id" ||
                                    field.id === "org_id"
                                      ? true
                                      : isSubmitting,
                                }}
                              />
                              {errors[field.id] && touched[field.id] && (
                                <div className="input-feedback">
                                  <AlertCircle size={18} />
                                  <p className="error-text">
                                    {errors[field.id]}
                                  </p>
                                </div>
                              )}
                            </div>
                          ))}
                        <Button className="btn__form--save" type="submit">
                          {t("common.saveItem")}
                        </Button>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
}

export default UserProfile;
