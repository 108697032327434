import { useEffect } from 'react';
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory
} from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { StylesProvider } from '@material-ui/core/styles';
import { pageview } from '../utils/googleAnalytics';
import { useCookies } from 'react-cookie'

import Dashboard from './Dashboard';
import Login from './Login';
import ErrorPage from './ErrorPage';
import SetPasswordPage from './SetPasswordPage';
import TermsAndConditions from './TermsAndConditions';
import PrivacyPolicy from './PrivacyPolicy';
import ErrorBoundary from './ErrorBoundary';
import { setLoginStatus } from '../actions/user';
import { setLocalToken } from '../utils/dataAPI';
import '../../css/style.scss';

function App() {
	const cookies = useCookies(['user']);
	const user = useSelector(state => state.user);
	const dispatch = useDispatch();
	let location = useLocation();
	const history = useHistory();

	useEffect(() => {
		pageview(location.pathname, document.title)
	},[location])

	useEffect(() => {
		if (!user.loginStatus && cookies.user !== undefined) {
			if (cookies.user.msg && cookies.user.msg === 'Token has expired') {
				dispatch(setLoginStatus(false));
			} else if (location.pathname === '/login') {
				setLocalToken(cookies.user);
				dispatch(setLoginStatus(true));
				history.push('/');
			}	else {
				setLocalToken(cookies.user);
				dispatch(setLoginStatus(true));
			}
		}
	},[])

	return (
		<StylesProvider injectFirst>
			<ErrorBoundary>
				<Switch>
					<Route path="/login" component={Login}/>
					<Route key="recovery" path="/recovery/:token">
						<SetPasswordPage pageType="recovery"/>
					</Route>
					<Route key="activate" path="/activate/:token">
						<SetPasswordPage pageType="activate"/>
					</Route>
					<Route path="/error" component={ErrorPage}/>
					<Route path="/terms" component={TermsAndConditions}/>
					<Route path="/privacy" component={PrivacyPolicy}/>
					<Route path="/">
						{user.loginStatus ? <Dashboard/> : <Login/>}
					</Route>
					<Route><Redirect to="/error"/></Route>
				</Switch>
			</ErrorBoundary>
		</StylesProvider>
	)
}

export default App;
