import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import TermsText from './TermsText';

function TermsAndConditions () {
	const { t } = useTranslation();
	useEffect(() => {
		document.title = t('terms.pageTitle')
	},[t])
	return (
		<div className='container__terms-page'>
			<p className='terms-title'>TERMOS DE USO</p>
			<TermsText/>
		</div>
	)
}

export default TermsAndConditions