import React from 'react';
import Icon from '@material-ui/core/Icon';
import * as icons from 'lucide-react';

function IconSelector({name,type,size}) {
	if (type === 'material-icon') {
		return (
			<Icon>{name}</Icon>
		)
	}

	if (type === 'feather-icon') {
		const FeatherIcon = icons[name];
		if (size) {
			return (
				<FeatherIcon size={size}/>
			)
		} else {
			return (
				<FeatherIcon/>
			)
		}
	}
}

export default IconSelector
