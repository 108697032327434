export const getYearOptions = () => {
	let date = new Date();
	let year = date.getFullYear();
	let minYear = year - 5;
	let maxYear = year + 1;

	let yearOptions = [];

	for (let i=minYear; i <= maxYear; i++) {
		yearOptions.push({id: i, name: i})
	}

	return yearOptions;
}

export const getDeadlineYearOptions = () => {
	let date = new Date();
	let year = date.getFullYear();
	let minYear = year;
	let maxYear = year + 1;

	let yearOptions = [];

	for (let i=minYear; i<= maxYear; i++) {
		yearOptions.push(i)
	}

	return yearOptions;
}