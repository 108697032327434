import React, { useEffect, useState, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import Avatar from '@material-ui/core/Avatar';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SearchIcon from '@material-ui/icons/Search';
import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import MenuItem from '@material-ui/core/MenuItem';
import { Formik } from 'formik';
import { AlertCircle, CheckCircle } from 'lucide-react';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Save, Trash2 } from 'lucide-react';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { Frown } from 'lucide-react';
import { toast } from 'react-toastify';
import { toastConfig }  from '../data/tableConfig';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Instructions from './Instructions';
import { 
	getFileTypes, 
	getModels, 
	getFieldsByFileType,
	getFieldsByModel,
	addModel, 
	deleteModel, 
	editModel,
	checkData,
	importData,
} from '../utils/dataAPI';


function ImportData() {
	const { t } = useTranslation();
	const submitBtn = useRef(null);
	const {acceptedFiles, getRootProps, getInputProps} = useDropzone({ accept: '.csv', maxFiles: 1});
	const steps = ['importData', 'relatedModel', 'readFullTable'];
	const [activeStep, setActiveStep] = useState(0);
	const [fileTypeSelect, setFileTypeSelect] = useState('none');
	const [selectedModel, setSelectedModel] = useState('');
	const [createModel, setCreateModel] = useState(false);
	const [errorCreate, setErrorCreate] = useState(false);
	const [modelEdit,setModelEdit] = useState(false);
	const [readModel,setReadModel] = useState(false);
	const [headerPairs,setHeaderPairs] = useState([]);
	const [modelList, setModelList] = useState([]); 
	const [fileTypes, setFileTypes] = useState({});
	const [typeFile, setTypeFile] = useState('');
	const [file,setFile] = useState([]);
	const [errorFileType, setErrorFileType] = useState(false);
	const [errorFileInput, setErrorFileInput] = useState(false);
	const [selectTouched, setSelectTouched] = useState(false);
	const [radioInputTouched, setRadioInputTouched] = useState(false);
	const [fileInputTouched, setFileInputTouched] = useState(false);
	const [loading, setLoading] = useState(false);
	const [createFields, setCreateFields] = useState([]);
	const [fileHeaders, setFileHeaders] = useState([]);
	const [editModelID, setEditModelID] = useState(null);
	const [errorModel,setErrorModel] = useState(false);
	const [tableData, setTableData] = useState({});
	const [showResults,setShowResults] = useState(false);
	const [tableResults, setTableResults] = useState({});
	const [resultsError, setResultsError] = useState('');
	const [resultsMessage, setResultsMessage] = useState('');
	const [loadingModel,setLoadingModel] = useState(false);
	const [errorAssociation, setErrorAssociation] = useState(false);
	const [totalLines, setTotalLines] = useState(0);
	const [checkDataError, setCheckDataError] = useState('');

	const getInitialValues = () => {
		let modelToEdit = {}
		if (modelEdit) {
			modelToEdit = modelList.filter(model => model.id === editModelID)
		}
		let initialValues = {
			name: modelEdit ? modelToEdit[0].name : '',
			is_shared: modelEdit ? modelToEdit[0].is_shared_model : false,
		}

		createFields.forEach((field) => {
			initialValues[field.id] = modelEdit ? field.header : 'none'
		})

		return initialValues
	}

	const handleStep = (step) => {
		setActiveStep(step)
	} 

	const handlePrevious = () => {
		if (activeStep === 1) {
			setCreateModel(false)
			setErrorCreate(false)
			setErrorAssociation(false)
			setCheckDataError('')
		}
		setActiveStep(currentStep => {
			return currentStep-1
		})
	}

	const changeFileType = (event) => {
		const { value } = event.target;
		if (fileTypeSelect !== value) {
			setSelectedModel('');
			setReadModel(false);
		}
		setFileTypeSelect(value)
	}

	const handleSubmit = () => {
		if (activeStep !== (steps.length - 1)) {
			if (activeStep === 0) {
				if (fileTypeSelect === 'none' || file.length === 0) {
					if (fileTypeSelect === 'none') {
						setErrorFileType(true)
					}
					if (file.length === 0) {
						setErrorFileInput(true)
					}
				} else {
					setLoading(true)
					getModels(fileTypeSelect).then((response) => {
						if (response.success) {
							setModelList(response.data);
							setLoading(false);
							setActiveStep(currentStep => {
								return currentStep + 1
							})
						} else {
							setLoading(false)
						}
					}).catch( error => {
						console.log(error)
					})
				}
			} else if (activeStep === 1) {
				if (!selectedModel) {
					setErrorModel(true)
				} else {
					const model_id = modelList.filter(model => model.name === selectedModel)
					const modelData = {
						file: file[0],
						model_id: model_id[0].id
					}
					setLoading(true)
					checkData(modelData).then(response => {
						if (response.success) {
							setTotalLines(response.total_lines)
							setTableData(response.data)
							setLoading(false)
							setActiveStep(currentStep => {
								return currentStep + 1
							})
						} else {
							setErrorAssociation(true);
						
							setCheckDataError(t([`importData.checkDataError.${response.type}`, 'importData.checkDataError.unspecific']));
							setLoading(false)
						}
					}).catch(error => {
						setLoading(false);
						console.log('error', error)
					})
				}
			} else {
				setActiveStep(currentStep => {
					return currentStep + 1
				})
			}
		} else {
			const model_id = modelList.filter(model => model.name === selectedModel)
			const modelData = {
				file: file[0],
				model_id: model_id[0].id
			}
			setLoading(true)
			setShowResults(true)
			importData(modelData).then(response => {
				if (response.status === 504) {
					setResultsMessage(t("importData.errorMessage.warning"));
					setResultsError('warning');
					setTableResults({})
					setLoading(false);
				} else if (response.success) {
					setResultsMessage(t("importData.success"))
					setResultsError('')
					setTableResults(response.data)
					setLoading(false);
				} else {
					if (response.data) {
						if (Object.keys(response.data).length === 1 && Object.keys(response.data)[0] === '0') {
							setResultsMessage(t(`importData.errorMessage.${response.data['0'].type}`))
						} else {
							setResultsMessage(t("importData.errorMessage.fileError"))
						}
						setTableResults(response.data)
					} else {
						setResultsMessage(t(`importData.errorMessage.${response.type}`))
						setTableResults({})
					}
					setResultsError('error')
					setLoading(false)
				}
			}).catch(error => {
				setLoading(false)
				setResultsError('error')
				setResultsMessage(t("importData.errorMessage.generalError"))
				setTableResults({})
				
			})
		}
	}

	const backToFirstStep = () => {
		setSelectTouched(false);
		setRadioInputTouched(false);
		setFileInputTouched(false);
		setCreateModel(false);
		setModelEdit(false);
		setReadModel(false);
		setFileTypeSelect('none');
		setFile([]);
		setTypeFile('');
		setSelectedModel('');
		setActiveStep(0);
		setShowResults(false);
		setTableResults({});
		setResultsError('');
		setTotalLines(0);
	}

	const handleChangeModel = (event,id) => {
		setErrorAssociation(false)
		setCheckDataError('')
		setLoadingModel(true)
		setCreateModel(false)
		setModelEdit(false)
		setSelectedModel(event.target.value)
		const fileType = fileTypes.data.filter(data => data.id === fileTypeSelect)
		setTypeFile(fileType[0].name)

		getFieldsByModel(id).then(response => {
			if (response.success) {
				const tempPairs = []
				response.data.forEach(item => {
					tempPairs.push({header: item.db_field_name, fieldHeader: item.header})
				})
				setHeaderPairs(tempPairs)
				setLoadingModel(false)
				setReadModel(true)
			}
		}).catch(error => {
			setLoadingModel(false)
		})
	}

	const handleCreateModel = () => {
		setErrorAssociation(false)
		setCheckDataError('')
		setLoadingModel(true)
		setModelEdit(false)
		setReadModel(false)
		setSelectedModel('');
		const reader = new FileReader();

		const fileType = fileTypes.data.filter(data => data.id === fileTypeSelect)
		setTypeFile(fileType[0].name);

		let copyHeaders = [];
		reader.onload = function(e) {
			const text = e.target.result;
			const headers = text.slice(0,text.indexOf('\n')).split(';');
			copyHeaders = [...headers];
			setFileHeaders(headers)
		}
		reader.readAsText(file[0]);
		getFieldsByFileType(fileTypeSelect).then((response) => {
			if (response.success) {
				setCreateFields(response.data)
				if (copyHeaders.length < response.data.length) {
					setErrorCreate(true)
				} else {
					setCreateModel(true)
				}
				setLoadingModel(false)
			}
			setLoadingModel(false)
		}).catch(error => {
			setLoadingModel(false)
			setErrorCreate(true)
		})
	}

	const handleEditModel = (id) => {
		setLoadingModel(true)
		setReadModel(false);
		setSelectedModel('');
		getFieldsByModel(id).then(response => {
			if(response.success) {
				const tempHeaders = []
				response.data.forEach(item => {
					tempHeaders.push(item.header)
				})
				setFileHeaders(tempHeaders)
				setCreateFields(response.data)
				setEditModelID(id)
				setLoadingModel(false);
				setModelEdit(true)
			}
		})
	}

	const handleSaveNewModel = (values,actions) => {
		if (createModel) {
			let newModel = {
				name: values.name,
				is_shared: values.is_shared,
				file_type_id: fileTypeSelect,
			}
			let field_header_list = []
			for (let key in values) {
				if (!(['name', 'is_shared'].includes(key))) {
					field_header_list.push({
						field_id: Number(key),
						header: values[key]
					})
				}
			}

			newModel['field_header_list'] = field_header_list;

			addModel(newModel).then((response) => {
				if (response.success) {
					getModels(fileTypeSelect).then(newResponse => {
						if (newResponse.success) {
							setModelList(newResponse.data);
							setCreateModel(false)
							toast.success(t("common.toast.importModelSuccess"), toastConfig)
						} else {
							toast.error(t("common.toast.loadModelListError"), toastConfig)
						}
					}).catch(error => {
						toast.error(t("common.toast.loadModelListError"), toastConfig)
					})
				} else {
					toast.error(t("common.toast.createModelError"), toastConfig)
				}
			}).catch(error => {
				toast.error(t("common.toast.createModelError"), toastConfig)
			})
		}

		if (modelEdit) {
			let editedModel = {
				id: editModelID,
			}
			let field_header_list = []
			for (let key in values) {
				if (!(['name', 'is_shared'].includes(key))) {
					field_header_list.push({
						field_id: Number(key),
						header:values[key]
					})
				}
			}

			editedModel['field_header_list'] = field_header_list;

			editModel(editedModel).then(response => {
				if (response.success) {
					setModelEdit(false)
					toast.success(t("common.toast.editModelSuccess"), toastConfig)
				} else {
					toast.error(t("common.toast.editModelError"), toastConfig)
				}
			}).catch(error => {
				toast.error(t("common.toast.editModelError"), toastConfig)
			})
		}
		
	}

	const fieldsValidation = (values) => {
		const errors = {}
		if (!values.name) {
			errors.name = t("common.inputError.missingField")
		}
		createFields.forEach(field => {
			if (values[field.id] === 'none') {
				errors[field.id] = t("common.inputError.missingField")
			}
		})
		return errors
	}

	const clickSubmit = () => {
		submitBtn.current.click()
	}

	const handleDelete = (event) => {
		event.stopPropagation();
		setFile([])
	}

	const handleDeleteModel = (id) => {
		const items = [id]
		deleteModel(items).then(response => {
			if (response.success) {
				getModels(fileTypeSelect).then(modelResponse => {
					if(modelResponse.success) {
						setModelList(modelResponse.data)
					}
				}).catch(error => {
					console.log(error)
				})
			}
		}).catch(error => {
			console.log(error)
		})
	}

	useEffect(() => {
		document.title = t('importData.pageTitle')
		getFileTypes().then((fileTypes) => {
			setFileTypes(fileTypes)
		})
	},[t])

	useEffect(() => {
		setFile(acceptedFiles)
	}, [acceptedFiles])

	useEffect(() => {
		if (fileTypeSelect !== 'none') {
			setErrorFileType(false)
			setSelectTouched(true)
		} else if (fileTypeSelect === 'none' && selectTouched) {
			setErrorFileType(true)
		}

		if (file.length !== 0) {
			setErrorFileInput(false)
			setFileInputTouched(true)
		} else if (file.length === 0 && fileInputTouched) {
			setErrorFileInput(true)
		}

		if (selectedModel) {
			setErrorModel(false);
			setRadioInputTouched(true)
		} else if (!selectedModel && radioInputTouched) {
			setErrorModel(true)
		}

	},[file, fileTypeSelect, selectedModel]
	)

	return (
		<div className='container__page'>
			<div className='header__page'>
				<p className='header__page-title'>{t("importData.pageTitle")}</p>
			</div>
			<div className='page-content'>
				<div className='form-controls'>									
					<Stepper nonLinear activeStep={activeStep} connector={<></>}>
						{
							steps.map((category,index) => (
								<Step key={category}>
									<StepButton className={activeStep === index ? 'button-style--active' : 'button-style'} disabled={true} icon={<span>{`${index+1}.`}</span>} onClick={() => handleStep(index)}>
										{t(`importData.stepLabels.${category}`)}
									</StepButton>
								</Step>
							))
						}	
					</Stepper>
				</div>
				<div className='main-content'>
				{
					(steps[activeStep] === 'importData') && (<div className='content'>
						<div className='left-content'>
							<p className='title-content'>{t("importData.contentTitle.sendFiles")}</p>
							<div className='form__input-container'>
							<label className={errorFileType ? 'form__label--error': 'form__label'} htmlFor='fileTypeSelect'>{t("importData.fields.selectFileType")}</label>
							<Select
								classes={{root: errorFileType ? 'select-input--error' : 'select-input'}}
								IconComponent={KeyboardArrowDownIcon}
								MenuProps={{
									anchorOrigin: {
						        vertical: "bottom",
						        horizontal: "left" 
						      },
						      transformOrigin: {
						        vertical: "top",
						        horizontal: "left"
						      },
						      getContentAnchorEl: null,
						     	PaperProps: {
						     		style: {
						     			maxHeight: `${5.4 * 4}rem`,
						     		}
						     	}
								}}
								id='fileTypeSelect'
								name='fileTypeSelect'
								value={fileTypeSelect}
								onChange={changeFileType}
							>
								<MenuItem className='select-menu-item-hidden' disabled value='none'>{t("importData.fields.chooseOptions")}</MenuItem>
								{
									Object.keys(fileTypes).length !== 0 && fileTypes.data.map((fileType) => (
										<MenuItem key={fileType.id} className='select-menu-item' value={fileType.id}>{fileType.name}</MenuItem>
									))
								}
							</Select>
							{
								errorFileType && <div className='input-feedback'><AlertCircle size={18}/><p className='error-text'>{t("importData.fields.requiredField")}</p></div>
							}
								
							</div>
							<div {...getRootProps({className: errorFileInput ? 'dropzone-area dropzone-area--error' : 'dropzone-area'})}>
								<input {...getInputProps()}/>
								{
									file.length === 0 ? (
									<>
										<Avatar classes={{root: 'container-import-icon'}}>
											<CloudUploadOutlinedIcon classes={{root: 'import-icon'}}/>
										</Avatar>
										<div className='text-container'>
											<p className='dropzone-text'>{t('importDocuments.dropzoneInfoPrimary')}</p>
										</div>
									</>
									) : (
									<div className='file-item'>
										<div className='file-item-text-container'>
											<Avatar classes={{root: 'file-item-icon-container'}}>
												<InsertDriveFileIcon classes={{root: 'file-item-icon'}}/>
											</Avatar>
											<p className='file-item-text'>{file[0].path}</p>
											<Button classes={{root: 'delete-btn'}} onClick={handleDelete}>
												<Trash2 className='delete-btn-icon' size={20}/>
												<p className='delete-btn-text'>{t('importDocuments.deleteBtn')}</p>
											</Button>
										</div>
									</div>
									)
								}
								<Button classes={{root: 'export-menu-btn'}}><SearchIcon classes={{root: 'search-icon'}}/><p>{t('importDocuments.dropzoneBtn')}</p></Button>
							</div>
							{
								errorFileInput && <div className='input-feedback'><AlertCircle size={18}/><p className='error-text'>{t("importData.fields.requiredField")}</p></div>
							}
						</div>
						<div className='right-content instructions-container'>
							<p className='instructions-title'>{t("importData.instructions.title")}</p>
							<p className='instructions-text'>{t("importData.instructions.text1")}</p>
							<p className='instructions-text'>{t("importData.instructions.text2")}</p>
							<p className='instructions-text'>{t("importData.instructions.text3")}</p>
							<p className='instructions-text'>{t("importData.instructions.text4")}</p>
							<p className='instructions-text'>{t("importData.instructions.text5")}</p>
							<Instructions textType={fileTypeSelect}/>
						</div>
					</div>)
				}
				{
					(steps[activeStep] === 'relatedModel') && (
						<div className='content'>
							<div className='left-content'>
								<p className='title-content'>{t("importData.fields.selectModel")}</p>
								<div className='model-list-container'>
									{
										modelList.length !== 0 && modelList.map((model) => (
											<div className='model-list-item' key={model.id}>
												<Radio
													checked={selectedModel === model.name}
													value={model.name}
													onChange={(event) => handleChangeModel(event,model.id)}
													classes={{
														root: 'radio-btn'
													}}
												/>
												<p className='model-label'>{model.name}</p>
												<div className='item-btn-container'>
													<Button className='export-menu-btn' type='button' onClick={()=>handleEditModel(model.id)}><NoteAddOutlinedIcon classes={{root: 'item-btn-icon'}}/> <p className='btn-item-text'>{t("common.edit")}</p></Button>
													<Button className='export-menu-btn' type='button' onClick={()=>handleDeleteModel(model.id)}><Trash2 className='item-btn-icon' size={20}/><p className='btn-item-text'>{t("common.delete")}</p></Button>
												</div>
											</div>
										))
									}									
								</div>
								{
									errorModel && <div className='input-feedback'><AlertCircle size={18}/><p className='error-text'>{t("importData.fields.requiredModel")}</p></div>
								}
								<Button className={createModel ? 'export-menu-btn--open new-model-btn' : 'export-menu-btn new-model-btn'} onClick={handleCreateModel}><NoteAddOutlinedIcon classes={{root: 'item-btn-icon'}}/><p className='btn-item-text'>{t("importData.fields.createNewModel")}</p></Button>
							</div>
							<div className='right-content model-container'>
								{
									errorCreate ? (
										<div className='create-model-container error-create-model'>
											<Avatar classes={{root: 'error-create-icon'}}>
												<Frown size={72} strokeWidth={1}/>
											</Avatar>
											<div className='container-error'>
												<p className='text-error-title'>{t("importData.modelErrors.create.title")}</p>
												<p className='text-error'><span className='bulletpoint'>{'\u2B24'}</span>{t("importData.modelErrors.create.text1", {length: createFields.length})}</p>
												<p className='text-error'><span className='bulletpoint'>{'\u2B24'}</span> {t("importData.modelErrors.create.text2")}</p>
											</div>
										</div>
									) : loadingModel ? (
										<div className='create-model-container error-create-model'>
											<CircularProgress classes={{root: 'btn__loading--alternative'}}/>
											<p className='text-loading'>{t("common.loading")}</p>
										</div>

									) : errorAssociation ? (
									<div className='create-model-container error-create-model'>
										<div className='container-error'>
											<p className='text-error-title'>{t("importData.modelErrors.associate.title")}</p>
												<p className='text-error'><span className='bulletpoint'>{'\u2B24'}</span> {checkDataError}</p>
										</div>
									</div>
									)	: readModel ? (
										<div className='create-model-container'>
											<p className='create-model-title'>{selectedModel}</p>
											<div className='create-model-form-container'>
												<div className='create-model-form'>
													<div className='create-model-header'>
														<p className='create-model-title'>{typeFile}</p>
													</div>
													<div className='association-header'>
														<div className='left-column'>
															<p className='association-title'>{t("importData.fields.associationSystemTitle")}</p>
														</div>
														<div className='right-column'>
															<p className='association-title'>{t("importData.fields.associationFileTitle")}</p>
														</div>
													</div>
													{
														headerPairs && headerPairs.map((pair, index) => (
															<div key={index} className='association-fields read-association-fields'>
																<div className='left-column'>
																	<p className='association-text'>{t(`importData.headers.${typeFile}.${pair.header}`)}</p>
																</div>
																<div className='association-fields-container right-column'>
																	<p className='association-text'>{pair.fieldHeader}</p>
																</div>
															</div>
														))
													}
												</div>
											</div>
										</div>
									) :
									(createModel || modelEdit) && (
										<div className='create-model-container'>
											<div className='create-model-header'>
												<p className='create-model-title'>{modelEdit ? 'Editar modelo' : 'Criar novo modelo'}</p>
												<Button className='export-menu-btn save-model-btn' onClick={clickSubmit}><Save size={20}/>{t("common.saveItem")}</Button>
											</div>
											<div className='create-model-form-container'>
												<div className='create-model-form'>
												<Formik
													initialValues={getInitialValues()}
													validate={values => fieldsValidation(values)}
													onSubmit={(values,actions) => handleSaveNewModel(values,actions)}
													enableReinitialize={true}
												>
													{
														({
														values,
														errors,
														touched,
														isSubmitting,
														handleBlur,
														handleChange,
														handleSubmit
													}) => (
														<form onSubmit={handleSubmit}>
															<div className='form__input-container'>
																<label className={errors.name && touched.name ? 'form__label--error' : 'form__label'}>{t("importData.fields.newModelName")}</label>
																<input
																	type='text'
																	className={errors.name && touched.name ? 'form__input--error' : 'form__input' }
																	id='name'
																	name='name'
																	onChange={handleChange}
																	onBlur={handleBlur}
																	disabled={isSubmitting || modelEdit}
																	value={values.name}
																></input>
																{
																	errors.name && touched.name && <div className='input-feedback'><AlertCircle size={18}/><p className='error-text'>{errors.name}</p></div>
																}
															</div>
															<div className='form__input-container checkbox-container'>
																<p className='checkbox-label'>{t("importData.fields.allowSharing")}</p>
																<Checkbox
																	id='is_shared'
																	name='is_shared'
																	classes={{root: 'checkbox-form'}}
																	checked={values.is_shared}
																	onChange={handleChange}
																	disabled={modelEdit}
																/>
															</div>
															<div className='form-info'>
																<p className='form-info-text'><span className='info-text-bold'>{t("importData.fields.important")}</span>{t("importData.fields.formInfo")}</p>
															</div>
															<p className='file-type'>{typeFile}</p>
															<div className='association-header'>
																<div className='left-column'>
																	<p className='association-title'>{t("importData.fields.associationSystemTitle")}</p>
																</div>
																<div className='right-column'>
																	<p className='association-title'>{t("importData.fields.associationFileTitle")}</p>
																</div>
															</div>
															<div>
															{
																createFields && createFields.map((field) => (
																	<div key={field.id} className='association-fields'>
																		<div className='left-column column-fields'>
																			<p className='association-text'>{t(`importData.headers.${typeFile}.${field.db_field_name}`)}</p>
																		</div>
																		<div className='form__input-container association-fields-container'>
																			<Select
																				classes={{
																					root: errors[field.id] && touched[field.id] ? 'select-input--error' : 'select-input',
																				}}
																				IconComponent={KeyboardArrowDownIcon}
																				MenuProps={{
																					anchorOrigin: {
																		        vertical: "bottom",
																		        horizontal: "left"
																		      },
																		      transformOrigin: {
																		        vertical: "top",
																		        horizontal: "left"
																		      },
																		      getContentAnchorEl: null,
																		     	PaperProps: {
																		     		style: {
																		     			maxHeight: `${5.4 * 4}rem`,
																		     		}
																		     	}
																				}}
																				id={field.id.toString()}
																				name={field.id.toString()}
																				onBlur={handleBlur}
																				value={values[field.id]}
																				onChange={handleChange}
																			>
																				<MenuItem className='select-menu-item-hidden' disabled value='none'>{t("importData.fields.selectOption")}</MenuItem>
																				{
																					fileHeaders && fileHeaders.map((fileHeader) => (
																						<MenuItem className='select-menu-item' key={fileHeader} value={fileHeader}>{fileHeader}</MenuItem>
																					))
																				}
																			</Select>
																			{
																				errors[field.id] && touched[field.id] && <div className='input-feedback'><AlertCircle size={18}/><p className='error-text'>{errors[field.id]}</p></div>
																			}
																		</div>
																	</div>
																))
															}
															<button className='btn-hidden' type='submit' ref={submitBtn}/>
															</div>
														</form>
													)
													}
												</Formik>
											</div>
											</div>
										</div>
									)
								}
							</div>					
						</div>
					)
				}
				{
					(steps[activeStep] === 'readFullTable') && (
						<div className='content-results'>
							{
								(Object.keys(tableResults).length === 0 && resultsError) ? (
									<div className={resultsError === 'error' ? 'results-message-container results-message-container--error' : 'results-message-container results-message-container--warning'}>
										<AlertCircle/>
										<p className='results-message-text'>{resultsMessage}</p>
									</div>
								) : Object.keys(tableResults).length !== 0 ? (
									<div className={resultsError === 'error' ? 'results-message-container results-message-container--error' : 'results-message-container'}>
										{
											resultsError ? (
												<AlertCircle/>
											) : (
												<CheckCircle/>
											)
										}
										<p className='results-message-text'>{resultsMessage}</p>
									</div>
								) : null
							}
							<div className='preview-table-container'>
								<div className='preview-table'>
									{
										!showResults ? (
											<TableContainer>
												<Table>
													<TableBody>
														{
															Object.keys(tableData).map((data,index) => (
																<TableRow key={index}>
																	<TableCell classes={{root: index === 0 ? 'first-column first-line' : (index === Object.keys(tableData).length - 1) ? 'first-column last-line' : 'first-column'}}><p className='first-column-text'>{data}</p></TableCell>
																	{
																		tableData[data].map((item,index) => (
																			<TableCell key={index} classes={{root: (index === Object.keys(tableData).length - 1) ? 'table-cell-last-line' : 'table-cell'  }}><p className='table-cell-text'>{item}</p></TableCell>
																		))
																	}
																</TableRow>
															))
														}
													</TableBody>
												</Table>
											</TableContainer>
										) : (
											<TableContainer>
												<Table>
													<TableBody>

														{
															[...Array(totalLines)].map((results,index) => (
																<TableRow key={index}>
																	<TableCell classes={{root: index === 0 ? 'first-column first-line result-first-column' : (index === Object.keys(tableData).length - 1) ? 'first-column last-line result-first-column' : 'first-column result-first-column'}}><p className='first-column-text'>{'Linha '+(index+1)}</p></TableCell>
																	<TableCell>
																		{
																			(Object.keys(tableResults).length === 0 && resultsError === 'warning') ? 
																			<p></p> :
																			(Object.keys(tableResults).length === 0 && resultsError === 'error') ?
																			<p className='result-text-error'>{t("common.fail")}</p> :
																			(Object.keys(tableResults).length === 0 && resultsError === '') ?
																			<CircularProgress size={'2rem'} classes={{root: 'results-loading'}}/> :
																			(Object.keys(tableResults).length === 1 && Object.keys(tableResults)[0] === '0') ? 
																			<p className='result-text-error'>{t("common.fail")}</p> :
																			tableResults[index+1].success ?
																			<p className='result-text-success'>{t("common.success")}</p> :
																			tableResults[index+1].type.map((error, i) => (<p key={i} className='importError-text'>{t(`importData.importErrors.${error}`)}</p>))
																		}
																	</TableCell>															
																</TableRow>																 
															))
														}
													</TableBody>
												</Table>
											</TableContainer>
										)
									}
								</div>
							</div>
						</div>
					)
				}

					<div className='step-control-container step-control-container--center'>
					{
						(activeStep === steps.length - 1 && showResults) ?
						(
							<Button className='btn-color-filled' onClick={backToFirstStep} disabled={loading}>{loading ? <CircularProgress classes={{root: 'btn__loading'}} size='2rem'/> : t("common.returnBeginning")}</Button>
						) :
						(
							<>
								<Button className='btn-color-inverted' disabled={activeStep === 0 || loading} onClick={handlePrevious} type='button'><ArrowBackIcon classes={{root: 'step-control-icon-left'}}/>Voltar</Button>
								<Button className='btn-color-filled' onClick={handleSubmit} disabled={loading} type='button'>{(activeStep === steps.length - 1) ? t("common.finish") : t("common.forward")} {loading ? <CircularProgress classes={{root: 'btn__loading'}} size='2rem'/> : <ArrowForwardIcon classes={{root: 'step-control-icon-right'}}/>}</Button>
							</>
						)
					}
					</div>
				</div>
			</div>
		</div>
	)
}

export default ImportData