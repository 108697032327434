import {
	SET_CATEGORY_OPTIONS,
	SET_PROFILE_OPTIONS,
	SET_OSS_OPTIONS,
	SET_GENDER_OPTIONS,
	SET_UNITS_OPTIONS,
	SET_UNIT_TYPES_OPTIONS,
	SET_COA_GROUPS_OPTIONS,
	SET_HEADINGS_GROUP_OPTIONS,
	SET_BANK_ACCOUNT_TYPES_OPTIONS,
	SET_AGENCIES_OPTIONS,
	SET_BANK_OPTIONS,
	SET_CONTRACT_OPTIONS,
	SET_UNITS_BY_CONTRACT,
	SET_HEADINGS_BY_CONTRACT,
	SET_DOC_TYPES,
	SET_BANK_ACCOUNTS_BY_CONTRACT,
	SET_STATES,
	SET_MODES,
	SET_PROVIDERS,
	SET_PURCHASE_PROCESS_LIST,
} from '../actions/selectOptions';

const initialState = {
	categoryOptions: {},
	profileOptions: {},
	ossOptions: {},
	genderOptions: {},
	unitTypesOptions: {},
	coaGroupsOptions: {},
	headingsGroupOptions: {},
	bankAccountTypesOptions: {},
	agenciesOptions: {},
	bankOptions: {},
	contractOptions: {},
	unitsOptions: {},
	unitsByContract: {},
	headingsByContract: {},
	docTypes: {},
	bankAccountsByContract: {},
	states: {},
	modeOptions: {},
	providerOptions: {},
	purchaseProcessOptions: {},
}

export default function selectOptions(state=initialState, action) {
	switch(action.type) {
		case SET_CATEGORY_OPTIONS:
			return {
				...state,
				categoryOptions: action.categoryOptions
			}
		case SET_PROFILE_OPTIONS:
			return {
				...state,
				profileOptions: action.profileOptions
			}
		case SET_OSS_OPTIONS:
			return {
				...state,
				ossOptions: action.ossOptions
			}
		case SET_GENDER_OPTIONS:
			return {
				...state,
				genderOptions: action.genderOptions
			}
		case SET_UNIT_TYPES_OPTIONS:
			return {
				...state,
				unitTypesOptions: action.unitTypesOptions
			}
		case SET_COA_GROUPS_OPTIONS:
			return {
				...state,
				coaGroupsOptions: action.coaGroupsOptions
			}
		case SET_HEADINGS_GROUP_OPTIONS:
			return {
				...state,
				headingsGroupOptions: action.headingsGroupOptions
			}
		case SET_BANK_ACCOUNT_TYPES_OPTIONS:
			return {
				...state,
				bankAccountTypesOptions: action.bankAccountTypesOptions
			}
		case SET_AGENCIES_OPTIONS:
			return {
				...state,
				agenciesOptions: action.agenciesOptions
			}
		case SET_BANK_OPTIONS:
			return {
				...state,
				bankOptions: action.bankOptions
			}
		case SET_CONTRACT_OPTIONS:
			return {
				...state,
				contractOptions: action.contractOptions
			}
		case SET_UNITS_OPTIONS:
			return {
				...state,
				unitsOptions: action.unitsOptions
			}
		case SET_UNITS_BY_CONTRACT:
			return {
				...state,
				unitsByContract: action.unitsByContract
			}
		case SET_HEADINGS_BY_CONTRACT:
			return {
				...state,
				headingsByContract: action.headingsByContract
			}
		case SET_DOC_TYPES:
			return {
				...state,
				docTypes: action.docTypes
			}
		case SET_BANK_ACCOUNTS_BY_CONTRACT: 
			return {
				...state,
				bankAccountsByContract: action.bankAccountsByContract
			}
		case SET_STATES:
			return {
				...state,
				states: action.states
			}
		case SET_MODES:
			return {
				...state,
				modeOptions: action.modeOptions
			}
		case SET_PROVIDERS:
			return {
				...state,
				providerOptions: action.providerOptions
			}
		case SET_PURCHASE_PROCESS_LIST:
			return {
				...state,
				purchaseProcessOptions: action.purchaseProcessOptions
			}
		default:
			return state
	}
}