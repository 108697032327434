import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable'
import moment from 'moment';

export const exportExcel = (data,columns,name) => {
	let headerCellMaxLength = [];
	let rowCellMaxLength = [];
	let headers = columns.map(column => {
		headerCellMaxLength.push(column.name.length)
		return column.name
	});
	let rows = data.map(item => {
		Object.values(item).forEach((value, index) => {
			if (value) {
				if (!rowCellMaxLength[index]) {
					rowCellMaxLength.push(value.length)
				} else if (value.length > rowCellMaxLength[index]) {
					rowCellMaxLength[index] = value.length 
				}
			} else {
				if (!rowCellMaxLength[index]) {
					rowCellMaxLength.push(0)
				}
			}
		})
		return Object.values(item)
	})
	let columnMaxLength = [];
	headerCellMaxLength.forEach((value,index) => {
		if (value < rowCellMaxLength[index]) {
			columnMaxLength[index] = { wch: rowCellMaxLength[index]}
		} else {
			columnMaxLength[index] = { wch: value} 
		}
	})

	let ws = XLSX.utils.aoa_to_sheet([headers, ...rows])
	ws['!cols'] = columnMaxLength;
	const wb = { Sheets: { [`${name}`]: ws }, SheetNames: [`${name}`] };
 	const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
	let blob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'})
	FileSaver.saveAs(blob, `${name}.xlsx`)
}

export const exportCSV = (data,columns,name) => {
	let headers = columns.map(column => column.name);
	let keys = columns.map(column => column.selector);
	let rows = data.map(item => {
		let {id, ...newData} = item;
		return newData
	})
	let result = '';
	const columnDelimiter = ';';
  const lineDelimiter = '\n';
  result += headers.join(columnDelimiter);
  result += lineDelimiter;

  rows.forEach(item => {
  	let ctr = 0;
  	keys.forEach(key => {
  		if (ctr > 0) result += columnDelimiter;

  		result += item[key];
  		ctr++;
  	});
  	result += lineDelimiter;
  })
  let blob = new Blob([result], {type: 'text/csv;charset=utf-8'})
  FileSaver.saveAs(blob, `${name}.csv`)
}

export const exportPDF = (data, columns,name) => {
	const doc = new jsPDF({
		orientation: "landscape",
	});
	let headers = columns.map(column => column.name);
	let rows = data.map(item => {
		return Object.values(item)
	})
	doc.text(name, 14,20)
	doc.autoTable({
		head: [headers],
		body: rows,
		startY: 30,
	})
	const created = moment().format('DD/MM/YYYY HH:mm')
	let pageSize = doc.internal.pageSize;
	let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
	let pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();

	doc.setFontSize(9)
	doc.text(created, pageWidth - 40, pageHeight - 10)
	doc.save(`${name}.pdf`);
}