import React, { Component } from "react";
import { connect } from "react-redux";

import ErrorPage from "./ErrorPage";
import { setErrorStatus } from "../actions/error";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMsg: null,
      errorInfo: null,
      errorCount: 0,
    };
  }

  resetError = () => {
    this.setState({
      hasError: false,
      errorCount: this.state.errorCount + 1,
    });

    this.props.dispatch(setErrorStatus(false));
  };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    //local para enviar os erros para serviço externo
  }

  render() {
    if (this.state.hasError || this.props.error.hasError) {
      return <ErrorPage reset={this.resetError} />;
    } else {
      return (
        <React.Fragment key={this.state.errorCount}>
          {this.props.children}
        </React.Fragment>
      );
    }
  }
}

function mapStateToProps({ error }) {
  return {
    error,
  };
}

export default connect(mapStateToProps)(ErrorBoundary);
