import { 
	SET_USER,
	SET_LOGIN_STATUS,
	SET_UNITS,
	SET_OSS,
	SET_PROFILES,
	SET_GENDER
} from '../actions/user';

const initialState = {
	userData: {},
	loginStatus: false,
	units: {},
	oss: {},
	profiles: {},
	gender: {},
}

export default function user(state=initialState, action) {
	switch(action.type) {
		case SET_USER:
			return {
				...state,
				userData: action.userData
			}
		case SET_LOGIN_STATUS:
			return {
				...state,
				loginStatus: action.loginStatus,
			}
		case SET_UNITS:
			return {
				...state,
				units: action.units
			}
		case SET_OSS:
			return {
				...state,
				oss: action.oss
			}
		case SET_PROFILES:
			return {
				...state,
				profiles: action.profiles
			}
		case SET_GENDER:
			return {
				...state,
				gender: action.gender
			}
		default:
			return state
	}
}