import { 
	getUnits, 
	getGender, 
	getCategoryOptions, 
	getProfileOptions, 
	getOSSOptions, 
	getUnitTypes,
	getChartOfAccountsGroups,
	getGroupOptions,
	getBankAccountTypesOptions,
	getAgenciesOptions,
	getBankOptions,
	getContractOptions,
	getUnitsByContract,
	getHeadingsByContract,
	getDocTypes,
	getBankAccountsByContract,
	getStates,
	getModes,
	getProviders,
	getPurchaseProcessList,
} from '../utils/dataAPI';
import { setErrorStatus } from './error';

export const SET_CATEGORY_OPTIONS = 'SET_CATEGORY_OPTIONS';
export const SET_PROFILE_OPTIONS = 'SET_PROFILE_OPTIONS';
export const SET_OSS_OPTIONS = 'SET_OSS_OPTIONS';
export const SET_GENDER_OPTIONS = 'SET_GENDER_OPTIONS';
export const SET_UNITS_OPTIONS = 'SET_UNITS_OPTIONS';
export const SET_UNIT_TYPES_OPTIONS = 'SET_UNIT_TYPES_OPTIONS';
export const SET_COA_GROUPS_OPTIONS = 'SET_COA_GROUPS_OPTIONS';
export const SET_HEADINGS_GROUP_OPTIONS = 'SET_HEADINGS_GROUP_OPTIONS';
export const SET_BANK_ACCOUNT_TYPES_OPTIONS = 'SET_BANK_ACCOUNT_TYPES_OPTIONS';
export const SET_AGENCIES_OPTIONS = 'SET_AGENCIES_OPTIONS';
export const SET_BANK_OPTIONS = 'SET_BANK_OPTIONS';
export const SET_CONTRACT_OPTIONS = 'SET_CONTRACT_OPTIONS';
export const SET_UNITS_BY_CONTRACT = 'SET_UNITS_BY_CONTRACT';
export const SET_HEADINGS_BY_CONTRACT = 'SET_HEADING_BY_CONTRACT';
export const SET_DOC_TYPES = 'SET_DOC_TYPES';
export const SET_BANK_ACCOUNTS_BY_CONTRACT = 'SET_BANK_ACCOUNTS_BY_CONTRACT';
export const SET_STATES = 'SET_STATES';
export const SET_MODES = 'SET_MODES';
export const SET_PROVIDERS = 'SET_PROVIDERS';
export const SET_PURCHASE_PROCESS_LIST = 'SET_PURCHASE_PROCESS_LIST';


export function setCategoryOptions(options) {
	return {
		type: SET_CATEGORY_OPTIONS,
		categoryOptions: options
	}
}

export function setProfileOptions(options) {
	return {
		type: SET_PROFILE_OPTIONS,
		profileOptions: options
	}
}

export function setOSSOptions(options) {
	return {
		type: SET_OSS_OPTIONS,
		ossOptions: options
	}
}

export function setGenderOptions(options) {
	return {
		type: SET_GENDER_OPTIONS,
		genderOptions: options
	}
}

export function setUnitsOptions(options) {
	return {
		type: SET_UNITS_OPTIONS,
		unitsOptions: options
	}
}

export function setUnitTypesOptions(options) {
	return {
		type: SET_UNIT_TYPES_OPTIONS,
		unitTypesOptions: options
	}
}

export function setCOAGroupsOptions(options) {
	return {
		type: SET_COA_GROUPS_OPTIONS,
		coaGroupsOptions: options
	}
}

export function setHeadingsGroupOptions(options) {
	return {
		type: SET_HEADINGS_GROUP_OPTIONS,
		headingsGroupOptions: options
	}
}

export function setBankAccountTypesOptions(options) {
	return {
		type: SET_BANK_ACCOUNT_TYPES_OPTIONS,
		bankAccountTypesOptions: options
	}
}

export function setAgenciesOptions(options) {
	return {
		type: SET_AGENCIES_OPTIONS,
		agenciesOptions: options
	}
}

export function setBankOptions(options) {
	return{
		type: SET_BANK_OPTIONS,
		bankOptions: options
	}
}

export function setContractOptions(options) {
	return {
		type: SET_CONTRACT_OPTIONS,
		contractOptions: options
	}
}

export function setUnitsByContract(options) {
	return {
		type: SET_UNITS_BY_CONTRACT,
		unitsByContract: options
	}
}

export function setHeadingsByContract(options) {
	return {
		type: SET_HEADINGS_BY_CONTRACT,
		headingsByContract: options
	}
}

export function setDocTypes(options) {
	return {
		type: SET_DOC_TYPES,
		docTypes: options
	}
}

export function setBankAccountsByContract(options) {
	return {
		type: SET_BANK_ACCOUNTS_BY_CONTRACT,
		bankAccountsByContract: options
	}
}

export function setStates(options) {
	return {
		type: SET_STATES,
		states: options
	}
}

export function setModes(options) {
	return {
		type: SET_MODES,
		modeOptions: options 
	}
}

export function setProviders(options) {
	return {
		type: SET_PROVIDERS,
		providerOptions: options
	}
}

export function setPurchaseProcessList(options) {
	return {
		type: SET_PURCHASE_PROCESS_LIST,
		purchaseProcessOptions: options
	}
}

export function handleGetCategoryOptions() {
	return(dispatch) => {
		return getCategoryOptions().then((categories) => {
			if (categories && categories.success) {
				let idList = {};
				categories.data.forEach((item) => {
					idList[item.id] = item.name
				})
				let newList = {...categories, idList}
				dispatch(setCategoryOptions(newList));
			} else {
				return Promise.reject();
			}
		}).catch(error => {
			dispatch(setErrorStatus(true))
		})
	}
}

export function handleGetProfileOptions(id) {
	return(dispatch) => {
		return getProfileOptions(id).then((profiles) => {
			if (profiles && profiles.success) {
				let idList = {};
				profiles.data.forEach((item) => {
					idList[item.id] = item.name
				})
				let newList = {...profiles, idList}
				dispatch(setProfileOptions(newList));
			} else {
				return Promise.reject();
			}
		}).catch(error => {
			dispatch(setErrorStatus(true))
		})
	}
}

export function handleGetOSSOptions(id,pageType) {
	return(dispatch) => {
		return getOSSOptions(id,pageType).then((oss) => {
			if (oss && oss.success) {
				let idList = {};
				oss.data.forEach((item) => {
					idList[item.id] = item.name
				})
				let newList = {...oss, idList}
				dispatch(setOSSOptions(newList))
			} else {
				return Promise.reject();
			}
		}).catch(error => {
			dispatch(setErrorStatus(true))
		})
	}
}

export function handleGetGenderOptions() {
	return(dispatch) => {
		return getGender().then((gender) => {
			if (gender && gender.success) {
				let idList = {};
				gender.data.forEach((item) => {
					idList[item.id] = item.name
				})
				let newList = {...gender, idList}
				dispatch(setGenderOptions(newList));
			} else {
				return Promise.reject();
			}
		}).catch(error => {
			dispatch(setErrorStatus(true))
		})
	}
}

export function handleGetUnitTypesOptions() {
	return(dispatch) => {
		return getUnitTypes().then((unitTypes) => {
			if (unitTypes && unitTypes.success) {
				dispatch(setUnitTypesOptions(unitTypes));
			} else {
				return Promise.reject();
			}
		}).catch(error => {
			dispatch(setErrorStatus(true))
		})
	}
}

export function handleGetUnitsOptions(ids) {
	return(dispatch) => {
		return getUnits(ids).then((units) => {
			if (units && units.success) {
				dispatch(setUnitsOptions(units))
			}
		})
	}
}

export function handleGetCOAGroupsOptions() {
	return (dispatch) => {
		return getChartOfAccountsGroups().then((groups) => {
			if (groups && groups.success) {
				dispatch(setCOAGroupsOptions(groups))
			} else {
				return Promise.reject();
			}
		}).catch(error => {
			dispatch(setErrorStatus(true))
		})
	}
}

export function handleGetHeadingsGroupOptions(id) {
	return(dispatch) => {
		return getGroupOptions(id).then((groups) => {
			if(groups && groups.success) {
				dispatch(setHeadingsGroupOptions(groups));
			} else {
				return Promise.reject();
			}
		}).catch(error => {
			dispatch(setErrorStatus(true))
		})
	}
}

export function handleGetBATypesOptions() {
	return(dispatch) => {
		return getBankAccountTypesOptions().then((types) => {
			if(types && types.success) {
				dispatch(setBankAccountTypesOptions(types));
			} else {
				return Promise.reject();
			}
		}).catch(error => {
			dispatch(setErrorStatus(true))
		})
	}
}

export function handleGetAgenciesOptions(id) {
	return(dispatch) => {
		return getAgenciesOptions(id).then((agencies) => {
			if (agencies && agencies.success) {
				dispatch(setAgenciesOptions(agencies));
			} else {
				return Promise.reject();
			}
		}).catch(error => {
			dispatch(setErrorStatus(true))
		})
	}
}

export function handleGetBankOptions() {
	return(dispatch) => {
		return getBankOptions().then((banks) => {
			if (banks && banks.success) {
				dispatch(setBankOptions(banks));
			} else {
				return Promise.reject();
			}
		}).catch(error => {
			dispatch(setErrorStatus(true))
		})
	}
}

export function handleGetContractOptions(id) {
	return(dispatch) => {
		return getContractOptions(id).then((contracts) => {
			if (contracts && contracts.success) {
				dispatch(setContractOptions(contracts));
			} else {
				return Promise.reject();
			}
		}).catch(error => {
			dispatch(setErrorStatus(true))
		})
	}
}

export function handleGetUnitsByContract(id) {
	return(dispatch) => {
		return getUnitsByContract(id).then((units) => {
			if (units && units.success) {
				dispatch(setUnitsByContract(units));
			} else {
				return Promise.reject();
			}
		}).catch(error => {
			dispatch(setErrorStatus(true))
		})
	}
}

export function handleGetHeadingsByContract(id) {
	return(dispatch) => {
		return getHeadingsByContract(id).then((headings) => {
			if(headings && headings.success) {
				dispatch(setHeadingsByContract(headings));
			} else {
				return Promise.reject();
			}
		}).catch(error => {
			dispatch(setErrorStatus(true))
		})
	}
}

export function handleGetDocTypes() {
	return(dispatch) => {
		return getDocTypes().then((docTypes) => {
			if (docTypes && docTypes.success) {
				dispatch(setDocTypes(docTypes));
			} else {
				return Promise.reject();
			}
		}).catch(error => {
			dispatch(setErrorStatus(true))
		})
	}
}

export function handleGetBankAccountsByContract(id) {
	return(dispatch) => {
		return getBankAccountsByContract(id).then((bankAccounts) => {
			if (bankAccounts && bankAccounts.success) {
				dispatch(setBankAccountsByContract(bankAccounts));
			} else {
				return Promise.reject();
			}
		}).catch(error => {
			dispatch(setErrorStatus(true))
		})
	}
}

export function handleGetStates() {
	return(dispatch) => {
		return getStates().then((states) => {
			if (states && states.success) {
				dispatch(setStates(states));
			} else {
				return Promise.reject();
			}
		}).catch(error => {
			dispatch(setErrorStatus(true))
		})
	}
}

export function handleGetModeOptions() {
	return(dispatch) => {
		return getModes().then((modes) => {
			if (modes && modes.success) {
				dispatch(setModes(modes));
			} else {
				return Promise.reject();
			}
		}).catch(error => {
			dispatch(setErrorStatus(true))
		})
	}
}

export function handleGetProviderOptions() {
	return(dispatch) => {
		return getProviders().then((providers) => {
			if (providers && providers.success) {
				dispatch(setProviders(providers));
			} else {
				return Promise.reject();
			}
		}).catch(error => {
			dispatch(setErrorStatus(true))
		})
	}
}

export function handleGetPurchaseProcessOptions() {
	return(dispatch) => {
		return getPurchaseProcessList().then((list) => {
			if (list && list.success) {
				dispatch(setPurchaseProcessList(list));
			} else {
				return Promise.reject();
			}
		}).catch(error => {
			dispatch(setErrorStatus(true))
		})
	}
}