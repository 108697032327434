import { combineReducers } from 'redux';

import user from './user';
import error from './error';
import selectOptions from './selectOptions';

export default combineReducers({
	user,
	error,
	selectOptions
})