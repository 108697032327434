import React from 'react';

function TermsText() {
	return (
		<div className='container__terms-text'>
			<p className='terms-text'>Este TERMO regulamenta o uso do Portal Di.Acordo, bem como os serviços/produtos neste oferecidos e fornecidos pela Wiiglo, representada, neste caso, pela Wiiglo Tecnologia da Informação Ltda., pessoa jurídica, inscrita no CNPJ sob o nº 18.101.387/0001-38, com sede na Rua Fonseca Teles, 121, São Cristóvão, Cidade e Estado do Rio de Janeiro, aos usuários de Internet. A utilização do Portal por você implica na aceitação integral e plena deste TERMO. Desta forma, é muito importante que você o leia com atenção.</p>

			<h2 className='terms-subtitle'>INTRODUÇÃO</h2>
			<p className='terms-text'>O Di.Acordo é um aplicativo da empresa Wiiglo, uma startup de base tecnológica localizada no Rio de Janeiro. No Di.Acordo, você encontrará uma solução que dá suporte ao monitoramento e execução de acordos, como contratos, convênios, entre outros.</p>

			<h2 className='terms-subtitle'>CONDIÇÕES DE ACESSO E UTILIZAÇÃO DO PORTAL</h2>
			<p className='terms-text'>Para navegar no Portal Di.Acordo lhe será exigido que tenha seu e-mail cadastrado pelo administrador responsável pela sua área. Seu login e sua senha de acesso são pessoais, e de sua inteira responsabilidade, devendo-os manter em sigilo. A Wiiglo se reserva ao direito de suspender (ou mesmo interromper) seu acesso em caso de mau uso do Portal, infringindo leis ou as próprias disposições deste TERMO. Vale ressaltar que a utilização do Portal não lhe permite, em hipótese alguma, reproduzir, copiar, distribuir, permitir o acesso público, transformar e/ou modificar os conteúdos deste, a menos que possua prévia autorização do titular dos correspondentes direitos ou que o faça com permissão legal. Não há, por meio do uso do Portal ou dos serviços, qualquer transferência ou conferência de propriedade intelectual sob o conteúdo acessado.</p>

			<h2 className='terms-subtitle'>DIREITOS AUTORAIS, MARCAS E PROPRIEDADE INTELECTUAL</h2>
			<p className='terms-text'>Todas as marcas relacionadas a Wiiglo, como a marca Di.Acordo, contidas neste Portal são de propriedade da Wiiglo e estão protegidos por leis do Brasil e internacionais. É permitida a citação da URL do Portal Di.Acordo em sites e páginas de terceiros. Os dados inseridos no Portal Di.Acordo são de inteira responsabilidade dos usuários que o fizerem, eximindo completamente a Wiiglo de responsabilidade legal sobre esses dados</p>

			<h2 className='terms-subtitle'>RESPONSABILIDADES</h2>
			<p className='terms-text'>A Wiiglo buscará sempre uma prestação de serviços de qualidade em seu Portal, sendo que quaisquer interrupções na prestação destes serviços, em razão de ocorrências técnicas, operacionais ou imprevisíveis, serão solucionadas da maneira mais célere e efetiva possível. Caso tenha qualquer problema na utilização de nossos serviços, não deixe de entrar em contato pelo e-mail: di.acordo@wiiglo.com. Vale ressaltar ainda que a Wiiglo não se responsabiliza pelas informações inseridas pelos seus usuários, tanto do Portal, como de suas redes sociais, como Facebook e Instagram. Os conteúdos inseridos pelos usuários são de inteira responsabilidade de quem os informou, não havendo qualquer revisão ou fiscalização pela Wiiglo nos referidos conteúdos. Caso você identifique algum conteúdo ofensivo disponibilizado no Portal, por favor nos comunique pelo e-mail: di.acordo@wiiglo.com. Eximem-se de qualquer responsabilidade, judicial ou extrajudicial, sobre eventuais prejuízos, perdas e/ou danos advindos pelo uso de dados e informações disponibilizadas no Painel Di.Acordo.</p>

			<h2 className='terms-subtitle'>SEGURANÇA</h2>
			<p className='terms-text'>A Wiiglo preza pela segurança, sigilo e inviolabilidade de todos os dados e informações fornecidas em seu acesso. No entanto, você deve estar ciente que as medidas de segurança na Internet não são infalíveis, principalmente em razão da rápida evolução do ambiente virtual. Deste modo, a Wiiglo não se responsabiliza por danos e/ou prejuízos decorrentes de caso fortuito ou força maior.</p>

			<h2 className='terms-subtitle'>USO INDEVIDO DO PORTAL DI.ACORDO</h2>
			<p className='terms-text'>Caso você constate a ocorrência de qualquer atividade em desacordo com o conteúdo deste TERMO, solicitamos que envie sua reclamação/comentário por meio do e-mail: di.acordo@wiiglo.com, contendo seus dados pessoais (nome, endereço, telefone e e-mail para contato) e detalhes da atividade denunciada. Sempre que possível, a Wiiglo entrará em contato com os denunciantes apresentando os resultados e consequências da denúncia (quando houver).</p>

			<h2 className='terms-subtitle'>MODIFICAÇÕES</h2>
			<p className='terms-text'>A Wiiglo pode modificar este TERMO, ou qualquer das políticas ou diretrizes que governam o Portal, a qualquer momento e a seu critério exclusivo, mediante publicação de novos Termos de Uso ou outras políticas e diretrizes modificadas. As modificações passarão a vigorar assim que forem publicadas. Por esse motivo, é importante que você reveja, periodicamente, este TERMO.</p>

			<h2 className='terms-subtitle'>DURAÇÃO</h2>
			<p className='terms-text'>O Portal e os demais serviços oferecidos possuem duração indeterminada, estando facultado a Wiiglo extingui-los, suspendê-los ou interrompê-los a qualquer momento.</p>

			<h2 className='terms-subtitle'>LEI APLICÁVEL E ELEIÇÃO DE FORO</h2>
			<p className='terms-text'>Todos os itens deste TERMO são regidos pelas leis vigentes no Brasil, e prevalecerão sobre quaisquer outras, independentemente de seu local de acesso. Fica estabelecido o Foro da Comarca de Rio de Janeiro para dirimir qualquer questão relacionada ao Portal Di.Acordo.</p>
		</div>
	)
}

export default TermsText