export const pageview = (url, title) => {
	window.gtag('config', process.env.REACT_APP_GA_TRACKING_ID, {
		page_title: title,
		page_location: url,
	})
}

export const event = (action, category, label) => {
	window.gtag('event', action, {
		event_category: category,
		event_label: label,
	})
}