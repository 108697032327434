import { useTranslation } from 'react-i18next';

function Footer() {
	const { t } = useTranslation();

	return	(
		<div className='footer-container'>
			<p className='footer-text'>{t("footer")}</p>
		</div>
	)
}

export default Footer