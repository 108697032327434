import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorMessage from "./ErrorMessage";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { requestLogout } from "../utils/authAPI";

function ErrorPage({ reset }) {
  const { t } = useTranslation();
  const [reloadPage, setReloadPage] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const onReloadPage = () => {
    setReloadPage(true);
  };

  const onGoBack = () => {
    reset();
    requestLogout(dispatch, history);
  };

  useEffect(() => {
    document.title = t("notFound.pageTitle");
    if (reloadPage) {
      reset();
      history.push(`${location.pathname}`);
      setReloadPage(false);
    }
  }, [reloadPage]);
  return (
    <div className="error-container">
      <div className="error-content">
        <ErrorMessage onReload={onReloadPage} onGoBack={onGoBack} />
      </div>
    </div>
  );
}

export default ErrorPage;
