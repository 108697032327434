function ThirdPartyContractsText() {
	return (
		<div>
			<p className='instructions-title'>Instruções para Importação de Contrato de Terceiros</p>
			<div>
				<p className='instructions-text'>Cada linha a ser importada é referente a um Contrato de Terceiro. Os fornecedores devem estar previamente cadastrados no sistema. Caso o Contrato de Terceiro a ser cadastrado seja oriundo de um Processo de Compras, basta informar o número do processo de compras que os dados do Fornecedor Vencedor e seu respectivo Valor Mensal serão associados automaticamente ao Contrato, não sendo necessário, neste caso, informar o CNPJ do Fornecedor e o Valor Mensal praticado.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: ORGANIZAÇÃO (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o código da ORGANIZAÇÃO a que se refere o Contrato de Terceiro. Deve conter apenas números. Será verificada sua existência na tabela do sistema de ORGANIZAÇÕES, bem como se a mesma está ativa. Este código deve ser o mesmo que o do usuário que está fazendo a importação, caso contrário, haverá um erro.</p>
				<p className='instructions-text'>Exemplo: 123, 999, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: UNIDADE (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o código da unidade a que se refere o Contrato de Terceiro. Deve conter apenas números. Será verificada sua existência na tabela do sistema de UNIDADES, bem como se a mesma está ativa e ligada à OS atual.</p>
				<p className='instructions-text'>Exemplo: 123, 999, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: CONTRATO (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o número do contrato de gestão. Deve ter o formato NNN/AAAA, onde NNN é o número do contrato e AAAA é o ano. Será verificada sua existência na tabela do sistema de CONTRATOS e se ele está ligado à OS atual.</p>
				<p className='instructions-text'>Exemplo: 001/2017, 100/2012, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: ANO-MÊS DE REFERÊNCIA (OBRIGATÓRIO)</p>
				<p className='instructions-text'>São o ano e o mês a que se refere os lançamentos das despesas. Deve ter o formato AAAA-MM, onde AAAA é o ano e MM é o mês.</p>
				<p className='instructions-text'>Caso a entrega já tenha sido encerrada para esta referência, OS e contrato, não será possível fazer a carga dos dados.</p>
				<p className='instructions-text'>Exemplo: outubro de 2016 deve ser preenchido 2016-10, janeiro de 2022 deve ser preenchido 2022-01, SEM ESPAÇOS.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: SERVIÇO (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É a Descrição do Serviço prestado por meio do Contrato.</p>
				<p className='instructions-text'>Exemplo: Serviços de coleta, transporte, tratamento e disposição final de Resíduos dos Serviços de Saúde (resíduo hospitalar), etc.</p>
				<p className='instructions-text'>Pode ter no máximo 255 caracteres.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: NÚMERO DO PROCESSO (OBRIGATÓRIO se CNPJ DO FORNECEDOR não estiver preenchido)</p>
				<p className='instructions-text'>É o NÚMERO interno DO PROCESSO do referido Processo de Compra da Organização.</p>
				<p className='instructions-text'>Exemplo: 15/2022, A2022P15, 025-2021, etc.</p>
				<p className='instructions-text'>Pode ter no máximo 255 caracteres.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: CNPJ DO FORNECEDOR (OBRIGATÓRIO se NÚMERO DO PROCESSO não estiver preenchido)</p>
				<p className='instructions-text'>É o CNPJ do fornecedor que presta o serviço relativo ao Contrato de Terceiro.</p>
				<p className='instructions-text'>O CNPJ tem que ser informado no formato 99.999.999/9999-99.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: VALOR MENSAL (OBRIGATÓRIO se CNPJ DO FORNECEDOR estiver preenchida)</p>
				<p className='instructions-text'>É o valor nominal mensal relativo ao serviço praticado pelo fornecedor no âmbito do respectivo Contrato de Terceiro. Poderá ter a VÍRGULA ou PONTO como separador dos centavos. Não deve ser utilizado nenhum separador de milhar.</p>
				<p className='instructions-text'>Exemplo: 1200, 34,78, 1200.34, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: DATA DE ASSINATURA (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É a DATA DE ASSINTURA do Contrato de Terceiro. Deve ter o formato AAAA-MM-DD, onde AAAA é o ano, MM é o mês e DD é o dia. O separador das datas tem que ser o -.</p>
				<p className='instructions-text'>Exemplo: 2022-02-05, 2020-10-25, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: DATA DE FIM DE VIGÊNCIA (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É a DATA DE FIM DE VIGÊNCIA do Contrato de Terceiro. Deve ter o formato AAAA-MM-DD, onde AAAA é o ano, MM é o mês e DD é o dia. O separador das datas tem que ser o -.</p>
				<p className='instructions-text'>Exemplo: 2022-02-05, 2020-10-25, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: NOME DO DOCUMENTO (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o nome do arquivo do documento do referido Contrato de Terceiro. Será verificado se o mesmo já existe no cadastro de documentos do sistema. Atenção para que o nome do arquivo seja EXATAMENTE o mesmo do que foi importado.</p>
				<p className='instructions-text'>Verifique se não há espaços extras e não use caracteres especiais (*/&, etc.).</p>
				<p className='instructions-text'>Não esqueça de colocar a extensão.</p>
				<p className='instructions-text'>Pode ter no máximo 150 caracteres.</p>
				<p className='instructions-text'>Exemplo: nome_do_arquivo.pdf, nomeDoArquivo.pdf, etc.</p>
			</div>
		</div>
	)
}

export default ThirdPartyContractsText