export const tableCustomStyles = {
  table: {
    style: {
      fontFamily: "inherit",
      backgroundColor: "transparent",
    },
  },

  tableWrapper: {
    style: {
      display: "table",
    },
  },

  subHeader: {
    style: {
      backgroundColor: "transparent",
      height: "7.8rem",
      borderBottomWidth: ".1rem",
      borderBottomColor: "#E8ECEE",
      borderBottomStyle: "solid",
      paddingLeft: "1.6rem",
      width: "100%",
    },
  },
  headCells: {
    style: {
      fontSize: "1.4rem",
      color: "#333333",
    },
  },
  rows: {
    style: {
      backgroundColor: "transparent",
      height: "6.5rem",
      fontSize: "1.4rem",
      "&:not(:last-of-type)": {
        borderBottomStyle: "solid",
        borderBottomWidth: ".1rem",
        borderBottomColor: "#E8ECEE",
      },
    },
    selectedHighlightStyle: {
      // use nth-of-type(n) to override other nth selectors
      "&:nth-of-type(n)": {
        color: "#0091D9",
        backgroundColor: "#EDF9FF",
        borderBottomColor: "#E8ECEE",
      },
    },
  },
  pagination: {
    style: {
      backgroundColor: "transparent",
      height: "7.8rem",
      fontSize: "1.2rem",
      borderTopStyle: "solid",
      borderTopWidth: ".1rem",
      borderTopColor: "#E8ECEE",
    },
  },
};
