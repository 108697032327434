import { getYearOptions } from "../utils/yearOptions";

export const config = {
  users: {
    fields: [
      {
        id: "email",
        name: "Email",
        tableProps: {
          selector: "email",
          sortable: true,
          searchable: true,
          type: "email",
          order: 4,
        },
        formProps: {
          type: "input",
          category: "contactData",
          order: 2,
          editDisabled: true,
          validation: {
            required: true,
            minChar: null,
            email: true,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "profile_name",
        name: "Perfil",
        tableProps: {
          selector: "profile_name",
          sortable: false,
          searchable: false,
          type: "string",
          order: 3,
        },
      },
      {
        id: "profile_id",
        name: "Perfil",
        formProps: {
          type: "select",
          category: "accountData",
          selectState: "profileOptions",
          order: 1,
          conditionalSelect: true,
          masterSelect: false,
          parentSelect: "category_id",
          childSelects: null,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "first_name",
        name: "Nome",
        tableProps: {
          selector: "first_name",
          sortable: true,
          searchable: true,
          type: "string",
          order: 1,
        },
        formProps: {
          type: "input",
          category: "personalData",
          order: 1,
          editDisabled: false,
          validation: {
            required: true,
            minChar: 3,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: true,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "last_name",
        name: "Sobrenome",
        tableProps: {
          selector: "last_name",
          sortable: true,
          searchable: true,
          type: "string",
          order: 2,
        },
        formProps: {
          type: "input",
          category: "personalData",
          order: 2,
          editDisabled: false,
          validation: {
            required: true,
            minChar: 3,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: true,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "social_name",
        name: "Nome Social",
        formProps: {
          type: "input",
          category: "personalData",
          order: 3,
          editDisabled: false,
          validation: {
            required: false,
            minChar: 3,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "birthday",
        name: "Data de aniversário",
        formProps: {
          type: "input",
          category: "personalData",
          mask: "##/##/####",
          maskPlaceholder: ["d", "d", "m", "m", "a", "a", "a", "a"],
          unmaskValue: false,
          order: 4,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: true,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "gender_id",
        name: "Gênero",
        formProps: {
          type: "select",
          category: "personalData",
          selectState: "genderOptions",
          order: 6,
          conditionalSelect: false,
          masterSelect: false,
          parentSelect: null,
          childSelects: null,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "cpf",
        name: "CPF",
        formProps: {
          type: "input",
          category: "personalData",
          mask: "###.###.###-##",
          maskPlaceholder: "_",
          unmaskValue: true,
          order: 5,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: true,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "mobile",
        name: "Celular",
        formProps: {
          type: "input",
          category: "contactData",
          mask: "(##) #####-####",
          maskPlaceholder: "_",
          unmaskValue: true,
          order: 1,
          editDisabled: false,
          validation: {
            required: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: true,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "address",
        name: "Endereço",
        formProps: {
          type: "input",
          category: "contactData",
          order: 0,
          editDisabled: false,
          validation: {
            required: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: true,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "photo",
        name: "Foto",
        formProps: {
          type: "upload",
          category: "personalData",
          order: 0,
          editDisabled: false,
          validation: {
            required: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },

      {
        id: "org_id",
        name: "Organização",
        formProps: {
          type: "select",
          category: "accountData",
          selectState: "ossOptions",
          order: 2,
          conditionalSelect: true,
          masterSelect: false,
          parentSelect: "category_id",
          childSelects: null,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "category_id",
        name: "Categoria",
        formProps: {
          type: "select",
          category: "accountData",
          selectState: "categoryOptions",
          order: 0,
          conditionalSelect: true,
          masterSelect: true,
          parentSelect: null,
          childSelects: ["profile_id", "org_id"],
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "created",
        name: "Data de Criação",
        tableProps: {
          selector: "created",
          sortable: true,
          searchable: true,
          type: "date",
          order: 5,
        },
      },
      {
        id: "status",
        name: "Status",
        tableProps: {
          selector: "status",
          sortable: true,
          searchable: false,
          type: "boolean",
          order: 6,
        },
      },
      {
        id: "socialMedia",
        name: "Redes Sociais",
        formProps: {
          type: "inputGroup",
          category: "contactData",
          inputs: [
            { id: "facebook", name: "Facebook", type: "feather-icon" },
            { id: "linkedin", name: "Linkedin", type: "feather-icon" },
            { id: "instagram", name: "Instagram", type: "feather-icon" },
          ],
          order: 3,
          editDisabled: false,
          validation: {
            required: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
    ],
    initialSort: "first_name",
    initialOrder: "asc",
    selectableRows: true,
    newItemIcon: {
      name: "UserPlus",
      type: "feather-icon",
    },
    createEditCategories: ["personalData", "contactData", "accountData"],
    omittedColumns: [],
  },
  profiles: {
    fields: [
      {
        id: "name",
        tableProps: {
          selector: "name",
          sortable: true,
          searchable: true,
          type: "string",
          order: 0,
        },
        formProps: {
          type: "input",
          category: "generalData",
          order: 0,
          editDisabled: false,
          validation: {
            required: true,
            minChar: 3,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: true,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "description",
        tableProps: {
          selector: "description",
          sortable: true,
          searchable: true,
          type: "string",
          order: 1,
        },
        formProps: {
          type: "inputTextArea",
          category: "generalData",
          order: 1,
          editDisabled: false,
          validation: {
            required: true,
            minChar: 3,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: true,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "permissions",
        tableProps: {
          selector: "permissions",
          sortable: false,
          searchable: false,
          type: "checkboxOptions",
          order: 2,
        },
        formProps: {
          type: "checkboxOptions",
          checkboxOptions: [
            {
              id: "users",
              fields: ["read", "update", "create", "delete"],
            },
            {
              id: "profiles",
              fields: ["read", "update", "create", "delete"],
            },
            {
              id: "contracts",
              fields: ["read", "update", "create", "delete"],
            },
            {
              id: "contracts-deadline",
              fields: ["read", "update"],
            },
            {
              id: "employees",
              fields: ["read", "update", "create", "delete"],
            },
          ],
          category: "permissions",
          order: 0,
          editDisabled: false,
          validation: {
            required: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: true,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "created",
        name: "Data de Criação",
        tableProps: {
          selector: "created",
          sortable: true,
          searchable: true,
          type: "date",
          order: 3,
        },
      },
      {
        id: "status",
        name: "Status",
        tableProps: {
          selector: "status",
          sortable: true,
          searchable: false,
          type: "boolean",
          order: 4,
        },
      },
    ],
    initialSort: "name",
    initialOrder: "asc",
    selectableRows: true,
    newItemIcon: {
      name: "Layers",
      type: "feather-icon",
    },
    createEditCategories: ["generalData", "permissions"],
    omittedColumns: [],
  },
  contracts: {
    fields: [
      {
        id: "id",
        name: "ID",
        tableProps: {
          selector: "id",
          sortable: true,
          searchable: false,
          type: "number",
          order: 0,
          minWidth: "23.5rem",
        },
      },
      {
        id: "contract_number",
        name: "Número do contrato",
        tableProps: {
          selector: "contract_number",
          sortable: true,
          searchable: true,
          type: "string",
          order: 1,
          minWidth: "23.5rem",
        },
        formProps: {
          type: "input",
          order: 0,
          category: "generalData",
          mask: "###/####",
          maskPlaceholder: "X",
          unmaskValue: false,
          editDisabled: true,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
            customValidation: /(([0-9]{3}))[//](19|20)[0-9]{2}/,
          },
        },
      },
      {
        id: "process_number",
        name: "Processo Instrutivo",
        tableProps: {
          selector: "process_number",
          sortable: true,
          searchable: true,
          type: "string",
          order: 2,
          minWidth: "23.5rem",
        },
        formProps: {
          type: "input",
          order: 1,
          category: "generalData",
          mask: "##/###.###/####",
          maskPlaceholder: [
            "X",
            "X",
            "X",
            "X",
            "X",
            "X",
            "X",
            "X",
            "a",
            "a",
            "a",
            "a",
          ],
          unmaskValue: false,
          editDisabled: false,
          validation: {
            required: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
            customValidation:
              /[0-9]{2}[//][0-9]{3}[/.][0-9]{3}[//](19|20)[0-9]{2}/,
          },
        },
      },
      {
        id: "org_name",
        name: "Organização Social",
        tableProps: {
          selector: "org_name",
          sortable: true,
          searchable: true,
          type: "string",
          order: 7,
          minWidth: "23.5rem",
        },
      },
      {
        id: "org_id",
        name: "Organização",
        formProps: {
          type: "select",
          order: 2,
          category: "generalData",
          conditionalSelect: false,
          masterSelect: false,
          parentSelect: null,
          childSelects: null,
          selectState: "ossOptions",
          editDisabled: true,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "original_value",
        name: "Valor Total",
        tableProps: {
          selector: "original_value",
          sortable: true,
          searchable: false,
          type: "currency",
          order: 10,
          minWidth: "23.5rem",
        },
        formProps: {
          type: "input",
          inputType: "currency",
          allowZero: false,
          order: 0,
          category: "values",
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "filename",
        name: "Imagem do contrato",
        tableProps: {
          selector: "filename",
          sortable: true,
          searchable: false,
          type: "pdf_file",
          order: 9,
          minWidth: "23.5rem",
        },
      },
      {
        id: "file",
        name: "Arquivo",
        formProps: {
          type: "fileUpload",
          order: 4,
          category: "generalData",
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "fixed_value",
        name: "Parcela Fixa",
        tableProps: {
          selector: "fixed_value",
          sortable: true,
          searchable: false,
          type: "currency",
          order: 11,
          minWidth: "23.5rem",
        },
        formProps: {
          type: "input",
          inputType: "currency",
          allowZero: false,
          order: 1,
          category: "values",
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "variable_value",
        name: "Parcela Variável",
        tableProps: {
          selector: "variable_value",
          sortable: true,
          searchable: false,
          type: "currency",
          order: 12,
          minWidth: "23.5rem",
        },
        formProps: {
          type: "input",
          inputType: "currency",
          allowZero: true,
          order: 2,
          category: "values",
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "last_update",
        name: "Última atualização",
        tableProps: {
          selector: "last_update",
          sortable: true,
          searchable: true,
          type: "string",
          order: 6,
          minWidth: "25rem",
        },
      },
      {
        id: "validity",
        name: "Período de Vigência (meses)",
        tableProps: {
          selector: "validity",
          sortable: true,
          searchable: false,
          type: "string",
          order: 8,
          minWidth: "23.5rem",
        },
        formProps: {
          type: "input",
          inputType: "number",
          order: 0,
          category: "dates",
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            validityPeriod: true,
            addendumNumber: false,
          },
        },
      },
      {
        id: "publication_date",
        name: "Data de publicação",
        tableProps: {
          selector: "publication_date",
          sortable: true,
          searchable: true,
          type: "string",
          order: 4,
          minWidth: "23.5rem",
        },
        formProps: {
          type: "input",
          order: 1,
          category: "dates",
          mask: "##/##/####",
          maskPlaceholder: ["d", "d", "m", "m", "a", "a", "a", "a"],
          unmaskValue: false,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: true,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "signature_date",
        name: "Data de assinatura",
        tableProps: {
          selector: "signature_date",
          sortable: true,
          searchable: true,
          type: "string",
          order: 3,
          minWidth: "23.5rem",
        },
        formProps: {
          type: "input",
          order: 2,
          category: "dates",
          mask: "##/##/####",
          maskPlaceholder: ["d", "d", "m", "m", "a", "a", "a", "a"],
          unmaskValue: false,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: true,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "end_date",
        name: "Data de término",
        tableProps: {
          selector: "end_date",
          sortable: true,
          searchable: true,
          type: "string",
          order: 5,
          minWidth: "23.5rem",
        },
        formProps: {
          type: "input",
          order: 4,
          category: "dates",
          mask: "##/##/####",
          maskPlaceholder: ["d", "d", "m", "m", "a", "a", "a", "a"],
          unmaskValue: false,
          editDisabled: false,
          conditionalDateRef: ["signature_date", "start_date"],
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: true,
            conditionalDate: true,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "start_date",
        name: "Data de Início",
        tableProps: {
          selector: "start_date",
          sortable: true,
          searchable: true,
          type: "string",
          order: 6,
          minWidth: "23.5rem",
        },
        formProps: {
          type: "input",
          order: 3,
          category: "dates",
          mask: "##/##/####",
          maskPlaceholder: ["d", "d", "m", "m", "a", "a", "a", "a"],
          unmaskValue: false,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: true,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "termination_date",
        name: "Data de encerramento",
        formProps: {
          type: "input",
          order: 5,
          category: "dates",
          mask: "##/##/####",
          maskPlaceholder: ["d", "d", "m", "m", "a", "a", "a", "a"],
          unmaskValue: false,
          editDisabled: false,
          conditionalDateRef: ["signature_date", "start_date"],
          validation: {
            required: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: true,
            conditionalDate: true,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "term_type",
        name: "Tipo de Termo",
        tableProps: {
          selector: "term_type",
          sortable: true,
          searchable: true,
          type: "termType",
          order: 13,
          minWidth: "23.5rem",
        },
      },
      {
        id: "notes",
        name: "Observação",
        tableProps: {
          selector: "notes",
          sortable: true,
          searchable: true,
          type: "string",
          order: 14,
          minWidth: "25rem",
        },
        formProps: {
          type: "inputTextArea",
          order: 3,
          category: "generalData",
          validation: {
            required: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "actions",
        name: "Ações",
        tableProps: {
          selector: "actions",
          sortable: false,
          searchable: false,
          type: "menu",
          menuMainOptions: [
            "unitAssociation",
            "headingsConfiguration",
            "issue_certificate",
          ],
          mainPermissions: ["read", "read", "create"],
          menuSubOptions: ["saveAsNewContract", "addAddendum"],
          subPermissions: ["create", "create"],
          order: 15,
          minWidth: "23.5rem",
        },
      },
    ],
    initialSort: "id",
    initialOrder: "asc",
    selectableRows: true,
    newItemIcon: {
      name: "FilePlus",
      type: "feather-icon",
    },
    createEditCategories: ["generalData", "values", "dates"],
    omittedColumns: [
      "id",
      "process_number",
      "original_value",
      "fixed_value",
      "variable_value",
      "last_update",
      "publication_date",
      "notes",
      "end_date",
    ],
  },
  bankAccounts: {
    fields: [
      {
        id: "id",
        name: "ID",
        tableProps: {
          selector: "id",
          sortable: true,
          searchable: false,
          type: "number",
          order: 0,
          minWidth: "23.5rem",
        },
      },
      {
        id: "org_name",
        name: "Organização Social",
        tableProps: {
          selector: "org_name",
          sortable: true,
          searchable: true,
          type: "string",
          order: 4,
          minWidth: "23.5rem",
        },
      },
      {
        id: "org_id",
        name: "Organização",
        formProps: {
          type: "select",
          order: 0,
          selectState: "ossOptions",
          conditionalSelect: true,
          masterSelect: true,
          parentSelect: null,
          childSelects: ["contract_id"],
          editDisabled: true,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "contract_id",
        name: "Contrato",
        formProps: {
          type: "select",
          order: 1,
          selectState: "contractOptions",
          conditionalSelect: true,
          masterSelect: false,
          parentSelect: "org_id",
          childSelects: null,
          editDisabled: true,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "account_type_name",
        name: "Tipo de Conta Bancária",
        tableProps: {
          selector: "account_type_name",
          sortable: true,
          searchable: true,
          type: "string",
          order: 6,
          minWidth: "23.5rem",
        },
      },
      {
        id: "account_type_id",
        name: "Tipo",
        formProps: {
          type: "select",
          order: 5,
          selectState: "bankAccountTypesOptions",
          conditionalSelect: false,
          masterSelect: false,
          parentSelect: null,
          childSelects: null,
          editDisabled: true,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "bank_name",
        name: "Banco",
        tableProps: {
          selector: "bank_name",
          sortable: true,
          searchable: true,
          type: "string",
          order: 2,
          minWidth: "23.5rem",
        },
      },
      {
        id: "bank_number",
        name: "Banco",
        formProps: {
          type: "select",
          order: 2,
          selectState: "bankOptions",
          conditionalSelect: true,
          masterSelect: true,
          parentSelect: null,
          childSelects: ["agency_number"],
          editDisabled: true,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "agency_name",
        name: "Agência",
        tableProps: {
          selector: "agency_name",
          sortable: true,
          searchable: true,
          type: "string",
          order: 3,
          minWidth: "23.5rem",
        },
      },
      {
        id: "agency_number",
        name: "Agência",
        formProps: {
          type: "select",
          order: 3,
          selectState: "agenciesOptions",
          conditionalSelect: true,
          masterSelect: false,
          parentSelect: "bank_number",
          childSelects: null,
          editDisabled: true,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "account_number",
        name: "Conta Corrente",
        tableProps: {
          selector: "account_number",
          sortable: true,
          searchable: true,
          type: "number",
          order: 1,
          minWidth: "23.5rem",
        },
        formProps: {
          type: "input",
          order: 4,
          inputType: "account_number",
          editDisabled: true,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "contract_number",
        name: "Número do contrato",
        tableProps: {
          selector: "contract_number",
          sortable: true,
          searchable: true,
          type: "number",
          order: 5,
          minWidth: "23.5rem",
        },
      },
    ],
    initialSort: "id",
    initialOrder: "asc",
    selectableRows: true,
    newItemIcon: {
      name: "CreditCard",
      type: "feather-icon",
    },
    createEditCategories: [],
    omittedColumns: ["id"],
  },
  headings: {
    fields: [
      {
        id: "name",
        name: "Rubrica",
        tableProps: {
          selector: "name",
          sortable: true,
          searchable: true,
          type: "string",
          order: 0,
        },
        formProps: {
          type: "input",
          order: 0,
          editDisabled: false,
          validation: {
            required: true,
            minChar: 3,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "type_id_name",
        name: "Tipo de unidade",
        tableProps: {
          selector: "type_id_name",
          sortable: true,
          searchable: true,
          type: "string",
          order: 1,
        },
      },
      {
        id: "type_unit_id",
        name: "Tipo de unidade",
        formProps: {
          type: "select",
          selectState: "unitTypesOptions",
          order: 1,
          conditionalSelect: false,
          masterSelect: false,
          parentSelect: null,
          childSelects: null,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
    ],
    initialSort: "name",
    initialOrder: "asc",
    selectableRows: true,
    newItemIcon: {
      name: "FolderPlus",
      type: "feather-icon",
    },
    createEditCategories: [],
    omittedColumns: [],
  },
  chartOfAccounts: {
    fields: [
      {
        id: "code",
        name: "Código",
        tableProps: {
          selector: "code",
          sortable: true,
          searchable: true,
          type: "number",
          order: 0,
        },
        formProps: {
          type: "input",
          order: 1,
          prefixRef: "group_id",
          prefixState: "coaGroupsOptions",
          prefixValue: "group_code",
          editDisabled: true,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: true,
            addendumNumber: false,
          },
        },
      },
      {
        id: "name",
        name: "Item de Despesa",
        tableProps: {
          selector: "name",
          sortable: true,
          searchable: true,
          type: "string",
          order: 1,
        },
        formProps: {
          type: "input",
          order: 2,
          editDisabled: false,
          validation: {
            required: true,
            minChar: 3,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "group_code",
        name: "Código Grupo de Item de Despesa",
        tableProps: {
          selector: "group_code",
          sortable: true,
          searchable: true,
          type: "number",
          order: 2,
        },
      },
      {
        id: "group_name",
        name: "Grupo",
        tableProps: {
          selector: "group_name",
          sortable: true,
          searchable: true,
          type: "string",
          order: 3,
        },
      },
      {
        id: "group_id",
        name: "Grupo",
        formProps: {
          type: "select",
          order: 0,
          selectState: "coaGroupsOptions",
          conditionalSelect: false,
          masterSelect: false,
          parentSelect: null,
          childSelects: null,
          editDisabled: true,
          validation: {
            required: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "status",
        name: "Status",
        tableProps: {
          selector: "status",
          sortable: true,
          searchable: false,
          type: "boolean",
          order: 4,
        },
      },
    ],
    initialSort: "code",
    initialOrder: "asc",
    selectableRows: true,
    newItemIcon: {
      name: "FolderPlus",
      type: "feather-icon",
    },
    createEditCategories: [],
    omittedColumns: [],
  },
  units: {
    fields: [
      {
        id: "unit_id",
        name: "ID da unidade",
        tableProps: {
          selector: "unit_id",
          sortable: true,
          searchable: true,
          type: "string",
          order: 0,
        },
      },
      {
        id: "type_unit_name",
        name: "Tipo de unidade",
        tableProps: {
          selector: "type_unit_name",
          sortable: true,
          searchable: true,
          type: "string",
          order: 2,
        },
      },
      {
        id: "unit_name",
        name: "Unidade",
        tableProps: {
          selector: "unit_name",
          sortable: true,
          searchable: true,
          type: "string",
          order: 1,
        },
      },
      {
        id: "type_unit_id",
        name: "Tipo de unidade",
        formProps: {
          type: "select",
          order: 0,
          selectState: "unitTypesOptions",
          conditionalSelect: true,
          masterSelect: true,
          parentSelect: null,
          childSelects: ["unit_id"],
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "unit_id",
        name: "Unidade",
        formProps: {
          type: "select",
          order: 1,
          selectState: "unitsOptions",
          conditionalSelect: true,
          masterSelect: false,
          parentSelect: "type_unit_id",
          childSelects: null,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
    ],
    initialSort: "unit_id",
    initialOrder: "asc",
    selectableRows: true,
    newItemIcon: {
      name: "FolderPlus",
      type: "feather-icon",
    },
    createEditCategories: [],
    omittedColumns: [],
  },
  set_headings: {
    fields: [
      {
        id: "heading_id",
        name: "ID da Rubrica",
        tableProps: {
          selector: "heading_id",
          sortable: true,
          searchable: true,
          type: "string",
          order: 0,
        },
      },
      {
        id: "name",
        name: "Rubricas",
        tableProps: {
          selector: "name",
          sortable: true,
          searchable: true,
          type: "string",
          order: 1,
        },
      },
      {
        id: "type_unit_name",
        name: "Tipo de unidade",
        tableProps: {
          selector: "type_unit_name",
          sortable: true,
          searchable: true,
          type: "string",
          order: 2,
        },
      },
      {
        id: "status",
        name: "Status",
        tableProps: {
          selector: "status",
          sortable: false,
          searchable: false,
          type: "boolean",
          order: 3,
        },
      },
    ],
    initialSort: "heading_id",
    initialOrder: "asc",
    selectableRows: true,
    newItemIcon: {
      name: "FolderPlus",
      type: "feather-icon",
    },
    createEditCategories: [],
    omittedColumns: [],
  },
  addendum: {
    fields: [
      {
        id: "org_id",
        name: "Organização",
        formProps: {
          type: "select",
          order: 0,
          category: "generalData",
          selectState: "ossOptions",
          conditionalSelect: false,
          masterSelect: false,
          parentSelect: null,
          childSelects: null,
          editDisabled: true,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "contract_number",
        name: "Numero do contrato",
        formProps: {
          type: "input",
          order: 1,
          category: "generalData",
          mask: "###/####",
          maskPlaceholder: "X",
          unmaskValue: false,
          editDisabled: true,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
            customValidation: /(([0-9]{3}))[//](19|20)[0-9]{2}/,
          },
        },
      },
      {
        id: "addendum_number",
        name: "Número do aditivo",
        formProps: {
          type: "input",
          order: 2,
          category: "generalData",
          unmaskValue: false,
          editDisabled: true,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: true,
          },
        },
      },
      {
        id: "process_number",
        name: "Processo Instrutivo",
        formProps: {
          type: "input",
          order: 3,
          category: "generalData",
          mask: "##/###.###/####",
          maskPlaceholder: [
            "X",
            "X",
            "X",
            "X",
            "X",
            "X",
            "X",
            "X",
            "a",
            "a",
            "a",
            "a",
          ],
          unmaskValue: false,
          editDisabled: false,
          validation: {
            required: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
            customValidation:
              /[0-9]{2}[//][0-9]{3}[/.][0-9]{3}[//](19|20)[0-9]{2}/,
          },
        },
      },
      {
        id: "notes",
        name: "Observação",
        formProps: {
          type: "inputTextArea",
          order: 4,
          category: "generalData",
          editDisabled: false,
          validation: {
            required: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "file",
        name: "Arquivo",
        formProps: {
          type: "fileUpload",
          order: 5,
          category: "generalData",
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "original_value",
        name: "Valor Total",
        formProps: {
          type: "input",
          inputType: "currency",
          allowZero: false,
          order: 0,
          category: "values",
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "fixed_value",
        name: "Parcela Fixa",
        formProps: {
          type: "input",
          inputType: "currency",
          allowZero: false,
          order: 1,
          category: "values",
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "variable_value",
        name: "Parcela Variável",
        formProps: {
          type: "input",
          inputType: "currency",
          allowZero: true,
          order: 2,
          category: "values",
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "validity",
        name: "Período de Vigência",
        formProps: {
          type: "input",
          inputType: "number",
          order: 0,
          category: "dates",
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
            validityPeriod: true,
          },
        },
      },
      {
        id: "publication_date",
        name: "Data de publicação",
        formProps: {
          type: "input",
          order: 1,
          category: "dates",
          mask: "##/##/####",
          maskPlaceholder: ["d", "d", "m", "m", "a", "a", "a", "a"],
          unmaskValue: false,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: true,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "signature_date",
        name: "Data de assinatura",
        formProps: {
          type: "input",
          order: 2,
          category: "dates",
          mask: "##/##/####",
          maskPlaceholder: ["d", "d", "m", "m", "a", "a", "a", "a"],
          unmaskValue: false,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: true,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "start_date",
        name: "Data de Início",
        formProps: {
          type: "input",
          order: 3,
          category: "dates",
          mask: "##/##/####",
          maskPlaceholder: ["d", "d", "m", "m", "a", "a", "a", "a"],
          unmaskValue: false,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: true,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "end_date",
        name: "Data de término",
        formProps: {
          type: "input",
          order: 4,
          category: "dates",
          mask: "##/##/####",
          maskPlaceholder: ["d", "d", "m", "m", "a", "a", "a", "a"],
          unmaskValue: false,
          editDisabled: false,
          conditionalDateRef: ["start_date", "signature_date"],
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: true,
            conditionalDate: true,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "termination_date",
        name: "Data de encerramento",
        formProps: {
          type: "input",
          order: 5,
          category: "dates",
          mask: "##/##/####",
          maskPlaceholder: ["d", "d", "m", "m", "a", "a", "a", "a"],
          unmaskValue: false,
          editDisabled: false,
          conditionalDateRef: ["signature_date", "start_date"],
          validation: {
            required: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: true,
            conditionalDate: true,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
    ],
    initialSort: "org_id",
    initialOrder: "asc",
    selectableRows: true,
    newItemIcon: {
      name: "FolderPlus",
      type: "feather-icon",
    },
    createEditCategories: ["generalData", "values", "dates"],
    omittedColumns: [],
    defaultInput: ["file"],
  },
  save_as: {
    fields: [
      {
        id: "contract_number",
        name: "Número do contrato",
        formProps: {
          type: "input",
          order: 0,
          category: "generalData",
          mask: "###/####",
          maskPlaceholder: "X",
          unmaskValue: false,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
            customValidation: /(([0-9]{3}))[//](19|20)[0-9]{2}/,
          },
        },
      },
      {
        id: "process_number",
        name: "Processo Instrutivo",
        formProps: {
          type: "input",
          order: 1,
          category: "generalData",
          mask: "##/###.###/####",
          maskPlaceholder: [
            "X",
            "X",
            "X",
            "X",
            "X",
            "X",
            "X",
            "X",
            "a",
            "a",
            "a",
            "a",
          ],
          unmaskValue: false,
          editDisabled: false,
          validation: {
            required: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
            customValidation:
              /[0-9]{2}[//][0-9]{3}[/.][0-9]{3}[//](19|20)[0-9]{2}/,
          },
        },
      },
      {
        id: "org_id",
        name: "Organização",
        formProps: {
          type: "select",
          order: 2,
          category: "generalData",
          conditionalSelect: false,
          masterSelect: false,
          parentSelect: null,
          childSelects: null,
          selectState: "ossOptions",
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "notes",
        name: "Observação",
        formProps: {
          type: "inputTextArea",
          order: 3,
          category: "generalData",
          editDisabled: false,
          validation: {
            required: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "file",
        name: "Arquivo",
        formProps: {
          type: "fileUpload",
          order: 4,
          category: "generalData",
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "original_value",
        name: "Valor Total",
        formProps: {
          type: "input",
          inputType: "currency",
          allowZero: false,
          order: 0,
          category: "values",
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "fixed_value",
        name: "Parcela Fixa",

        formProps: {
          type: "input",
          inputType: "currency",
          allowZero: false,
          order: 1,
          category: "values",
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "variable_value",
        name: "Parcela Variável",
        formProps: {
          type: "input",
          inputType: "currency",
          allowZero: false,
          order: 2,
          category: "values",
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "validity",
        name: "Período de Vigência (meses)",
        formProps: {
          type: "input",
          inputType: "number",
          order: 0,
          category: "dates",
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
            validityPeriod: true,
          },
        },
      },
      {
        id: "publication_date",
        name: "Data de publicação",
        formProps: {
          type: "input",
          order: 1,
          category: "dates",
          mask: "##/##/####",
          maskPlaceholder: ["d", "d", "m", "m", "a", "a", "a", "a"],
          unmaskValue: false,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: true,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "signature_date",
        name: "Data de assinatura",
        formProps: {
          type: "input",
          order: 2,
          category: "dates",
          mask: "##/##/####",
          maskPlaceholder: ["d", "d", "m", "m", "a", "a", "a", "a"],
          unmaskValue: false,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: true,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "end_date",
        name: "Data de término",
        formProps: {
          type: "input",
          order: 4,
          category: "dates",
          mask: "##/##/####",
          maskPlaceholder: ["d", "d", "m", "m", "a", "a", "a", "a"],
          unmaskValue: false,
          editDisabled: false,
          conditionalDateRef: ["signature_date", "start_date"],
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: true,
            conditionalDate: true,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "start_date",
        name: "Data de Início",
        formProps: {
          type: "input",
          order: 3,
          category: "dates",
          mask: "##/##/####",
          maskPlaceholder: ["d", "d", "m", "m", "a", "a", "a", "a"],
          unmaskValue: false,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: true,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "termination_date",
        name: "Data de encerramento",
        formProps: {
          type: "input",
          order: 5,
          category: "dates",
          mask: "##/##/####",
          maskPlaceholder: ["d", "d", "m", "m", "a", "a", "a", "a"],
          unmaskValue: false,
          editDisabled: false,
          conditionalDateRef: ["signature_date", "start_date"],
          validation: {
            required: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: true,
            conditionalDate: true,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
    ],
    initialSort: "contract_number",
    initialOrder: "asc",
    selectableRows: true,
    createEditCategories: ["generalData", "values", "dates"],
    omittedColumns: [],
    defaultInput: ["file", "contract_number", "process_number"],
  },
  importReport: {
    fields: [
      {
        id: "org",
        name: "OS",
        tableProps: {
          selector: "org",
          sortable: true,
          searchable: true,
          type: "string",
          order: 0,
        },
      },
      {
        id: "datetime",
        name: "Data/Hora de Importação",
        tableProps: {
          selector: "datetime",
          sortable: true,
          searchable: true,
          type: "string",
          order: 1,
        },
      },
      {
        id: "date_ref",
        name: "Referência",
        tableProps: {
          selector: "date_ref",
          sortable: true,
          searchable: true,
          type: "string",
          order: 2,
        },
      },
      {
        id: "filename",
        name: "Arquivo",
        tableProps: {
          selector: "filename",
          sortable: true,
          searchable: true,
          type: "csv_file",
          order: 3,
        },
      },
      {
        id: "type",
        name: "Tipo",
        tableProps: {
          selector: "type",
          sortable: true,
          searchable: true,
          type: "string",
          order: 4,
        },
      },
      {
        id: "status",
        name: "Status/Detalhes",
        tableProps: {
          selector: "status",
          sortable: true,
          searchable: false,
          type: "modal",
          order: 5,
        },
      },
    ],
    initialSort: "datetime",
    initialOrder: "desc",
    selectableRows: true,
    omittedColumns: [],
  },
  accountability: {
    fields: [
      {
        id: "organization",
        name: "OS",
        tableProps: {
          selector: "organization",
          sortable: true,
          searchable: true,
          type: "string",
          order: 0,
        },
      },
      {
        id: "contract_number",
        name: "Contrato",
        tableProps: {
          selector: "contract_number",
          sortable: true,
          searchable: true,
          type: "string",
          order: 1,
        },
      },
      {
        id: "month_ref",
        name: "Mês de referência",
        tableProps: {
          selector: "month_ref",
          sortable: true,
          searchable: true,
          type: "string",
          order: 2,
        },
      },
      {
        id: "year_ref",
        name: "Ano de referência",
        tableProps: {
          selector: "year_ref",
          sortable: true,
          searchable: true,
          type: "string",
          order: 3,
        },
      },
      {
        id: "type",
        name: "Tipo",
        tableProps: {
          selector: "type",
          sortable: true,
          searchable: true,
          type: "string",
          order: 4,
        },
      },
      {
        id: "deadline",
        name: "Data Limite",
        tableProps: {
          selector: "deadline",
          sortable: true,
          searchable: true,
          type: "date",
          order: 5,
        },
      },
    ],
    initialSort: "organization",
    initialOrder: "asc",
    selectableRows: true,
    dateRef: true,
    omittedColumns: ["month_ref", "year_ref"],
  },

  employees: {
    fields: [
      {
        id: "cpf",
        name: "cpf",
        tableProps: {
          selector: "cpf",
          sortable: true,
          searchable: true,
          type: "int",
          order: 1,
        },
        formProps: {
          category: "identityData",
          type: "input",
          order: 3,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
            validityPeriod: false,
          },
        },
      },

      {
        id: "nationality_id",
        name: "Código Nacionalidade",
        tableProps: {
          selector: "nationality_name",
          sortable: false,
          searchable: false,
          order: 2,
        },
        formProps: {
          category: "identityData",
          type: "select",
          selectState: "fixedOptions",
          fixedOptions: [
            { id: 1, name: "Brasileiro" },
            { id: 2, name: "Estrangeiro" },
          ],
          order: 4,
          editDisabled: false,
          validation: {
            required: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
            validityPeriod: false,
          },
        },
      },
      {
        id: "full_name",
        name: "Nome",
        tableProps: {
          selector: "name",
          sortable: false,
          searchable: false,
          type: "string",
          order: 3,
        },
        formProps: {
          category: "personalData",
          type: "input",
          order: 5,
          editDisabled: false,
          validation: {
            required: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
            validityPeriod: false,
          },
        },
      },
      {
        id: "mother_name",
        name: "Nome da Mãe",
        tableProps: {
          selector: "mother_name",
          sortable: false,
          searchable: false,
          type: "string",
          order: 4,
        },
        formProps: {
          category: "personalData",
          type: "input",
          order: 6,
          editDisabled: false,
          validation: {
            required: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
            validityPeriod: false,
          },
        },
      },
      {
        id: "father_name",
        name: "Nome do Pai",
        tableProps: {
          selector: "father_name",
          sortable: false,
          searchable: false,
          type: "string",
          order: 5,
        },
        formProps: {
          category: "personalData",
          type: "input",
          order: 7,
          editDisabled: false,
          validation: {
            required: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
            validityPeriod: false,
          },
        },
      },
      {
        id: "social_name",
        name: "Nome Social",
        tableProps: {
          selector: "social_name",
          sortable: false,
          searchable: false,
          type: "string",
          order: 6,
        },
        formProps: {
          category: "personalData",
          type: "input",
          order: 8,
          editDisabled: false,
          validation: {
            required: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
            validityPeriod: false,
          },
        },
      },
      {
        id: "identity_agency",
        name: "Órgão Expedidor RG",
        tableProps: {
          selector: "identity_agency",
          sortable: false,
          searchable: false,
          type: "string",
          order: 7,
        },
        formProps: {
          category: "identityData",
          type: "input",
          order: 9,
          editDisabled: false,
          validation: {
            required: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
            validityPeriod: false,
          },
        },
      },
      {
        id: "identity_emission_date",
        name: "Data de Emissão",
        tableProps: {
          selector: "identity_emission_date",
          sortable: false,
          searchable: false,
          type: "date",
          order: 8,
        },
        formProps: {
          category: "identityData",
          type: "input",
          order: 10,
          editDisabled: false,
          validation: {
            required: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
            validityPeriod: false,
          },
        },
      }, //TODO creationn date backend
      {
        id: "birthday",
        name: "Data de Nascimento",
        tableProps: {
          selector: "birthday",
          sortable: true,
          searchable: true,
          type: "date",
          order: 10,
        },
        formProps: {
          category: "personalData",
          type: "input",
          order: 12,
          editDisabled: false,
          validation: {
            required: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
            validityPeriod: false,
          },
        },
      },
      {
        id: "identity_number",
        name: "RG",
        tableProps: {
          selector: "identity_number",
          sortable: false,
          searchable: false,
          type: "string",
          order: 9,
        },
        formProps: {
          category: "identityData",
          type: "input",
          order: 13,

          editDisabled: false,
          validation: {
            required: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
            validityPeriod: false,
          },
        },
      },
      {
        id: "education_level_id",
        name: "Código Grau de Instrução",
        tableProps: {
          selector: "education_level",
          sortable: true,
          searchable: true,
          type: "string",
          order: 11,
        },
        formProps: {
          category: "personalData",
          type: "select",
          selectState: "fixedOptions",
          order: 14,
          fixedOptions: [
            { id: 1, name: "Analfabeto" },
            { id: 2, name: "Até o 5º ano incompleto" },
            { id: 3, name: "5º ano completo" },
            { id: 4, name: "Do 6º ao 9º ano" },
            { id: 5, name: "Ensino Fundamental completo" },
            { id: 6, name: "Ensino Médio incompleto" },
            { id: 7, name: "Ensino Médio completo" },
            { id: 8, name: "Educação Superior incompleta" },
            { id: 9, name: "Educação Superior completa" },
            { id: 10, name: "Mestrado completo" },
            { id: 11, name: "Doutorado completo" },
          ],
          editDisabled: false,
          validation: {
            required: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
            validityPeriod: false,
          },
        },
      },

      {
        id: "race_id",
        name: "Código raca-cor",
        tableProps: {
          selector: "race", ///alias na Query do SQL
          sortable: true,
          searchable: true,
          type: "string",
          order: 12,
        },
        formProps: {
          category: "personalData",
          type: "select",
          selectState: "fixedOptions",
          fixedOptions: [
            { id: 1, name: "Indígena" },
            { id: 2, name: "Branco(a)" },
            { id: 8, name: "Pardo(a)" },
            { id: 4, name: "Preto(a)" },
            { id: 6, name: "Amarelo(a)" },
            { id: 9, name: "Não informado" },
          ],
          order: 15,
          unmaskValue: false,
          editDisabled: false,
          validation: {
            required: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            conditionalDate: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "birth_city",
        name: "Código IBGE de Nascimento",
        formProps: {
          category: "identityData",
          type: "input",

          order: 16,

          editDisabled: false,
          validation: {
            required: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
            validityPeriod: false,
          },
        },
      },
      {
        id: "org_id",
        name: "Código Organização",
        formProps: {
          category: "generalData",
          type: "input",
          order: 17,

          editDisabled: false,
          validation: {
            required: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
            validityPeriod: false,
          },
        },
      },
      {
        id: "sex_id",
        name: "Código sexo",
        tableProps: {
          selector: "sex", ///alias na Query do SQL
          sortable: true,
          searchable: true,
          type: "string",
          order: 9,
        },

        formProps: {
          type: "select",
          selectState: "fixedOptions",
          fixedOptions: [
            { id: 1, name: "Masculino" },
            { id: 2, name: "Feminino" },
          ],
          category: "personalData",
          conditionalSelect: false,
          masterSelect: false,
          parentSelect: null,
          childSelects: null,
          order: 18,
          unmaskValue: false,
          editDisabled: false,
          validation: {
            required: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            conditionalDate: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "cnes",
        name: "Código CNES",
        tableProps: {
          selector: "cnes", ///alias na Query do SQL
          sortable: true,
          searchable: true,
          type: "string",
          order: 0,
        },
        formProps: {
          category: "generalData",
          order: 19,
          type: "input",
          editDisabled: false,
          validation: {
            required: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            conditionalDate: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
    ],

    initialSort: "name",
    initialOrder: "asc",
    selectableRows: true,
    dateRef: true,
    createEditCategories: ["generalData", "personalData", "identityData"], 
    omittedColumns: ["month_ref", "year_ref"],
    newItemIcon: {
      name: "Trello",
      type: "feather-icon",
    },
  },
  expenses: {
    fields: [
      {
        id: "id",
        name: "ID",
        tableProps: {
          selector: "id",
          sortable: true,
          searchable: true,
          type: "string",
          minWidth: "23.5rem",
          order: 0,
        },
      },
      {
        id: "org",
        name: "Organização Social",
        tableProps: {
          selector: "org",
          sortable: true,
          searchable: true,
          type: "string",
          minWidth: "23.5rem",
          order: 1,
        },
      },
      {
        id: "unit",
        name: "Unidade",
        tableProps: {
          selector: "unit",
          sortable: true,
          searchable: true,
          type: "string",
          minWidth: "23.5rem",
          order: 2,
        },
      },
      {
        id: "contract_number",
        name: "Contrato",
        tableProps: {
          selector: "contract_number",
          sortable: true,
          searchable: true,
          type: "string",
          minWidth: "23.5rem",
          order: 3,
        },
      },
      {
        id: "cnpj",
        name: "CNPJ",
        tableProps: {
          selector: "cnpj",
          sortable: true,
          searchable: true,
          type: "string",
          minWidth: "23.5rem",
          order: 4,
        },
        formProps: {
          type: "input",
          mask: "##.###.###/####-##",
          maskPlaceholder: "_",
          unmaskValue: false,
          category: "generalData",
          order: 5,
          conditionalVisibility: true,
          parentVisibility: "type_of_benefited",
          visibilityReq: ["Pessoa Jurídica"],
          editDisabled: false,
          validation: {
            required: true,
            conditionalRequired: true,
            mainConditionRef: "type_of_benefited",
            mainConditionValue: "Pessoa Jurídica",
            minChar: null,
            email: false,
            cpf: false,
            cnpj: true,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "corporate_name",
        name: "Razão Social",
        tableProps: {
          selector: "corporate_name",
          sortable: true,
          searchable: true,
          type: "string",
          minWidth: "23.5rem",
          order: 5,
        },
        formProps: {
          type: "input",
          category: "generalData",
          order: 4,
          conditionalVisibility: true,
          parentVisibility: "type_of_benefited",
          visibilityReq: ["Pessoa Jurídica"],
          editDisabled: false,
          validation: {
            required: true,
            conditionalRequired: true,
            mainConditionRef: "type_of_benefited",
            mainConditionValue: "Pessoa Jurídica",
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "cpf",
        name: "CPF",
        tableProps: {
          selector: "cpf",
          sortable: true,
          searchable: true,
          type: "string",
          minWidth: "23.5rem",
          order: 6,
        },
        formProps: {
          type: "input",
          mask: "###.###.###-##",
          maskPlaceholder: "_",
          unmaskValue: true,
          category: "generalData",
          order: 7,
          conditionalVisibility: true,
          parentVisibility: "type_of_benefited",
          visibilityReq: ["Pessoa Física"],
          editDisabled: false,
          validation: {
            required: true,
            conditionalRequired: true,
            mainConditionRef: "type_of_benefited",
            mainConditionValue: "Pessoa Física",
            minChar: null,
            email: false,
            cpf: true,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "name",
        name: "Nome",
        tableProps: {
          selector: "name",
          sortable: true,
          searchable: true,
          type: "string",
          minWidth: "23.5rem",
          order: 7,
        },
        formProps: {
          type: "input",
          category: "generalData",
          order: 6,
          conditionalVisibility: true,
          parentVisibility: "type_of_benefited",
          visibilityReq: ["Pessoa Física"],
          editDisabled: false,
          validation: {
            required: true,
            conditionalRequired: true,
            mainConditionRef: "type_of_benefited",
            mainConditionValue: "Pessoa Física",
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "chart_of_accounts_item",
        name: "Despesa",
        tableProps: {
          selector: "chart_of_accounts_item",
          sortable: true,
          searchable: true,
          type: "string",
          minWidth: "23.5rem",
          order: 8,
        },
      },
      {
        id: "heading",
        name: "Rubrica",
        tableProps: {
          selector: "heading",
          sortable: true,
          searchable: true,
          type: "string",
          minWidth: "23.5rem",
          order: 9,
        },
      },
      {
        id: "document_value",
        name: "Valor",
        tableProps: {
          selector: "document_value",
          sortable: true,
          searchable: true,
          type: "currency",
          minWidth: "23.5rem",
          order: 10,
        },
        formProps: {
          type: "input",
          inputType: "currency",
          allowZero: false,
          category: "paymentDates",
          order: 0,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "amount_paid",
        name: "Valor de Pagamento",
        tableProps: {
          selector: "amount_paid",
          sortable: true,
          searchable: true,
          type: "currency",
          minWidth: "23.5rem",
          order: 11,
        },
        formProps: {
          type: "input",
          inputType: "currency",
          allowZero: false,
          category: "paymentDates",
          order: 1,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "due_date",
        name: "Data de Vencimento",
        tableProps: {
          selector: "due_date",
          sortable: true,
          searchable: true,
          type: "date",
          minWidth: "23.5rem",
          order: 12,
        },
        formProps: {
          type: "input",
          mask: "####-##-##",
          maskPlaceholder: ["a", "a", "a", "a", "m", "m", "d", "d"],
          unmaskValue: false,
          category: "dates",
          order: 2,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: true,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "payday",
        name: "Data de Pagamento",
        tableProps: {
          selector: "payday",
          sortable: true,
          searchable: true,
          type: "date",
          minWidth: "23.5rem",
          order: 13,
        },
        formProps: {
          type: "input",
          mask: "####-##-##",
          maskPlaceholder: ["a", "a", "a", "a", "m", "m", "d", "d"],
          unmaskValue: false,
          category: "dates",
          order: 4,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: true,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "month_ref",
        name: "Mês",
        tableProps: {
          selector: "month_ref",
          sortable: true,
          searchable: true,
          type: "string",
          minWidth: "23.5rem",
          order: 14,
        },
        formProps: {
          type: "select",
          selectState: "fixedOptions",
          fixedOptions: [
            { id: 1, name: "Janeiro" },
            { id: 2, name: "Fevereiro" },
            { id: 3, name: "Março" },
            { id: 4, name: "Abril" },
            { id: 5, name: "Maio" },
            { id: 6, name: "Junho" },
            { id: 7, name: "Julho" },
            { id: 8, name: "Agosto" },
            { id: 9, name: "Setembro" },
            { id: 10, name: "Outubro" },
            { id: 11, name: "Novembro" },
            { id: 12, name: "Dezembro" },
          ],
          category: "dates",
          order: 0,
          editDisabled: true,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "year_ref",
        name: "Ano",
        tableProps: {
          selector: "year_ref",
          sortable: true,
          searchable: true,
          type: "string",
          minWidth: "23.5rem",
          order: 15,
        },
        formProps: {
          type: "select",
          selectState: "fixedOptions",
          fixedOptions: getYearOptions(),
          category: "dates",
          order: 1,
          editDisabled: true,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "doc_number",
        name: "Nº da NF",
        tableProps: {
          selector: "doc_number",
          sortable: true,
          searchable: true,
          type: "string",
          minWidth: "23.5rem",
          order: 16,
        },
        formProps: {
          type: "input",
          category: "generalData",
          order: 12,
          conditionalVisibility: true,
          parentVisibility: "type_id",
          subVisibility: "no_tax_code",
          visibilityReq: [1],
          editDisabled: false,
          validation: {
            required: true,
            conditionalRequired: true,
            mainConditionRef: "type_id",
            mainConditionValue: 1,
            subConditionRef: "no_tax_code",
            subConditionValue: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "type",
        name: "Tipo de Documento",
        tableProps: {
          selector: "type",
          sortable: true,
          searchable: true,
          type: "string",
          minWidth: "23.5rem",
          order: 17,
        },
      },
      {
        id: "document_filename",
        name: "Documento",
        tableProps: {
          selector: "document_filename",
          sortable: true,
          searchable: true,
          type: "pdf_file",
          minWidth: "23.5rem",
          order: 18,
        },
        formProps: {
          type: "input",
          category: "generalData",
          order: 0,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
            filenamePDF: true,
          },
        },
      },
      {
        id: "monthly_installment",
        name: "Parcela Mensal",
        tableProps: {
          selector: "monthly_installment",
          sortable: true,
          searchable: true,
          type: "string",
          minWidth: "23.5rem",
          order: 19,
        },
        formProps: {
          type: "input",
          inputType: "number",
          category: "paymentDates",
          order: 4,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "total_installment",
        name: "Parcela Total",
        tableProps: {
          selector: "total_installment",
          sortable: true,
          searchable: true,
          type: "string",
          minWidth: "23.5rem",
          order: 20,
        },
        formProps: {
          type: "input",
          inputType: "number",
          category: "paymentDates",
          order: 5,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "agency",
        name: "Agência",
        tableProps: {
          selector: "agency",
          sortable: true,
          searchable: true,
          type: "string",
          minWidth: "23.5rem",
          order: 21,
        },
      },
      {
        id: "bank",
        name: "Banco",
        tableProps: {
          selector: "bank",
          sortable: true,
          searchable: true,
          type: "string",
          minWidth: "23.5rem",
          order: 22,
        },
      },
      {
        id: "bank_account",
        name: "Conta Bancária",
        tableProps: {
          selector: "bank_account",
          sortable: true,
          searchable: true,
          type: "string",
          minWidth: "23.5rem",
          order: 23,
        },
      },
      // category generalData
      {
        id: "contract_id",
        name: "Contrato",
        formProps: {
          type: "select",
          category: "generalData",
          order: 1,
          selectState: "contractOptions",
          conditionalSelect: false,
          masterSelect: true,
          parentSelect: null,
          childSelects: ["unit_id"],
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "unit_id",
        name: "Unidade",
        formProps: {
          type: "select",
          category: "generalData",
          selectState: "unitsByContract",
          order: 2,
          conditionalSelect: true,
          masterSelect: false,
          parentSelect: "contract_id",
          childSelects: null,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "type_of_benefited",
        name: "Tipo de Favorecido",
        formProps: {
          type: "select",
          category: "generalData",
          order: 3,
          selectState: "fixedOptions",
          fixedOptions: [
            { id: "Pessoa Física", name: "Pessoa Fisica" },
            { id: "Pessoa Jurídica", name: "Pessoa Jurídica" },
          ],
          conditionalSelect: false,
          masterSelect: false,
          parentSelect: null,
          childSelects: null,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "chart_of_accounts_item_id",
        name: "Despesa",
        formProps: {
          type: "select",
          category: "generalData",
          order: 8,
          selectState: "coaGroupsOptions",
          conditionalSelect: false,
          masterSelect: false,
          parentSelect: null,
          childSelects: null,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "heading_id",
        name: "Rubrica",
        formProps: {
          type: "select",
          category: "generalData",
          order: 9,
          selectState: "headingsByContract",
          conditionalSelect: true,
          masterSelect: false,
          parentSelect: "contract_id",
          childSelects: null,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "type_id",
        name: "Tipo de Documento",
        formProps: {
          type: "select",
          category: "generalData",
          order: 10,
          selectState: "docTypes",
          conditionalSelect: false,
          masterSelect: false,
          parentSelect: null,
          childSelects: null,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "no_tax_code",
        name: "Código fiscal ausente",
        formProps: {
          type: "checkbox",
          category: "generalData",
          order: 11,
          conditionalVisibility: true,
          parentVisibility: "type_id",
          visibilityReq: [1],
          editDisabled: false,
          validation: {
            required: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "serie_number",
        name: "Série",
        formProps: {
          type: "input",
          category: "generalData",
          order: 13,
          conditionalVisibility: true,
          parentVisibility: "type_id",
          subVisibility: "no_tax_code",
          visibilityReq: [1],
          editDisabled: false,
          validation: {
            required: true,
            conditionalRequired: true,
            mainConditionRef: "type_id",
            mainConditionValue: 1,
            subConditionRef: "no_tax_code",
            subConditionValue: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "tax_code",
        name: "Código Fiscal",
        formProps: {
          type: "input",
          category: "generalData",
          order: 14,
          conditionalVisibility: true,
          parentVisibility: "type_id",
          subVisibility: "no_tax_code",
          visibilityReq: [1],
          editDisabled: false,
          validation: {
            required: true,
            conditionalRequired: true,
            mainConditionRef: "type_id",
            mainConditionValue: 1,
            subConditionRef: "no_tax_code",
            subConditionValue: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      //category paymentDates
      {
        id: "bank_account_id",
        name: "Conta Bancária",
        formProps: {
          type: "select",
          category: "paymentDates",
          order: 2,
          selectState: "bankAccountsByContract",
          conditionalSelect: true,
          masterSelect: false,
          parentSelect: "contract_id",
          childSelects: null,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "bank_code",
        name: "Identificador bancário",
        formProps: {
          type: "input",
          category: "paymentDates",
          order: 3,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
     //category dates
      {
        id: "issue_date",
        name: "Data de Emissão",
        formProps: {
          type: "input",
          mask: "####-##-##",
          maskPlaceholder: ["a", "a", "a", "a", "m", "m", "d", "d"],
          unmaskValue: false,
          category: "dates",
          order: 3,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: true,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "calculation_date",
        name: "Data de Apuração",
        formProps: {
          type: "input",
          mask: "####-##-##",
          maskPlaceholder: ["a", "a", "a", "a", "m", "m", "d", "d"],
          unmaskValue: false,
          category: "dates",
          order: 5,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: true,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
    ],
    initialSort: "id",
    initialOrder: "asc",
    selectableRows: true,
    dateRef: true,
    newItemIcon: {
      name: "FilePlus",
      type: "feather-icon",
    },
    createEditCategories: ["generalData", "paymentDates", "dates"],
    omittedColumns: ["agency", "bank", "bank_account", "month_ref", "year_ref"],
  },
  incomes: {
    fields: [
      {
        id: "org",
        name: "Organização Social",
        tableProps: {
          selector: "org",
          sortable: true,
          searchable: true,
          type: "string",
          order: 0,
        },
      },
      {
        id: "month_ref",
        name: "Mês",
        tableProps: {
          selector: "month_ref",
          sortable: true,
          searchable: true,
          type: "string",
          order: 1,
        },
        formProps: {
          type: "select",
          selectState: "fixedOptions",
          fixedOptions: [
            { id: 1, name: "Janeiro" },
            { id: 2, name: "Fevereiro" },
            { id: 3, name: "Março" },
            { id: 4, name: "Abril" },
            { id: 5, name: "Maio" },
            { id: 6, name: "Junho" },
            { id: 7, name: "Julho" },
            { id: 8, name: "Agosto" },
            { id: 9, name: "Setembro" },
            { id: 10, name: "Outubro" },
            { id: 11, name: "Novembro" },
            { id: 12, name: "Dezembro" },
          ],
          order: 0,
          editDisabled: true,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "year_ref",
        name: "Ano",
        tableProps: {
          selector: "year_ref",
          sortable: true,
          searchable: true,
          type: "string",
          order: 2,
        },
        formProps: {
          type: "select",
          selectState: "fixedOptions",
          fixedOptions: getYearOptions(),
          order: 1,
          editDisabled: true,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "contract_number",
        name: "Contrato",
        tableProps: {
          selector: "contract_number",
          sortable: true,
          searchable: true,
          type: "string",
          order: 3,
        },
      },
      {
        id: "contract_id",
        name: "Contrato",
        formProps: {
          type: "select",
          order: 2,
          selectState: "contractOptions",
          conditionalSelect: false,
          masterSelect: true,
          parentSelect: null,
          childSelects: ["bank_account_id"],
          editDisabled: true,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "bank_account",
        name: "Conta Corrente",
        tableProps: {
          selector: "bank_account",
          sortable: true,
          searchable: true,
          type: "string",
          order: 4,
        },
      },
      {
        id: "bank_account_id",
        name: "Conta Corrente",
        formProps: {
          type: "select",
          order: 3,
          selectState: "bankAccountsByContract",
          conditionalSelect: true,
          masterSelect: false,
          parentSelect: "contract_id",
          childSelects: null,
          editDisabled: true,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "agency",
        name: "Agência",
        tableProps: {
          selector: "agency",
          sortable: true,
          searchable: true,
          type: "string",
          order: 5,
        },
      },
      {
        id: "bank",
        name: "Banco",
        tableProps: {
          selector: "bank",
          sortable: true,
          searchable: true,
          type: "string",
          order: 6,
        },
      },
      {
        id: "fixed_part",
        name: "Parte Fixa",
        tableProps: {
          selector: "fixed_part",
          sortable: true,
          searchable: true,
          type: "currency",
          order: 7,
        },
        formProps: {
          type: "input",
          inputType: "currency",
          allowZero: true,
          order: 4,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "variable_part",
        name: "Parte Variável",
        tableProps: {
          selector: "variable_part",
          sortable: true,
          searchable: true,
          type: "currency",
          order: 8,
        },
        formProps: {
          type: "input",
          inputType: "currency",
          allowZero: true,
          order: 5,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "others",
        name: "Outras Verbas",
        tableProps: {
          selector: "others",
          sortable: true,
          searchable: true,
          type: "currency",
          order: 9,
        },
        formProps: {
          type: "input",
          inputType: "currency",
          allowZero: true,
          order: 6,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
    ],
    initialSort: "org",
    initialOrder: "asc",
    selectableRows: true,
    dateRef: true,
    newItemIcon: {
      name: "FilePlus",
      type: "feather-icon",
    },
    omittedColumns: ["month_ref", "year_ref"],
    createEditCategories: [],
  },
  balances: {
    fields: [
      {
        id: "org",
        name: "Organização Social",
        tableProps: {
          selector: "org",
          sortable: true,
          searchable: true,
          type: "string",
          minWidth: "23.5rem",
          order: 0,
        },
      },
      {
        id: "month_ref",
        name: "Mês",
        tableProps: {
          selector: "month_ref",
          sortable: true,
          searchable: true,
          type: "string",
          minWidth: "23.5rem",
          order: 1,
        },
        formProps: {
          type: "select",
          selectState: "fixedOptions",
          fixedOptions: [
            { id: 1, name: "Janeiro" },
            { id: 2, name: "Fevereiro" },
            { id: 3, name: "Março" },
            { id: 4, name: "Abril" },
            { id: 5, name: "Maio" },
            { id: 6, name: "Junho" },
            { id: 7, name: "Julho" },
            { id: 8, name: "Agosto" },
            { id: 9, name: "Setembro" },
            { id: 10, name: "Outubro" },
            { id: 11, name: "Novembro" },
            { id: 12, name: "Dezembro" },
          ],
          order: 0,
          editDisabled: true,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "year_ref",
        name: "Ano",
        tableProps: {
          selector: "year_ref",
          sortable: true,
          searchable: true,
          type: "string",
          minWidth: "23.5rem",
          order: 2,
        },
        formProps: {
          type: "select",
          selectState: "fixedOptions",
          fixedOptions: getYearOptions(),
          order: 1,
          editDisabled: true,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "contract_number",
        name: "Contrato",
        tableProps: {
          selector: "contract_number",
          sortable: true,
          searchable: true,
          type: "string",
          minWidth: "23.5rem",
          order: 3,
        },
      },
      {
        id: "contract_id",
        name: "Contrato",
        formProps: {
          type: "select",
          order: 2,
          selectState: "contractOptions",
          conditionalSelect: false,
          masterSelect: true,
          parentSelect: null,
          childSelects: ["bank_account_id"],
          editDisabled: true,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "bank_account",
        name: "Conta Corrente",
        tableProps: {
          selector: "bank_account",
          sortable: true,
          searchable: true,
          type: "string",
          minWidth: "23.5rem",
          order: 4,
        },
      },
      {
        id: "bank_account_id",
        name: "Conta Corrente",
        formProps: {
          type: "select",
          order: 3,
          selectState: "bankAccountsByContract",
          conditionalSelect: true,
          masterSelect: false,
          parentSelect: "contract_id",
          childSelects: null,
          editDisabled: true,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "agency",
        name: "Agência",
        tableProps: {
          selector: "agency",
          sortable: true,
          searchable: true,
          type: "string",
          minWidth: "23.5rem",
          order: 5,
        },
      },
      {
        id: "bank",
        name: "Banco",
        tableProps: {
          selector: "bank",
          sortable: true,
          searchable: true,
          type: "string",
          minWidth: "23.5rem",
          order: 6,
        },
      },
      {
        id: "bank_account_type",
        name: "Tipo de Conta",
        tableProps: {
          selector: "bank_account_type",
          sortable: true,
          searchable: true,
          type: "string",
          minWidth: "23.5rem",
          order: 7,
        },
      },
      {
        id: "account_value",
        name: "Valor em conta corrente",
        tableProps: {
          selector: "account_value",
          sortable: true,
          searchable: true,
          type: "currency",
          minWidth: "23.5rem",
          order: 8,
        },
        formProps: {
          type: "input",
          inputType: "currency",
          allowZero: true,
          order: 4,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "financial_application",
        name: "Aplicação Financeira",
        tableProps: {
          selector: "financial_application",
          sortable: true,
          searchable: true,
          type: "currency",
          minWidth: "23.5rem",
          order: 9,
        },
        formProps: {
          type: "input",
          inputType: "currency",
          allowZero: true,
          order: 5,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "provision",
        name: "Provisão",
        tableProps: {
          selector: "provision",
          sortable: true,
          searchable: true,
          type: "currency",
          minWidth: "23.5rem",
          order: 10,
        },
        formProps: {
          type: "input",
          inputType: "currency",
          allowZero: true,
          order: 6,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "value_in_kind",
        name: "Valor em espécie",
        tableProps: {
          selector: "value_in_kind",
          sortable: true,
          searchable: true,
          type: "currency",
          minWidth: "23.5rem",
          order: 11,
        },
        formProps: {
          type: "input",
          inputType: "currency",
          allowZero: true,
          order: 7,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "sum",
        name: "Soma dos Saldos",
        tableProps: {
          selector: "sum",
          sortable: true,
          searchable: true,
          type: "currency",
          minWidth: "23.5rem",
          order: 12,
        },
      },
      {
        id: "document_filename",
        name: "Nº da NF",
        tableProps: {
          selector: "document_filename",
          sortable: true,
          searchable: true,
          type: "pdf_file",
          minWidth: "23.5rem",
          order: 13,
        },
        formProps: {
          type: "input",
          order: 8,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
            filenamePDF: true,
          },
        },
      },
    ],
    initialSort: "org",
    initialOrder: "asc",
    selectableRows: true,
    newItemIcon: {
      name: "FilePlus",
      type: "feather-icon",
    },
    dateRef: true,
    createEditCategories: [],
    omittedColumns: ["month_ref", "year_ref"],
  },
  providers: {
    fields: [
      {
        id: "id",
        name: "ID",
        tableProps: {
          selector: "id",
          sortable: true,
          searchable: true,
          minWidth: "23.5rem",
          type: "string",
          order: 0,
        },
      },
      {
        id: "name",
        name: "Nome do Fornecedor",
        tableProps: {
          selector: "name",
          sortable: true,
          searchable: true,
          minWidth: "23.5rem",
          type: "string",
          order: 1,
        },
        formProps: {
          type: "input",
          order: 1,
          conditionalVisibility: true,
          parentVisibility: "pj_pf",
          visibilityReq: ["J", "F"],
          conditionalLabels: ["social_name", "name"],
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "cnpj_cpf",
        name: "CNPJ/CPF",
        tableProps: {
          selector: "cnpj_cpf",
          sortable: true,
          searchable: true,
          minWidth: "23.5rem",
          type: "string",
          order: 2,
        },
        formProps: {
          type: "input",
          order: 2,
          mask: true,
          conditionalMasks: ["##.###.###/####-##", "###.###.###-##"],
          conditionalMaskRef: ["J", "F"],
          conditionalMaskParent: "pj_pf",
          maskPlaceholder: "_",
          conditionalVisibility: true,
          parentVisibility: "pj_pf",
          visibilityReq: ["J", "F"],
          conditionalLabels: ["cnpj", "cpf"],
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            conditionalCPFCNPJ: true,
            cpfcnpjRef: "pj_pf",
            cpf: false,
            cnpj: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "email",
        name: "Email",
        tableProps: {
          selector: "email",
          sortable: true,
          searchable: true,
          minWidth: "23.5rem",
          type: "string",
          order: 3,
        },
        formProps: {
          type: "input",
          order: 18,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: true,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "pj_pf",
        name: "Tipo de Pessoa",
        tableProps: {
          selector: "pj_pf",
          sortable: true,
          searchable: true,
          minWidth: "23.5rem",
          type: "pj_pf",
          order: 4,
        },
        formProps: {
          type: "select",
          order: 0,
          selectState: "fixedOptions",
          fixedOptions: [
            { id: "F", name: "Pessoa Física" },
            { id: "J", name: "Pessoa Jurídica" },
          ],
          conditionalSelect: false,
          masterSelect: false,
          parentSelect: null,
          childSelects: null,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "address",
        name: "Endereço",
        tableProps: {
          selector: "address",
          sortable: true,
          searchable: true,
          minWidth: "23.5rem",
          type: "string",
          order: 5,
        },
        formProps: {
          type: "input",
          order: 5,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "address_number",
        name: "Número",
        formProps: {
          type: "input",
          order: 6,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "address_complement",
        name: "Complemento",
        tableProps: {
          selector: "address_complement",
          sortable: true,
          searchable: true,
          minWidth: "23.5rem",
          type: "string",
          order: 6,
        },
        formProps: {
          type: "input",
          order: 7,
          editDisabled: false,
          validation: {
            required: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "city",
        name: "Cidade",
        tableProps: {
          selector: "city",
          sortable: true,
          searchable: true,
          minWidth: "23.5rem",
          type: "string",
          order: 7,
        },
        formProps: {
          type: "input",
          order: 10,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "contact_name",
        name: "Contato",
        tableProps: {
          selector: "contact_name",
          sortable: true,
          searchable: true,
          minWidth: "23.5rem",
          type: "string",
          order: 8,
        },
        formProps: {
          type: "input",
          order: 19,
          editDisabled: false,
          validation: {
            required: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "created_by",
        name: "Criado em",
        tableProps: {
          selector: "created_by",
          sortable: true,
          searchable: true,
          minWidth: "23.5rem",
          type: "date",
          order: 9,
        },
      },
      {
        id: "neighborhood",
        name: "Bairro",
        tableProps: {
          selector: "neighborhood",
          sortable: true,
          searchable: true,
          minWidth: "23.5rem",
          type: "string",
          order: 10,
        },
        formProps: {
          type: "input",
          order: 9,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "phone1",
        name: "Telefone 1",
        tableProps: {
          selector: "phone1",
          sortable: true,
          searchable: true,
          minWidth: "23.5rem",
          type: "string",
          order: 11,
        },
        formProps: {
          type: "input",
          mask: "(##) ####-####",
          maskPlaceholder: "_",
          unmaskValue: true,
          order: 13,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            phone: true,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "phone1_extension",
        name: "Ramal 1",
        tableProps: {
          selector: "phone1_extension",
          sortable: true,
          searchable: true,
          minWidth: "23.5rem",
          type: "string",
          order: 12,
        },
        formProps: {
          type: "input",
          inputType: "number",
          order: 14,
          editDisabled: false,
          validation: {
            required: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "phone2",
        name: "Telefone 2",
        tableProps: {
          selector: "phone 2",
          sortable: true,
          searchable: true,
          minWidth: "23.5rem",
          type: "string",
          order: 13,
        },
        formProps: {
          type: "input",
          mask: "(##) ####-####",
          maskPlaceholder: "_",
          unmaskValue: true,
          order: 15,
          editDisabled: false,
          validation: {
            required: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            phone: true,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "phone2_extension",
        name: "Ramal 2",
        tableProps: {
          selector: "Ramal 2",
          sortable: true,
          searchable: true,
          minWidth: "23.5rem",
          type: "string",
          order: 14,
        },
        formProps: {
          type: "input",
          inputType: "number",
          order: 16,
          editDisabled: false,
          validation: {
            required: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "reference",
        name: "Ponto de Referência",
        tableProps: {
          selector: "reference",
          sortable: true,
          searchable: true,
          minWidth: "23.5rem",
          type: "string",
          order: 15,
        },
        formProps: {
          type: "input",
          order: 12,
          editDisabled: false,
          validation: {
            required: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "uf",
        name: "UF",
        tableProps: {
          selector: "uf",
          sortable: true,
          searchable: true,
          minWidth: "23.5rem",
          type: "string",
          order: 16,
        },
        formProps: {
          type: "select",
          order: 11,
          selectState: "states",
          conditionalSelect: false,
          masterSelect: false,
          parentSelect: null,
          childSelects: null,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "whatsapp",
        name: "WhatsApp",
        tableProps: {
          selector: "whatsapp",
          sortable: true,
          searchable: true,
          minWidth: "23.5rem",
          type: "string",
          order: 17,
        },
        formProps: {
          type: "input",
          mask: "(##) #####-####",
          maskPlaceholder: "_",
          unmaskValue: true,
          order: 17,
          editDisabled: false,
          validation: {
            required: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: true,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "zipcode",
        name: "CEP",
        tableProps: {
          selector: "zipcode",
          sortable: true,
          searchable: true,
          minWidth: "23.5rem",
          type: "string",
          order: 18,
        },
        formProps: {
          type: "input",
          mask: "#####-###",
          maskPlaceholder: "_",
          unmaskValue: false,
          order: 8,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
            zipcode: true,
          },
        },
      },
    ],
    initialSort: "name",
    initialOrder: "asc",
    selectableRows: true,
    newItemIcon: {
      name: "FilePlus",
      type: "feather-icon",
    },
    createEditCategories: [],
    omittedColumns: [
      "address",
      "address_complement",
      "city",
      "contact_name",
      "created_by",
      "id",
      "neighborhood",
      "phone1",
      "phone1_extension",
      "phone2",
      "phone2_extension",
      "reference",
      "uf",
      "whatsapp",
      "zipcode",
    ],
  },
  purchase_process: {
    fields: [
      {
        id: "process_number",
        name: "Identificador do Processo",
        tableProps: {
          selector: "process_number",
          sortable: true,
          searchable: true,
          minWidth: "23.5rem",
          type: "string",
          order: 0,
        },
        formProps: {
          type: "input",
          order: 4,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "process_mode",
        name: "Modalidade",
        tableProps: {
          selector: "process_mode",
          sortable: true,
          searchable: true,
          minWidth: "23.5rem",
          type: "string",
          order: 1,
        },
      },
      {
        id: "process_mode_id",
        name: "Modalidade",
        formProps: {
          type: "select",
          order: 0,
          conditionalSelect: false,
          masterSelect: false,
          parentSelect: null,
          childSelects: null,
          selectState: "modeOptions",
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "process_link",
        name: "Link da Publicação",
        tableProps: {
          selector: "process_link",
          sortable: false,
          searchable: false,
          minWidth: "23.5rem",
          type: "link",
          order: 2,
        },
        formProps: {
          type: "input",
          order: 3,
          editDisabled: false,
          validation: {
            required: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "provider_winner_code",
        name: "CNPJ Vencedor",
        tableProps: {
          selector: "provider_winner_code",
          sortable: true,
          searchable: true,
          minWidth: "23.5rem",
          type: "string",
          order: 3,
        },
      },
      {
        id: "provider_winner_name",
        name: "Razão Social",
        tableProps: {
          selector: "provider_winner_name",
          sortable: true,
          searchable: true,
          minWidth: "23.5rem",
          type: "string",
          order: 4,
        },
      },
      {
        id: "provider_winner_price",
        name: "Preço Praticado",
        tableProps: {
          selector: "provider_winner_price",
          sortable: true,
          searchable: true,
          minWidth: "23.5rem",
          type: "currency",
          order: 5,
        },
      },
      {
        id: "object_description",
        name: "Objeto Comprado",
        tableProps: {
          selector: "object_description",
          sortable: true,
          searchable: true,
          minWidth: "23.5rem",
          maxWidth: "35rem",
          type: "string",
          order: 6,
        },
        formProps: {
          type: "inputTextArea",
          order: 5,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "competitors",
        name: "Detalhes",
        tableProps: {
          selector: "competitors",
          sortable: false,
          searchable: false,
          minWidth: "23.5rem",
          type: "table",
          order: 7,
        },
        formProps: {
          type: "dynamicInput",
          order: 8,
          selectState: "providerOptions",
          allowZero: false,
          inputs: ["id", "value", "winner"],
          editDisabled: false,
          validation: {
            required: false,
            dynamicInput: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "release_date",
        name: "Data de Lançamento",
        formProps: {
          type: "input",
          mask: "##/##/####",
          maskPlaceholder: ["d", "d", "m", "m", "a", "a", "a", "a"],
          unmaskValue: false,
          order: 1,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: true,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "result_date",
        name: "Data do Resultado",
        formProps: {
          type: "input",
          mask: "##/##/####",
          maskPlaceholder: ["d", "d", "m", "m", "a", "a", "a", "a"],
          unmaskValue: false,
          order: 2,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: true,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "call_filename",
        name: "Documento da Chamada",
        formProps: {
          type: "input",
          order: 6,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
            filenamePDF: true,
          },
        },
      },
      {
        id: "result_filename",
        name: "Documento do Resultado",
        formProps: {
          type: "input",
          order: 7,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
            filenamePDF: true,
          },
        },
      },
    ],
    initialSort: "process_number",
    initialOrder: "asc",
    selectableRows: true,
    newItemIcon: {
      name: "FilePlus",
      type: "feather-icon",
    },
    createEditCategories: [],
    omittedColumns: [],
  },
  third_party_contracts: {
    fields: [
      {
        id: "org",
        name: "Organização",
        tableProps: {
          selector: "org",
          sortable: true,
          searchable: true,
          type: "string",
          order: 0,
        },
      },
      {
        id: "contract_number",
        name: "Contrato",
        tableProps: {
          selector: "contract_number",
          sortable: true,
          searchable: true,
          type: "string",
          order: 1,
        },
      },
      {
        id: "contract_id",
        name: "Contrato",
        formProps: {
          type: "select",
          order: 0,
          selectState: "contractOptions",
          conditionalSelect: false,
          masterSelect: false,
          parentSelect: null,
          childSelects: null,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "provider_name",
        name: "Nome",
        tableProps: {
          selector: "provider_name",
          sortable: true,
          searchable: true,
          type: "string",
          order: 2,
        },
      },
      {
        id: "signature_date",
        name: "Data de assinatura",
        tableProps: {
          selector: "signature_date",
          sortable: true,
          searchable: true,
          type: "string",
          order: 3,
        },
        formProps: {
          type: "input",
          mask: "##/##/####",
          maskPlaceholder: ["d", "d", "m", "m", "a", "a", "a", "a"],
          unmaskValue: false,
          order: 8,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "end_term_date",
        name: "Fim da Vigência",
        tableProps: {
          selector: "end_term_date",
          sortable: true,
          searchable: true,
          type: "string",
          order: 4,
        },
        formProps: {
          type: "input",
          mask: "##/##/####",
          maskPlaceholder: ["d", "d", "m", "m", "a", "a", "a", "a"],
          unmaskValue: false,
          order: 9,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "monthly_value",
        name: "Valor Mensal",
        tableProps: {
          selector: "monthly_value",
          sortable: true,
          searchable: true,
          type: "currency",
          order: 5,
        },
        formProps: {
          type: "input",
          inputType: "currency",
          order: 7,
          conditionalVisibility: true,
          parentVisibility: "purchase_process_id",
          visibilityReq: [],
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "filename",
        name: "Arquivo do Contrato",
        tableProps: {
          selector: "filename",
          sortable: true,
          searchable: true,
          type: "pdf_file",
          order: 6,
        },
        formProps: {
          type: "input",
          order: 10,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
            filenamePDF: true,
          },
        },
      },
      {
        id: "unit_id",
        name: "Unidade",
        formProps: {
          type: "select",
          order: 1,
          selectState: "unitsByContract",
          conditionalSelect: true,
          masterSelect: false,
          parentSelect: "contract_id",
          childSelects: null,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "description",
        name: "Descrição dos Serviços",
        formProps: {
          type: "inputTextArea",
          order: 2,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "month_ref",
        name: "Mês de Referência",
        formProps: {
          type: "select",
          selectState: "fixedOptions",
          fixedOptions: [
            { id: 1, name: "Janeiro" },
            { id: 2, name: "Fevereiro" },
            { id: 3, name: "Março" },
            { id: 4, name: "Abril" },
            { id: 5, name: "Maio" },
            { id: 6, name: "Junho" },
            { id: 7, name: "Julho" },
            { id: 8, name: "Agosto" },
            { id: 9, name: "Setembro" },
            { id: 10, name: "Outubro" },
            { id: 11, name: "Novembro" },
            { id: 12, name: "Dezembro" },
          ],
          order: 3,
          editDisabled: true,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "year_ref",
        name: "Ano de Referência",
        formProps: {
          type: "select",
          selectState: "fixedOptions",
          fixedOptions: getYearOptions(),
          order: 4,
          editDisabled: true,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "purchase_process_id",
        name: "Processo de compra",
        tableProps: {
          selector: "purchase_process_number",
          sortable: true,
          searchable: true,
          type: "string",
          order: 7,
        },
        formProps: {
          type: "select",
          order: 5,
          selectState: "purchaseProcessOptions",
          conditionalSelect: false,
          masterSelect: false,
          parentSelect: null,
          childSelects: null,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "provider_id",
        name: "Fornecedor",
        formProps: {
          type: "select",
          order: 6,
          selectState: "providerOptions",
          conditionalSelect: false,
          masterSelect: false,
          parentSelect: null,
          childSelects: null,
          conditionalVisibility: true,
          parentVisibility: "purchase_process_id",
          visibilityReq: [],
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
    ],
    initialSort: "org",
    initialOrder: "asc",
    selectableRows: true,
    dateRef: true,
    newItemIcon: {
      name: "FilePlus",
      type: "feather-icon",
    },
    createEditCategories: [],
    omittedColumns: [],
  },
  organizations: {
    fields: [
      {
        id: "initials",
        name: "Nome",
        tableProps: {
          selector: "initials",
          sortable: true,
          searchable: true,
          type: "string",
          order: 0,
        },
      },
      {
        id: "email",
        name: "Email",
        tableProps: {
          selector: "email",
          sortable: true,
          searchable: true,
          type: "string",
          order: 1,
        },
      },
      {
        id: "org_type",
        name: "Categoria",
        tableProps: {
          selector: "org_type",
          sortable: true,
          searchable: true,
          type: "string",
          order: 2,
        },
      },
      {
        id: "cnpj",
        name: "CNPJ",
        tableProps: {
          selector: "cnpj",
          sortable: true,
          searchable: true,
          type: "cnpj",
          order: 3,
        },
      },
      {
        id: "contact",
        name: "Contato",
        tableProps: {
          selector: "contact",
          sortable: true,
          searchable: true,
          type: "string",
          order: 4,
        },
      },
    ],
    initialSort: "initials",
    initialOrder: "asc",
    selectableRows: false,
    dateRef: false,
    newItemIcon: {
      name: "FilePlus",
      type: "feather-icon",
    },
    createEditCategories: [],
    omittedColumns: [],
  },
  fiscal_document: {
    fields: [
      {
        id: "document_name",
        name: "Documento",
        tableProps: {
          selector: "document_name",
          sortable: true,
          wrap: true,
          minWidth: "30rem",
          searchable: true,
          type: "string",
          order: 0,
        },
      },
      {
        id: "issued_by",
        name: "Orgão",
        tableProps: {
          selector: "issued_by",
          sortable: true,
          searchable: true,
          type: "string",
          minWidth: "20rem",
          wrap: true,
          order: 1,
        },
      },
      {
        id: "filename",
        name: "Link",
        tableProps: {
          selector: "filename",
          sortable: true,
          searchable: false,
          type: "pdf_file",
          minWidth: "15rem",
          order: 2,
        },
      },
      {
        id: "expiration_date",
        name: "Validade",
        tableProps: {
          selector: "expiration_date",
          sortable: true,
          searchable: true,
          type: "string",
          minWidth: "9rem",
          order: 3,
        },
      },
      {
        id: "expiration_status",
        name: "Status de Vencimento",
        tableProps: {
          selector: "expiration_status",
          sortable: false,
          searchable: false,
          type: "boolean",
          minWidth: "14rem",
          order: 4,
        },
      },
      {
        id: "validator",
        name: "Validador",
        tableProps: {
          selector: "validator",
          sortable: true,
          searchable: true,
          wrap: true,
          type: "string",
          minWidth: "25rem",
          order: 5,
        },
      },
      {
        id: "validation_date",
        name: "Data de Validação",
        tableProps: {
          selector: "validation_date",
          sortable: true,
          searchable: true,
          type: "string",
          minWidth: "9rem",
          order: 6,
        },
      },
      {
        id: "validation_status",
        name: "Status de Validação",
        tableProps: {
          selector: "validation_status",
          sortable: false,
          searchable: false,
          type: "boolean",
          minWidth: "15rem",
          order: 7,
        },
      },
      {
        id: "validate",
        name: "Validar",
        tableProps: {
          selector: "validate",
          sortable: false,
          searchable: false,
          type: "validate_button",
          order: 8,
        },
      },
      {
        id: "update",
        name: "Atualizar",
        tableProps: {
          selector: "update",
          sortable: false,
          searchable: false,
          type: "update_button",
          minWidth: "17rem",
          order: 9,
        },
      },
    ],
    initialSort: "document_name",
    initialOrder: "asc",
    selectableRows: false,
    dateRef: false,
    omittedColumns: [],
  },
  certificates: {
    fields: [
      {
        id: "contract_number",
        name: "Número do contrato",
        tableProps: {
          selector: "contract_number",
          sortable: true,
          searchable: true,
          type: "string",
          order: 0,
        },
      },
      {
        id: "month_ref",
        name: "Mês",
        tableProps: {
          selector: "month_ref",
          sortable: true,
          searchable: true,
          type: "string",
          order: 1,
        },
      },
      {
        id: "year_ref",
        name: "Ano",
        tableProps: {
          selector: "year_ref",
          sortable: true,
          searchable: true,
          type: "string",
          order: 2,
        },
      },
      {
        id: "issue_date",
        name: "Data de emissão",
        tableProps: {
          selector: "issue_date",
          sortable: true,
          searchable: true,
          type: "string",
          order: 3,
        },
      },
      {
        id: "filename",
        name: "Certificado",
        tableProps: {
          selector: "filename",
          sortable: true,
          searchable: true,
          type: "pdf_file",
          order: 4,
        },
      },
      {
        id: "status",
        name: "Status",
        tableProps: {
          selector: "status",
          sortable: false,
          searchable: false,
          type: "multiStatus",
          order: 5,
        },
      },
    ],
    initialSort: "contract_number",
    initialOrder: "asc",
    selectableRows: false,
    dateRef: false,
    omittedColumns: [],
  },
};

export const orgDetailsConfig = {
  generalData: {
    fields: [
      {
        id: "org_name",
        name: "Nome",
        formProps: {
          type: "input",
          order: 0,
          editDisabled: true,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: true,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "corporate_name",
        name: "Razão Social",
        formProps: {
          type: "input",
          order: 1,
          editDisabled: true,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: true,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      //Campo ocultado temporariamente
      /*{
				id: 'address',
				name: 'Endereço',
				formProps: {
					type: 'input',
					order: 2,
					editDisabled: false,
					validation: {
			      required: false,
			      minChar: null,
			      email: false,
			      cpf: false,
			      phoneNumber: false,
			      noSpace: true,
			      checkboxGroup: false,
			      date: false,
			      codeNumber: false,
			      addendumNumber: false,
			    },
				}
			},*/
      {
        id: "contact",
        name: "Contato",
        formProps: {
          type: "input",
          order: 3,
          editDisabled: false,
          mask: "(##) ####-####",
          maskPlaceholder: "_",
          unmaskValue: false,
          validation: {
            required: false,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            phone: true,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "email",
        name: "Email",
        formProps: {
          type: "input",
          order: 4,
          editDisabled: false,
          validation: {
            required: false,
            minChar: null,
            email: true,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "cnpj",
        name: "CNPJ",
        formProps: {
          type: "input",
          order: 5,
          editDisabled: true,
          mask: "##.###.###/####-#",
          maskPlaceholder: "_",
          unmaskValue: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            cnpj: true,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "org_type",
        name: "Categoria",
        formProps: {
          type: "input",
          order: 6,
          editDisabled: true,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            cnpj: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
    ],
  },
  fiscal_document: {
    fields: [
      {
        id: "file",
        name: "Arquivo",
        formProps: {
          type: "fileUpload",
          order: 0,
          editDisabled: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: false,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
      {
        id: "expiration_date",
        name: "Validade",
        formProps: {
          type: "input",
          order: 1,
          editDisabled: false,
          mask: "##/##/####",
          maskPlaceholder: ["d", "d", "m", "m", "a", "a", "a", "a"],
          unmaskValue: false,
          validation: {
            required: true,
            minChar: null,
            email: false,
            cpf: false,
            phoneNumber: false,
            noSpace: false,
            checkboxGroup: false,
            date: true,
            codeNumber: false,
            addendumNumber: false,
          },
        },
      },
    ],
  },
};

export const docsValidationResults = {
  fields: [
    {
      id: "document_type",
      name: "document_type",
      selector: "document_type",
      sortable: true,
      searchable: true,
      type: "string",
      order: 0,
    },
    {
      id: "document_ext",
      name: "document_ext",
      selector: "document_ext",
      sortable: true,
      searchable: true,
      type: "string",
      order: 1,
    },
    {
      id: "filename",
      name: "filename",
      selector: "filename",
      sortable: true,
      searchable: true,
      type: "string",
      order: 2,
    },
    {
      id: "status",
      name: "status",
      selector: "status",
      sortable: true,
      searchable: true,
      type: "boolean",
      order: 3,
    },
    {
      id: "reason",
      selector: "reason",
      sortable: true,
      searchable: false,
      type: "string",
      order: 4,
    },
  ],
};

export const apiConfig = {
  users: {
    pageType: "users",
    apiPath: "users",
    create: "user",
    edit: "user",
  },
  profiles: {
    pageType: "profiles",
    apiPath: "users",
    create: "profile",
    edit: "profiles",
  },
};

export const toastConfig = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
};

export const editConfig = {
  headings: ["name_group", "type_id_name", "group_id"],
  chartOfAccounts: [
    "chart_of_accounts_start_month",
    "chart_of_accounts_start_year",
    "chart_of_accounts_end_month",
    "chart_of_accounts_end_year",
    "chart_of_accounts_version_id",
    "chart_of_accounts_version_name",
    "chart_of_accounts_end_reference",
    "chart_of_accounts_start_reference",
    "chart_of_accounts_main_group",
    "group_name",
    "group_code",
    "status",
    "contractor_description",
    "contractor_id",
  ],
  contracts: [
    "addendum_name",
    "addendum_number",
    "term_type",
    "original_value_formatted",
    "last_update",
    "contractor_id",
    "agreement",
    "contractor_name",
    "fixed_value_formatted",
    "file_link",
    "type_id",
    "variable_value_formatted",
    "org_name",
    "is_addendum",
    "original_contract_number",
    "headquarter_id",
    "type_name",
    "amendment_number",
    "original_contract_id",
  ],
  addendum: [
    "addendum_name",
    "term_type",
    "original_value_formatted",
    "last_update",
    "contractor_id",
    "agreement",
    "contractor_name",
    "fixed_value_formatted",
    "file_link",
    "type_id",
    "variable_value_formatted",
    "org_name",
    "is_addendum",
    "original_contract_number",
    "headquarter_id",
    "type_name",
    "amendment_number",
    "original_contract_id",
    "contract_id",
  ],
  expenses: [
    "no_tax_code",
    "type_of_benefited",
    "type",
    "bank",
    "bank_account",
    "unit",
    "chart_of_accounts_item",
    "contract_number",
    "agency",
    "org",
    "heading",
  ],
  balances: [
    "bank",
    "bank_account_type",
    "bank_account",
    "org",
    "contract_number",
    "sum",
    "agency",
  ],
  incomes: ["agency", "bank", "bank_account", "contract_number", "org", "sum"],
  providers: ["created_by"],
  purchase_process: [
    "process_mode",
    "provider_winner_code",
    "provider_winner_name",
    "provider_winner_price",
    "org",
  ],
  third_party_contracts: [
    "contract_number",
    "unit_name",
    "provider_name",
    "org",
    "provider_code",
  ],
};

export const actionMenuConfig = {
  contracts: {
    unitAssociation: "/contracts/units",
    headingsConfiguration: "/contracts/set_headings",
    addAddendum: "/contracts/addendum/add",
    saveAsNewContract: "/contracts/save_as/new",
  },
};

export const permissionsConfig = {
  users: "users",
  profiles: "profiles",
  headings: "headings",
  bankAccounts: "bankAccounts",
  contracts: "contracts",
  chartOfAccounts: "chartOfAccounts",
  units: "unitAssociation",
  set_headings: "headingsConfiguration",
  addendum: "addAddendum",
  save_as: "saveAsNewContract",
  importReport: "importReport",
  accountability: "accountability",
  expenses: "expenses",
  balances: "balances",
  incomes: "incomes",
  providers: "providers",
  purchase_process: "purchase_process",
  third_party_contracts: "third_party_contracts",
  employees: "employees",
  organizations: "list_organizations",
  orgDetails: "my_organization",
  certificates: "certificates",
};

export const modalTableConfig = {
  purchase_process: ["name", "code", "value"],
};
