import {
  Switch,
  Route,
  Redirect,
  useLocation,
  matchPath,
} from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Drawer from "@material-ui/core/Drawer";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import Skeleton from "@material-ui/lab/Skeleton";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import {
  Home,
  DollarSign,
  FileText,
  Upload,
  Users,
  Layers,
  User,
  Trello
} from "lucide-react";
import { ToastContainer } from "react-toastify";
import HomePage from "./Home";
import UserProfile from "./UserProfile";
import TablePage from "./TablePage";
import CreateEditPage from "./CreateEditPage";
import ImportDocuments from "./ImportDocuments";
import ImportData from "./ImportData";
import Footer from "./Footer";
import OrganizationDetails from './OrganizationDetails';
import { requestLogout } from "../utils/authAPI";
import { handleGetUser } from "../actions/user";
import logo from "../../img/logo_estado_para.png";
import foursafeLogo from "../../img/logo-cliente.png";


function Dashboard() {
  let location = useLocation();
  const paths = [
    "/",
    "/contracts",
    "/bankAccounts",
    "/organizations",
    "/organizations/org-details",
    "/accountability",
    "/certificates",
    "/incomes",
    "/expenses",
    "/balances",
    "/providers",
    "/purchase_process",
    "/third_party_contracts",
    "/chartOfAccounts",
    "/headings",
    "/importReport",
    "/import-documents",
    "/import-data",
    "/employees",
    "/users",
    "/profiles",
  ];



  const dispatch = useDispatch();
  let history = useHistory();
  const { t } = useTranslation();

  const [openSidebar, setOpenSidebar] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(
    paths.findIndex((element) => element === location.pathname)
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const user = useSelector((state) => state.user.userData);
  const [openItem, setOpenItem] = useState([false, false, false, false]);
  const [loading, setLoading] = useState(true);

  const onOpenSidebar = () => {
    if (openSidebar) {
      const newState = [false, false];
      setOpenItem(newState);
    }
    setOpenSidebar(!openSidebar);
  };

  const onClickSidebarItem = (event, index) => {
    setSelectedIndex(index);
    if (index === 4) {
    	const sidebarPath = paths[index]+'/'+user.data.org_id
    	history.push(sidebarPath)

    } else {
    	history.push(paths[index]);
    }
  };

  const onOpenProfileMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onCloseProfileMenu = () => {
    setAnchorEl(null);
  };

  const onClickMenuItem = (event, index) => {
    setAnchorEl(null);
    if (index === 0) {
      history.push("/my-profile");
      setSelectedIndex(-1);
    }
    if (index === 1) {
      requestLogout(dispatch, history);
    }
  };

  const handleExpandableItem = (index) => {
    if (!openSidebar) {
      onOpenSidebar();
    }
    let newState = [...openItem];
    newState[index] = !openItem[index];
    setOpenItem(newState);
  };

  useEffect(() => {
    dispatch(handleGetUser());
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(user).length === 0) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    paths.forEach((path, index) => {
      const match = matchPath(location.pathname, { path: path });
      if (match !== null && !(!match.isExact && path === "/")) {
        setSelectedIndex(index);
      }
    });

    if (location.pathname === "/my-profile") {
      setSelectedIndex(-1);
    }
  }, [location]);

  return (
    <div className="dashboard-container">
      <div
        className={
          openSidebar ? "dashboard-header-shifted" : "dashboard-header"
        }
      >
        <button
          className={"dashboard-sidebar-controls"}
          onClick={onOpenSidebar}
          type="button"
        >
          {openSidebar ? (
            <ChevronLeftIcon classes={{ root: "btn-icon" }} />
          ) : (
            <ChevronRightIcon classes={{ root: "btn-icon" }} />
          )}
        </button>
        <img className="dashboard-logo-img" src={logo} />
        {loading ? (
          <Skeleton
            animation="wave"
            variant="circle"
            classes={{ root: "skeleton-profile-menu-btn" }}
          />
        ) : (
          <button
            onClick={onOpenProfileMenu}
            className={
              Boolean(anchorEl) ? "profile-menu-btn--open" : "profile-menu-btn"
            }
          >
            {Object.keys(user).length !== 0 && (
              <Avatar
                classes={{ root: "profile-img" }}
                src={`data:image/jpeg;base64,${user.data.photo}`}
              >
                <User />
              </Avatar>
            )}
          </button>
        )}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={onCloseProfileMenu}
          getContentAnchorEl={null}
          classes={{ paper: "profile-menu" }}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <div className="profile-info">
            {Object.keys(user).length !== 0 && (
              <>
                <p className="profile-name">
                	{t('home.userMenu.profileInfo', {first_name: user.data.first_name, last_name: user.data.last_name})}
                </p>
                <p className="profile-position">{user.data.profile_name}</p>
              </>
            )}
          </div>
          <MenuItem
            classes={{ root: "profile-menu-item" }}
            onClick={(event) => onClickMenuItem(event, 0)}
          >
            <ListItemIcon classes={{ root: "profile-menu-icon" }}>
              <AccountCircleOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ root: "profile-menu-text" }}
              primary={t('home.userMenu.userProfile')}
            />
          </MenuItem>
          <Divider />
          <MenuItem
            classes={{ root: "profile-menu-item" }}
            onClick={(event) => onClickMenuItem(event, 1)}
          >
            <ListItemIcon classes={{ root: "profile-menu-icon" }}>
              <ExitToAppOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ root: "profile-menu-text" }}
              primary={t('home.userMenu.logout')}
            />
          </MenuItem>
        </Menu>
      </div>
      <Drawer
        variant="permanent"
        classes={{
          paper: openSidebar
            ? "dashboard-sidebar-open"
            : "dashboard-sidebar-close",
        }}
      >
        <div className="dashboard-sidebar-logo">
          <div className="dashboard-product-logo">
            <img className="dashboard-sidebar-img" src={foursafeLogo} />
          </div>
          <p className="dashboard-sidebar-name">{t('home.sidebar.sidebarTitle')}</p>
        </div>
        <div className="dashboard-main-menu">
          {loading ? (
            <>
              <div className="skeleton-list-item">
                <Skeleton
                  variant="circle"
                  animation="wave"
                  classes={{ root: "skeleton-sidebar-icon-wrapper" }}
                />
                <Skeleton
                  variant="text"
                  animation="wave"
                  classes={{ root: "skeleton-sidebar-item-txt" }}
                />
              </div>
              <div className="skeleton-list-item">
                <Skeleton
                  variant="circle"
                  animation="wave"
                  classes={{ root: "skeleton-sidebar-icon-wrapper" }}
                />
                <Skeleton
                  variant="text"
                  animation="wave"
                  classes={{ root: "skeleton-sidebar-item-txt" }}
                />
              </div>
              <div className="skeleton-list-item">
                <Skeleton
                  variant="circle"
                  animation="wave"
                  classes={{ root: "skeleton-sidebar-icon-wrapper" }}
                />
                <Skeleton
                  variant="text"
                  animation="wave"
                  classes={{ root: "skeleton-sidebar-item-txt" }}
                />
              </div>
              <div className="skeleton-list-item">
                <Skeleton
                  variant="circle"
                  animation="wave"
                  classes={{ root: "skeleton-sidebar-icon-wrapper" }}
                />
                <Skeleton
                  variant="text"
                  animation="wave"
                  classes={{ root: "skeleton-sidebar-item-txt" }}
                />
              </div>
            </>
          ) : (
            <>
              <ListItem
                classes={{
                  root: openSidebar
                    ? "sidebar-list-item"
                    : "sidebar-list-item--hidden",
                }}
                onClick={(event) => onClickSidebarItem(event, 0)}
                selected={selectedIndex === 0}
                button
              >
                <ListItemAvatar classes={{ root: "sidebar-icon" }}>
                  <Avatar classes={{ root: "sidebar-icon-wrapper" }}>
                    <Home size={18} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  classes={{ root: "sidebar-item-text" }}
                  primary={t('home.sidebar.home')}
                />
              </ListItem>
              {Object.keys(user).length !== 0 &&
                (user.permissions.contracts?.read ||
                  user.permissions.bankAccounts?.read ||
                  user.permissions.accountability?.read ||
                  user.permissions.list_organizations?.read ||
                  user.permissions.my_organization?.read ||
                  user.permissions.certificates?.read
                 ) && (
                  <ListItem
                    classes={{
                      root: openSidebar
                        ? "sidebar-list-item"
                        : "sidebar-list-item--hidden",
                    }}
                    onClick={() => handleExpandableItem(0)}
                    selected={
                      selectedIndex === 1 ||
                      selectedIndex === 2 ||
                      selectedIndex === 3 ||
                      selectedIndex === 4 ||
                      selectedIndex === 5 ||
                      selectedIndex === 6
                    }
                    button
                  >
                    <ListItemAvatar classes={{ root: "sidebar-icon" }}>
                      <Avatar classes={{ root: "sidebar-icon-wrapper" }}>
                        <FileText size={18} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      classes={{ root: "sidebar-item-text" }}
                      primary={t('home.sidebar.contracts')}
                    />
                    {openItem[0] ? (
                      <ExpandLess classes={{ root: "sub-item-icon" }} />
                    ) : (
                      <ExpandMore classes={{ root: "sub-item-icon" }} />
                    )}
                  </ListItem>
                )}
              <Collapse in={openItem[0]} unmountOnExit>
                {Object.keys(user).length !== 0 &&
                  user.permissions.contracts?.read && (
                    <ListItem
                      classes={{ root: "sidebar-sub-item" }}
                      onClick={(event) => onClickSidebarItem(event, 1)}
                      selected={selectedIndex === 1}
                      button
                    >
                      <ListItemText
                        classes={{ root: "sidebar-item-text--sub-item" }}
                        primary={t('home.sidebar.contracts')}
                      />
                    </ListItem>
                  )}
                {Object.keys(user).length !== 0 &&
                  user.permissions.bankAccounts?.read && (
                    <ListItem
                      classes={{ root: "sidebar-sub-item" }}
                      onClick={(event) => onClickSidebarItem(event, 2)}
                      selected={selectedIndex === 2}
                      button
                    >
                      <ListItemText
                        classes={{ root: "sidebar-item-text--sub-item" }}
                        primary={t('home.sidebar.bankAccounts')}
                      />
                    </ListItem>
                  )}

                {Object.keys(user).length !== 0 &&
                  (user.permissions.list_organizations?.read || user.permissions.my_organization?.read) && (
                    <ListItem
                      classes={{ root: "sidebar-sub-item" }}
                      onClick={(event) => {
                      	if (user.permissions?.list_organizations.read) {
                      		onClickSidebarItem(event, 3)
                      	} else {
                      		onClickSidebarItem(event, 4)
                      	}
                      }}
                      selected={(selectedIndex === 3 || selectedIndex === 4)}
                      button
                    >
                     	<ListItemText
                        classes={{ root: "sidebar-item-text--sub-item" }}
                        primary={t('home.sidebar.organizations')}
                      />
                    </ListItem>
                  )}
                {Object.keys(user).length !== 0 &&
                  user.permissions.accountability?.read && (
                    <ListItem
                      classes={{ root: "sidebar-sub-item" }}
                      onClick={(event) => onClickSidebarItem(event, 5)}
                      selected={selectedIndex === 5}
                      button
                    >
                      <ListItemText
                        classes={{ root: "sidebar-item-text--sub-item" }}
                        primary={t('home.sidebar.accountability')}
                      />
                    </ListItem>
                  )}
                {Object.keys(user).length !== 0 &&
                  user.permissions.certificates?.read && (
                    <ListItem
                      classes={{ root: "sidebar-sub-item" }}
                      onClick={(event) => onClickSidebarItem(event, 6)}
                      selected={selectedIndex === 6}
                      button
                    >
                      <ListItemText
                        classes={{ root: "sidebar-item-text--sub-item" }}
                        primary={t('home.sidebar.certificates')}
                      />
                    </ListItem>
                )}
              </Collapse>
              {Object.keys(user).length !== 0 &&
                (user.permissions.incomes?.read ||
                  user.permissions.expenses?.read ||
                  user.permissions.chartOfAccounts?.read ||
                  user.permissions.headings?.read) && (
                  <ListItem
                    classes={{
                      root: openSidebar
                        ? "sidebar-list-item"
                        : "sidebar-list-item--hidden",
                    }}
                    onClick={() => handleExpandableItem(1)}
                    selected={
                      selectedIndex === 7 ||
                      selectedIndex === 8 ||
                      selectedIndex === 9 ||
                      selectedIndex === 10 ||
                      selectedIndex === 11 ||
                      selectedIndex === 12 ||
                      selectedIndex === 13
                    }
                    button
                  >
                    <ListItemAvatar classes={{ root: "sidebar-icon" }}>
                      <Avatar classes={{ root: "sidebar-icon-wrapper" }}>
                        <DollarSign size={18} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      classes={{ root: "sidebar-item-text" }}
                      primary={t('home.sidebar.financials')}
                    />
                    {openItem[1] ? (
                      <ExpandLess classes={{ root: "sub-item-icon" }} />
                    ) : (
                      <ExpandMore classes={{ root: "sub-item-icon" }} />
                    )}
                  </ListItem>
                )}
              <Collapse in={openItem[1]} unmountOnExit>
                {Object.keys(user).length !== 0 &&
                  user.permissions.incomes?.read && (
                    <ListItem
                      classes={{ root: "sidebar-sub-item" }}
                      onClick={(event) => onClickSidebarItem(event, 7)}
                      selected={selectedIndex === 7}
                      button
                    >
                      <ListItemText
                        classes={{ root: "sidebar-item-text--sub-item" }}
                        primary={t('home.sidebar.incomes')}
                      />
                    </ListItem>
                  )}
                {Object.keys(user).length !== 0 &&
                  user.permissions.expenses?.read && (
                    <ListItem
                      classes={{ root: "sidebar-sub-item" }}
                      onClick={(event) => onClickSidebarItem(event, 8)}
                      selected={selectedIndex === 8}
                      button
                    >
                      <ListItemText
                        classes={{ root: "sidebar-item-text--sub-item" }}
                        primary={t('home.sidebar.expenses')}
                      />
                    </ListItem>
                  )}
                {Object.keys(user).length !== 0 &&
                  user.permissions.balances?.read && (
                    <ListItem
                      classes={{ root: "sidebar-sub-item" }}
                      onClick={(event) => onClickSidebarItem(event, 9)}
                      selected={selectedIndex === 9}
                      button
                    >
                      <ListItemText
                        classes={{ root: "sidebar-item-text--sub-item" }}
                        primary={t('home.sidebar.balances')}
                      />
                    </ListItem>
                  )}
                {Object.keys(user).length !== 0 &&
                  user.permissions.providers?.read &&
                  user.permissions.purchase_process?.read &&
                  user.permissions.third_party_contracts?.read && (
                    <ListItem
                      classes={{
                        root: openSidebar
                          ? "sidebar-sub-item"
                          : "sidebar-list-item--hidden",
                      }}
                      onClick={() => handleExpandableItem(2)}
                      selected={
                        selectedIndex === 10 ||
                        selectedIndex === 11 ||
                        selectedIndex === 12
                      }
                      button
                    >
                      <ListItemText
                        classes={{ root: "sidebar-item-text--sub-item" }}
                        primary={t('home.sidebar.third_party')}
                      />
                      {openItem[2] ? (
                        <ExpandLess classes={{ root: "sub-item-icon" }} />
                      ) : (
                        <ExpandMore classes={{ root: "sub-item-icon" }} />
                      )}
                    </ListItem>
                  )}
                <Collapse in={openItem[2]} unmountOnExit>
                  {Object.keys(user).length !== 0 &&
                    user.permissions.providers?.read && (
                      <ListItem
                        classes={{ root: "sidebar-sub-item" }}
                        onClick={(event) => onClickSidebarItem(event, 10)}
                        selected={selectedIndex === 10}
                        button
                      >
                        <ListItemText
                          classes={{
                            root: "sidebar-item-text--sub-item sidebar-subitem-submenu",
                          }}
                          primary={t('home.sidebar.providers')}
                        />
                      </ListItem>
                    )}
                  {Object.keys(user).length !== 0 &&
                    user.permissions.purchase_process?.read && (
                      <ListItem
                        classes={{ root: "sidebar-sub-item" }}
                        onClick={(event) => onClickSidebarItem(event, 11)}
                        selected={selectedIndex === 11}
                        button
                      >
                        <ListItemText
                          classes={{
                            root: "sidebar-item-text--sub-item sidebar-subitem-submenu",
                          }}
                          primary={t('home.sidebar.purchase_process')}
                        />
                      </ListItem>
                    )}
                  {Object.keys(user).length !== 0 &&
                    user.permissions.third_party_contracts?.read && (
                      <ListItem
                        classes={{ root: "sidebar-sub-item" }}
                        onClick={(event) => onClickSidebarItem(event, 12)}
                        selected={selectedIndex === 12}
                        button
                      >
                        <ListItemText
                          classes={{
                            root: "sidebar-item-text--sub-item sidebar-subitem-submenu",
                          }}
                          primary={t('home.sidebar.third_party_contracts')}
                        />
                      </ListItem>
                    )}
                </Collapse>
                {Object.keys(user).length !== 0 &&
                  user.permissions.chartOfAccounts?.read && (
                    <ListItem
                      classes={{ root: "sidebar-sub-item" }}
                      onClick={(event) => onClickSidebarItem(event, 13)}
                      selected={selectedIndex === 13}
                      button
                    >
                      <ListItemText
                        classes={{ root: "sidebar-item-text--sub-item" }}
                        primary={t('home.sidebar.chartOfAccounts')}
                      />
                    </ListItem>
                  )}
                {Object.keys(user).length !== 0 &&
                  user.permissions.headings?.read && (
                    <ListItem
                      classes={{ root: "sidebar-sub-item" }}
                      onClick={(event) => onClickSidebarItem(event, 14)}
                      selected={selectedIndex === 14}
                      button
                    >
                      <ListItemText
                        classes={{ root: "sidebar-item-text--sub-item" }}
                        primary={t('home.sidebar.headings')}
                      />
                    </ListItem>
                  )}
              </Collapse>
              {Object.keys(user).length !== 0 &&
                (user.permissions.importDocuments?.read ||
                  user.permissions.importData?.read ||
                  user.permissions.importReport?.read) && (
                  <ListItem
                    classes={{
                      root: openSidebar
                        ? "sidebar-list-item"
                        : "sidebar-list-item--hidden",
                    }}
                    onClick={() => handleExpandableItem(3)}
                    selected={
                      selectedIndex === 15 ||
                      selectedIndex === 16 ||
                      selectedIndex === 17
                    }
                    button
                  >
                    <ListItemAvatar classes={{ root: "sidebar-icon" }}>
                      <Avatar classes={{ root: "sidebar-icon-wrapper" }}>
                        <Upload size={18} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      classes={{ root: "sidebar-item-text" }}
                      primary={t('home.sidebar.import')}
                    />
                    {openItem[3] ? (
                      <ExpandLess classes={{ root: "sub-item-icon" }} />
                    ) : (
                      <ExpandMore classes={{ root: "sub-item-icon" }} />
                    )}
                  </ListItem>
                )}
              <Collapse in={openItem[3]} unmountOnExit>
                {Object.keys(user).length !== 0 &&
                  user.permissions.importReport?.read && (
                    <ListItem
                      classes={{ root: "sidebar-sub-item" }}
                      onClick={(event) => onClickSidebarItem(event, 15)}
                      selected={selectedIndex === 15}
                      button
                    >
                      <ListItemText
                        classes={{ root: "sidebar-item-text--sub-item" }}
                        primary={t('home.sidebar.importReport')}
                      />
                    </ListItem>
                  )}
                {Object.keys(user).length !== 0 &&
                  user.permissions.importDocuments?.read && (
                    <ListItem
                      classes={{ root: "sidebar-sub-item" }}
                      onClick={(event) => onClickSidebarItem(event, 16)}
                      selected={selectedIndex === 16}
                      button
                    >
                      <ListItemText
                        classes={{ root: "sidebar-item-text--sub-item" }}
                        primary={t('home.sidebar.importDocuments')}
                      />
                    </ListItem>
                  )}
                {Object.keys(user).length !== 0 &&
                  user.permissions.importData?.read && (
                    <ListItem
                      classes={{ root: "sidebar-sub-item" }}
                      onClick={(event) => onClickSidebarItem(event, 17)}
                      selected={selectedIndex === 17}
                      button
                    >
                      <ListItemText
                        classes={{ root: "sidebar-item-text--sub-item" }}
                        primary={t('home.sidebar.importData')}
                      />
                    </ListItem>
                  )}
              </Collapse>
          		{Object.keys(user).length !== 0 &&
          			user.permissions.employees?.read && (
	          		<ListItem
	                classes={{
	                  root: openSidebar
	                    ? "sidebar-list-item"
	                    : "sidebar-list-item--hidden",
	                }}
	                onClick={(event) => onClickSidebarItem(event, 18)}
	                selected={selectedIndex === 18}
	                button
	              >
	                <ListItemAvatar classes={{ root: "sidebar-icon" }}>
	                  <Avatar classes={{ root: "sidebar-icon-wrapper" }}>
	                    <Trello size={18} />
	                  </Avatar>
	                </ListItemAvatar>
	                <ListItemText
	                  classes={{ root: "sidebar-item-text" }}
	                  primary={t('home.sidebar.employees')}
	                />
	              </ListItem>
          		)}
            </>
          )}
        </div>
        <div className="dashboard-submenu">
          {loading ? (
            <>
              <div className="skeleton-list-item">
                <Skeleton
                  variant="circle"
                  animation="wave"
                  classes={{ root: "skeleton-sidebar-icon-wrapper" }}
                />
                <Skeleton
                  variant="text"
                  animation="wave"
                  classes={{ root: "skeleton-sidebar-item-txt" }}
                />
              </div>
              <div className="skeleton-list-item">
                <Skeleton
                  variant="circle"
                  animation="wave"
                  classes={{ root: "skeleton-sidebar-icon-wrapper" }}
                />
                <Skeleton
                  variant="text"
                  animation="wave"
                  classes={{ root: "skeleton-sidebar-item-txt" }}
                />
              </div>
            </>
          ) : (
            <>
            	{Object.keys(user).length !== 0 && 
            		user.permissions.users?.read && (
								<ListItem 
									classes={{root: openSidebar ? 'sidebar-list-item' : 'sidebar-list-item--hidden'}} 
									onClick={(event) => onClickSidebarItem(event,19)} 
									selected={selectedIndex === 19} 
									button
								>
									<ListItemAvatar classes={{root: 'sidebar-icon'}}>
										<Avatar classes={{root: 'sidebar-icon-wrapper'}}>
											<Users size={18}/>
										</Avatar>
									</ListItemAvatar>
									<ListItemText 
										classes={{root: 'sidebar-item-text'}} 
										primary={t('home.sidebar.users')}
									/>
								</ListItem>
							)}
              {Object.keys(user).length !== 0 &&
                user.permissions.profiles?.read && (
                <ListItem
                  classes={{
                    root: openSidebar
                      ? "sidebar-list-item"
                      : "sidebar-list-item--hidden",
                  }}
                  onClick={(event) => onClickSidebarItem(event, 20)}
                  selected={selectedIndex === 20}
                  button
                >
                  <ListItemAvatar classes={{ root: "sidebar-icon" }}>
                    <Avatar classes={{ root: "sidebar-icon-wrapper" }}>
                      <Layers size={18} />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    classes={{
                      root: openSidebar
                        ? "sidebar-item-text"
                        : "sidebar-item-text--hidden",
                    }}
                    primary={t('home.sidebar.profiles')}
                  />
                </ListItem>
              )}
            </>
          )}
        </div>
      </Drawer>
      <div
        className={
          openSidebar ? "dashboard-content-shifted" : "dashboard-content"
        }
      >
        <Switch>
          <Route key="employees" exact path="/employees">
            <TablePage pageType="employees" />
          </Route>
          <Route key="contracts" exact path="/contracts">
            <TablePage pageType="contracts" />
          </Route>
          <Route key="organizations" exact path="/organizations">
            <TablePage pageType="organizations" />
          </Route>
          <Route key="org-details" exact path="/organizations/org-details/:orgId">
          	<OrganizationDetails/>
          </Route>
          <Route key="units" exact path="/contracts/units/:menuId">
            <TablePage pageType="units" />
          </Route>
          <Route key="units" exact path="/contracts/set_headings/:menuId">
            <TablePage pageType="set_headings" />
          </Route>
          <Route key="bankAccounts" exact path="/bankAccounts">
            <TablePage pageType="bankAccounts" />
          </Route>
          <Route key="certificates" exact path="/certificates">
            <TablePage pageType="certificates" />
          </Route>
          <Route key="chartOfAccounts" exact path="/chartOfAccounts">
            <TablePage pageType="chartOfAccounts" />
          </Route>
          <Route key="headings" exact path="/headings">
            <TablePage pageType="headings" />
          </Route>
          <Route key="users" exact path="/users">
            <TablePage pageType="users" />
          </Route>
          <Route key="profiles" exact path="/profiles">
            <TablePage pageType="profiles" />
          </Route>
          <Route key="importReport" exact path="/importReport">
            <TablePage pageType="importReport" />
          </Route>
          <Route key="accountability" exact path="/accountability">
            <TablePage pageType="accountability" />
          </Route>
          <Route key="incomes" exact path="/incomes">
            <TablePage pageType="incomes" />
          </Route>
          <Route key="expenses" exact path="/expenses">
            <TablePage pageType="expenses" />
          </Route>
          <Route key="balances" exact path="/balances">
            <TablePage pageType="balances" />
          </Route>
          <Route key="providers" exact path="/providers">
            <TablePage pageType="providers" />
          </Route>
          <Route key="purchase_process" exact path="/purchase_process">
            <TablePage pageType="purchase_process" />
          </Route>
          <Route key="third_party_contracts" exact path="/third_party_contracts">
            <TablePage pageType="third_party_contracts" />
          </Route>
          <Route key="associate" path="/contracts/:pageType/:menuId/associate">
            <CreateEditPage />
          </Route>
          <Route key="addAddendum" path="/contracts/:pageType/add/:contractId">
            <CreateEditPage />
          </Route>
          <Route
            key="editAddendum"
            path="/contracts/:pageType/edit/:addendumId"
          >
            <CreateEditPage />
          </Route>
          <Route
            key="saveAsNewContract"
            path="/contracts/:pageType/new/:contractId"
          >
            <CreateEditPage />
          </Route>
          <Route key="create" path="/:pageType/create">
            <CreateEditPage />
          </Route>
          <Route key="edit" path="/:pageType/edit/:itemId">
            <CreateEditPage />
          </Route>
          <Route path="/import-documents" component={ImportDocuments} />
          <Route path="/import-data" component={ImportData} />
          <Route path="/my-profile" component={UserProfile} />
          <Route exact path="/" component={HomePage} />
          <Route>
            <Redirect to="/error" />
          </Route>
        </Switch>
        <Footer />
      </div>
      <ToastContainer />
    </div>
  );
}

export default Dashboard;
