import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FileText } from "lucide-react";
import Avatar from "@material-ui/core/Avatar";

function Home() {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("home.pageTitle");
  }, [t]);

  return (
    <div className="homepage-container">
      <p className="homepage-title">{t("home.page.title")}</p>
      <p className="homepage-subtitle">{t("home.page.subtitle")}</p>
      <div className="homepage-content">
        <Avatar classes={{ root: "dashboard-content-icon" }}>
          <FileText size={72} strokeWidth={0.5} />
        </Avatar>
        <div className="dashboard-content-text">
          <p className="content-title">{t("home.content.title")}</p>
          <p>{t("home.content.text")}</p>
        </div>
      </div>
    </div>
  );
}

export default Home;
