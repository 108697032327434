export const cpfValidation = (value) => {
	value = value.replace(/[^\d]+/g, '');
	if (value.length < 11 || value.length > 11) {
		return true
	}
	let sum = 0;
	if (
		value === '00000000000' || value === '11111111111' || value === '22222222222' ||
		value === '33333333333' || value === '44444444444' || value === '55555555555' ||
		value === '66666666666' || value === '77777777777' || value === '88888888888' ||
		value === '99999999999'
	) {
		return true;
	}

	for (let i = 1; i <= 9; i++) {
		sum = sum + parseInt(value.substring(i - 1, i)) * (11 - i);
	}

	let remainder = (sum * 10) % 11;

	if (remainder === 10 || remainder === 11) {
		remainder = 0;
	}

	if (remainder !== parseInt(value.substring(9, 10))) {
		return true;
	}

	sum = 0;
	for (let i = 1; i <= 10; i++) {
		sum = sum + parseInt(value.substring(i - 1, i)) * (12 - i);
	}

	remainder = (sum * 10) % 11;

	if (remainder === 10 || remainder === 11) {
		remainder = 0;
	}

	if (remainder !== parseInt(value.substring(10, 11))) {
		return true;
	}

	return false;
}

export const cnpjValidation = (value) => {
	const valueWithOnlyDigits = value.replace(/[^\d]+/g, '');

	if (valueWithOnlyDigits.length !== 14) {
		return true;
	}



	const invalidSequences = [
		'00000000000000', '11111111111111', '22222222222222',
		'33333333333333', '44444444444444', '55555555555555',
		'66666666666666', '77777777777777', '88888888888888',
		'99999999999999'
	];

	if (invalidSequences.includes(valueWithOnlyDigits)) {
		return true;
	}

	// Validation weights for first digit
	const weight1 = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
	// Validation weights for second digit
	const weight2 = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

	// Calculate first verification digit
	const calculateDigit = (numbers, weights) => {
		const sum = numbers.split('')
			.reduce((acc, current, index) => {
				return acc + (parseInt(current) * weights[index]);
			}, 0);
		const remainder = sum % 11;
		return remainder < 2 ? 0 : 11 - remainder;
	};

	const numbers = valueWithOnlyDigits.substring(0, 12);
	const digits = valueWithOnlyDigits.substring(12);

	const digit1 = calculateDigit(numbers, weight1);
	if (digit1 !== parseInt(digits[0])) {
		return true;
	}

	const digit2 = calculateDigit(numbers + digit1, weight2);
	if (digit2 !== parseInt(digits[1])) {
		return true;
	}

	return false;
}

const emailValidation = (value) => {
	return !/\S+@\S+/.test(value)
}

const nameValidation = (value) => {
	return value.length < 3
}

const phoneValidation = (value) => {
	return !/^[1-9]{1}[0-9]{10}$/.test(value)
}

export const inputValidation = (value, type) => {
	const functions = {
		name: nameValidation,
		email: emailValidation,
		cpf: cpfValidation,
		mobilePhone: phoneValidation,
	}

	return functions[type](value)
}

export const hasValidation = (type) => {
	const validations = ['name', 'email', 'cpf', 'mobilePhone'];

	if (validations.includes(type)) {
		return true
	} else {
		return false
	}
}