import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { Frown } from 'lucide-react';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';


function ErrorMessage({onReload, onGoBack}) {
	const { t } = useTranslation()
	return (
		<div className='error-message__container'>
			<Avatar classes={{root: 'dashboard-content-icon'}}>
				<Frown size={72} strokeWidth={.5}/>
			</Avatar>
			<div className='dashboard-content-text'>
				<p className='content-title'>{t("errorMessagePage.title")}</p>
				<p>{t("errorMessagePage.text")}</p>
				<Button className='btn__reload' onClick={onReload}>{t("errorMessagePage.reloadBtn")}</Button>
				{
					Boolean(onGoBack) && <Button className='btn__back' onClick={onGoBack}>{t("errorMessagePage.backBtn")}</Button>
				}
			</div>
		</div>
	)
}

export default ErrorMessage