function ExpensesText() {
	return (
		<div>
			<p className='instructions-title'>Instruções para Importação de Despesas</p>
			<div>
				<p className='instructions-title'>Campo do sistema: Descrição (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o nome do arquivo de imagem do documento de comprovação da despesa. Será verificado se o mesmo já existe no cadastro de documentos do sistema. Atenção para que o nome do arquivo seja EXATAMENTE o mesmo do que foi importado.</p>
				<p className='instructions-text'>Verifique se não há espaços extras e não use caracteres especiais (*/&, etc.).</p> 
				<p className='instructions-text'>Não esqueça de colocar a extensão.</p> 
				<p className='instructions-text'>Pode ter no máximo 150 caracteres.</p>
				<p className='instructions-text'>Exemplo: nome_do_arquivo.pdf, nomeDoArquivo.pdf, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: Contrato (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o número do contrato de gestão. Deve ter o formato NNN/AAAA, onde NNN é o número do contrato e AAAA é o ano. Será verificada sua existência na tabela do sistema de CONTRATOS e se ele está ligado à OS atual. Exemplo: 001/2017, 100/2012, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: ORGANIZAÇÃO (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o código da ORGANIZAÇÃO a que se refere a despesa. Deve conter apenas números. Será verificada sua existência na tabela do sistema de ORGANIZAÇÕES, bem como se a mesma está ativa. Este código deve ser o mesmo que o do usuário que está fazendo a
				importação. Se não for, haverá um erro.</p>
				<p className='instructions-text'>Exemplo: 123, 999, etc</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: Unidade (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o código da unidade a que se refere a despesa. Deve conter apenas números. Será verificada sua existência na tabela do
				sistema de UNIDADES, bem como se a mesma está ativa e ligada à OS atual.</p>
				<p className='instructions-text'>Exemplo: 123, 999, etc</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: Razão Social (OBRIGATÓRIO se CNPJ estiver preenchido).</p>
				<p className='instructions-text'>É a razão social do fornecedor a que se refere a despesa, caso seja pessoa jurídica.</p>
				<p className='instructions-text'>Exemplo: Alfa Empresa de Sistemas. ACME ltda, etc.</p>
				<p className='instructions-text'>Pode ter no máximo 100 caracteres.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: CNPJ (OBRIGATÓRIO se RAZÃO SOCIAL estiver preenchida).</p>
				<p className='instructions-text'>É o CNPJ do fornecedor. Tem que ser informado no formato 99.999.999/9999-99.</p>
				<p className='instructions-text'>Também será verificada sua validade.</p>
				<p className='instructions-text'>Exemplo: 99.999.999/0001-99, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: Nome (OBRIGATÓRIO se CPF estiver preenchido)</p>
				<p className='instructions-text'>É o nome do fornecedor a que se refere a despesa, caso seja pessoa física.</p>
				<p className='instructions-text'>Exemplo: Pedro da Silva, Maria de Carvalho, etc.</p>
				<p className='instructions-text'>Pode ter no máximo 100 caracteres.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: CPF (OBRIGATÓRIO se NOME estiver preenchido)</p>
				<p className='instructions-text'>É o CPF do fornecedor. Tem que ser informado no formato 999.999.999-99. Também será verificada sua validade.</p>
				<p className='instructions-text'>Exemplo: 999.999.999-99, etc.</p>
				<p className='instructions-text'><b>ATENÇÃO:</b> Se <b>CNPJ/RAZÃO SOCIAL</b> estiverem preenchido, <b>CPF/NOME</b> não podem estar, e vice-versa. Caso isto aconteça, ocasionará um erro.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: Tipo de Despesa (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o código de item de despesa, de acordo com o Plano de Contas vigente. Será verificada sua existência na tabela do sistema de PLANO DE CONTAS. Para verificar este código, deve-se navegar pela plataforma até a tela de Plano de Contas. Terá que ter, OBRIGATORIAMENTE, o formato XX.XX.XX.</p>
				<p className='instructions-text'>Exemplo: 01.01, 03.01.02, 03, 04.01.02.12, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: Identificação Bancária (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o identificador bancário da despesa no extrato bancário. Pode ter no máximo 100 caracteres.</p>
				<p className='instructions-text'>Exemplo: 1.2356.56, 2345-4-B</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: Parcela Paga (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o número da parcela que está sendo paga, em relação ao total de parcelas. Deve conter apenas números.</p>
				<p className='instructions-text'>Exemplo: terceira parcela (3/5 por exemplo) deve ser preenchido 3, quinta parcela (5/5 por exemplo) deve ser preenchido 5, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: Total de Parcelas (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É a quantidade total de parcelas a serem pagas. Deve conter apenas números.</p>
				<p className='instructions-text'>Exemplo: total de parcelas 10 deve ser preenchido 10, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: Ano-mês de Referência (OBRIGATÓRIO)</p>
				<p className='instructions-text'>São o ano e o mês a que se refere os lançamentos das despesas. Deve ter o formato AAAA-MM, onde AAAA é o ano e MM é o mês.</p>
				<p className='instructions-text'>Caso a entrega já tenha sido encerrada para esta referência, OS e contrato, não será possível fazer a carga dos dados.</p>
				<p className='instructions-text'>Exemplo: outubro de 2016 deve ser preenchido 2016-10, janeiro de 2017 deve ser preenchido 2017-01, SEM ESPAÇOS.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: Data de Vencimento (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É a data de vencimento do documento da despesa. Deve ter o formato AAAA-MM-DD, onde AAAA é o ano, MM é o mês e DD é o dia. O separador das datas tem que ser o -.</p>
				<p className='instructions-text'>Exemplo: 2012-02-05, 2016-10-25, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: Data de Emissão (NÃO OBRIGATÓRIO)</p>
				<p className='instructions-text'>É a data de emissão do documento da despesa. Se preenchido, deve ter o formato AAAA-MM-DD, onde AAAA é o ano, MM é o mês e DD é o dia. O separador das datas tem que ser o -.</p>
				<p className='instructions-text'>Exemplo: 2012-02-05, 2016-10-25, etc</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: Data de Pagamento (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É a data de pagamento do documento da despesa. Se preenchido, deve ter o formato AAAA-MM-DD, onde AAAA é o ano, MM é o mês e DD é o dia. O separador das datas tem que ser o -.</p>
				<p className='instructions-text'>Exemplo: 2012-02-05, 2016-10-25, etc.</p>
        <p className='instructions-text'><b>IMPORTANTE:</b> O mês e ano da data de pagamento devem ser os mesmos da referência.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: Data de Apuração (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É a data de competência do documento. Deve ter o formato AAAA-MM-DD, onde AAAA é o ano, MM é o mês e DD é o dia.</p>
				<p className='instructions-text'>O separador das datas tem que ser o -. Caso não seja a data de competência, mas o período de competência, por exemplo, janeiro/2019, informar o dia como 01.</p>
				<p className='instructions-text'>Exemplo: 2012-02-05, 2016-10-25, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: Série da Nota Fiscal (OBRIGATÓRIO se o tipo de documento for NF)</p>
				<p className='instructions-text'>É a série do documento da despesa. Pode ter no máximo 3 caracteres.</p>
				<p className='instructions-text'>Exemplo: A, B, 123, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: Código Fiscal (OBRIGATÓRIO se o tipo de documento for NF)</p>
				<p className='instructions-text'>É o código fiscal da despesa.</p>
				<p className='instructions-text'>Exemplo: 561, 1708, 5952, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: Número da Nota Fiscal (OBRIGATÓRIO se o tipo de documento for NF)</p>
				<p className='instructions-text'>É o número do documento de comprovação da despesa.</p>
				<p className='instructions-text'>Exemplo: 12345, 63456, etc.</p>
				<p className='instructions-text'>Pode ter no máximo 20 caracteres.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: Tipo de Documento (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o código do tipo de documento da despesa, em maiúsculas. Será verificada sua existência na tabela do sistema de TIPOS DE DOCUMENTOS.</p>
				<p className='instructions-text'><b>Tabela (Código - Descrição):</b></p>
				<p className='instructions-text'><b>NF - </b>Nota Fiscal</p>
				<p className='instructions-text'><b>DARF - </b>DARF</p>
				<p className='instructions-text'><b>IR - </b>Imposto de Renda</p>
				<p className='instructions-text'><b>FGTS - </b>Fundo de Garantia</p>
				<p className='instructions-text'><b>GPS - </b>GPS</p>
				<p className='instructions-text'><b>FOLHA - </b>Folha de Pagamento</p>
				<p className='instructions-text'><b>RECIBO - </b>Recibos para Pessoa Física</p>
				<p className='instructions-text'><b>BOLETO - </b>Boleto Bancário (Água, Energia, Telefonia)</p>
				<p className='instructions-text'><b>IMPOSTO - </b>Outros Tipos de Impostos</p>
				<p className='instructions-text'><b>GUIA - </b>Outras Guias de Recolhimento</p>
				<p className='instructions-text'><b>INSS - </b>INSS</p>
				<p className='instructions-text'><b>RDV - </b>RDV</p>
				<p className='instructions-text'><b>RPA - </b>RPA</p>
				<p className='instructions-text'><b>PGCH - </b>Pagamentos em Cheque</p>
				<p className='instructions-text'><b>PGPA - </b>Recibo de Pensão Alimentícia</p>
				<p className='instructions-text'><b>PGRF - </b>Recibo de Férias</p>				
				<p className='instructions-text'><b>PGRCT - </b>Rescisão de Contrato de Trabalho</p>
				<p className='instructions-text'><b>EMPRE - </b>Empréstimos outros contratos</p>
				<p className='instructions-text'><b>PGEMPR - </b>Pagamento de Empréstimos</p>
				<p className='instructions-text'><b>DARM - </b>Documento de Arrecadação de Receitas Municipais</p>
				<p className='instructions-text'><b>EXTRATO - </b>Extrato</p>
				<p className='instructions-text'><b>CONTRATO - </b>Contrato</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: Valor do Documento (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o valor nominal do documento de comprovação da despesa. Poderá ter a VÍRGULA ou PONTO como separador dos centavos. Não deve haver nenhum separador de milhar.</p>
				<p className='instructions-text'>Exemplo: 1200, 34,78, 1.200.345,45, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: Valor Pago (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o valor efetivamente pago do documento de comprovação da despesa. Poderá ter a VÍRGULA ou PONTO como separador dos centavos. Não deve haver nenhum separador de milhar.</p>
				<p className='instructions-text'>Exemplo: 1200, 34,78, 1.200.345,45, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: Rubrica (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o código (ID) da rubrica do documento da despesa. Será verificada sua existência na tabela do sistema de RUBRICAS CONFIGURADAS NO CONTRATO. Para verificar o ID, deve-se navegar pela plataforma nas telas de CONTRATOS --{">"} AÇÕES --{">"} CONFIGURAR RUBRICAS DO CONTRATO.</p>
				<p className='instructions-text'>Exemplo: 1, 45, 130, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: Conta Corrente (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o número da conta corrente da despesa. Deve conter um hífen como separador do dígito verificador do número. Será verificada sua existência na tabela do sistema de CONTAS CORRENTES (em conjunto com o código do banco e da agência), e também se está ligada à OS e ao CONTRATO.</p>
				<p className='instructions-text'>Exemplo: 3432-4: deve ser preenchido como 3432-4, 889-0: deve ser preenchido como 889-0, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: Agência (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o código da agência do banco associado à conta corrente. Será verificada sua existência na tabela de AGÊNCIA. Este campo deverá conter 4 números, e não poderá ter o dígito verificador.</p>
				<p className='instructions-text'>Exemplo: 50: deve ser preenchido como 0050, 157: deve ser preenchido como 0157, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: Banco (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o código de compensação do banco associado à conta corrente. Será verificada sua existência na tabela de BANCO. Este campo deverá conter apenas 3 números.</p>
				<p className='instructions-text'>Exemplo: 37: deve ser preenchido como 037, 1: deve ser preenchido como 001, etc.</p>
			</div>
		</div>
	)
}

export default ExpensesText