import React from "react";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import { toast } from "react-toastify";
import IconSelector from "../IconSelector";
import { toastConfig } from "../../data/tableConfig";

function UploadFileInput({ props }) {
  const { t } = useTranslation();
  const {
    field,
    values,
    handleBlur,
    pageType,
    errors,
    touched,
    disabled,
    setFieldValue,
  } = props;
  const handleFileUpload = (event, fieldId) => {
    if (event.target.files[0]) {
      if (event.target.files[0].type === "application/pdf") {
        setFieldValue("filename", event.target.files[0].name);
        setFieldValue(fieldId, event.target.files[0]);
      } else {
        toast.error("Extensão de arquivo inválida", toastConfig);
      }
    }
  };

  const handleDeleteFile = (event, fieldId) => {
    event.preventDefault();
    setFieldValue("filename", "");
    setFieldValue(fieldId, "");
  };

  const resetEvent = (event) => {
    event.target.value = "";
  };

  return (
    <>
      <p
        className={
          errors[field.id] && touched[field.id]
            ? "form__label--error"
            : "form__label"
        }
      >
        {t(`${pageType}.fields.${field.id}`)}
        {field.validation.required ? "*" : null}
      </p>
      <label
        className={
          errors[field.id] && touched[field.id]
            ? "label-input--error"
            : "label-input"
        }
        htmlFor={field.id}
      >
        <Button className="upload-button" component="span">
          <IconSelector name="PlusCircle" type="feather-icon" size={20} />
          <p className="add-text">Adicionar</p>
        </Button>
        <span className="filename-text">{values["filename"]}</span>

        {(values[field.id] || values["filename"]) && (
          <Button
            type="button"
            component="button"
            onClick={(event) => handleDeleteFile(event, field.id)}
            className="cancel-upload-btn"
          >
            <CloseIcon classes={{ root: "cancel-icon" }} />
          </Button>
        )}
      </label>
      <input
        type="file"
        accept=".pdf"
        id={field.id}
        disabled={disabled}
        onBlur={handleBlur}
        onClick={resetEvent}
        className="visually-hidden"
        onChange={(event) => handleFileUpload(event, field.id)}
      ></input>
    </>
  );
}

export default UploadFileInput;
