import { SET_ERROR_STATUS } from '../actions/error';

const initialState = {
	hasError: false
}

export default function error(state=initialState,action) {
	switch(action.type) {
		case SET_ERROR_STATUS:
			return {
				...state,
				hasError: action.hasError
			}
		default:
			return state
	}
}