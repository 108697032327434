import ExpensesText from './instructionsText/Expenses';
import IncomesText from './instructionsText/Incomes';
import BalancesText from './instructionsText/Balances';
import ProvidersText from './instructionsText/Providers';
import PurchaseProcessText from './instructionsText/PurchaseProcess';
import ThirdPartyContractsText from './instructionsText/ThirdPartyContracts';


function Instructions({textType}) {
	switch(textType) {
		case 2: 
			return <ExpensesText/>
			break
		case 11:
			return <IncomesText/>
			break
		case 12:
			return <BalancesText/>
			break
		case 9:
			return <ProvidersText/>
			break
		case 20:
			return <PurchaseProcessText/>
			break
		case 13:
			return <ThirdPartyContractsText/>
			break
		default:
			return null
	}
}

export default Instructions