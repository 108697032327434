import React from 'react';

import { useTranslation } from 'react-i18next';

function TextAreaInput({props}) {
	const { t } = useTranslation();
	const { field, values, handleChange, handleBlur, pageType, errors, touched, disabled} = props;

	return (
		<>
			<label className={errors[field.id] && touched[field.id] ? 'form__label--error' : 'form__label'} htmlFor={field.id}>{t(`${pageType}.fields.${field.id}`)}{field.validation.required ? '*' : null}</label><br></br>
			<textarea  className={errors[field.id] && touched[field.id] ? 'input__text-area--error' : 'input__text-area'} disabled={disabled} placeholder={t(`${pageType}.placeholder.${field.id}`)} id={field.id} value={values[field.id]} onChange={handleChange} onBlur={handleBlur}></textarea>
		</>
	)
}

export default TextAreaInput