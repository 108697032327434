import React from "react";

import TextInput from "./formElements/TextInput";
import TextAreaInput from "./formElements/TextAreaInput";
import UploadImgInput from "./formElements/UploadImgInput";
import MultiTextInput from "./formElements/MultiTextInput";
import SelectInput from "./formElements/SelectInput";
import CheckboxListInput from "./formElements/CheckboxListInput";
import UploadFileInput from "./formElements/UploadFileInput";
import CheckboxItem from "./formElements/CheckboxItem";
import DynamicMultiTextInput from "./formElements/DynamicMultiTextInput";
import AddressAutoComplete from "./formElements/AddressInput";

function FormElement({ props }) {
  switch (props.field.type) {
    case "input":
      return <TextInput props={props} />;
      break;
    case "inputTextArea":
      return <TextAreaInput props={props} />;
      break;
    case "select":
      return <SelectInput props={props} />;
      break;
    case "upload":
      return <UploadImgInput props={props} />;
      break;
    case "inputGroup":
      return <MultiTextInput props={props} />;
      break;
    case "checkboxOptions":
      return <CheckboxListInput props={props} />;
      break;
    case "fileUpload":
      return <UploadFileInput props={props} />;
      break;
    case "checkbox":
      return <CheckboxItem props={props} />;
    case "dynamicInput":
      return <DynamicMultiTextInput props={props} />;
      break;
    case "multiInput":
      return <MultiTextInput props={props} />;
    case "addressCodeInput":
      return <AddressAutoComplete props={props} />;
      break;
    default:
      return null;
  }
}

export default FormElement;
