import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import { handleGetProviderOptions } from "../../actions/selectOptions";
import { useDispatch } from "react-redux";
import { AlertCircle } from "lucide-react";

function DynamicMultiTextInput({ props }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    field,
    values,
    pageType,
    disabled,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    options,
  } = props;

  let inputGroup = {};

  field.inputs.forEach((input, index) => {
    if (index === 0) {
      inputGroup[input] = "none";
    } else if (index === 1) {
      inputGroup[input] = "";
    } else {
      inputGroup[input] = false;
    }
  });

  const [formFields, setFormFields] = useState(values.competitors);

  const keyDown = (event) => {
    if (event.code === "Backspace" && !values[field.id]) {
      event.preventDefault();
      if (!field.allowZero) {
        setFieldValue(field.id, "");
      }
    }

    if (field.allowZero) {
      if (event.code === "Backspace" && values[field.id] < 1000) {
        event.preventDefault();
        setFieldValue(field.id, 0);
      }
    }
  };

  const handleMultiChange = (event, index) => {
    let newFormFields = [...formFields];
    if (event.target.name === "winner") {
      newFormFields[index][event.target.name] = event.target.checked;
      setFieldTouched(event.target.name);
    } else {
      newFormFields[index][event.target.name] = event.target.value;
    }
    setFormFields(newFormFields);
    setFieldValue(field.id, newFormFields);
  };

  const addFormFields = () => {
    setFormFields([...formFields, inputGroup]);
    setFieldValue(field.id, [
      ...values.competitors,
      { id: "none", value: "", winner: false },
    ]);
    if (Array.isArray(touched[field.id])) {
      setFieldTouched(field.id, [
        ...touched[field.id],
        { id: false, value: false, winner: false },
      ]);
    }
  };

  const removeFormFields = (index) => {
    let newFormFields = [...formFields];
    newFormFields.splice(index, 1);
    let newValues = [...values.competitors];
    newValues.splice(index, 1);
    if (Array.isArray(touched[field.id])) {
      let newTouched = [...touched[field.id]];
      newTouched.splice(index, 1);
      setFieldTouched(field.id, newTouched);
    }
    setFormFields(newFormFields);
    setFieldValue(field.id, newValues);
  };

  const handleCustomBlur = (event, index) => {
    setFieldTouched(event.target.name + index);
  };

  useEffect(() => {
    dispatch(handleGetProviderOptions());
  }, []);

  return (
    <>
      <p className="group-input-title">Concorrência</p>
      {values &&
        values.competitors.length !== 0 &&
        values.competitors.map((formField, index) => (
          <div key={index}>
            <div className="group-subtitle-container">
              <p className="group-input-subtitle">Concorrente #{index + 1}</p>
              {index ? (
                <Tooltip
                  title="Excluir"
                  classes={{
                    tooltip: "tooltip",
                  }}
                  placement="bottom-start"
                >
                  <button
                    className="btn__close"
                    type="button"
                    onClick={() => removeFormFields(index)}
                  >
                    <CloseIcon classes={{ root: "btn__modal-icon--close" }} />
                  </button>
                </Tooltip>
              ) : null}
            </div>
            <div className="form__input-container-alt">
              <label
                className={
                  errors[field.id] &&
                  errors[field.id][index] &&
                  errors[field.id][index][field.inputs[0]] &&
                  (Array.isArray(touched[field.id])
                    ? touched[field.id][index][field.inputs[0]]
                    : touched[field.inputs[0] + index])
                    ? "form__label-alt--error"
                    : "form__label-alt"
                }
              >
                {t(`${pageType}.fields.${field.inputs[0]}`) + "*"}
              </label>
              <Select
                classes={{
                  root:
                    errors[field.id] &&
                    errors[field.id][index] &&
                    errors[field.id][index][field.inputs[0]] &&
                    (Array.isArray(touched[field.id])
                      ? touched[field.id][index][field.inputs[0]]
                      : touched[field.inputs[0] + index])
                      ? "select-input--error"
                      : "select-input",
                }}
                IconComponent={KeyboardArrowDownIcon}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      maxHeight: `${5.4 * 4}rem`,
                    },
                  },
                }}
                id={field.inputs[0]}
                name={field.inputs[0]}
                value={values[field.id][index][field.inputs[0]]}
                onChange={(event) => handleMultiChange(event, index)}
                onBlur={(event) => handleCustomBlur(event, index)}
                disabled={disabled}
              >
                <MenuItem
                  className="select-menu-item-hidden"
                  disabled
                  value="none"
                >
                  {t(`${pageType}.placeholder.${field.inputs[0]}`)}
                </MenuItem>
                {options &&
                  options.length !== 0 &&
                  Object.values(options[field.selectState]).length !== 0 &&
                  options[field.selectState].data.map((item) => (
                    <MenuItem
                      className="select-menu-item"
                      key={item.id}
                      value={item.id}
                    >
                      {item.code + " - " + item.name}
                    </MenuItem>
                  ))}
              </Select>
              {errors[field.id] &&
                errors[field.id][index] &&
                errors[field.id][index]["id"] &&
                (Array.isArray(touched[field.id])
                  ? touched[field.id][index][field.inputs[0]]
                  : touched[field.inputs[0] + index]) && (
                  <div className="input-feedback">
                    <AlertCircle size={18} />
                    <p className="error-text">
                      {errors[field.id][index]["id"]}
                    </p>
                  </div>
                )}
            </div>
            <div className="form__input-container-alt">
              <label
                className={
                  errors[field.id] &&
                  errors[field.id][index] &&
                  errors[field.id][index][field.inputs[1]] &&
                  (Array.isArray(touched[field.id])
                    ? touched[field.id][index][field.inputs[1]]
                    : touched[field.inputs[1] + index])
                    ? "form__label-alt--error"
                    : "form__label-alt"
                }
              >
                {t(`${pageType}.fields.${field.inputs[1]}`) + "*"}
              </label>
              <NumberFormat
                className={
                  errors[field.id] &&
                  errors[field.id][index] &&
                  errors[field.id][index][field.inputs[1]] &&
                  (Array.isArray(touched[field.id])
                    ? touched[field.id][index][field.inputs[1]]
                    : touched[field.inputs[1] + index])
                    ? "form__input--error"
                    : "form__input"
                }
                id={field.inputs[1]}
                name={field.inputs[1]}
                placeholder={t(`${pageType}.placeholder.${field.inputs[1]}`)}
                value={Number(values[field.id][index][field.inputs[1]]) * 100}
                onValueChange={(v) => {
                  const { value } = v;
                  let newFormFields = [...values.competitors];

                  if (value === "") {
                    newFormFields[index][field.inputs[1]] = "";
                    setFormFields(newFormFields);
                    setFieldValue(field.id, newFormFields);
                  } else {
                    newFormFields[index][field.inputs[1]] = parseFloat(
                      value / 100
                    );
                    setFormFields(newFormFields);
                    setFieldValue(field.id, newFormFields);
                  }
                }}
                onBlur={(event) => handleCustomBlur(event, index)}
                isNumericString={true}
                disabled={disabled}
                format={(formattedValue) => {
                  if (!Number(formattedValue)) {
                    if (field.allowZero) {
                      return "R$ 0,00";
                    } else {
                      return "";
                    }
                  }
                  return new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(formattedValue / 100);
                }}
                decimalSeparator=","
                thousandSeparator="."
                decimalScale={2}
                onKeyDown={keyDown}
              />
              {errors[field.id] &&
                errors[field.id][index] &&
                errors[field.id][index]["value"] &&
                (Array.isArray(touched[field.id])
                  ? touched[field.id][index][field.inputs[1]]
                  : touched[field.inputs[1] + index]) && (
                  <div className="input-feedback">
                    <AlertCircle size={18} />
                    <p className="error-text">
                      {errors[field.id][index]["value"]}
                    </p>
                  </div>
                )}
            </div>
            <div className="container__checkbox-alt">
              <p className="form__label-alt">
                {t(`${pageType}.fields.${field.inputs[2]}`)}
              </p>
              <Checkbox
                name={field.inputs[2]}
                checked={values[field.id][index][field.inputs[2]]}
                onChange={(event) => handleMultiChange(event, index)}
                classes={{ root: "checkbox-alt" }}
                disabled={disabled}
              />
            </div>
          </div>
        ))}
      <Button
        className="add-new-input-btn"
        type="button"
        onClick={() => addFormFields()}
      >
        <p className="input-btn">Adicionar Concorrente</p>
      </Button>
      {errors[field.id] &&
        errors[field.id][0][field.inputs[2]] &&
        (Array.isArray(touched[field.id])
          ? touched[field.id][0][field.inputs[2]]
          : touched[field.inputs[2]]) && (
          <div className="input-feedback">
            <AlertCircle size={18} />
            <p className="error-text">{errors[field.id][0]["winner"]}</p>
          </div>
        )}
    </>
  );
}

export default DynamicMultiTextInput;
