import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import { Frown } from "lucide-react";
import Button from "@material-ui/core/Button";

function OrgDetailsError({ type }) {
  const { t } = useTranslation();

  const history = useHistory();

  const goHome = () => {
    history.push("/");
  };

  return (
    <div className="error-container">
      <div className="error-content">
        <div className="error-message__container">
          <Avatar classes={{ root: "dashboard-content-icon" }}>
            <Frown size={72} strokeWidth={0.5} />
          </Avatar>
          <div className="dashboard-content-text">
            <p className="content-title">
              {t(`orgDetailsError.error.${type}.title`)}
            </p>
            <p>{t(`orgDetailsError.error.${type}.text`)}</p>
            <Button className="btn__back" onClick={goHome}>
              {t(`orgDetailsError.homeBtn`)}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrgDetailsError;
