import moment from 'moment';
import i18next from 'i18next';

export const formatData = (data, columns,pageType) => {
	let formattedData = []
	data.forEach(line => {
		Object.keys(line).forEach(key => {
			const keyColumn = columns.find(column => column.selector === key)
			if (keyColumn !== undefined) {
				line[key] = reducer(line,keyColumn.type,key,pageType)
			}
		})
		formattedData.push(line)
	})

	return formattedData
}

const reducer = (data, type, column, pageType) => {
	const mapping = {
		date: moment(data[column]).format('DD/MM/YYYY'),
		datetime: moment(data[column]).format('DD/MM/YYYY HH:mm'),
		boolean: type === "boolean" ? replaceBool(data[column], column, pageType) : null,
		
		currency: type === 'currency' ? data[column].toLocaleString('pt-BR', {
			style: 'currency',
			currency: 'BRL',
		}) : null,
		termType: type === 'termType' ? (data['is_addendum'] ? i18next.t(`${pageType}.addendum`, {number: data['addendum_number']}) : i18next.t(`${pageType}.contract`)) : null,
		pj_pf: data[column] === 'J' ? 'Jurídica' : 'Física',
		cnpj: type === 'cnpj' && formatCNPJ(data[column]),
	
	}

	if (mapping.hasOwnProperty(type)) {
		return mapping[type]
	} else {
		return data[column]
	}

}

const replaceBool = (value, column, pageType) => {
	const mapping = {
		status: value === null ? null : value ? i18next.t(`${pageType}.itemStatusTrue`) : i18next.t(`${pageType}.itemStatusFalse`),
		expiration_status: value === null ? i18next.t(`${pageType}.itemStatusNull`) : value ? i18next.t(`${pageType}.itemStatusTrue`) : i18next.t(`${pageType}.itemStatusFalse`),
		validation_status: value === null ? i18next.t(`${pageType}.validationStatusNull`) : value ? i18next.t(`${pageType}.validationStatusTrue`) : i18next.t(`${pageType}.validationStatusFalse`)
	}

	return mapping[column]
}

export const formatMultiStatus = (value, column) => {
	const mapping = {
		status: i18next.t(`common.multiStatus.${value}`)
	}

	return mapping[column]
}

export const convertValue = (value) => {
	return value.toLocaleString('pt-BR', {
		style: 'currency',
		currency: 'BRL',
	})
}

export const formatCNPJ = (cnpj) => {
	const formattedCNPJ = cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"\$1.\$2.\$3\/\$4\-\$5")

	return formattedCNPJ

}

export const formatPhone = (phoneNumber) => {
	const formattedNumber = phoneNumber.replace(/(\d{2})(\d{4})(\d{4})/g,"(\$1) \$2-\$3")

	return formattedNumber
}