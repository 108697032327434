import React, { useEffect } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useTranslation } from "react-i18next";
import {
  handleGetCategoryOptions,
  handleGetProfileOptions,
  handleGetOSSOptions,
  handleGetGenderOptions,
  handleGetUnitTypesOptions,
  handleGetCOAGroupsOptions,
  handleGetHeadingsGroupOptions,
  handleGetBATypesOptions,
  handleGetAgenciesOptions,
  handleGetBankOptions,
  handleGetContractOptions,
  handleGetUnitsOptions,
  handleGetUnitsByContract,
  handleGetHeadingsByContract,
  handleGetDocTypes,
  handleGetBankAccountsByContract,
  handleGetStates,
  handleGetModeOptions,
  handleGetProviderOptions,
  handleGetPurchaseProcessOptions,
} from "../../actions/selectOptions";
import { useDispatch } from "react-redux";

function SelectInput({ props }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    field,
    values,
    options,
    handleChange,
    handleBlur,
    pageType,
    errors,
    touched,
    disabled,
    setFieldValue,
    isEditing,
  } = props;

  const selectFunction = {
    users: {
      category_id: handleGetCategoryOptions,
      profile_id: handleGetProfileOptions,
      org_id: handleGetOSSOptions,
      gender_id: handleGetGenderOptions,
    },
    profiles: {
      profile_id: handleGetProfileOptions,
    },
    headings: {
      type_unit_id: handleGetUnitTypesOptions,
      group_id: handleGetHeadingsGroupOptions,
    },
    chartOfAccounts: {
      group_id: handleGetCOAGroupsOptions,
    },
    contracts: {
      org_id: handleGetOSSOptions,
    },
    bankAccounts: {
      org_id: handleGetOSSOptions,
      account_type_id: handleGetBATypesOptions,
      agency_number: handleGetAgenciesOptions,
      bank_number: handleGetBankOptions,
      contract_id: handleGetContractOptions,
    },
    units: {
      type_unit_id: handleGetUnitTypesOptions,
      unit_id: handleGetUnitsOptions,
    },
    addendum: {
      org_id: handleGetOSSOptions,
    },
    save_as: {
      org_id: handleGetOSSOptions,
    },
    expenses: {
      contract_id: handleGetContractOptions,
      unit_id: handleGetUnitsByContract,
      type_id: handleGetDocTypes,
      chart_of_accounts_item_id: handleGetCOAGroupsOptions,
      heading_id: handleGetHeadingsByContract,
      bank_account_id: handleGetBankAccountsByContract,
    },
    balances: {
      contract_id: handleGetContractOptions,
      bank_account_id: handleGetBankAccountsByContract,
    },
    incomes: {
      contract_id: handleGetContractOptions,
      bank_account_id: handleGetBankAccountsByContract,
    },
    providers: {
      uf: handleGetStates,
    },
    purchase_process: {
      org_id: handleGetOSSOptions,
      process_mode_id: handleGetModeOptions,
    },
    third_party_contracts: {
      purchase_process_id: handleGetPurchaseProcessOptions,
      provider_id: handleGetProviderOptions,
      unit_id: handleGetUnitsByContract,
      contract_id: handleGetContractOptions,
    },
  };

  const handleConditionalChange = (event) => {
    const { value } = event.target;
    setFieldValue(field.id, value);
    field.childSelects.forEach((childSelect) => {
      dispatch(selectFunction[pageType][childSelect](value, pageType)).then(
        () => {
          setFieldValue(childSelect, "none");
        }
      );
    });
  };

  useEffect(() => {
    if (
      field.conditionalSelect &&
      !field.masterSelect &&
      values[field.parentSelect] !== "none"
    ) {
      dispatch(
        field.conditionalSelect &&
        !field.masterSelect &&
        selectFunction[pageType][field.id](
          values[field.parentSelect],
          pageType
        )
      );
    } else if (
      field.conditionalSelect &&
      !field.masterSelect &&
      values[field.parentSelect] === "none"
    ) {
    } else if (field.selectState !== "fixedOptions") {
      dispatch(selectFunction[pageType][field.id](null, pageType));
    }
  }, [values[field.parentSelect] ? values[field.parentSelect] : ""]);

  useEffect(() => {
    if (field.id === "provider_id") {
      if (
        values[field.parentVisibility] !== "none" &&
        values[field.parentVisibility] !== "null"
      ) {
        const selectedOption = options["purchaseProcessOptions"].data.filter(
          (option) => option.id === values[field.parentVisibility]
        );
        setFieldValue("provider_id", selectedOption[0].provider_id);
      } else if (values[field.parentVisibility] === "null" && !isEditing) {
        setFieldValue(field.id, "none");
      }
    }
  }, [field.conditionalVisibility ? values[field.parentVisibility] : ""]);

  return (
    <>
      {field.conditionalVisibility ? (
        (field.visibilityReq.length === 0
          ? values[field.parentVisibility] !== "none"
          : field.visibilityReq.includes(values[field.parentVisibility])) &&
          (field.subVisibility ? !values[field.subVisibility] : true) ? (
          <>
            <label
              className={
                errors[field.id] && touched[field.id]
                  ? "form__label--error"
                  : "form__label"
              }
              htmlFor={field.id}
            >
              {t(`${pageType}.fields.${field.id}`)}
              {field.validation.required ? "*" : null}
            </label>
            <Select
              classes={{
                root:
                  errors[field.id] && touched[field.id]
                    ? "select-input--error"
                    : "select-input",
              }}
              IconComponent={KeyboardArrowDownIcon}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    maxHeight: `${5.4 * 4}rem`,
                  },
                },
              }}
              id={field.id}
              name={field.id}
              value={values[field.id]}
              onChange={
                field.conditionalSelect && field.masterSelect
                  ? (event) => handleConditionalChange(event)
                  : handleChange
              }
              onBlur={handleBlur}
              disabled={
                field.conditionalSelect && !field.masterSelect
                  ? values[field.parentSelect] === "none" || disabled
                  : field.id === "provider_id" &&
                    values[field.parentVisibility] !== "null"
                    ? true
                    : disabled
              }
            >
              <MenuItem
                className="select-menu-item-hidden"
                disabled
                value="none"
              >
                {t(`${pageType}.placeholder.${field.id}`)}
              </MenuItem>
              {field.selectState === "fixedOptions"
                ? field.fixedOptions.map((item) => (
                  <MenuItem
                    className="select-menu-item"
                    key={item.id}
                    value={item.id}
                  >
                    {item.name}
                  </MenuItem>
                ))
                : options &&
                options.length !== 0 &&
                Object.values(options[field.selectState]).length !== 0 &&
                options[field.selectState].data.map((item) => (
                  <MenuItem
                    className="select-menu-item"
                    key={item.id}
                    value={item.id}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              {field.selectState !== "fixedOptions" &&
                options &&
                Object.values(options[field.selectState]).length !== 0 &&
                options[field.selectState].data.length === 0 && (
                  <MenuItem
                    className="select-menu-item"
                    disabled
                    value="no-item"
                  >
                    Nenhum item disponível
                  </MenuItem>
                )}
            </Select>
          </>
        ) : null
      ) : (
        <>
          <>
            <label
              className={
                errors[field.id] && touched[field.id]
                  ? "form__label--error"
                  : "form__label"
              }
              htmlFor={field.id}
            >
              {t(`${pageType}.fields.${field.id}`)}
              {field.validation.required ? "*" : null}
            </label>
            <Select
              classes={{
                root:
                  errors[field.id] && touched[field.id]
                    ? "select-input--error"
                    : "select-input",
              }}
              IconComponent={KeyboardArrowDownIcon}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    maxHeight: `${5.4 * 4}rem`,
                  },
                },
              }}
              id={field.id}
              name={field.id}
              value={values[field.id]}
              onChange={
                field.conditionalSelect && field.masterSelect
                  ? (event) => handleConditionalChange(event)
                  : handleChange
              }
              onBlur={handleBlur}
              disabled={
                (pageType === "headings" && isEditing) ||
                (field.conditionalSelect &&
                  !field.masterSelect &&
                  values[field.parentSelect] === "none") ||
                disabled
              }
            >
              <MenuItem
                className="select-menu-item-hidden"
                disabled
                value="none"
              >
                {t(`${pageType}.placeholder.${field.id}`)}
              </MenuItem>
              {field.selectState === "fixedOptions"
                ? field.fixedOptions.map((item) => (
                  <MenuItem
                    className="select-menu-item"
                    key={item.id}
                    value={item.id}
                  >
                    {item.name}
                  </MenuItem>
                ))
                : options &&
                options.length !== 0 &&
                Object.values(options[field.selectState]).length !== 0 &&
                options[field.selectState].data.map((item) => (
                  <MenuItem
                    className="select-menu-item"
                    key={item.id}
                    value={item.id}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              {field.selectState !== "fixedOptions" &&
                options &&
                Object.values(options[field.selectState]).length !== 0 &&
                options[field.selectState].data.length === 0 && (
                  <MenuItem
                    className="select-menu-item"
                    disabled
                    value="no-item"
                  >
                    Nenhum item disponível
                  </MenuItem>
                )}
            </Select>
          </>
        </>
      )}
    </>
  );
}

export default SelectInput;
