import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { Formik } from "formik";
import { AlertCircle } from "lucide-react";
import { useHistory } from "react-router-dom";

import { handleLogin } from "../actions/user";
import { requestNewPassword } from "../utils/dataAPI";
import backgroundImage from "../../img/login_background.png";
import logo from "../../img/logo_estado_para.png";
import TermsText from "./TermsText";
import PolicyText from "./PolicyText";

function Login() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const recoveryForm = useRef(null);
  const [sentEmail, setSentEmail] = useState(false);
  const [recoveryPassModal, setRecoveryPassModal] = useState(false);
  const [termsModal, setTermsModal] = useState(false);
  const [modalMode, setModalMode] = useState("");
  const [visiblePass, setVisiblePass] = useState(false);
  const loginStatus = useSelector((state) => state.user.loginStatus);

  const loginInitialValues = {
    username: "",
    password: "",
    termsCheckbox: false,
    captcha: null,
  };

  const recoveryInitialValues = {
    email: "",
  };

  const submitLogin = (values, actions) => {
    dispatch(
      handleLogin(values.username, values.password, values.captcha)
    ).then((response) => {
      if (response) {
        actions.setFieldError("username", t(`login.errorMessage.${response}`));
        actions.setFieldError("password", t(`login.errorMessage.${response}`));
      } else {
        history.push("/");
      }
      actions.setSubmitting(false);
    });
  };

  const loginValidation = (values) => {
    const errors = {};
    if (!values.username) {
      errors.username = t("common.inputError.missingField");
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.username)
    ) {
      errors.username = t("common.inputError.invalidUsername");
    }

    if (!values.password) {
      errors.password = t("common.inputError.missingField");
    }

    if (!values.termsCheckbox) {
      errors.termsCheckbox = t("common.inputError.acceptTerms");
    }

    if (values.captcha === null) {
      errors.captcha = t("common.inputError.verifyCaptcha");
    }

    return errors;
  };

  const submitEmail = (values, setSubmitting) => {
    requestNewPassword(values.email).then((data) => {
      if (data.success) {
        setSentEmail(true);
      }
      setSubmitting(false);
    });
  };

  const recoveryValidation = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = t("common.inputError.missingField");
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = t("common.inputError.emailValidation");
    }
    return errors;
  };

  const onChangeVisibility = () => {
    setVisiblePass(!visiblePass);
  };

  const openModal = () => {
    setRecoveryPassModal(true);
  };

  const closeModal = () => {
    setRecoveryPassModal(false);
    if (!sentEmail) {
      recoveryForm.current.resetForm();
    }
    setTimeout(() => {
      setSentEmail(false);
    }, 1000);
  };

  const openTerms = (mode) => {
    setModalMode(mode);
    setTermsModal(true);
  };

  const closeTerms = () => {
    setTermsModal(false);
  };

  useEffect(() => {
    document.title = t("login.pageTitle");
  }, [t]);

  return (
    <div className="container__login-page">
      <div className="container__login-content">
        <div className="header--login-page">
          <img
            alt="logo governo pará"
            className="header__logo-img"
            src={logo}
          />
        </div>
        <div className="container__form">
          <p className="form__title">{t("login.formTitle")}</p>
          <Formik
            initialValues={loginInitialValues}
            validate={(values) => loginValidation(values)}
            onSubmit={(values, actions) => submitLogin(values, actions)}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              handleSubmit,
              handleBlur,
              handleChange,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="form__input-container">
                  <label
                    className={
                      errors.username && touched.username
                        ? "form__label--error"
                        : "form__label"
                    }
                    htmlFor="username"
                  >
                    {t("login.formFields.username")}
                  </label>
                  <input
                    type="text"
                    className={
                      errors.username && touched.username
                        ? "form__input--error"
                        : "form__input"
                    }
                    id="username"
                    placeholder={t("login.placeholder.username")}
                    value={values.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                  ></input>
                  {errors.username && touched.username && (
                    <div className="input-feedback">
                      <AlertCircle size={18} />
                      <p className="error-text">{errors.username}</p>
                    </div>
                  )}
                </div>
                <div className="form__input-container">
                  <label
                    className={
                      errors.password && touched.password
                        ? "form__label--error"
                        : "form__label"
                    }
                    htmlFor="password"
                  >
                    {t("login.formFields.password")}
                  </label>
                  <input
                    type={visiblePass ? "text" : "password"}
                    className={
                      errors.password && touched.password
                        ? "form__input-password--error"
                        : "form__input-password"
                    }
                    id="password"
                    placeholder={t("login.placeholder.password")}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                  ></input>
                  <button
                    className="btn__input-btn"
                    onClick={onChangeVisibility}
                    type="button"
                  >
                    {visiblePass ? (
                      <VisibilityOutlinedIcon
                        classes={{ root: "input__icon" }}
                      />
                    ) : (
                      <VisibilityOffOutlinedIcon
                        classes={{ root: "input__icon" }}
                      />
                    )}
                  </button>
                  {errors.password && touched.password && (
                    <div className="input-feedback">
                      <AlertCircle size={18} />
                      <p className="error-text">{errors.password}</p>
                    </div>
                  )}
                </div>
                <button
                  className="btn--forgot-password"
                  type="button"
                  onClick={openModal}
                >
                  {t("login.recoverPassLink")}
                </button>
                <br />
                <br />
                <div className="container__checkbox">
                  <Checkbox
                    name="termsCheckbox"
                    checked={values.termsCheckbox}
                    onChange={handleChange}
                    classes={{ root: "checkbox" }}
                    disabled={isSubmitting}
                  />
                  <p className="checkbox__text">
                    {t("login.checkbox.text1")}
                    <button
                      className="checkbox__link"
                      onClick={() => openTerms("terms")}
                      type="button"
                    >
                      {t("login.checkbox.text2")}
                    </button>
                    {t("login.checkbox.text3")}
                    <button
                      className="checkbox__link"
                      onClick={() => openTerms("policy")}
                      type="button"
                    >
                      {t("login.checkbox.text4")}
                    </button>
                    {t("login.checkbox.text5")}
                  </p>
                </div>
                <div className="recaptcha">
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={(value) => setFieldValue("captcha", value)}
                  />
                </div>
                {!loginStatus &&
                  ((errors.termsCheckbox && touched.termsCheckbox) ||
                    (errors.captcha && touched.captcha)) && (
                    <div className="container__error-message">
                      {errors.termsCheckbox && touched.termsCheckbox && (
                        <div className="container__error-text">
                          <AlertCircle size={18} />
                          <p className="error-text">{errors.termsCheckbox}</p>
                        </div>
                      )}
                      {errors.captcha && touched.captcha && (
                        <div className="container__error-text">
                          <AlertCircle size={18} />
                          <p className="error-text">{errors.captcha}</p>
                        </div>
                      )}
                    </div>
                  )}
                <Button
                  classes={{ root: "btn__login-btn" }}
                  type="submit"
                  disabled={isSubmitting}
                >
                  {t("login.loginBtn")}{" "}
                  {isSubmitting ? (
                    <CircularProgress
                      classes={{ root: "btn__loading" }}
                      size="2rem"
                    />
                  ) : (
                    <ArrowForwardIcon classes={{ root: "btn__icon" }} />
                  )}
                </Button>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <div className="container__background-img">
        <img
          alt="imagem de fundo"
          className="login__background-img"
          src={backgroundImage}
        />
      </div>
      <Dialog
        open={recoveryPassModal}
        onClose={closeModal}
        classes={{
          root: "modal__root",
          paper: "modal",
        }}
        keepMounted
      >
        <>
          <div className="modal__header">
            <img
              alt="logo governo pará"
              className="modal__logo-img"
              src={logo}
            />
            <Tooltip
              title={t("common.tooltip.close")}
              classes={{
                tooltip: "tooltip",
              }}
              placement="bottom-start"
            >
              <Button
                onClick={closeModal}
                classes={{ root: "btn__modal-btn--close" }}
              >
                <CloseIcon classes={{ root: "btn__modal-icon--close" }} />
              </Button>
            </Tooltip>
          </div>
          <div className="modal__form">
            {!sentEmail ? (
              <>
                <p className="modal__title">{t("login.recoveryTitle")}</p>
                <Formik
                  innerRef={recoveryForm}
                  initialValues={recoveryInitialValues}
                  validate={(values) => recoveryValidation(values)}
                  onSubmit={(values, { setSubmitting }) =>
                    submitEmail(values, setSubmitting)
                  }
                >
                  {({
                    values,
                    errors,
                    touched,
                    isSubmitting,
                    handleChange,
                    handleSubmit,
                    handleBlur,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="form__input-container">
                        <label
                          className={
                            errors.email && touched.email
                              ? "form__label--error"
                              : "form__label"
                          }
                          htmlFor="email"
                        >
                          {t("login.recoveryField.email")}
                        </label>
                        <input
                          id="email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            errors.email && touched.email
                              ? "form__input--error"
                              : "form__input"
                          }
                          placeholder={t("login.placeholder.email")}
                        />
                        {errors.email && touched.email && (
                          <div className="input-feedback">
                            <AlertCircle size={18} />
                            <p className="error-text">{errors.email}</p>
                          </div>
                        )}
                      </div>
                      {isSubmitting ? (
                        <CircularProgress
                          classes={{ root: "btn__loading--alternative" }}
                        />
                      ) : (
                        <Button
                          classes={{ root: "btn__modal-btn" }}
                          type="submit"
                        >
                          {t("login.recoverBtn")}
                        </Button>
                      )}
                    </form>
                  )}
                </Formik>
              </>
            ) : (
              <>
                <p className="modal__title">{t("login.successTitle")}</p>
                <p className="modal__text">{t("login.successText")}</p>
                <Button
                  onClick={closeModal}
                  classes={{ root: "btn__modal-btn" }}
                  type="button"
                >
                  {t("login.okBtn")}
                </Button>
              </>
            )}
          </div>
        </>
      </Dialog>
      <Dialog
        open={termsModal}
        onClose={closeTerms}
        scroll="paper"
        classes={{
          root: "modal__root",
          paper: "modal__body",
        }}
      >
        <DialogTitle classes={{ root: "dialog__title" }}>
          <div className="container__modal-header">
            <Tooltip
              title={t("common.tooltip.close")}
              classes={{
                tooltip: "tooltip",
              }}
              placement="bottom-start"
            >
              <button type="button" className="btn__close" onClick={closeTerms}>
                <CloseIcon classes={{ root: "btn__modal-icon--close" }} />
              </button>
            </Tooltip>
            <p className="modal__header-title">
              {t(`login.modalTitle.${modalMode}`)}
            </p>
          </div>
        </DialogTitle>
        <DialogContent classes={{ root: "modal__content" }}>
          {modalMode === "terms" ? (
            <TermsText />
          ) : modalMode === "policy" ? (
            <PolicyText />
          ) : null}
        </DialogContent>
      </Dialog>
    </div>
  );
}
export default Login;
