import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import DataTable from "react-data-table-component";
import { useDropzone } from "react-dropzone";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import Avatar from "@material-ui/core/Avatar";
import SearchIcon from "@material-ui/icons/Search";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import LabelOutlinedIcon from "@material-ui/icons/LabelOutlined";
import KeyboardArrowDownOutlinedIcon from "@material-ui/icons/KeyboardArrowDownOutlined";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import Tooltip from "@material-ui/core/Tooltip";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { useTranslation } from "react-i18next";
import { XOctagon } from "lucide-react";
import { importDocument, xmlValidation, importXML } from "../utils/dataAPI";
import { docsValidationResults } from "../data/tableConfig";
import { tableCustomStyles } from "../data/tableStyles";
import IconSelector from "./IconSelector";

function ImportDocuments() {
  const { t } = useTranslation();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: ["application/pdf", "text/xml", "application/xml"],
  });
  const [files, setFiles] = useState([]);

  const [showSendList, setShowSendList] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [inValidation, setInValidation] = useState(false);
  const [multiActionEl, setMultiActionEl] = useState(null);

  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filesSent, setFilesSent] = useState(false);
  const [xmlFiles, setXmlFiles] = useState({});
  const [importError, setImportError] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [openResultModal, setOpenResultModal] = useState(false);
  const [numberOfFiles, setNumberOfFiles] = useState(0);
  const [importStatus, setImportStatus] = useState(null);

  let tableColumns = [];

  docsValidationResults.fields.forEach((field) => {
    let tableItem = {
      ...field,
      ...(field.hasOwnProperty("name") && {
        name: t(`importDocuments.validationResult.${field.id}`),
      }),
    };

    if (field.type === "boolean") {
      tableItem.cell = (row) => {
        if (row[field.selector] !== null) {
          return (
            <div
              className={
                row[field.selector] ===
                  t("importDocuments.statusValidation.itemStatusTrue") ||
                row[field.selector] ===
                  t("importDocuments.statusImport.itemStatusTrue")
                  ? "status-active"
                  : "status-invalid"
              }
            >
              {row[field.selector]}
            </div>
          );
        }
      };
    }

    tableColumns[field.order] = tableItem;
  });

  const [inputText, setInputText] = useState(() =>
    tableColumns.map((tableColumn) => "")
  );

  const onImportDocuments = async () => {
    if (files.length !== 0) {
      let xmlImportStatus = null;
      let pdfImportStatus = null;
      let copyFiles = [...files];
      let xmlFilteredFiles = files.filter(
        (file) =>
          file.filename.slice(-3).toLowerCase() === "xml" &&
          file.status === t("importDocuments.statusValidation.itemStatusTrue")
      );
      let pdfFilteredFiles = files.filter(
        (file) => file.filename.slice(-3).toLowerCase() === "pdf"
      );
      setNumberOfFiles(xmlFilteredFiles.length + pdfFilteredFiles.length);
      if (xmlFilteredFiles.length === 0 && pdfFilteredFiles.length === 0) {
        setFilesSent(true);
        setImportStatus("allInvalid");
        setOpenResultModal(true);
        setLoadingProgress(100);
        return;
      }
      setLoading(true);
      try {
        if (xmlFilteredFiles.length !== 0) {
          const xmlResponse = await importXML(xmlFilteredFiles);
          copyFiles.forEach((file, index) => {
            if (xmlResponse && xmlResponse.success) {
              if (xmlResponse.data.hasOwnProperty(file.filename)) {
                if (!xmlResponse.data[file.filename].status) {
                  file.reason = t(
                    `importDocuments.reason.${
                      xmlResponse.data[file.filename].reason
                    }`
                  );
                }
                file.status = xmlResponse.data[file.filename].status
                  ? t("importDocuments.statusImport.itemStatusTrue")
                  : t("importDocuments.statusImport.itemStatusFalse");
              }
            } else {
              if (
                xmlFilteredFiles.filter((f) => f.filename === file.filename)
                  .length !== 0
              ) {
                file.status = t("importDocuments.statusImport.itemStatusFalse");
              }
            }
          });

          if (xmlResponse.stats.n_success === xmlFilteredFiles.length) {
            xmlImportStatus = "success";
          } else if (xmlResponse.stats.n_failed === xmlFilteredFiles.length) {
            xmlImportStatus = "failed";
          } else {
            xmlImportStatus = "warning";
          }
        }

        setLoadingProgress(
          (currentLoadingProgress) => currentLoadingProgress + 50
        );

        if (pdfFilteredFiles.length !== 0) {
          let pdfResponse = await Promise.allSettled(
            pdfFilteredFiles.map(async (pdfFile) => {
              const response = await importDocument(pdfFile.file);

              return response;
            })
          );

          copyFiles.forEach((file) => {
            if (
              pdfFilteredFiles.filter((f) => f.filename === file.filename)
                .length !== 0
            ) {
              const fileIndex = pdfFilteredFiles.findIndex(
                (f) => f.filename === file.filename
              );
              if (pdfResponse[fileIndex].value.status === 204) {
                file["status"] = t(
                  "importDocuments.statusImport.itemStatusTrue"
                );
              } else {
                file["status"] = t(
                  "importDocuments.statusImport.itemStatusFalse"
                );
              }
            }
          });

          if (pdfResponse.every((response) => response.value.status === 204)) {
            pdfImportStatus = "success";
          } else if (
            pdfResponse.every((response) => response.value.status !== 204)
          ) {
            pdfImportStatus = "failed";
          } else {
            pdfImportStatus = "warning";
          }
        }

        if (pdfImportStatus === null) {
          setImportStatus(xmlImportStatus);
        } else if (xmlImportStatus === null) {
          setImportStatus(pdfImportStatus);
        } else if (
          pdfImportStatus === "success" &&
          xmlImportStatus === "success"
        ) {
          setImportStatus("success");
        } else if (
          pdfImportStatus === "failed" &&
          xmlImportStatus === "failed"
        ) {
          setImportStatus("failed");
        } else {
          setImportStatus("warning");
        }

        setFiles(copyFiles);
        setFilesSent(true);
        setOpenResultModal(true);
        setLoadingProgress(
          (currentLoadingProgress) => currentLoadingProgress + 50
        );
      } catch (error) {
        setImportError(true);
        setImportStatus(null);
        setOpenResultModal(true);
        setLoadingProgress(100);
      }
    }
  };

  const handleCloseAlert = () => {
    setOpenModal(false);
  };

  const handleBacktoStart = () => {
    setLoadingProgress(0);
    setShowSendList(false);
    setFilesSent(false);
    setFiles([]);
    setNumberOfFiles(0);
    setXmlFiles({});
    importStatus(null);
    importError(false);
    setOpenModal(false);
    setLoading(false);
  };

  const onFilter = (value, column, index) => {
    let newState = [...inputText];
    newState[index] = value;
    setInputText(newState);
  };

  const handleMultiActionMenu = (event) => {
    event.stopPropagation();
    setMultiActionEl(null);
  };

  const onDeleteItem = (event) => {
    handleMultiActionMenu(event);
    let newData = files.filter((file) =>
      selectedRows.every((row) => row.id !== file.id)
    );

    setFiles(newData);
  };

  const onShowMultiActionMenu = (event) => {
    event.stopPropagation();
    setMultiActionEl(event.currentTarget);
  };

  const handleSelectedRows = (allSelected) => {
    setSelectedRows(allSelected.selectedRows);
  };

  const handleResultModal = () => {
    setOpenResultModal(false);
    setTimeout(() => {
      if (importError) {
        setFiles([]);
        setLoadingProgress(0);
        setInValidation(false);
        setShowSendList(false);
      }
      setXmlFiles({});
      setImportStatus(null);
      setImportError(false);
    }, 1500);
  };

  const goBack = () => {
    if (filesSent) {
      setLoadingProgress(0);
      setShowSendList(false);
      setFilesSent(false);
      setFiles([]);
      setOpenModal(false);
      setLoading(false);
      setNumberOfFiles(0);
    } else {
      setOpenModal(true);
    }
  };

  const importMoreDocs = (event) => {
    setLoading(true);

    if (event.target.files.length !== 0) {
      let newFiles = [...event.target.files];
      setNumberOfFiles(newFiles.length);
      let correctFiles = newFiles.filter(
        (file) =>
          file.name.slice(-3).toLowerCase() === "xml" ||
          file.name.slice(-3).toLowerCase() === "pdf"
      );
      let filteredFiles = newFiles.filter(
        (file) => file.name.slice(-3).toLowerCase() === "xml"
      );

      let progressIncrement = 0;
      if (
        filteredFiles.length < process.env.REACT_APP_XML_VALIDATION_BATCH_SIZE
      ) {
        progressIncrement = 100;
      } else {
        progressIncrement =
          100 /
          (Math.floor(
            filteredFiles.length /
              process.env.REACT_APP_XML_VALIDATION_BATCH_SIZE
          ) +
            (filteredFiles.length %
              process.env.REACT_APP_XML_VALIDATION_BATCH_SIZE));
      }

      if (filteredFiles.length !== 0) {
        validateXMLFiles(filteredFiles, progressIncrement).then(
          (resultXmlFiles) => {
            let fileList = formatInputData(correctFiles, resultXmlFiles);
            setFiles(fileList);
          }
        );
      } else {
        let fileList = formatInputData(correctFiles, {});
        setFiles(fileList);
        setLoadingProgress(100);
      }
    }
  };

  const validateXMLFiles = async (filteredFiles, increment) => {
    let nextBatch = [];
    if (filteredFiles.length !== 0) {
      if (
        filteredFiles.length > process.env.REACT_APP_XML_VALIDATION_BATCH_SIZE
      ) {
        nextBatch = filteredFiles.splice(
          process.env.REACT_APP_XML_VALIDATION_BATCH_SIZE - filteredFiles.length
        );
      }

      const validationResult = await xmlValidation(filteredFiles);

      if (validationResult && validationResult.success) {
        setXmlFiles(Object.assign(xmlFiles, validationResult.data));
        setLoadingProgress(
          (currentLoadingProgress) => currentLoadingProgress + increment
        );

        if (nextBatch.length !== 0) {
          return validateXMLFiles(nextBatch, increment);
        }
        return Object.assign({}, xmlFiles, validationResult.data);
      } else {
        setImportError(true);
        setOpenResultModal(true);
      }
    }
  };

  const formatInputData = (inputFiles, xmlFiles) => {
    let fileList = [];
    let copyFiles = [...files];
    inputFiles.forEach((item, index) => {
      if (files.length === 0) {
        const file_info = formatTableData(item, index, xmlFiles);

        fileList.push(file_info);
      } else {
        let verifyDuplicate = [];
        files.forEach((file, index) => {
          if (file.filename === item.name) {
            verifyDuplicate.push(true);
          } else {
            verifyDuplicate.push(false);
          }
        });
        if (verifyDuplicate.every((value) => value === false)) {
          const filesLength = files.length;
          const newIndex = filesLength + index;
          const file_info = formatTableData(item, newIndex, xmlFiles);
          copyFiles.push(file_info);
        }
      }
    });
    if (files.length > 0) {
      fileList = [...copyFiles, ...fileList];
    }

    return fileList;
  };

  const formatTableData = (item, index, xmlFiles) => {
    let doc_info = {};

    if (
      Object.keys(xmlFiles).length !== 0 &&
      xmlFiles.hasOwnProperty(item.name)
    ) {
      doc_info["document_type"] = t(
        `importDocuments.documentType.${xmlFiles[item.name].type}`
      );
      doc_info["document_ext"] = "XML";
      doc_info["status"] = xmlFiles[item.name].status
        ? t("importDocuments.statusValidation.itemStatusTrue")
        : t("importDocuments.statusValidation.itemStatusFalse");
      doc_info["reason"] = t(
        `importDocuments.reason.${xmlFiles[item.name].reason}`
      );
    } else {
      doc_info["document_type"] = "Documento";
      doc_info["document_ext"] = "PDF";
      doc_info["status"] = null;
      doc_info["reason"] = null;
    }
    doc_info["filename"] = item.name;
    doc_info["file"] = item;
    doc_info["id"] = index;

    return doc_info;
  };

  useEffect(() => {
    document.title = t("importDocuments.pageTitle");
  });

  useEffect(() => {
    if (acceptedFiles.length !== 0) {
      setInValidation(true);
      setNumberOfFiles(acceptedFiles.length);
      let filteredFiles = acceptedFiles.filter(
        (file) => file.path.slice(-3).toLowerCase() === "xml"
      );

      let progressIncrement = 0;
      if (
        filteredFiles.length < process.env.REACT_APP_XML_VALIDATION_BATCH_SIZE
      ) {
        progressIncrement = 100;
      } else {
        progressIncrement =
          100 /
          (Math.floor(
            filteredFiles.length /
              process.env.REACT_APP_XML_VALIDATION_BATCH_SIZE
          ) +
            (filteredFiles.length %
              process.env.REACT_APP_XML_VALIDATION_BATCH_SIZE));
      }
      if (filteredFiles.length !== 0) {
        validateXMLFiles(filteredFiles, progressIncrement)
          .then((resultXmlFiles) => {
            const fileList = formatInputData(acceptedFiles, resultXmlFiles);
            setFiles(fileList);
          })
          .catch((error) => {
            setImportError(true);
            setOpenResultModal(true);
          });
      } else {
        const fileList = formatInputData(acceptedFiles, {});
        setFiles(fileList);
        setLoadingProgress(100);
      }
    }
  }, [acceptedFiles]);

  useEffect(() => {
    if (loadingProgress === 100) {
      const timer = setInterval(() => {
        setShowSendList(true);
        setInValidation(false);
        setLoading(false);
        setLoadingProgress(0);
      }, 800);

      return () => {
        clearInterval(timer);
      };
    }
  }, [loadingProgress]);

  return (
    <div className="container__page">
      <div className="header__page">
        {showSendList && (
          <Button className="back-btn" onClick={goBack} type="button">
            <ArrowBackIcon />
          </Button>
        )}
        <p className="header__page-title">{t("importDocuments.pageTitle")}</p>
      </div>
      <div className="content-container">
        <div className="info-container">
          <p className="info-title">{t("importDocuments.infoTitle")}</p>
          <p className="info-text">{t("importDocuments.infoTextPrimary")}</p>
          <p className="info-text">{t("importDocuments.infoTextSecondary")}</p>
        </div>
        {showSendList ? (
          <div className="tablepage-content table-full-width">
            <DataTable
              columns={tableColumns}
              data={files}
              noHeader
              subHeader
              progressPending={loading}
              progressComponent={
                <div className="error-content--table">
                  <div className="loading-table-container">
                    <div className="loading-percentage">
                      <CircularProgress
                        classes={{ root: "progress-loading" }}
                        style={{ width: "7rem", height: "7rem" }}
                      />
                      <div className="loading-percentage-container">
                        <p className="loading-percentage-number">{`${Math.floor(
                          loadingProgress
                        )}%`}</p>
                      </div>
                    </div>
                    <p className="loading-percentage-text">
                      {t("importDocuments.filesValidation.number", {
                        count: numberOfFiles,
                      })}
                    </p>
                  </div>
                </div>
              }
              selectableRows
              selectableRowsComponent={Checkbox}
              selectableRowsComponentProps={{
                indeterminate: (isIndeterminate) => isIndeterminate,
              }}
              onSelectedRowsChange={handleSelectedRows}
              highlightOnHover
              selectableRowsHighlight
              sortIcon={<ArrowDownwardIcon />}
              pagination
              customStyles={tableCustomStyles}
              noDataComponent={
                <div className="error-content--table">
                  <div className="message__container">
                    <Avatar classes={{ root: "nodata-content-icon" }}>
                      <XOctagon size={60} strokeWidth={0.5} />
                    </Avatar>
                    <p className="title-message">{t("common.files.noData")}</p>
                  </div>
                </div>
              }
              subHeaderComponent={
                <Accordion
                  expanded={openSearch}
                  classes={{ root: "accordion__tableHeader" }}
                >
                  <AccordionSummary
                    classes={{ root: "accordion__summary-table" }}
                  >
                    <div className="tablepage-subheader">
                      <Button
                        classes={{
                          root: Boolean(multiActionEl)
                            ? "multiaction-menu-btn--open"
                            : "multiaction-menu-btn",
                        }}
                        onClick={onShowMultiActionMenu}
                        disabled={loading}
                      >
                        <LabelOutlinedIcon />
                        {t("common.table.headerBtn.multiAction")}
                        <KeyboardArrowDownOutlinedIcon />
                      </Button>
                      <Menu
                        keepMounted
                        anchorEl={multiActionEl}
                        open={Boolean(multiActionEl)}
                        onClose={handleMultiActionMenu}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        classes={{ paper: "export-menu-container" }}
                      >
                        <MenuItem
                          classes={{ root: "export-menu-item" }}
                          onClick={(event) => onDeleteItem(event)}
                        >
                          {t("common.delete")}
                        </MenuItem>
                      </Menu>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails
                    classes={{
                      root: "accordion__details-table",
                    }}
                  >
                    <div className="container-search-column">
                      {tableColumns.map((column, index) => (
                        <div className="search-input-column" key={index}>
                          <SearchIcon
                            classes={{ root: "icon__search-input" }}
                          />
                          <input
                            className="search-input"
                            id={index}
                            value={inputText[index]}
                            placeholder={t(
                              `importDocuments.searchPlaceholder.${column.id}`
                            )}
                            onChange={(e) =>
                              onFilter(e.target.value, column.id, index)
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>
              }
            />
          </div>
        ) : (
          <div className="dropzone-container">
            <div {...getRootProps({ className: "file-dropzone" })}>
              {inValidation ? (
                <>
                  <div className="loading-percentage">
                    <CircularProgress
                      classes={{ root: "progress-loading" }}
                      style={{ width: "7rem", height: "7rem" }}
                    />
                    <div className="loading-percentage-container">
                      <p className="loading-percentage-number">{`${Math.floor(
                        loadingProgress
                      )}%`}</p>
                    </div>
                  </div>
                  <p className="loading-percentage-text">
                    {t("importDocuments.filesValidation.number", {
                      count: numberOfFiles,
                    })}
                  </p>
                </>
              ) : (
                <>
                  <input {...getInputProps()} />
                  <Avatar classes={{ root: "container-import-icon" }}>
                    <CloudUploadOutlinedIcon
                      classes={{ root: "import-icon" }}
                    />
                  </Avatar>
                  <div className="text-container">
                    <p className="dropzone-text">
                      {t("importDocuments.dropzoneInfoPrimary")}
                    </p>
                  </div>
                  <Button classes={{ root: "export-menu-btn" }}>
                    <SearchIcon classes={{ root: "search-icon" }} />
                    <p>{t("importDocuments.dropzoneBtn")}</p>
                  </Button>
                </>
              )}
            </div>
          </div>
        )}
        {!filesSent && showSendList && (
          <div className="import-btn-container">
            <Button
              className="btn-color-filled import-btn"
              onClick={onImportDocuments}
              disabled={files.length === 0}
            >
              {t("importDocuments.importBtn")}
            </Button>
            <label className="btn-color-inverted import-btn margin-left">
              <input
                accept=".pdf, .xml"
                type="file"
                className="visually-hidden"
                multiple
                onChange={(event) => importMoreDocs(event)}
              />
              {t("importDocuments.addMoreBtn")}
            </label>
          </div>
        )}
      </div>
      <Dialog
        open={openModal}
        onClose={handleCloseAlert}
        keepMounted
        classes={{
          root: "modal__backdrop",
          paper: "finish-modal-container",
        }}
      >
        <Tooltip
          title={t("common.tooltip.close")}
          classes={{
            tooltip: "tooltip",
          }}
          placement="bottom-start"
        >
          <button className="btn__close" onClick={handleCloseAlert}>
            <CloseIcon classes={{ root: "btn__modal-icon--close" }} />
          </button>
        </Tooltip>
        <div className="modal-content--align-left">
          <p className="title-modal">{t("importDocuments.modal.title")}</p>
          <p className="text-modal">{t("importDocuments.modal.text")}</p>
          <div className="button-container">
            <Button
              type="button"
              className="btn-confirm"
              onClick={handleBacktoStart}
            >
              {t("importDocuments.modal.btnConfirm")}
            </Button>
            <Button
              type="button"
              className="btn-cancel"
              onClick={handleCloseAlert}
            >
              {t("importDocuments.modal.btnCancel")}
            </Button>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={openResultModal}
        onClose={handleResultModal}
        keepMounted
        classes={{
          root: "modal__backdrop",
          paper: "finish-modal-container",
        }}
      >
        <Tooltip
          title={t("common.tooltip.close")}
          classes={{
            tooltip: "tooltip",
          }}
          placement="bottom-start"
        >
          <button className="btn__close" onClick={handleResultModal}>
            <CloseIcon classes={{ root: "btn__modal-icon--close" }} />
          </button>
        </Tooltip>
        <div className="modal-content--align-left">
          <div
            className={
              importStatus !== null
                ? importStatus === "success"
                  ? "icon-wrapper"
                  : importStatus === "failed" || importStatus === "allInvalid"
                  ? "icon-wrapper--error"
                  : "icon-wrapper--warning"
                : "icon-wrapper--error"
            }
          >
            <IconSelector
              name={
                importStatus !== null
                  ? importStatus === "success"
                    ? "CheckCircle"
                    : "AlertTriangle"
                  : "AlertTriangle"
              }
              type="feather-icon"
            />
          </div>
          <div className="modal-text-container">
            <p className="modal-title--align-left">
              {importStatus !== null
                ? t(`importDocuments.result.${importStatus}.title`)
                : t("importDocuments.error.title")}
            </p>
            <p>
              {importStatus !== null
                ? t(`importDocuments.result.${importStatus}.msg`)
                : t("importDocuments.error.msg")}
            </p>
          </div>
          <button
            className="modal-btn"
            type="button"
            onClick={handleResultModal}
          >
            {t("login.okBtn")}
          </button>
        </div>
      </Dialog>
    </div>
  );
}

export default ImportDocuments;
