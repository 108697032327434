function ProvidersText() {
	return (
		<div>
			<p className='instructions-title'>Instruções para Importação de Fornecedores</p>
			<div>
				<p className='instructions-title'>Campo no sistema: NOME_RAZAO (OBRIGATÓRIO).</p>
				<p className='instructions-text'>Caso seja pessoa jurídica, é a razão social do fornecedor.</p>
				<p className='instructions-text'>Caso seja pessoa física, é o nome do fornecedor.</p> 
				<p className='instructions-text'>Exemplo: Alfa Empresa de Sistemas. ACME ltda, Pedro da Silva, Maria de Carvalho, etc.</p> 
				<p className='instructions-text'>Pode ter no máximo 100 caracteres.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: CNPJ_CPF (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o CNPJ ou o CPF do fornecedor.</p>
				<p className='instructions-text'>Caso seja Pessoa Jurídica, o CNPJ tem que ser informado no formato 99.999.999/9999-99</p>
				<p className='instructions-text'>Caso seja Pessoa Física, o CPF tem que ser informado no formato 999.999.999-99.</p>
				<p className='instructions-text'>Também será verificada sua validade.</p>
				<p className='instructions-text'>Exemplo: 99.999.999/0001-99 (CNPJ), 999.999.999-99 (CPF), etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: F_J (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o identificador se o fornecedor é Pessoa Física (F) ou Pessoa Jurídica (J).</p>
				<p className='instructions-text'>Caso seja Pessoa Física, deve-se utilizar a letra F.</p>
				<p className='instructions-text'>Caso seja Pessoa Jurídica, deve-se utilizar a letra J.</p>
				<p className='instructions-text'>Exemplo: F, J</p>
				<p className='instructions-text'>Pode ter no máximo 1 caractere.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: ENDERECO (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o endereço (logradouro) do fornecedor.</p>
				<p className='instructions-text'>Exemplo: Avenida Brasil, Rua das Flores, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: END_NUMERO (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o número do endereço do fornecedor.</p>
				<p className='instructions-text'>Exemplo: 10, 5, 3530, SN, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: END_COMPLEMENTO (NÃO OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o complemento do endereço do fornecedor.</p>
				<p className='instructions-text'>Exemplo: Sala 301, Apto 104, Anexo 2, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: END_CEP (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o CEP do endereço do fornecedor e tem que ser informado no formato 99999-999.</p>
				<p className='instructions-text'>Exemplo: 65075-180, 66017-971, 20940-200, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: END_BAIRRO (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o bairro do endereço do fornecedor.</p>
				<p className='instructions-text'>Exemplo: Copacabana, Centro, Nazaré, Morumbi, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: END_CIDADE (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o município/cidade onde está localizado o endereço do fornecedor.</p>
				<p className='instructions-text'>Exemplo: Belém, São Paulo, Rio de Janeiro, São José dos Campos, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: END_UF (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É a unidade federativa (estado ou distrito federal) onde está localizado o endereço do fornecedor.</p>
				<p className='instructions-text'>Exemplo: PA, RJ, SP, DF, etc.</p>
				<p className='instructions-text'>Tem 2 caracteres.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: END_REFERENCIA (NÃO OBRIGATÓRIO)</p>
				<p className='instructions-text'>É a descrição de um ponto de referência que auxilia na localização de um determinado endereço.</p>
				<p className='instructions-text'>Exemplo: Próximo ao supermercado, Em frente à Igreja, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: TEL1 (NÃO OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o telefone principal para contato com o fornecedor, com DDD.</p>
				<p className='instructions-text'>Exemplo: 91998765432, 1155556666, 21944445555, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: TEL1_RAMAL (NÃO OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o ramal do telefone principal para contato com o fornecedor.</p>
				<p className='instructions-text'>Exemplo: 94, 3007, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: TEL2 (NÃO OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o telefone secundário para contato com o fornecedor, com DDD.</p>
				<p className='instructions-text'>Exemplo: 91998765432, 1155556666, 21944445555, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: TEL2_RAMAL (NÃO OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o ramal do telefone secundário para contato com o fornecedor.</p>
				<p className='instructions-text'>Exemplo: 94, 3007, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: WHATSAPP (NÃO OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o número do Whatsapp para contato com o fornecedor, com DDD.</p>
				<p className='instructions-text'>Exemplo: 91998765432, 1199665544, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: EMAIL (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o endereço eletrônico (e-mail) utilizado para contato com o fornecedor.</p>
				<p className='instructions-text'>Exemplo: contato@empresa.com.br, fernando.pessoa@gmail.com, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: PESSOA_CONTATO (NÃO OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o código fiscal da despesa.</p>
				<p className='instructions-text'>Exemplo: 561, 1708, 5952, etc.</p>
			</div>
		</div>
	)
}

export default ProvidersText