import { Cookies } from "react-cookie";

const getAuthHeaders = (token, contentType) => {
  const authHeaders = new Headers();

  if (token) {
    authHeaders.set("Authorization", `Bearer ${token}`);
  }

  if (contentType === "json") {
    authHeaders.set("Content-Type", "application/json");
  }

  authHeaders.set("Access-Control-Allow-Origin", "*");

  return authHeaders;
};

let currentToken = null;

export const setLocalToken = (token) => {
  currentToken = token;
};

const refreshToken = () => {
  return fetch(`${process.env.REACT_APP_SERVICE_URL}/api/credential/refresh`, {
    method: "POST",
    headers: getAuthHeaders(currentToken.refresh_token, "json"),
  }).then((response) => response.json());
};

const requestData = (params) => {
  const cookies = new Cookies();
  return fetch(params.url, {
    method: params.method.toUpperCase(),
    headers: getAuthHeaders(currentToken.access_token, params.contentType),
    ...params.options,
  })
    .then(async (response) => {
      if (response.status === 401 && params.retries > 0) {
        params.retries--;
        currentToken = await refreshToken();
        cookies.set("user", currentToken, {
          path: "/",
          domain: window.location.hostname,
          maxAge: 604800,
        });
        return requestData(params);
      } else if (response.status === 200) {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json();
        } else if (contentType && contentType.indexOf("text/csv") !== -1) {
          return response.text();
        }
      } else if (response.status === 504) {
        if (!params.disable504Retry && params.retries > 0) {
          params.retries--;
          return requestData(params);
        } else {
          return response;
        }
      } else if (response.status === 302) {
        return response.json();
      } else {
        return Promise.reject(response.json());
      }
    })
    .catch((error) => {
      console.log(
        "There has been a problem with your fetch operation: " + error
      );
    });
};

//Password change and user activation functions

export const requestNewPassword = (email) => {
  const options = {
    body: JSON.stringify({
      email: email,
    }),
  };
  return fetch(`${process.env.REACT_APP_SERVICE_URL}/api/credential/forget`, {
    method: "POST",
    headers: getAuthHeaders(false, "json"),
    ...options,
  }).then((response) => response.json());
};

export const sendNewPassword = (password, token) => {
  const options = {
    body: JSON.stringify({
      reset_token: token,
      password: password,
    }),
  };

  return fetch(`${process.env.REACT_APP_SERVICE_URL}/api/credential/reset`, {
    method: "POST",
    headers: getAuthHeaders(false, "json"),
    ...options,
  }).then((response) => response.json());
};

export const activateUser = (password, token) => {
  const options = {
    body: JSON.stringify({
      user_token: token,
      password: password,
    }),
  };

  return fetch(
    `${process.env.REACT_APP_SERVICE_URL}/api/credential/activate_user`,
    {
      method: "POST",
      headers: getAuthHeaders(false, "json"),
      ...options,
    }
  ).then((response) => response.json());
};

//User Profile management functions

export const getCurrentUser = () => {
  const requestParams = {
    method: "post",
    url: `${process.env.REACT_APP_SERVICE_URL}/api/users/get/`,
    options: {},
    contentType: "json",
    retries: 3,
  };

  return requestData(requestParams).then((response) => response);
};

export const editCurrentUser = (user) => {
  const options = {
    body: JSON.stringify(user),
  };
  const requestParams = {
    method: "post",
    url: `${process.env.REACT_APP_SERVICE_URL}/api/users/update`,
    options: options,
    contentType: "json",
    retries: 3,
  };
  return requestData(requestParams).then((response) => response);
};

export const getOSS = () => {
  const requestParams = {
    method: "post",
    url: `${process.env.REACT_APP_SERVICE_URL}/api/contracts/get_oss`,
    options: {},
    contentType: "json",
    retries: 3,
  };
  return requestData(requestParams).then((response) => response);
};

export const getProfiles = () => {
  const options = {
    body: JSON.stringify({
      page: 1,
      perPage: null,
      sortBy: "name",
      order: "asc",
      search: [],
    }),
  };
  const requestParams = {
    method: "post",
    url: `${process.env.REACT_APP_SERVICE_URL}/api/profiles/get`,
    options: options,
    contentType: "json",
    retries: 3,
  };

  return requestData(requestParams).then((response) => response);
};

export const getGender = () => {
  const requestParams = {
    method: "post",
    url: `${process.env.REACT_APP_SERVICE_URL}/api/users/gender`,
    options: {},
    contentType: "json",
    retries: 3,
  };
  return requestData(requestParams).then((response) => response);
};

//Table Page content management functions

export const getTableItems = (params) => {
  let options = {
    body: JSON.stringify({
      page: params.page,
      perPage: params.perPage,
      sortBy: params.sortBy,
      order: params.order,
      search: params.search,
    }),
  };

  if (params.month_ref && params.year_ref) {
    options = {
      body: JSON.stringify({
        page: params.page,
        perPage: params.perPage,
        sortBy: params.sortBy,
        order: params.order,
        search: params.search,
        month_ref: params.month_ref,
        year_ref: params.year_ref,
      }),
    };
  }

  if (params.signal) {
    if (params.month_ref && params.year_ref) {
      options = {
        body: JSON.stringify({
          page: params.page,
          perPage: params.perPage,
          sortBy: params.sortBy,
          order: params.order,
          search: params.search,
          month_ref: params.month_ref,
          year_ref: params.year_ref,
        }),
        signal: params.signal,
      };
    } else {
      options = {
        body: JSON.stringify({
          page: params.page,
          perPage: params.perPage,
          sortBy: params.sortBy,
          order: params.order,
          search: params.search,
        }),
        signal: params.signal,
      };
    }
  }

  let url = "";

  if (params.menuId) {
    url = `${process.env.REACT_APP_SERVICE_URL}/api/contracts/${params.pageType}/get/${params.menuId}`;
  } else if (params.contractId) {
    url = `${process.env.REACT_APP_SERVICE_URL}/api/contracts/get/${params.contractId}`;
  } else if (params.addendumId) {
    url = `${process.env.REACT_APP_SERVICE_URL}/api/contracts/get/${params.addendumId}`;
  } else if (params.id) {
    url = `${process.env.REACT_APP_SERVICE_URL}/api/${params.pageType}/get/${params.id}`;
  } else {
    url = `${process.env.REACT_APP_SERVICE_URL}/api/${params.pageType}/get`;
  }

  const requestParams = {
    method: "post",
    url: url,
    options: options,
    contentType: "json",
    retries: 3,
  };

  return requestData(requestParams).then((response) => response);
};

export const addTableItem = (pageType, itemData, itemId) => {
  const options = {
    body: JSON.stringify(itemData),
  };

  let url = "";

  if (itemId) {
    url = `${process.env.REACT_APP_SERVICE_URL}/api/contracts/${pageType}/create/${itemId}`;
  } else if (pageType === "addendum" || pageType === "save_as") {
    url = `${process.env.REACT_APP_SERVICE_URL}/api/contracts/${pageType}/create`;
  } else {
    url = `${process.env.REACT_APP_SERVICE_URL}/api/${pageType}/create`;
  }

  const requestParams = {
    method: "post",
    url: url,
    options: options,
    contentType: "json",
    retries: 3,
  };

  return requestData(requestParams).then((response) => response);
};

export const deleteTableItems = (pageType, items) => {
  const options = {
    body: JSON.stringify({
      id: items,
    }),
  };

  let url = "";

  if (pageType === "units") {
    url = `${process.env.REACT_APP_SERVICE_URL}/api/contracts/${pageType}/delete`;
  } else {
    url = `${process.env.REACT_APP_SERVICE_URL}/api/${pageType}/delete`;
  }

  const requestParams = {
    method: "post",
    url: url,
    options: options,
    contentType: "json",
    retries: 3,
  };

  return requestData(requestParams);
};

export const editTableItem = (pageType, item) => {
  const options = {
    body: JSON.stringify(item),
  };

  const requestParams = {
    method: "post",
    url:
      pageType === "addendum"
        ? `${process.env.REACT_APP_SERVICE_URL}/api/contracts/${pageType}/update`
        : `${process.env.REACT_APP_SERVICE_URL}/api/${pageType}/update`,
    options: options,
    contentType: "json",
    retries: 3,
  };

  return requestData(requestParams);
};

export const updateStatusTableItem = (pageType, item) => {
  const options = {
    body: JSON.stringify(item),
  };

  let url = "";

  if (pageType === "set_headings") {
    url = `${process.env.REACT_APP_SERVICE_URL}/api/contracts/${pageType}/update_status`;
  } else {
    url = `${process.env.REACT_APP_SERVICE_URL}/api/${pageType}/update_status`;
  }

  const requestParams = {
    method: "post",
    url: url,
    options: options,
    contentType: "json",
    retries: 3,
  };

  return requestData(requestParams);
};

export const updateDeadline = (pageType, items) => {
  const options = {
    body: JSON.stringify(items),
  };

  const requestParams = {
    method: "post",
    url: `${process.env.REACT_APP_SERVICE_URL}/api/${pageType}/deadline/update`,
    options: options,
    contentType: "json",
    retries: 3,
  };

  return requestData(requestParams);
};

//PDF management functions

export const uploadFile = (fileInfo) => {
  const options = {};
  if (process.env.REACT_APP_AWS_FILE_UPLOAD === "true") {
    let url = "";
    if (fileInfo.hasOwnProperty("id_issued_by")) {
      url = `api/fiscal_document/get_upload_url/${fileInfo.id_org}/${fileInfo.id_issued_by}/${fileInfo.filename}`;
    } else {
      url = `api/contracts/get_upload_url/${fileInfo.org_id}/${fileInfo.contract_number}/${fileInfo.filename}`;
    }
    const requestParams = {
      method: "post",
      url: `${process.env.REACT_APP_SERVICE_URL}/${url}`,
      options: options,
      contentType: "json",
      retries: 3,
    };
    return requestData(requestParams).then((urlData) => {
      const data = new FormData();
      for (const property in urlData.data.fields) {
        data.append(property, urlData.data.fields[property]);
      }
      data.append("file", fileInfo.file);

      return fetch(urlData.data.url, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        body: data,
      }).then((response) => {
        return {
          response: response,
          filename: urlData.data.filename,
        };
      });
    });
  } else {
    const data = new FormData();
    data.append("file", fileInfo.file);
    data.append("filename", fileInfo.filename);
    data.append("org_id", fileInfo.org_id);
    data.append("contract_number", fileInfo.contract_number);

    const options = {
      body: data,
    };

    const requestParams = {
      method: "post",
      url: `${process.env.REACT_APP_SERVICE_URL}/api/contracts/save_file`,
      options: options,
      contentType: "formData",
      retries: 3,
    };

    return requestData(requestParams).then((response) => response);
  }
};

export const downloadFile = (filename, pageType) => {
  if (process.env.REACT_APP_AWS_FILE_UPLOAD === "true") {
    let url = "";
    if (pageType === "contracts" || pageType === "fiscal_document") {
      url = `${process.env.REACT_APP_SERVICE_URL}/api/${pageType}/get_download_url/${filename}`;
    } else if (pageType === "certificates") {
      url = `${process.env.REACT_APP_SERVICE_URL}/api/${pageType}/get_cert/${filename}`;
    } else {
      url = `${process.env.REACT_APP_SERVICE_URL}/api/import/documents/download/${filename}`;
    }

    const requestParams = {
      method: "post",
      url: url,
      options: {},
      contentType: "json",
      retries: 3,
    };

    return requestData(requestParams).then((urlData) => {
      if (urlData.success) {
        return fetch(urlData.url, {
          method: "GET",
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }).then((response) => response);
      } else {
        return urlData;
      }
    });
  } else {
    const requestParams = {
      method: "post",
      url: `${process.env.REACT_APP_SERVICE_URL}/api/contracts/download_file/${filename}`,
      options: {},
      contentType: "formData",
      retries: 3,
    };

    return requestData(requestParams).then((response) => response);
  }
};

export const importDocument = (file) => {
  if (process.env.REACT_APP_AWS_FILE_UPLOAD === "true") {
    const requestParams = {
      method: "post",
      url: `${process.env.REACT_APP_SERVICE_URL}/api/import/documents/get_upload_url/${file.path}`,
      options: {},
      contentType: "json",
      retries: 3,
    };
    return requestData(requestParams).then((urlData) => {
      const data = new FormData();

      for (const property in urlData.data.fields) {
        data.append(property, urlData.data.fields[property]);
      }
      data.append("file", file);

      return fetch(urlData.data.url, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        body: data,
      }).then((response) => response);
    });
  } else {
    const data = new FormData();
    data.append("file", file);

    const options = {
      body: data,
    };

    const requestParams = {
      method: "post",
      url: `${process.env.REACT_APP_SERVICE_URL}/api/import/documents`,
      options: options,
      contentType: "formData",
      retries: 3,
    };

    return requestData(requestParams).then((response) => response);
  }
};

export const xmlValidation = (files) => {
  const data = new FormData();

  files.forEach((file) => {
    data.append("folder", file);
  });

  const options = {
    body: data,
  };

  const requestParams = {
    method: "post",
    url: `${process.env.REACT_APP_SERVICE_URL}/api/invoices/validate_xml`,
    options: options,
    contentType: "formData",
    retries: 3,
  };

  return requestData(requestParams).then((response) => response);
};

export const importXML = (files) => {
  const data = new FormData();

  files.forEach((file) => {
    data.append("folder", file.file);
  });

  const options = {
    body: data,
  };

  const requestParams = {
    method: "post",
    url: `${process.env.REACT_APP_SERVICE_URL}/api/invoices/import_xml`,
    options: options,
    contentType: "formData",
    retries: 3,
  };

  return requestData(requestParams).then((response) => response);
};

//Chart Of Accounts Code Validation

export const checkCode = (code) => {
  const options = {
    body: JSON.stringify({
      code: code,
    }),
  };

  const requestParams = {
    method: "post",
    url: `${process.env.REACT_APP_SERVICE_URL}/api/chartOfAccounts/check_code`,
    options: options,
    contentType: "json",
    retries: 3,
  };

  return requestData(requestParams).then((response) => response);
};

//Form Select Component options management functions

export const getCategoryOptions = () => {
  const requestParams = {
    method: "post",
    url: `${process.env.REACT_APP_SERVICE_URL}/api/profiles/categories`,
    options: {},
    contentType: "json",
    retries: 3,
  };
  return requestData(requestParams);
};

export const getProfileOptions = (id) => {
  const requestParams = {
    method: "post",
    url: `${process.env.REACT_APP_SERVICE_URL}/api/profiles/get_by_category/${id}`,
    options: {},
    contentType: "json",
    retries: 3,
  };
  return requestData(requestParams);
};

export const getOSSOptions = (id, pageType) => {
  let url = "";
  if (id !== null) {
    if (pageType === "users") {
      url = `${process.env.REACT_APP_SERVICE_URL}/api/users/get_oss/${id}`;
    } else {
      url = `${process.env.REACT_APP_SERVICE_URL}/api/contracts/get_oss/${id}`;
    }
  } else {
    if (pageType === "users") {
      url = `${process.env.REACT_APP_SERVICE_URL}/api/users/get_oss`;
    } else {
      url = `${process.env.REACT_APP_SERVICE_URL}/api/contracts/get_oss`;
    }
  }

  const requestParams = {
    method: "post",
    url: url,
    options: {},
    contentType: "json",
    retries: 3,
  };

  return requestData(requestParams);
};

export const getUnitTypes = () => {
  const requestParams = {
    method: "post",
    url: `${process.env.REACT_APP_SERVICE_URL}/api/contracts/get_unit_types`,
    options: {},
    contentType: "json",
    retries: 3,
  };

  return requestData(requestParams);
};

export const getChartOfAccountsGroups = () => {
  const requestParams = {
    method: "post",
    url: `${process.env.REACT_APP_SERVICE_URL}/api/chartOfAccounts/get_chart_of_accounts_items`,
    options: {},
    contentType: "json",
    retries: 3,
  };
  return requestData(requestParams);
};

export const getGroupOptions = (id) => {
  const requestParams = {
    method: "post",
    url: `${process.env.REACT_APP_SERVICE_URL}/api/headings/get_group/${id}`,
    options: {},
    contentType: "json",
    retries: 3,
  };
  return requestData(requestParams);
};

export const getBankAccountTypesOptions = () => {
  const requestParams = {
    method: "post",
    url: `${process.env.REACT_APP_SERVICE_URL}/api/bankAccounts/get_bank_account_types`,
    options: {},
    contentType: "json",
    retries: 3,
  };
  return requestData(requestParams);
};

export const getAgenciesOptions = (id) => {
  const requestParams = {
    method: "post",
    url: `${process.env.REACT_APP_SERVICE_URL}/api/bankAccounts/get_agencies/${id}`,
    options: {},
    contentType: "json",
    retries: 3,
  };
  return requestData(requestParams);
};

export const getBankOptions = () => {
  const requestParams = {
    method: "post",
    url: `${process.env.REACT_APP_SERVICE_URL}/api/bankAccounts/get_banks`,
    options: {},
    contentType: "json",
    retries: 3,
  };
  return requestData(requestParams);
};

export const getContractOptions = (id) => {
  let url = "";
  if (id !== null) {
    url = `${process.env.REACT_APP_SERVICE_URL}/api/contracts/get_by_org/${id}`;
  } else {
    url = `${process.env.REACT_APP_SERVICE_URL}/api/contracts/get_by_org`;
  }

  const requestParams = {
    method: "post",
    url: url,
    options: {},
    contentType: "json",
    retries: 3,
  };

  return requestData(requestParams);
};

export const getUnits = (ids) => {
  const options = {
    body: JSON.stringify({
      unit_type_id: [ids],
    }),
  };
  const requestParams = {
    method: "post",
    url: `${process.env.REACT_APP_SERVICE_URL}/api/contracts/get_units`,
    options: options,
    contentType: "json",
    retries: 3,
  };
  return requestData(requestParams).then((response) => response);
};

export const getUnitsByContract = (id) => {
  const requestParams = {
    method: "post",
    url: `${process.env.REACT_APP_SERVICE_URL}/api/contracts/units/get_list/${id}`,
    options: {},
    contentType: "json",
    retries: 3,
  };
  return requestData(requestParams).then((response) => response);
};

export const getFileTypes = () => {
  const requestParams = {
    method: "post",
    url: `${process.env.REACT_APP_SERVICE_URL}/api/import/file_type/get`,
    options: {},
    contentType: "json",
    retries: 3,
  };
  return requestData(requestParams).then((response) => response);
};

export const getHeadingsByContract = (id) => {
  const requestParams = {
    method: "post",
    url: `${process.env.REACT_APP_SERVICE_URL}/api/headings/get_by_contract/${id}`,
    options: {},
    contentType: "json",
    retries: 3,
  };
  return requestData(requestParams).then((response) => response);
};

export const getDocTypes = () => {
  const requestParams = {
    method: "post",
    url: `${process.env.REACT_APP_SERVICE_URL}/api/expenses/get_doc_types`,
    options: {},
    contentType: "json",
    retries: 3,
  };
  return requestData(requestParams).then((response) => response);
};

export const getBankAccountsByContract = (id) => {
  const requestParams = {
    method: "post",
    url: `${process.env.REACT_APP_SERVICE_URL}/api/bankAccounts/get_by_contract/${id}`,
    options: {},
    contentType: "json",
    retries: 3,
  };
  return requestData(requestParams).then((response) => response);
};

export const getModels = (id) => {
  const requestParams = {
    method: "post",
    url: `${process.env.REACT_APP_SERVICE_URL}/api/import/model/get/${id}`,
    options: {},
    contentType: "json",
    retries: 3,
  };
  return requestData(requestParams).then((response) => response);
};

export const getFieldsByFileType = (id) => {
  const requestParams = {
    method: "post",
    url: `${process.env.REACT_APP_SERVICE_URL}/api/import/model/get_fields_by_file_type/${id}`,
    options: {},
    contentType: "json",
    retries: 3,
  };
  return requestData(requestParams).then((response) => response);
};

export const getFieldsByModel = (id) => {
  const requestParams = {
    method: "post",
    url: `${process.env.REACT_APP_SERVICE_URL}/api/import/model/get_fields_by_model/${id}`,
    options: {},
    contentType: "json",
    retries: 3,
  };
  return requestData(requestParams).then((response) => response);
};

export const getStates = () => {
  const requestParams = {
    method: "post",
    url: `${process.env.REACT_APP_SERVICE_URL}/api/providers/get_states`,
    options: {},
    contentType: "json",
    retries: 3,
  };
  return requestData(requestParams).then((response) => response);
};

export const getModes = () => {
  const requestParams = {
    method: "post",
    url: `${process.env.REACT_APP_SERVICE_URL}/api/purchase_process/get_purchase_modes`,
    options: {},
    contentType: "json",
    retries: 3,
  };
  return requestData(requestParams).then((response) => response);
};

export const getProviders = () => {
  const requestParams = {
    method: "post",
    url: `${process.env.REACT_APP_SERVICE_URL}/api/purchase_process/get_providers`,
    options: {},
    contentType: "json",
    retries: 3,
  };
  return requestData(requestParams).then((response) => response);
};

export const getPurchaseProcessList = () => {
  const requestParams = {
    method: "post",
    url: `${process.env.REACT_APP_SERVICE_URL}/api/purchase_process/get_list`,
    options: {},
    contentType: "json",
    retries: 3,
  };
  return requestData(requestParams).then((response) => response);
};

export const addModel = (modelData) => {
  const options = {
    body: JSON.stringify(modelData),
  };
  const requestParams = {
    method: "post",
    url: `${process.env.REACT_APP_SERVICE_URL}/api/import/model/create`,
    options: options,
    contentType: "json",
    retries: 3,
  };
  return requestData(requestParams).then((response) => response);
};

export const editModel = (modelData) => {
  const options = {
    body: JSON.stringify(modelData),
  };
  const requestParams = {
    method: "post",
    url: `${process.env.REACT_APP_SERVICE_URL}/api/import/model/update`,
    options: options,
    contentType: "json",
    retries: 3,
  };
  return requestData(requestParams).then((response) => response);
};

export const deleteModel = (items) => {
  const options = {
    body: JSON.stringify({
      id: items,
    }),
  };
  const requestParams = {
    method: "post",
    url: `${process.env.REACT_APP_SERVICE_URL}/api/import/model/delete`,
    options: options,
    contentType: "json",
    retries: 3,
  };

  return requestData(requestParams).then((response) => response);
};

export const checkData = (modelData) => {
  const data = new FormData();
  data.append("file", modelData.file);
  data.append("model_id", modelData.model_id);

  const options = {
    body: data,
  };

  const requestParams = {
    method: "post",
    url: `${process.env.REACT_APP_SERVICE_URL}/api/import/data/check`,
    options: options,
    contentType: "formData",
    retries: 3,
  };

  return requestData(requestParams);
};

export const importData = (modelData) => {
  const data = new FormData();
  data.append("file", modelData.file);
  data.append("model_id", modelData.model_id);

  const options = {
    body: data,
  };

  const requestParams = {
    method: "post",
    url: `${process.env.REACT_APP_SERVICE_URL}/api/import/data/upload`,
    options: options,
    contentType: "formData",
    retries: 3,
    disable504Retry: true,
  };

  return requestData(requestParams);
};

export const downloadCSV = (filename) => {
  const requestParams = {
    method: "post",
    url: `${process.env.REACT_APP_SERVICE_URL}/api/importReport/download_file/${filename}`,
    options: {},
    contentType: "formData",
    retries: 3,
  };

  return requestData(requestParams).then((response) => response);
};

export const getImportReportDetails = (id) => {
  const requestParams = {
    method: "post",
    url: `${process.env.REACT_APP_SERVICE_URL}/api/importReport/get_details/${id}`,
    options: {},
    contentType: "json",
    retries: 3,
  };

  return requestData(requestParams).then((response) => response);
};

export const getOrgsById = (id) => {
  const requestParams = {
    method: "post",
    url: `${process.env.REACT_APP_SERVICE_URL}/api/organizations/get/${id}`,
    options: {},
    contentType: "json",
    retries: 3,
  };

  return requestData(requestParams).then((response) => response);
};


export const updateFidoValidation = (org_id, fido_id, status) => {
  const data = { status: status };

  const options = {
    body: JSON.stringify(data)
  };

  const requestParams = {
    method: "post",
    url: `${process.env.REACT_APP_SERVICE_URL}/api/fiscal_document/get/${org_id}/${fido_id}`,
    options: options,
    contentType: "json",
    retries: 3,
  };

  return requestData(requestParams).then((response) => response);
};
