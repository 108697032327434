import React from 'react';
import { useTranslation } from 'react-i18next';
import IconSelector from '../IconSelector';
import { Link } from 'lucide-react';

function MultiTextInput({props}) {
	const { t } = useTranslation();
	const { field, values, handleChange, handleBlur, pageType, disabled} = props;

	return (
		<>
			<p className='group-input-title'>{t(`${pageType}.fields.${field.id}.title`)}</p>
			{
				field.inputs.map((item,index) => (
					<div key={index} className='social-media-input-container'>
						<div className='social-media-icon-wrapper'>
							<IconSelector name={item.name} type={item.type} size={20}/>
						</div>
							<input 
								className='wrapped-input' 
								id={item.id} 
								placeholder={t(`${pageType}.placeholder.${item.id}`)} 
								value={values[item.id]} 
								onChange={handleChange}
								onBlur={handleBlur}
								disabled={disabled}
							>
							</input>
							<div className='group-input-icon'><Link size={20} /></div>
				
					</div>
				))
			}
		</>
	);
}

export default MultiTextInput