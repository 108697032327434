import React from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';


function CheckboxList({props}) {
	const { t } = useTranslation();
	const { field, values, pageType, handleChange, disabled } = props;

	return (
		<div className='container__list'>
			{
				field.checkboxOptions.map(item => (
					<div key={item.id} className='container__list-section'>
						<p className='list-section__title'>{t(`${pageType}.checkboxOptions.${item.id}.title`)}</p>
						{
							item.fields.map(option => (
								<div key={option} className={values[field.category][item.id][option] ? 'container__list-item--selected' : 'container__list-item'}>
									<p className={values[field.category][item.id][option] ? 'list-item__text--selected' : 'list-item__text'}>{t(`${pageType}.checkboxOptions.${item.id}.${option}`)}</p><Checkbox checked={values[field.category][item.id][option]} disabled={disabled} id={`${field.category}.${item.id}.${option}`} name={`${field.category}.${item.id}.${option}`} onChange= {handleChange} classes={{root: 'checkbox--right'}}/>
								</div>
							))
						}
					</div>
				))
			}
		</div>
	)
}

export default CheckboxList