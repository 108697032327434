import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';

import { useTranslation } from 'react-i18next';

function TextInput({props}) {
	const { t } = useTranslation();
	const [prefix, setPrefix] = useState('');
	const { field, values, handleChange, handleBlur, pageType, errors, touched, disabled, setFieldValue, options, isEditing } = props;
	const [visibleValue, setVisibleValue] = useState('');
	

	const handlePrefixChange = (event) => {
		const { value } = event.target;
		

		setFieldValue(field.id, prefix + value)

		setVisibleValue(value)
		
	}
	

	const keyDown = (event) => {
		if (event.code === 'Backspace' && !values[field.id]) {
			event.preventDefault();
			if (!field.allowZero) {
				setFieldValue(field.id, '')
			}
		}

		if (field.allowZero) {
			if (event.code === 'Backspace' && values[field.id] < 1000) {
				event.preventDefault();
				setFieldValue(field.id, 0)
			}
		}
	}

	useEffect(() => {
		if (field.prefixRef) {
			if (values[field.prefixRef] !== 'none' && !isEditing && options && Object.keys(options[field.prefixState]).length !== 0 && options[field.prefixState].data.length !== 0) {
				const value = options[field.prefixState].data.filter((option) => option.id === values[field.prefixRef])


				const inputValue = value[0][field.prefixValue]

				setPrefix(inputValue+'.')

				setFieldValue(field.id, prefix + visibleValue)
			} else {
				setVisibleValue(values[field.id])
			}
		} 
	}, [options, values])

	useEffect(() => {
		if (field.conditionalVisibility && values[field.id] !== '') {
			if (!field.visibilityReq.includes(values[field.parentVisibility])) {
				setFieldValue(field.id, '')			
			}

			if (field.subVisibility && values[field.subVisibility]) {
				setFieldValue(field.id,'')
			}
		}

		if (field.id === 'monthly_value') {
			if (values[field.parentVisibility] !== 'none' && values[field.parentVisibility] !== 'null') {
				let selectedOption = options['purchaseProcessOptions'].data.filter(option => option.id === values[field.parentVisibility])
				setFieldValue(field.id, selectedOption[0].monthly_value)
			} else if (values[field.parentVisibility] === 'null' && !isEditing) {
				setFieldValue(field.id, '')
			} else if (values[field.parentVisibility] === 'null' && isEditing) {
				setFieldValue(field.id, values[field.id])
			}
		}
	}, [field.conditionalVisibility ? values[field.parentVisibility] : ''])

	return (

		<>
			{
			field.conditionalVisibility ?
			(
				((field.visibilityReq.length === 0 ? values[field.parentVisibility] !== 'none' : field.visibilityReq.includes(values[field.parentVisibility])) && (field.subVisibility ? !values[field.subVisibility]: true)) ? 
				(
					<>
				<label className={errors[field.id] && touched[field.id] ? 'form__label--error' : 'form__label'} htmlFor={field.id}>{field.conditionalLabels ? t(`${pageType}.fields.${field.conditionalLabels[field.visibilityReq.findIndex(element => element === values[field.parentVisibility])]}`): t(`${pageType}.fields.${field.id}`)}{field.validation.required ? '*' : null}</label>
				{
					field.mask ? 
					(
						<NumberFormat
							className={errors[field.id] && touched[field.id] ? 'form__input--error' : 'form__input' }
							id={field.id}
							name={field.id}
							placeholder={field.conditionalLabels ? t(`${pageType}.placeholder.${field.conditionalLabels[field.visibilityReq.findIndex(element => element === values[field.parentVisibility])]}`): t(`${pageType}.placeholder.${field.id}`)}
							value={values[field.id]}
							onValueChange={(values) => {
								const { formattedValue, value } = values
								if (field.unmaskValue) {
									setFieldValue(field.id, value)
								} else {
									setFieldValue(field.id,formattedValue)
								}
							}}
							onBlur={handleBlur}
							disabled={disabled}
							format={field.conditionalMasks ? field.conditionalMasks[field.conditionalMaskRef.findIndex(element => element === values[field.conditionalMaskParent])]: field.mask}
							mask={field.maskPlaceholder}
						/>
					) : (field.inputType && field.inputType === 'currency') ?
					(
						<NumberFormat
							className={errors[field.id] && touched[field.id] ? 'form__input--error' : 'form__input' }
							id={field.id}
							name={field.id}
							placeholder={field.conditionalLabels ? t(`${pageType}.placeholder.${field.conditionalLabels[field.visibilityReq.findIndex(element => element === values[field.parentVisibility])]}`): t(`${pageType}.placeholder.${field.id}`)}
							value={Number(values[field.id])*100}
							onValueChange={(v) => {
								const { value } = v
								if (value === '') {
									setFieldValue (field.id, '')
								} else {
									setFieldValue(field.id, parseFloat(value/100))
								}							
							}}
							onBlur={handleBlur}
							isNumericString={true}
							disabled={field.id === 'monthly_value' && values[field.parentVisibility] !== 'null'? true : disabled}
							format={(formattedValue) => {
								if(!Number(formattedValue)) {
									if (field.allowZero) {
										return 'R$ 0,00';
									} else {
										return '';
									}
								}
								return new Intl.NumberFormat('pt-BR', {style: 'currency', currency:'BRL'} ).format(formattedValue/100)
							}}
							decimalSeparator=','
							thousandSeparator='.'
							decimalScale={2}
							onKeyDown={keyDown}

						/>
					) : (field.inputType && field.inputType === 'account_number') ? 
					(
						<NumberFormat
							className={errors[field.id] && touched[field.id] ? 'form__input--error' : 'form__input' }
							id={field.id}
							name={field.id}
							placeholder={field.conditionalLabels ? t(`${pageType}.placeholder.${field.conditionalLabels[field.visibilityReq.findIndex(element => element === values[field.parentVisibility])]}`): t(`${pageType}.placeholder.${field.id}`)}
							value={values[field.id]}
							onValueChange={(v) => {
								const { formattedValue } = v
								setFieldValue(field.id, formattedValue)
							}}
							onBlur={handleBlur}
							disabled={field.id === 'monthly_value' && values[field.parentVisibility] !== 'null'? true : disabled}
							format={(formattedValue) => {
								if(formattedValue === '') return '';
								if(formattedValue.length === 1) {
									return formattedValue
								} else {
									return formattedValue.slice(0,formattedValue.length-1)+'-'+formattedValue%10
								}

							}}
							onKeyDown={keyDown}
						/>
					)	: field.prefixRef ?
					(
						<div className='wrapper-code-input'>
							<div className={!prefix ? 'prefix-input--hidden' : 'prefix-input'}>
								{prefix}
							</div>
							<input 
								className={errors[field.id] && touched[field.id] ? 'code-input--error' : 'code-input'} 
								id={field.id} 
								name={field.id}
								placeholder={field.conditionalLabels ? t(`${pageType}.placeholder.${field.conditionalLabels[field.visibilityReq.findIndex(element => element === values[field.parentVisibility])]}`): t(`${pageType}.placeholder.${field.id}`)}
								value={visibleValue} 
								onChange={handlePrefixChange}
								onBlur={handleBlur}
								disabled={disabled}
							></input>
						</div>
					) :
					(
						<input 
							className={errors[field.id] && touched[field.id] ? 'form__input--error' : 'form__input' } 
							id={field.id} 
							name={field.id}
							placeholder={field.conditionalLabels ? t(`${pageType}.placeholder.${field.conditionalLabels[field.visibilityReq.findIndex(element => element === values[field.parentVisibility])]}`): t(`${pageType}.placeholder.${field.id}`)}
							value={values[field.id]} 
							onChange={handleChange}
							onBlur={handleBlur}
							disabled={disabled}
							type={field.inputType === 'number' ? 'number' : 'text'}
						></input>
					)
				}
			</>
				) :
				null
			) :
			<>
				<label className={errors[field.id] && touched[field.id] ? 'form__label--error' : 'form__label'} htmlFor={field.id}>{t(`${pageType}.fields.${field.id}`)}{field.validation.required ? '*' : null}</label>
				{
					field.mask ? 
					(
						<NumberFormat
							className={errors[field.id] && touched[field.id] ? 'form__input--error' : 'form__input' }
							id={field.id}
							name={field.id}
							placeholder={t(`${pageType}.placeholder.${field.id}`)}
							value={values[field.id]}
							onValueChange={(values) => {
								const { formattedValue, value } = values
								if (field.unmaskValue) {
									setFieldValue(field.id, value)
								} else {
									setFieldValue(field.id,formattedValue)
								}
							}}
							onBlur={handleBlur}
							disabled={disabled}
							format={field.mask}
							mask={field.maskPlaceholder}
						/>
					) : (field.inputType && field.inputType === 'currency') ?
					(
						<NumberFormat
							className={errors[field.id] && touched[field.id] ? 'form__input--error' : 'form__input' }
							id={field.id}
							name={field.id}
							placeholder={t(`${pageType}.placeholder.${field.id}`)}
							value={Number(values[field.id])*100}
							onValueChange={(v) => {
								const { value } = v
								if (value === '') {
									setFieldValue (field.id, 0)
								} else {
									setFieldValue(field.id, parseFloat(value/100))
								}				
							}}
							onBlur={handleBlur}
							isNumericString={true}
							disabled={disabled}
							format={(formattedValue) => {
								if(!Number(formattedValue)) {
									if (field.allowZero) {
										return 'R$ 0,00';
									} else {
										return '';
									}
								}
								return new Intl.NumberFormat('pt-BR', {style: 'currency', currency:'BRL'} ).format(formattedValue/100)
							}}
							decimalSeparator=','
							thousandSeparator='.'
							decimalScale={2}
							onKeyDown={keyDown}

						/>
					) : (field.inputType && field.inputType === 'account_number') ? 
					(
						<NumberFormat
							className={errors[field.id] && touched[field.id] ? 'form__input--error' : 'form__input' }
							id={field.id}
							name={field.id}
							placeholder={t(`${pageType}.placeholder.${field.id}`)}
							value={values[field.id]}
							onValueChange={(v) => {
								const { formattedValue } = v
								setFieldValue(field.id, formattedValue)
							}}
							onBlur={handleBlur}
							disabled={disabled}
							format={(formattedValue) => {
								if(formattedValue === '') return '';
								if(formattedValue.length === 1) {
									return formattedValue
								} else {
									return formattedValue.slice(0,formattedValue.length-1)+'-'+formattedValue%10
								}

							}}
							onKeyDown={keyDown}
						/>
					)	: field.prefixRef ?
					(
						<div className='wrapper-code-input'>
							<div className={!prefix ? 'prefix-input--hidden' : 'prefix-input'}>
								{prefix}
								
							</div>
							<input 
								className={errors[field.id] && touched[field.id] ? 'code-input--error' : 'code-input'} 
								id={field.id} 
								name={field.id}
								placeholder={t(`${pageType}.placeholder.${field.id}`)}
								value={visibleValue} 
								onChange={handlePrefixChange}
								onBlur={handleBlur}
								disabled={disabled}
							></input>
						</div>
					) :
					(
						<input 
							className={errors[field.id] && touched[field.id] ? 'form__input--error' : 'form__input' } 
							id={field.id} 
							name={field.id}
							placeholder={t(`${pageType}.placeholder.${field.id}`)}
							value={values[field.id]} 
							onChange={handleChange}
							onBlur={handleBlur}
							disabled={disabled}
							type={field.inputType === 'number' ? 'number' : 'text'}
						></input>
					)
				}
			</>
			}
		</>
	)
}

export default TextInput