function BalancesText() {
	return (
		<div>
			<p className='instructions-title'>Instruções de Importação para Saldos</p>
			<div>
				<p className='instructions-title'>Campo no sistema: Organização Social (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o código da Organização Social a que se refere a despesa. Deve conter apenas números. Será verificada sua existência na tabela do sistema de ORGANIZAÇÕES, bem como se a mesma está ativa.</p>
				<p className='instructions-text'>Este código deve ser o mesmo que o do usuário que está fazendo a importação. Se não for, haverá um erro.</p>
				<p className='instructions-text'>Exemplo: 123, 999, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: Unidade (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o código da unidade a que se refere a despesa. Deve conter apenas números. Será verificada sua existência na tabela de unidades, bem como se está ativa e ligada à Organização atual.</p>
				<p className='instructions-text'>Exemplo: 123, 999, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: Contrato (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o número do contrato de gestão. Deve ter o formato NNN/AAAA, onde NNN é o número do contrato e AAAA é o ano. Será verificada sua existência na tabela de contrato e se ele está ligado à Organização atual.</p>
				<p className='instructions-text'>Exemplo: 001/2017, 100/2012, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: Ano-Mês de Referência (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É a referência do lançamento (ano/mês). Deve ter o formato AAAA-MM, onde AAAA é o ano e MM é o mês. Caso a entrega já tenha sido encerrada para esta referência, Organização e contrato, não será possível fazer a carga dos dados.</p>
				<p className='instructions-text'>Exemplo: outubro de 2016 deve ser preenchido 2016-10, janeiro de 2017 deve ser preenchido 2017-01</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: Banco (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o código do banco associado à conta corrente. Será verificada sua existência na tabela do sistema de BANCOS. Este campo deverá conter apenas números.</p>
				<p className='instructions-text'>Exemplo: 1, 237, 341, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: Agência (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o código da agência do banco associado à conta corrente. Será verificada sua existência na tabela de AGÊNCIA. Este campo deverá conter apenas números, e não poderá ter o dígito verificador.</p>
				<p className='instructions-text'>Exemplo: 8686-4: deve ser preenchido como 8686, 9685-78: deve ser preenchido como 9685, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: Conta Corrente (OBRIGATÓRIO)</p>
				<p className='instructions-text'>É o número da conta corrente da despesa. Não pode ter nenhum caractere que separe o dígito verificador do número. Será verificada sua existência na tabela do sistema de CONTAS CORRENTES (em conjunto com o código do banco e da agência), e também se está ligada à OS e ao CONTRATO.</p>
				<p className='instructions-text'>Exemplo: 3432-4: deve ser preenchido como 34324, 889-0: deve ser preenchido como 8890, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: Valor em Conta Corrente (OBRIGATÓRIO)</p>
				<p className='instructions-text'>Valor do Saldo em Conta Corrente no último dia do mês de referência.</p>
				<p className='instructions-text'>Dever ter o formato NN,NN (até duas casas decimais), onde N é um número de 0 a 9.</p>
				<p className='instructions-text'>Exemplo: 1200, 34,78, 1.200.345,45, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: Valor em Aplicação Financeira (OBRIGATÓRIO)</p>
				<p className='instructions-text'>Valor da Aplicação Financeira no último dia do mês de referência.</p>
				<p className='instructions-text'>Dever ter o formato NN,NN (até duas casas decimais), onde N é um número de 0 a 9.</p>
				<p className='instructions-text'>Exemplo: 1200, 34,78, 1.200.345,45, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: Valor em Provisões (OBRIGATÓRIO)</p>
				<p className='instructions-text'>Valor do Saldo na Conta de Provisões no último dia do mês de referência.</p>
				<p className='instructions-text'>Dever ter o formato NN,NN (até duas casas decimais), onde N é um número de 0 a 9.</p>
				<p className='instructions-text'>Exemplo: 1200, 34,78, 1.200.345,45, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: Valor em Espécie (OBRIGATÓRIO)</p>
				<p className='instructions-text'>Valor em espécie disponível no "caixinha" no último dia do mês de Referência.</p>
				<p className='instructions-text'>Dever ter o formato NN,NN (até duas casas decimais), onde N é um número de 0 a 9.</p>
				<p className='instructions-text'>Exemplo: 1200, 34,78, 1.200.345,45, etc.</p>
			</div>
			<div>
				<p className='instructions-title'>Campo no sistema: Imagem do Extrato</p>
				<p className='instructions-text'>É o nome do arquivo de imagem do extrato bancário. Será verificado se o mesmo já existe no cadastro de documentos do sistema.</p>
				<p className='instructions-text'>Atenção para que o nome do arquivo seja EXATAMENTE o mesmo do que foi importado. Verifique se não há espaços extras e não use caracteres especiais (*/&, etc.).</p>
				<p className='instructions-text'>Não esqueça de colocar a extensão.</p>
				<p className='instructions-text'>Pode ter no máximo 255 caracteres.</p>
				<p className='instructions-text'>Exemplo: Extrato_264_2017_02_BEM_2341234.pdf</p>
			</div>
		</div>
	)
}

export default BalancesText