import React from "react";
import { useHistory, useParams, Redirect } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { AlertCircle } from "lucide-react";
import { Formik } from "formik";
import { toast } from "react-toastify";
import moment from "moment";
import {
  config,
  toastConfig,
  editConfig,
  permissionsConfig,
} from "../data/tableConfig";
import IconSelector from "./IconSelector";
import NavigationPrompt from "./NavigationPrompt";
import {
  getTableItems,
  addTableItem,
  editTableItem,
  uploadFile,
} from "../utils/dataAPI";
import { cpfValidation, cnpjValidation } from "../utils/inputValidation";
import FormElement from "./FormElement";

function CreateEditPage() {
  const { t } = useTranslation();
  const { pageType, itemId, menuId, contractId, addendumId } = useParams();
  let history = useHistory();
  const editBtn = useRef(null);
  const createEditForm = useRef(null);
  const [activeStep, setActiveStep] = useState(0);
  const [finishModal, setFinishModal] = useState(false);
  const [item, setItem] = useState({});
  const [submitError, setSubmitError] = useState(false);
  const [submitWarning, setSubmitWarning] = useState(false);
  const [editSubmitting, setEditSubmitting] = useState(false);
  const user = useSelector((state) => state.user.userData);
  const selectOptions = useSelector((state) => state.selectOptions);
  const [message, setMessage] = useState(null);
  const [messageArray, setMessageArray] = useState([]);

  let formFields = {};

  if (config[pageType].createEditCategories.length > 1) {
    config[pageType].createEditCategories.forEach((category) => {
      formFields[category] = [];
    });
  } else {
    formFields["all"] = [];
  }

  config[pageType].fields.forEach((field) => {
    if (field.hasOwnProperty("formProps")) {
      let formItem = {
        id: field.id,
        ...field.formProps,
      };

      if (config[pageType].createEditCategories.length > 1) {
        formFields[field.formProps.category][field.formProps.order] = formItem;
      } else {
        formFields["all"][field.formProps.order] = formItem;
      }
    }
  });

  const getInitialValues = () => {
    let initialValues = {};
    for (const category in formFields) {
      formFields[category].forEach((field) => {
        if (field.type === "inputGroup") {
          field.inputs.forEach((input) => {
            initialValues[input.id] = item[input.id] ? item[input.id] : "";
          });
        } else if (field.type === "select") {
          initialValues[field.id] =
            item[field.id] ||
            (!item[field.id] && typeof item[field.id]) === "number"
              ? item[field.id]
              : "none";
        } else if (field.type === "checkboxOptions") {
          initialValues[field.category] = {};
          field.checkboxOptions.forEach((input) => {
            initialValues[field.category][input.id] = {};
            input.fields.forEach((option) => {
              initialValues[field.category][input.id][option] = item[
                field.category
              ]
                ? item[field.category][input.id][option]
                : false;
            });
          });
        } else if (field.type === "checkbox") {
          initialValues[field.id] = item[field.id] ? item[field.id] : false;
        } else if (field.type === "dynamicInput") {
          initialValues[field.id] = item[field.id]
            ? item[field.id]
            : [{ id: "none", value: "", winner: false }];
        } else {
          initialValues[field.id] = item[field.id]
            ? item[field.id]
            : field.inputType === "currency" && field.allowZero
            ? 0
            : "";
        }
      });
    }

    if (pageType === "contracts") {
      initialValues["filename"] = item["filename"] ? item["filename"] : "";
    }

    if (pageType === "addendum") {
      initialValues["filename"] =
        item["filename"] && addendumId ? item["filename"] : "";
    }

    return initialValues;
  };

  const fieldsValidation = (values) => {
    const errors = {};
    const doValidations = (field) => {
      if (
        field.validation.required &&
        (pageType === "contracts" ? field.id !== "file" && !itemId : true) &&
        (pageType === "addendum" ? field.id === "file" && !addendumId : true) &&
        ((!values[field.id] && typeof values[field.id] !== "number") ||
          (field.type === "select" && values[field.id] === "none"))
      ) {
        if (field.validation.conditionalRequired) {
          if (
            values[field.validation.mainConditionRef] ===
              field.validation.mainConditionValue &&
            (field.validation.subConditionRef
              ? values[field.validation.subConditionRef] ===
                field.validation.subConditionValue
              : true)
          ) {
            errors[field.id] = t("common.inputError.missingField");
          }
        } else {
          //TODO: retirar a exceção dos campos de provider_id e monthly_value e implementar uma forma genérica de não mostrar o erro quando esses campos estiverem escondidos
          if (
            !(
              (field.id === "provider_id" || field.id === "monthly_value") &&
              values["purchase_process_id"] === "none"
            )
          ) {
            errors[field.id] = t("common.inputError.missingField");
          }
        }
      } else if (
        field.validation.minChar !== null &&
        values[field.id] &&
        values[field.id].length < field.validation.minChar
      ) {
        errors[field.id] = t("common.inputError.minCharValidation");
      } else if (
        field.validation.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values[field.id])
      ) {
        errors[field.id] = t("common.inputError.emailValidation");
      } else if (field.validation.cpf && cpfValidation(values[field.id])) {
        errors[field.id] = t("common.inputError.cpfValidation");
      } else if (field.validation.cnpj && cnpjValidation(values[field.id])) {
        errors[field.id] = t("common.inputError.cnpjValidation");
      } else if (field.validation.conditionalCPFCNPJ) {
        if (
          values[field.validation.cpfcnpjRef] === "F" &&
          cpfValidation(values[field.id])
        ) {
          errors[field.id] = t("common.inputError.cpfValidation");
        }
        if (
          values[field.validation.cpfcnpjRef] === "J" &&
          cnpjValidation(values[field.id])
        ) {
          errors[field.id] = t("common.inputError.cnpjValidation");
        }
      } else if (field.validation.noSpace && !/^(?!\s).{1,}/) {
        errors[field.id] = t("common.inputError.noSpaceValidation");
      } else if (
        values[field.id] &&
        field.validation.phoneNumber &&
        !/^[1-9]{1}[0-9]{10}$/.test(values[field.id])
      ) {
        errors[field.id] = t("common.inputError.cellphoneValidation");
      } else if (
        values[field.id] &&
        field.validation.phone &&
        !/^[1-9]{1}[0-9]{9}$/.test(values[field.id])
      ) {
        errors[field.id] = t("common.inputError.phoneValidation");
      } else if (
        field.validation.date &&
        !moment(values[field.id], "DD/MM/YYYY", true).isValid() ===
          !moment(values[field.id], "YYYY-MM-DD", true).isValid()
      ) {
        if (
          field.validation.required ||
          (!field.validation.required && values[field.id])
        ) {
          errors[field.id] = t("common.inputError.dateValidation");
        }
      } else if (field.validation.conditionalDate) {
        field.conditionalDateRef.forEach((date) => {
          if (
            values[date] &&
            moment(values[field.id], "DD/MM/YYYY").isSameOrBefore(
              moment(values[date], "DD/MM/YYYY"),
              "day"
            )
          ) {
            errors[field.id] = t("common.inputError.conditionalDateValidation");
          }
        });
      } else if (
        field.validation.filenamePDF &&
        !/([a-zA-Z0-9\s_\\.\-\(\):])+\.(pdf|PDF|Pdf)$/.test(values[field.id])
      ) {
        errors[field.id] = t("common.inputError.filenameValidation");
      } else if (
        field.validation.addendumNumber &&
        !/^[1-9][0-9]{0,2}$/.test(values[field.id])
      ) {
        errors[field.id] = t("common.inputError.addendumNumberValidation");
      } else if (field.validation.dynamicInput) {
        let tempError = [];
        let winnerCheck = [];
        let valueIdList = [];
        values[field.id].forEach((value, index) => {
          let unitError = {};
          valueIdList.push(value.id);
          if (value.id === "none") {
            unitError["id"] = t("common.inputError.missingField");
          }
          if (!value.value) {
            unitError["value"] = t("common.inputError.missingField");
          }
          winnerCheck[index] = value.winner;
          tempError[index] = unitError;
        });
        const set = new Set(valueIdList);

        if (
          [...set].every((element) => element !== "none") &&
          [...set].length !== values[field.id].length
        ) {
          tempError.forEach((errorValue) => {
            errorValue["id"] = t("common.inputError.providerIDValidation");
          });
        }
        if (winnerCheck.every((winner) => winner === false)) {
          tempError.forEach((value, index) => {
            value["winner"] = t("common.inputError.providerRequiredValidation");
          });
        }

        const winnerCount = winnerCheck.filter((item) => item === true);

        if (winnerCount.length > 1) {
          tempError.forEach((value, index) => {
            value["winner"] = t("common.inputError.oneWinnerValidation");
          });
        }

        if (
          !tempError.every((tempError) => Object.keys(tempError).length === 0)
        ) {
          errors[field.id] = tempError;
        }
      } else if (field.validation.codeNumber) {
        if (values[field.prefixRef] === "none") {
          if (
            !/^[0-9]{2}$/.test(values[field.id]) ||
            values[field.id] === "00"
          ) {
            errors[field.id] = t("common.inputError.invalidCode");
          }
        } else {
          const splitCode = values[field.id].split(".");

          splitCode.forEach((code) => {
            if (!/^[0-9]{2}$/.test(code) || code === "00") {
              errors[field.id] = t("common.inputError.invalidCode");
            }
          });
        }
      } else if (field.validation.checkboxGroup) {
        let categoryOptions = [];
        Object.keys(values[field.category]).forEach((group) => {
          categoryOptions.push(
            Object.values(values[field.category][group]).every(
              (option) => option === false
            )
          );
        });
        if (categoryOptions.every((item) => item === true)) {
          errors[field.category] = t("common.inputError.checkboxValidation");
        }
      } else if (
        field.validation.customValidation &&
        !field.validation.customValidation.test(values[field.id]) &&
        (!field.validation.required ? values[field.id] : true)
      ) {
        errors[field.id] = t("common.inputError.invalidValue");
      } else if (field.validation.validityPeriod) {
        if (!Number.isInteger(values[field.id])) {
          errors[field.id] = t("common.inputError.noDecimalsAllowed");
        } else if (!(values[field.id] >= 1 && values[field.id] <= 120)) {
          errors[field.id] = t("common.inputError.invalidPeriod");
        }
      } else if (
        field.validation.zipcode &&
        !/^[0-9]{5}-[0-9]{3}$/.test(values[field.id])
      ) {
        errors[field.id] = t("common.input.invalidZipcode");
      }
    };

    if (itemId || addendumId) {
      if (config[pageType].createEditCategories.length > 1) {
        for (const category in formFields) {
          formFields[category].forEach((field) => {
            doValidations(field);
          });
        }
      } else {
        formFields["all"].forEach((field) => {
          doValidations(field);
        });
      }
    } else {
      if (config[pageType].createEditCategories.length > 1) {
        formFields[config[pageType].createEditCategories[activeStep]].forEach(
          (field) => {
            doValidations(field);
          }
        );
      } else {
        formFields["all"].forEach((field) => {
          doValidations(field);
        });
      }
    }

    return errors;
  };

  const handleStep = (step) => {
    setActiveStep(step);
  };

  const handlePrevious = () => {
    setActiveStep((currentStep) => {
      return currentStep - 1;
    });
  };

  const handleBackBtn = () => {
    if (pageType === "units") {
      history.push(`/contracts/${pageType}/${menuId}`);
    } else if (pageType === "addendum" || pageType === "save_as") {
      history.push("/contracts");
    } else {
      history.push(`/${pageType}`);
    }
  };

  const handleFinishModal = () => {
    setFinishModal(false);
    setSubmitError(false);
    setSubmitWarning(false);
    setMessageArray([]);
    if (!submitError) {
      setTimeout(() => {
        setSubmitError(false);
      }, 1000);
      if (pageType === "units") {
        history.push(`/contracts/${pageType}/${menuId}`);
      } else if (pageType === "addendum" || pageType === "save_as") {
        history.push(`/contracts`);
      } else {
        history.push(`/${pageType}`);
      }
    }
  };

  const formSubmit = (values, actions) => {
    if (!itemId && !addendumId) {
      if (
        activeStep === config[pageType].createEditCategories.length - 1 ||
        config[pageType].createEditCategories.length === 0
      ) {
        let newItem = { ...values };
        for (let input in values) {
          if (values[input] === "" || values[input] === "none") {
            newItem[input] = null;
          }
        }

        let copyNewItem = { ...newItem };

        if (pageType === "contracts" || pageType === "save_as") {
          newItem["filename"] = `CO_OS_${newItem["org_id"]}_NUM_${newItem[
            "contract_number"
          ].replace(/\D/g, "")}.pdf`;
          copyNewItem["filename"] = `CO_OS_${
            copyNewItem["org_id"]
          }_NUM_${copyNewItem["contract_number"].replace(/\D/g, "")}.pdf`;
          delete newItem["file"];
        } else if (pageType === "addendum") {
          newItem["filename"] = `TA_OS_${newItem["org_id"]}_NUM_${newItem[
            "contract_number"
          ].replace(/\D/g, "")}_${newItem["addendum_number"]}.pdf`;
          copyNewItem["filename"] = `TA_OS_${
            copyNewItem["org_id"]
          }_NUM_${copyNewItem["contract_number"].replace(/\D/g, "")}_${
            copyNewItem["addendum_number"]
          }.pdf`;
          delete newItem["file"];
        }

        if (pageType === "units") {
          newItem["unit_id"] = [newItem["unit_id"]];
        }

        if (pageType === "addendum" || pageType === "save_as") {
          newItem["id"] = Number(contractId);
        }

        if (pageType === "expenses") {
          delete newItem["no_tax_code"];
          delete newItem["type_of_benefited"];
          newItem["org_id"] = user.data.org_id;
        }

        if (
          pageType === "balances" ||
          pageType === "incomes" ||
          pageType === "purchase_process" ||
          pageType === "third_party_contracts"
        ) {
          newItem["org_id"] = user.data.org_id;
        }

        addTableItem(pageType, newItem, menuId)
          .then((response) => {
            if (response && response.success) {
              if (pageType === "contracts" || pageType === "addendum") {
                let fileObj = {
                  file: copyNewItem["file"],
                  filename: copyNewItem["filename"],
                  org_id: copyNewItem["org_id"],
                  contract_number: copyNewItem["contract_number"],
                };

                uploadFile(fileObj)
                  .then((upload) => {
                    if (process.env.REACT_APP_AWS_FILE_UPLOAD === "true") {
                      if (upload.response.status === 204) {
                        actions.setSubmitting(false);
                        createEditForm.current.resetForm({ values: values });
                        setFinishModal(true);
                      } else {
                        setSubmitWarning(true);
                        actions.setSubmitting(false);
                        setFinishModal(true);
                      }
                    } else {
                      if (upload && upload.success) {
                        actions.setSubmitting(false);
                        createEditForm.current.resetForm({ values: values });
                        setFinishModal(true);
                      } else {
                        setSubmitWarning(true);
                        actions.setSubmitting(false);
                        setFinishModal(true);
                      }
                    }
                  })
                  .catch((error) => {
                    setSubmitWarning(true);
                    actions.setSubmitting(false);
                    setFinishModal(true);
                  });
              } else {
                actions.setSubmitting(false);
                createEditForm.current.resetForm({ values: values });
                setFinishModal(true);
              }
            } else {
              if (typeof response.type === "string") {
                setMessage(t(`createEditErrors.${response.type}`));
              }
              if (Array.isArray(response.type)) {
                let copyMessageArray = [...messageArray];
                response.type.forEach((type) => {
                  copyMessageArray.push(t(`createEditErrors.${type}`));
                });
                setMessageArray(copyMessageArray);
              }
              setSubmitError(true);
              actions.setSubmitting(false);
              setFinishModal(true);
            }
          })
          .catch((error) => {
            setSubmitError(true);
            actions.setSubmitting(false);
            setFinishModal(true);
          });
      } else {
        actions.setTouched({});
        actions.setSubmitting(false);
        setActiveStep((currentStep) => {
          return currentStep + 1;
        });
      }
    } else {
      setEditSubmitting(true);
      let editItem = { ...values };
      for (let input in values) {
        if (values[input] === "" || values[input] === "none") {
          editItem[input] = null;
        }
      }

      editItem = { ...item, ...editItem };

      if (editConfig[pageType]) {
        editConfig[pageType].forEach((item) => {
          if (editItem.hasOwnProperty(item)) {
            delete editItem[item];
          }
        });
      }

      let copyEditItem = { ...editItem };

      if (pageType === "contracts") {
        if (editItem["file"]) {
          editItem["filename"] = `CO_OS_${editItem["org_id"]}_NUM_${editItem[
            "contract_number"
          ].replace(/\D/g, "")}.pdf`;
          copyEditItem["filename"] = `CO_OS_${
            copyEditItem["org_id"]
          }_NUM_${copyEditItem["contract_number"].replace(/\D/g, "")}.pdf`;
        }

        delete editItem["file"];
      }

      if (pageType === "addendum") {
        if (editItem["file"]) {
          editItem["filename"] = `TA_OS_${editItem["org_id"]}_NUM_${editItem[
            "contract_number"
          ].replace(/\D/g, "")}_${editItem["addendum_number"]}.pdf`;
          copyEditItem["filename"] = `TA_OS_${
            editItem["org_id"]
          }_NUM_${editItem["contract_number"].replace(/\D/g, "")}_${
            editItem["addendum_number"]
          }.pdf`;
        }

        delete editItem["file"];
      }

      editTableItem(pageType, editItem)
        .then((response) => {
          if (response && response.success) {
            if (
              (pageType === "contracts" || pageType === "addendum") &&
              copyEditItem["file"]
            ) {
              let fileObj = {
                file: copyEditItem["file"],
                filename: copyEditItem["filename"],
                org_id: copyEditItem["org_id"],
                contract_number: copyEditItem["contract_number"],
              };

              uploadFile(fileObj)
                .then((upload) => {
                  if (process.env.REACT_APP_AWS_FILE_UPLOAD === "true") {
                    if (upload.status === 204) {
                      createEditForm.current.resetForm({ values: values });
                      actions.setSubmitting(false);
                      setEditSubmitting(false);
                      setFinishModal(true);
                    } else {
                      setSubmitWarning(true);
                      actions.setSubmitting(false);
                      setEditSubmitting(false);
                      setFinishModal(true);
                    }
                  } else {
                    if (upload && upload.success) {
                      createEditForm.current.resetForm({ values: values });
                      actions.setSubmitting(false);
                      setEditSubmitting(false);
                      setFinishModal(true);
                    } else {
                      setSubmitWarning(true);
                      actions.setSubmitting(false);
                      setEditSubmitting(false);
                      setFinishModal(true);
                    }
                  }
                })
                .catch((error) => {
                  setSubmitWarning(true);
                  actions.setSubmitting(false);
                  setEditSubmitting(false);
                  setFinishModal(true);
                });
            } else {
              createEditForm.current.resetForm({ values: values });
              actions.setSubmitting(false);
              setEditSubmitting(false);
              setFinishModal(true);
            }
          } else {
            setSubmitError(true);
            actions.setSubmitting(false);
            setEditSubmitting(false);
            setFinishModal(true);
          }
        })
        .catch((error) => {
          setSubmitError(true);
          actions.setSubmitting(false);
          setEditSubmitting(false);
          setFinishModal(true);
        });
    }
  };

  const clickEdit = () => {
    editBtn.current.click();
  };

  useEffect(() => {
    document.title =
      itemId || addendumId
        ? t(`${pageType}.editItemText`)
        : t(`${pageType}.newItemText`);
    const fetchData = async () => {
      try {
        const params = {
          page: 1,
          perPage: null,
          search: [],
          sortBy: "name",
          order: "asc",
          pageType: pageType,
          id: itemId,
          contractId: contractId,
          addendumId: addendumId,
        };
        const response = await getTableItems(params);

        if (response && response.success) {
          const editItem = { ...response.data };
          if (pageType === "expenses") {
            if (editItem["cpf"] !== "" && editItem["cpf"] !== null) {
              editItem["type_of_benefited"] = t("common.cpf");
            } else {
              editItem["type_of_benefited"] = t("common.cnpj");
            }

            if (
              editItem["doc_number"] === null &&
              editItem["tax_code"] === null &&
              editItem["serie_number"] === null
            ) {
              editItem["no_tax_code"] = true;
            } else {
              editItem["no_tax_code"] = false;
            }
          }

          if (editItem.hasOwnProperty("permissions")) {
            let newPermissions = {};
            editItem.permissions.forEach((permission) => {
              const { module, resource, execute, ...permissionList } =
                permission;
              const filteredPermissions = Object.keys(permissionList)
                .filter((key) => permissionList[key] !== null)
                .reduce((obj, key) => {
                  return {
                    ...obj,
                    [key]: permissionList[key],
                  };
                }, {});

              if (permission.module !== permission.resource) {
                newPermissions[`${permission.module}-${permission.resource}`] =
                  filteredPermissions;
              } else {
                newPermissions[permission.module] = filteredPermissions;
              }
            });
            editItem.permissions = newPermissions;
          }

          for (let input in response.data) {
            if (response.data[input] === null) {
              editItem[input] = "";
            }

            if (config[pageType].defaultInput) {
              config[pageType].defaultInput.forEach((defaultInput) => {
                editItem[defaultInput] = "";
              });
            }
          }

          if (pageType === "contracts") {
            editItem["filename"] = response.data["filename"];
          }

          setItem(editItem);
        } else {
          toast.error(t("common.toast.dataError"), toastConfig);
          setItem({});
        }
      } catch (error) {
        toast.error(t("common.toast.dataError"), toastConfig);
        setItem({});
      }
    };

    if (itemId || contractId || addendumId) {
      fetchData();
    }
  }, [user]);
  return (
    <div className="page-container">
      {Object.keys(user).length !== 0 && (
        <>
          {user.permissions[permissionsConfig[pageType]].create ||
          (user.permissions[permissionsConfig[pageType]].update && itemId) ||
          (user.permissions[permissionsConfig[pageType]].update &&
            addendumId) ? (
            <>
              <div className="title-container">
                <Button
                  className="back-btn"
                  onClick={handleBackBtn}
                  type="button"
                >
                  <ArrowBackIcon />
                </Button>
                <p className="tablepage-title">
                  {itemId || addendumId
                    ? t(`${pageType}.editItemText`)
                    : t(`${pageType}.newItemText`)}
                </p>
              </div>
              <div className="page-content">
                {config[pageType].createEditCategories.length > 1 && (
                  <div className="form-controls">
                    <Stepper
                      nonLinear
                      activeStep={activeStep}
                      connector={<></>}
                    >
                      {config[pageType].createEditCategories.map(
                        (category, index) => (
                          <Step key={category}>
                            <StepButton
                              className={
                                activeStep === index
                                  ? "button-style--active"
                                  : "button-style"
                              }
                              disabled={!itemId && !addendumId}
                              icon={<span>{`${index + 1}.`}</span>}
                              onClick={() => handleStep(index)}
                            >
                              {t(`${pageType}.stepLabels.${category}`)}
                            </StepButton>
                          </Step>
                        )
                      )}
                    </Stepper>
                  </div>
                )}
                <div className="page-form-container">
                  {pageType === "profiles" && activeStep === 1 ? (
                    <p className="form__intro-text">
                      {t("common.selectPermissions")}
                    </p>
                  ) : (
                    <p className="required-fields-text">
                      {t("common.requiredFields")}
                    </p>
                  )}
                  <Formik
                    initialValues={getInitialValues()}
                    validate={(values) => fieldsValidation(values)}
                    onSubmit={(values, actions) => formSubmit(values, actions)}
                    enableReinitialize={true}
                    innerRef={createEditForm}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      dirty,
                      setFieldValue,
                      setFieldTouched,
                      isSubmitting,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                    }) => (
                      <form className="form" onSubmit={handleSubmit}>
                        {selectOptions &&
                          formFields[
                            config[pageType].createEditCategories.length > 1
                              ? config[pageType].createEditCategories[
                                  activeStep
                                ]
                              : "all"
                          ].map((field) => (
                            <div
                              className="form__input-container"
                              key={field.id}
                            >
                              <FormElement
                                props={{
                                  field: field,
                                  values: values,
                                  handleChange: handleChange,
                                  handleBlur: handleBlur,
                                  pageType: pageType,
                                  setFieldValue: setFieldValue,
                                  setFieldTouched: setFieldTouched,
                                  options: selectOptions,
                                  errors: errors,
                                  touched: touched,
                                  disabled:
                                    (itemId ||
                                      (contractId &&
                                        field.id !== "addendum_number") ||
                                      addendumId) &&
                                    field.editDisabled
                                      ? true
                                      : isSubmitting,
                                  isEditing: itemId,
                                }}
                              />
                              {field.id !== "competitors" &&
                                errors[field.id] &&
                                touched[field.id] && (
                                  <div className="input-feedback">
                                    <AlertCircle size={18} />
                                    <p className="error-text">
                                      {errors[field.id]}
                                    </p>
                                  </div>
                                )}
                            </div>
                          ))}
                        {!itemId && !addendumId && (
                          <div className="step-control-container">
                            {activeStep !== 0 && (
                              <Button
                                className="btn-color-inverted"
                                disabled={isSubmitting}
                                onClick={handlePrevious}
                                type="button"
                              >
                                <ArrowBackIcon
                                  classes={{ root: "step-control-icon-left" }}
                                />
                                {t("common.back")}
                              </Button>
                            )}
                            {
                              <Button
                                className="btn-color-filled"
                                disabled={isSubmitting}
                                type="submit"
                              >
                                {config[pageType].createEditCategories.length <
                                1
                                  ? t("common.saveItem")
                                  : activeStep ===
                                    config[pageType].createEditCategories
                                      .length -
                                      1
                                  ? t("common.finish")
                                  : t("common.forward")}{" "}
                                {isSubmitting ? (
                                  <CircularProgress
                                    classes={{ root: "btn__loading" }}
                                    size="2rem"
                                  />
                                ) : config[pageType].createEditCategories <
                                  1 ? null : (
                                  <ArrowForwardIcon
                                    classes={{
                                      root: "step-control-icon-right",
                                    }}
                                  />
                                )}
                              </Button>
                            }
                          </div>
                        )}
                        <button
                          className="btn-hidden"
                          type="submit"
                          ref={editBtn}
                        />
                        {/*TODO: retirar a exceção de prompt para a página de contratos e expenses*/}
                        <NavigationPrompt
                          when={!!values && dirty}
                          onConfirm={() => true}
                          onCancel={() => false}
                        />
                      </form>
                    )}
                  </Formik>
                </div>
                {(itemId || addendumId) && pageType !== "bankAccounts" && (
                  <div className="container__edit-controls">
                    <Button
                      className="btn__save-edit"
                      type="submit"
                      disabled={editSubmitting}
                      onClick={clickEdit}
                    >
                      {t("common.saveEdit")}{" "}
                      {editSubmitting && (
                        <CircularProgress
                          classes={{ root: "btn__loading" }}
                          size="2rem"
                        />
                      )}
                    </Button>
                  </div>
                )}
              </div>
              {finishModal && (
                <Dialog
                  open={finishModal}
                  onClose={handleFinishModal}
                  keepMounted
                  classes={{
                    root: "modal__backdrop",
                    paper: "finish-modal-container",
                  }}
                >
                  <Tooltip
                    title={t("common.tooltip.close")}
                    classes={{
                      tooltip: "tooltip",
                    }}
                    placement="bottom-start"
                  >
                    <button className="btn__close" onClick={handleFinishModal}>
                      <CloseIcon classes={{ root: "btn__modal-icon--close" }} />
                    </button>
                  </Tooltip>

                  <div className="modal-content--align-left">
                    <div
                      className={
                        submitError
                          ? "icon-wrapper--error"
                          : submitWarning
                          ? "icon-wrapper--warning"
                          : "icon-wrapper"
                      }
                    >
                      <IconSelector
                        name={submitError ? "AlertTriangle" : "CheckCircle"}
                        type="feather-icon"
                      />
                    </div>
                    <div className="modal-text-container">
                      {itemId || addendumId ? (
                        <>
                          {submitError ? (
                            <>
                              <p className="modal-title--align-left">
                                {t(`${pageType}.errorEdit.title`)}
                              </p>
                              <p>{t(`${pageType}.errorEdit.msg`)}</p>
                            </>
                          ) : submitWarning ? (
                            <>
                              <p className="modal-title--align-left">
                                {t(`${pageType}.warningEdit.title`)}
                              </p>
                              <p>{t(`${pageType}.warningEdit.msg`)}</p>
                            </>
                          ) : (
                            <>
                              <p className="modal-title--align-left">
                                {t(`${pageType}.successEdit.title`)}
                              </p>
                              <p>{t(`${pageType}.successEdit.msg`)}</p>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {submitError ? (
                            <>
                              <p className="modal-title--align-left">
                                {t(`${pageType}.errorCreate.title`)}
                              </p>
                              {messageArray.length !== 0 ? (
                                <>
                                  {messageArray.map((arrayItem, index) => (
                                    <p key={index}>{arrayItem}</p>
                                  ))}
                                </>
                              ) : (
                                <p>{message}</p>
                              )}
                            </>
                          ) : submitWarning ? (
                            <>
                              <p className="modal-title--align-left">
                                {t(`${pageType}.warningCreate.title`)}
                              </p>
                              <p>{t(`${pageType}.warningCreate.msg`)}</p>
                            </>
                          ) : (
                            <>
                              <p className="modal-title--align-left">
                                {t(`${pageType}.successCreate.title`)}
                              </p>
                              <p>{t(`${pageType}.successCreate.msg`)}</p>
                            </>
                          )}
                        </>
                      )}
                    </div>
                    <button
                      className="modal-btn"
                      type="button"
                      onClick={handleFinishModal}
                    >
                      {t("login.okBtn")}
                    </button>
                  </div>
                </Dialog>
              )}
            </>
          ) : (
            <Redirect to="/" />
          )}
        </>
      )}
    </div>
  );
}

export default CreateEditPage;
