import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { Formik } from "formik";
import { AlertCircle } from "lucide-react";

import logo from "../../img/logo_estado_para.png";
import { sendNewPassword, activateUser } from "../utils/dataAPI";

function SetPasswordPage({ pageType }) {
  const { t } = useTranslation();
  const { token } = useParams();
  let history = useHistory();
  const [sentPassword, setSentPassword] = useState(false);
  const [passwordStatus, setPasswordStatus] = useState("");
  const [visibleNewPass, setVisibleNewPass] = useState(false);
  const [visibleConfirmPass, setVisibleConfirmPass] = useState(false);

  const errorInitialValues = {
    lengthError: "",
    numberError: "",
    lowerCaseError: "",
    upperCaseError: "",
    specialCharError: "",
    forbiddenCharError: "",
  };
  const [errorMessage, setErrorMessage] = useState(errorInitialValues);

  useEffect(() => {
    document.title = t(`${pageType}.pageTitle`);
  }, [pageType, t]);

  const recoveryInitialValues = {
    newPassword: "",
    confirmPassword: "",
  };

  const passwordValidation = (values) => {
    const errors = {};
    if (!values.newPassword) {
      errors.newPassword = t("common.inputError.missingField");
      setErrorMessage({
        lengthError: t("common.inputError.lengthError"),
        numberError: t("common.inputError.numberError"),
        lowerCaseError: t("common.inputError.lowerCaseError"),
        upperCaseError: t("common.inputError.upperCaseError"),
        specialCharError: t("common.inputError.specialCharError"),
        forbiddenCharError: t("common.inputError.forbiddenCharError"),
      });
    } else if (
      !/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&<>?~_+\-=])(?!.*[ \\*.()[\]{};:,¨"'/|]).{8,12}$/.test(
        values.newPassword
      )
    ) {
      if (!/(?=.*[0-9]).{1,}/.test(values.newPassword)) {
        setErrorMessage((prevState) => ({
          ...prevState,
          numberError: t("common.inputError.numberError"),
        }));
      } else {
        setErrorMessage((prevState) => ({
          ...prevState,
          numberError: "",
        }));
      }
      if (!/^.{8,12}$/.test(values.newPassword)) {
        setErrorMessage((prevState) => ({
          ...prevState,
          lengthError: t("common.inputError.lengthError"),
        }));
      } else {
        setErrorMessage((prevState) => ({
          ...prevState,
          lengthError: "",
        }));
      }
      if (!/(?=.*[a-z]).{1,}/.test(values.newPassword)) {
        setErrorMessage((prevState) => ({
          ...prevState,
          lowerCaseError: t("common.inputError.lowerCaseError"),
        }));
      } else {
        setErrorMessage((prevState) => ({
          ...prevState,
          lowerCaseError: "",
        }));
      }
      if (!/(?=.*[A-Z]).{1,}/.test(values.newPassword)) {
        setErrorMessage((prevState) => ({
          ...prevState,
          upperCaseError: t("common.inputError.upperCaseError"),
        }));
      } else {
        setErrorMessage((prevState) => ({
          ...prevState,
          upperCaseError: "",
        }));
      }
      if (!/(?=.*[!@#$%^&<>?~_+\-=]).{1,}/.test(values.newPassword)) {
        setErrorMessage((prevState) => ({
          ...prevState,
          specialCharError: t("common.inputError.specialCharError"),
        }));
      } else {
        setErrorMessage((prevState) => ({
          ...prevState,
          specialCharError: "",
        }));
      }
      if (!/^(?!.*[ \\*.()[\]{};:,¨"'/|]).{1,}$/.test(values.newPassword)) {
        setErrorMessage((prevState) => ({
          ...prevState,
          forbiddenCharError: t("common.inputError.forbiddenCharError"),
        }));
      } else {
        setErrorMessage((prevState) => ({
          ...prevState,
          forbiddenCharError: "",
        }));
      }
      errors.newPassword = t("common.inputError.invalidPassword");
    } else {
      setErrorMessage({
        lengthError: "",
        numberError: "",
        lowerCaseError: "",
        upperCaseError: "",
        specialCharError: "",
        forbiddenCharError: "",
      });
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = t("common.inputError.missingField");
    } else if (values.confirmPassword !== values.newPassword) {
      errors.confirmPassword = t("common.inputError.confirmPassInvalid");
    }

    return errors;
  };

  const passwordSubmit = (values, actions) => {
    if (pageType === "recovery") {
      sendNewPassword(values.newPassword, token).then((response) => {
        if (response && response.success) {
          setPasswordStatus("recoverySuccess");
          setSentPassword(true);
        } else if (response && response.type === "token.expired") {
          setPasswordStatus("tokenExpired");
          setSentPassword(true);
        } else {
          actions.setFieldError(
            "newPassword",
            t("common.inputError.setPasswordError")
          );
          actions.setFieldError(
            "confirmPassword",
            t("common.inputError.setPasswordError")
          );
        }
        actions.setSubmitting(false);
      });
    }
    if (pageType === "activate") {
      activateUser(values.newPassword, token).then((response) => {
        if (response && response.success) {
          setPasswordStatus("activationSuccess");
          setSentPassword(true);
        } else if (response && response.type === "token.expired") {
          setPasswordStatus("tokenExpired");
          setSentPassword(true);
        } else if (response && response.type === "activation.duplicated") {
          setPasswordStatus("activationDuplicated");
          setSentPassword(true);
        } else if (response && response.type === "email.non-existant") {
          setPasswordStatus("emailNonExistant");
          setSentPassword(true);
        } else {
          actions.setFieldError(
            "newPassword",
            t("common.inputError.setPasswordError")
          );
          actions.setFieldError(
            "confirmPassword",
            t("common.inputError.setPasswordError")
          );
        }
        actions.setSubmitting(false);
      });
    }
  };

  const onChangeVisibleNewPass = () => {
    setVisibleNewPass(!visibleNewPass);
  };

  const onChangeVisibleConfirmPass = () => {
    setVisibleConfirmPass(!visibleConfirmPass);
  };

  const backToLogin = () => {
    history.push("/");
  };

  return (
    <div className="recover-container">
      <div className="recover-content-container">
        <div className="recover-form-header">
          <img
            alt="logo governo pará"
            className="recover-logo-img"
            src={logo}
          />
        </div>
        <div className="recover-form-container">
          <p className="recover-form-title">{t(`${pageType}.pageTitle`)}</p>
          {!sentPassword ? (
            <Formik
              initialValues={recoveryInitialValues}
              validate={(values) => passwordValidation(values)}
              onSubmit={(values, actions) => passwordSubmit(values, actions)}
            >
              {({
                values,
                errors,
                touched,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="form__input-container">
                    <label
                      className={
                        errors.newPassword && touched.newPassword
                          ? "form__label--error"
                          : "form__label"
                      }
                      htmlFor="newPassword"
                    >
                      {t(`${pageType}.fields.newPassword`)}
                    </label>
                    <input
                      id="newPassword"
                      name="newPassword"
                      type={visibleNewPass ? "text" : "password"}
                      value={values.newPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.newPassword && touched.newPassword
                          ? "form__input-password--error"
                          : "form__input-password"
                      }
                      placeholder={t(`${pageType}.placeholder.newPassword`)}
                      disabled={isSubmitting}
                    />
                    <button
                      onClick={onChangeVisibleNewPass}
                      className="btn__input-btn"
                      type="button"
                    >
                      {visibleNewPass ? (
                        <VisibilityOutlinedIcon
                          classes={{ root: "input__icon" }}
                        />
                      ) : (
                        <VisibilityOffOutlinedIcon
                          classes={{ root: "input__icon" }}
                        />
                      )}
                    </button>
                    {errors.newPassword && touched.newPassword && (
                      <div className="input-feedback">
                        <AlertCircle size={18} />
                        <p className="error-text">{errors.newPassword}</p>
                      </div>
                    )}
                  </div>
                  <div className="form__input-container">
                    <label
                      className={
                        errors.confirmPassword && touched.confirmPassword
                          ? "form__label--error"
                          : "form__label"
                      }
                      htmlFor="confirm-password"
                    >
                      {t(`${pageType}.fields.confirmPassword`)}
                    </label>
                    <input
                      id="confirmPassword"
                      name="confirmPassword"
                      type={visibleConfirmPass ? "text" : "password"}
                      value={values.confirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.confirmPassword && touched.confirmPassword
                          ? "form__input-password--error"
                          : "form__input-password"
                      }
                      placeholder={t(`${pageType}.placeholder.confirmPassword`)}
                      disabled={isSubmitting}
                    />
                    <button
                      onClick={onChangeVisibleConfirmPass}
                      className="btn__input-btn"
                      type="button"
                    >
                      {visibleConfirmPass ? (
                        <VisibilityOutlinedIcon
                          classes={{ root: "input__icon" }}
                        />
                      ) : (
                        <VisibilityOffOutlinedIcon
                          classes={{ root: "input__icon" }}
                        />
                      )}
                    </button>
                    {errors.confirmPassword && touched.confirmPassword && (
                      <div className="input-feedback">
                        <AlertCircle size={18} />
                        <p className="error-text">{errors.confirmPassword}</p>
                      </div>
                    )}
                  </div>
                  {errors.newPassword &&
                    touched.newPassword &&
                    !Object.values(errorMessage).every(
                      (value) => value === ""
                    ) && (
                      <div className="container__error-message">
                        {Object.keys(errorMessage).map((error) => (
                          <div key={error}>
                            {errorMessage[error] && (
                              <div className="container__error-text">
                                <AlertCircle size={16} />
                                <p className="error-text">
                                  {errorMessage[error]}
                                </p>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  {isSubmitting ? (
                    <div className="container__loading">
                      <CircularProgress
                        classes={{ root: "btn__loading--alternative" }}
                      />
                    </div>
                  ) : (
                    <Button classes={{ root: "reset-btn" }} type="submit">
                      {t(`${pageType}.submitBtn`)}
                    </Button>
                  )}
                </form>
              )}
            </Formik>
          ) : (
            <>
              <p className="recover-success-text">
                {t(`common.inputError.${passwordStatus}`)}
              </p>
              <Button
                classes={{ root: "recover-back-home-btn" }}
                type="button"
                onClick={backToLogin}
              >
                {t("recovery.backToLogin")}
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default SetPasswordPage;
