import { Cookies } from 'react-cookie'; 

import { setLoginStatus, setUser } from '../actions/user';
import { setLocalToken } from './dataAPI';

export const requestLogin = (username,password,captcha) => {
	const loginHeaders = new Headers();

	const authString = `${username}:${password}`;
	loginHeaders.set('Authorization', `Basic ${btoa(authString)}`)
	loginHeaders.set('g-recaptcha-response', `${captcha}`)
	loginHeaders.set('Access-Control-Allow-Origin', '*')
	return fetch(`${process.env.REACT_APP_SERVICE_URL}/api/credential/login`, {
		method: 'POST',
		credentials: 'omit',
		headers: loginHeaders
	})
}

export const requestLogout = (dispatch,history) => {
	const cookies = new Cookies();
	cookies.remove('user', {path: '/', domain: window.location.hostname});
	setLocalToken(null);
	dispatch(setUser({}));
	history.push('/')
	dispatch(setLoginStatus(false,''));
}