import React from 'react';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

function PolicyText() {
	return (
		<div className='container__terms-text'>
			<p className='terms-text'>Seja bem-vindo (a)!</p>
			<p className='terms-text'>Ficamos muito felizes por escolher a Plataforma Di.Acordo!</p>
			<p className='terms-text'>Caso esteja com muita pressa em acessar a Plataforma, disponibilizamos um breve resumo no quadro abaixo.</p>

			<h2 className='terms-subtitle'>DIREITOS DOS TITULARES DE DADOS PESSOAIS</h2>
			<p className='terms-text'>Vocês são os titulares (pessoas naturais) de dados pessoais a quem se referem os dados pessoais que serão coletados por Nós.</p>
			<p className='terms-text'>A legislação brasileira concede certos direitos à Pessoa Física quanto a seus dados pessoais. Dessa forma, Nós garantimos transparência e controle de acesso aos titulares de dados pessoais, proporcionando-lhes a melhor experiência possível durante a utilização da Plataforma. Os direitos do titular dos dados pessoais são os seguintes:</p>
			<ul className='terms-list'>
				<li className='terms-text'>Direito de confirmação da existência de tratamento - Direito de solicitar a confirmação da existência de tratamento aos seus dados pessoais através de informações claras sobre a origem dos dados, a inexistência de registro, os critérios utilizados e a finalidade do tratamento;</li>
				<li className='terms-text'>Direito de acesso - direito de ser informado e solicitar acesso aos dados pessoais tratados por Nós;</li>
				<li className='terms-text'>Direito de retificação - direito de solicitar que Nós alteremos ou atualizemos os seus dados pessoais quando os mesmos estiverem incorretos ou incompletos;</li>
				<li className='terms-text'>Direito de exclusão - direito de solicitar a eliminação de seus dados pessoais coletados por Nós;</li>
				<li className='terms-text'>Direito de restrição - direito de solicitar que Nós deixemos, temporária ou permanentemente, de processar todos ou alguns dos seus dados pessoais;</li>
				<li className='terms-text'>Direito de oposição – direito de se opor, a qualquer momento, (i) ao processamento de seus dados pessoais por motivos relacionados à sua situação particular e (ii) à manipulação dos seus dados pessoais para fins de marketing direto;</li>
				<li className='terms-text'>Direito à portabilidade de dados - direito de solicitar uma cópia dos seus dados pessoais em formato eletrônico e/ou transmitir os referidos dados pessoais para utilização no serviço de terceiros;</li>
				<li className='terms-text'>Direito a não se submeter a decisões automatizadas - direito de não se submeter a decisões tomadas de forma unicamente automatizada, incluindo no que diz respeito à definição de perfis, caso a decisão exerça efeito jurídico sobre Você ou produza um efeito igualmente significativo.</li>
			</ul>

			<p className='terms-text'>Na tabela abaixo exibimos quais dados serão coletados, sua categoria e finalidade.</p>

			<Table>
				<TableContainer>
					<TableRow>
						<TableCell colSpan={2}><p className='terms-text'><b>INFORMAÇÕES / DADOS PESSOAIS COLETADOS E SUA FINALIDADE</b></p></TableCell>
					</TableRow>
					<TableRow>
						<TableCell><p className='terms-text'>DADO COLETADO</p></TableCell>
						<TableCell><p className='terms-text'>CLASSIFICAÇÃO</p></TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<p className='terms-text'>Nome completo</p>
							<p className='terms-text'>Idade</p>
							<p className='terms-text'>Email</p>
							<p className='terms-text'>Endereço</p>
							<p className='terms-text'>Telefone</p>
							<p className='terms-text'>CPF</p>
							<p className='terms-text'>Gênero</p>
							<p></p>
						</TableCell>
						<TableCell><p className='terms-text'>Dados cadastrais</p></TableCell>
					</TableRow>
				</TableContainer>
			</Table>

			<br></br>

			<p className='terms-text'>Os dados coletados serão utilizados com as seguintes finalidades:</p>

			<br></br>

			<Table>
				<TableContainer>
					<TableRow>
						<TableCell><p className='terms-text'>DADO COLETADO</p></TableCell>
						<TableCell><p className='terms-text'>FINALIDADE</p></TableCell>
						<TableCell><p className='terms-text'>BASE LEGAL</p></TableCell>
					</TableRow>
					<TableRow>
						<TableCell><p className='terms-text'>Dados cadastrais</p></TableCell>
						<TableCell>
							<p className='terms-text'>Te identificar;</p>
							<p className='terms-text'>Validar seu acesso;</p>
							<p className='terms-text'>Enviar comunicações a Você</p>
						</TableCell>
						<TableCell>
							<p className='terms-text'>Art. 7º O tratamento de dados pessoais somente poderá ser realizado nas seguintes hipóteses:</p>
							<p className='terms-text'>I - mediante o fornecimento de consentimento pelo titular;</p>
							<p className='terms-text'>VII - para a proteção da vida ou da incolumidade física do titular ou de terceiro;</p>
							<p className='terms-text'>VIII - para a tutela da saúde, em procedimento realizado por profissionais da área da saúde ou por entidades sanitárias;</p>
						</TableCell>
					</TableRow>
				</TableContainer>
			</Table>

			<br></br>

			<p className='terms-text'>Para que todas as informações sejam corretamente compreendidas, nós apresentamos nossa Política de Privacidade de forma transparente e acessível, como poderá ser visto a seguir: </p>
			
			<br></br>
			<p className='terms-text'><b>DESTACAMOS QUE É EXTREMAMENTE IMPORTANTE A COMPREENSÃO COMPLETA E A LEITURA TOTAL DESTE DOCUMENTO.</b></p>

			<h2 className='terms-subtitle'>TERMOS GERAIS</h2>
			<p className='terms-text'><b>1.1. Geral.</b> Esta Política de Privacidade foi concebida para comunicar as nossas práticas relativas ao tratamento (inclui coleta, armazenamento, utilização, processamento e compartilhamento) de seus dados pessoais por Nós (doravante assim denominada ou somente “Plataforma”), que engloba o website e seus subdomínios, aplicativos, programas, painéis analíticos, dashboards, API de dados e demais extensões. A Plataforma é de propriedade da Wiiglo Tecnologia da Informação LTDA., sociedade inscrita no CNPJ sob o nº 18.101.387/0001-39, com sede na Rua Fonseca Teles, 121, São Cristóvão, Rio de Janeiro/RJ, CEP 20940-200, e está sob sua responsabilidade e gestão.</p>
			<p className='terms-text'><b>1.2. O que são Dados Pessoais.</b> Os dados pessoais são aqueles informados por Vocês, os quais podem ser usados para identificá-los, individualmente.</p>
			<p className='terms-text'><b>1.3. O que são Dados Pessoais Sensíveis.</b> Dados pessoais sensíveis são aqueles sobre a origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural.</p>
			<p className='terms-text'><b>1.4. Autorização de Coleta de Informações.</b> Ao utilizar a plataforma, Você deverá aceitar e autorizar a coleta de informações apenas para o cumprimento dos serviços ora contratados, através do botão “li e concordo com os termos”, nos termos da presente política de privacidade.</p>
			<p className='terms-text terms-sublist-item'><b>1.4.1.</b> Você poderá desistir de consentir com as disposições desta Política de Privacidade, a qualquer momento, bastando para tal que notifique a Nós através do e-mail di.acordo@wiiglo.com.</p>
			<p className='terms-text'><b>1.5. Regulamentação.</b> A Plataforma atua de acordo com a legislação brasileira, atendendo, inclusive e especialmente, com os termos da Lei nº. 12.965/2014 (o Marco Civil da Internet) e da Lei nº 13.709/2018 (Lei Geral de Proteção de Dados) e demais leis que venham a ser aplicadas.</p>
			<p className='terms-text'><b>1.6. Contato.</b> Em caso de dúvida ou sugestões sobre a Política de Privacidade da Plataforma ou qualquer outra informação, Você poderá entrar em contato com os administradores através do e-mail di.acordo@wiiglo.com, que será respondido em horário comercial, de segunda-feira a sexta-feira, das 9h 30min às 18h.</p>
			<p className='terms-text'><b>1.7. Controle de Informações.</b> Esta Política descreve quais os dados pessoais podem ser coletados de Vocês, enquanto utilizam os serviços da Plataforma, como essas informações podem ser utilizadas e as precauções tomadas contra o acesso ou uso destas informações de forma não autorizada.</p>
		
			<h2 className='terms-subtitle'>DADOS PESSOAIS COLETADOS</h2>
			<p className='terms-text'><b>2.1.</b> Nós poderemos coletar, armazenar e utilizar as seguintes informações e/ou dados pessoais de Vocês:</p>
			<ul className='terms-list'>
				<li className='terms-text'>Nome completo;</li>
				<li className='terms-text'>Idade;</li>
				<li className='terms-text'>E-mail;</li>
				<li className='terms-text'>Endereço;</li>
				<li className='terms-text'>Telefone;</li>
				<li className='terms-text'>CPF;</li>
				<li className='terms-text'>Gênero;</li>
			</ul>
			<p className='terms-text'><b>2.2.</b> Nós também poderemos coletar, armazenar e utilizar as seguintes informações:</p>
			<ul className='terms-list'>
				<li className='terms-text'>Registro em banco de dados de quaisquer arquivos ou informação carregados na Nossa Plataforma pelos titulares;</li>
				<li className='terms-text'>Os detalhes de acesso à Plataforma Di.Acordo e dos recursos que o titular acessou;</li>
				<li className='terms-text'>Informações do dispositivo de acesso utilizado, incluindo, por exemplo, modelo do hardware, sistema operacional e versão, 	nomes e versões dos arquivos, idioma de preferência, identificador exclusivo do dispositivo, identificadores de publicidade, número de série, informações referentes ao movimento do dispositivo e informações de rede; </li>
				<li className='terms-text'>Informações de registro do servidor que podem incluir informações como endereço IP do dispositivo.</li>
				<li className='terms-text'>Mapeamento de cliques no navegador, dados de navegação, estatísticos, demográficos, entre outros.</li>
				<li className='terms-text'>Mapeamento de cliques nos aplicativos e dados de navegação</li>
			</ul>

			<h2 className='terms-subtitle'>USO DOS DADOS PESSOAIS COLETADOS</h2>
			<p className='terms-text'><b>3.1.</b> Os dados coletados por Nós sobre Você são usados para Te identificar, validar seu acesso e enviar comunicações a Você. Os dados sobre sua geolocalização são usados para enviar alertas de risco em determinados ambientes e para criar estatísticas. Toda coleta de dados tem base legal no Art. 7º da LGPD: O tratamento de dados pessoais somente poderá ser realizado nas seguintes hipóteses: I - mediante o fornecimento de consentimento pelo titular; VII - para a proteção da vida ou da incolumidade física do titular ou de terceiro; VIII - para a tutela da saúde, em procedimento realizado por profissionais da área da saúde ou por entidades sanitárias.</p>
			<p className='terms-text'><b>3.2.</b> Nós nos limitamos a usar dados pessoais de Vocês, quando necessário, de forma anônima para fins estatísticos e de pesquisa. Os dados adquiridos de Vocês são usados para gerar conhecimento através dos nossos modelos matemáticos e estatísticos e serão utilizados para prover informação voltada para Você e dentro dos objetivos da Plataforma. Ao adquirir um dado, o Di.Acordo informa a Você o objetivo daquela coleta de dados específica.</p>
			<p className='terms-text'><b>3.3. Segurança nos Dados.</b> Todos os dados pessoais coletados por Nós sobre Você são manipulados com segurança e confidencialidade, incluindo a transmissão usando criptografia moderna (HTTPS).</p>
		
			<h2 className='terms-subtitle'>COMPARTILHAMENTO DOS DADOS PESSOAIS COLETADOS</h2>
			<p className='terms-text'><b>4.1. Exceções para Compartilhamento de Dados Pessoais.</b> Nós não divulgamos a terceiros qualquer dado pessoal fornecido por Vocês, através da Nossa plataforma, exceto:</p>
			<ul className='terms-list'>
				<li className='terms-text'>Casos em que Nós formos obrigados a divulgar ou partilhar os dados coletados, a fim de cumprir ordem judicial, ou para efeitos de prevenção de fraude ou outros crimes, bem como em resposta à solicitação de informações apresentada por autoridade competente, se entendermos que a divulgação está de acordo ou é exigida conforme as leis, regulamentos ou procedimentos processuais aplicáveis;</li>
				<li className='terms-text'>Para proteger os direitos, bens ou a segurança de Nossa Plataforma;</li>
				<li className='terms-text'>Com agentes da lei e/ou autoridades governamentais, se entender-se que suas ações são inconsistentes em relação às disposições em nossos termos de uso, ou para proteger os direitos, a propriedade ou a Nossa segurança, a de Vocês ou de outros;</li>
				<li className='terms-text'>Mediante ação própria de Vocês;</li>
				<li className='terms-text'>Nos casos de venda, parcial ou total, do negócio ou de seus ativos, ou como parte de qualquer reorganização ou reestruturação do negócio, fusão, cisão ou incorporação, de modo que Nós poderemos compartilhar as informações de Vocês com terceiros que façam parte dos respectivos negócios, tomando as medidas necessárias para garantir que os direitos de privacidade continuem a ser protegidos, conforme esta Política;</li>
				<li className='terms-text'>Quando devidamente anonimizados após a criação de estatísticas.</li>
			</ul>
			<p className='terms-text'><b>4.2. Autorizações de Contato e Divulgação.</b> Vocês autorizam a Di.Acordo a contatá-los utilizando qualquer um dos meios de contato informados no cadastro na Plataforma.</p>
		
			<h2 className='terms-subtitle'>COOKIES</h2>
			<p className='terms-text'><b>5.1. O que são os Cookies.</b> Um cookie é um trecho de informação armazenado localmente no seu computador ou dispositivo, e que contém informação acerca das atividades deste na Internet. </p>
			<p className='terms-text'><b>5.2. Cookies Utilizados.</b> Os cookies podem ser usados de diferentes formas e modalidades. Nós poderemos utilizar:</p>
			<ul className='terms-list'>
				<li className='terms-text'>Cookies de Desempenho: Este tipo de cookie coleta informações anônimas sobre a forma como Vocês utilizam a plataforma, de forma a otimizá-la. As informações coletadas por estes cookies nunca contêm detalhes pessoais a partir dos quais seja possível identificá-lo.</li>
				<li className='terms-text'>Cookies Funcionais: Estes cookies são usados por Nós por algumas de suas funcionalidades, buscando lhe oferecer uma melhor experiência ao navegar pela plataforma. </li>
				<li className='terms-text'>Cookies publicitários: Estes cookies coletam informações sobre os hábitos de navegação, buscando tornar a publicidade mais relevante para Você.</li>
			</ul>
			<p className='terms-text'><b>5.3. Acesso aos Cookies.</b> O acesso aos cookies termina assim que Você fecha o navegador. É dada a Você a possibilidade de aceitar ou recusar os cookies.</p>
			<p className='terms-text'><b>5.4. Consentimento para Utilização de Cookies.</b> É necessária sua aceitação, antes de iniciada a seção, para que os Cookies sejam utilizados. </p>
			<p className='terms-text'><b>5.5. Opção sem Cookies.</b> O titular pode optar por recusar o uso de cookies. Se Você optar por não os aceitar, o seu acesso à maior parte das informações disponíveis na Plataforma não será comprometido. Contudo, o titular poderá não conseguir usar plenamente os serviços oferecidos.</p>
		
			<h2 className='terms-subtitle'>ACESSO E CORREÇÃO DE DADOS PESSOAIS</h2>
			<p className='terms-text'><b>6.1. Acesso e Retificação de Dados Pessoais.</b> Vocês têm direito a acessar os seus dados pessoais sob Nossa posse, contatando o Encarregado de Proteção de Dados (DPO) através dos meios de contato dispostos na cláusula 8.5, que será respondido em horário comercial, de segunda-feira a sexta-feira, das 9h 30 min às 18h, no prazo de 15 (quinze) dias, cumulada com descrição da forma, finalidade e duração do tratamento dos dados pessoais, que poderá ser enviada por e-mail ou através de carta, de acordo com o previsto no artigo 9º da LGPD.</p>
			<p className='terms-text'><b>6.2. Exclusão.</b> Você tem direito de solicitar a exclusão dos seus dados pessoais armazenados no Di.Acordo, a qualquer momento, salvo nos casos em que houver obrigação legal ou decisão judicial para a manutenção do armazenamento dos dados, de acordo com o disposto nos artigos 18, XVI, e 16 da LGPD.</p>
			<p className='terms-text'><b>6.3. Incorreção de Informações.</b> É responsabilidade do titular manter suas informações atualizadas, sendo certo que, no caso de incorreção, Nós poderemos atualizá-las ou excluí-las, excetuando-se os casos de necessidade de manutenção para fins comerciais legítimos ou jurídicos. </p>
			<p className='terms-text'><b>6.4. Medidas Necessárias de Proteção.</b> Nós tomamos as medidas de segurança necessárias para proteger seus dados pessoais contra a perda, utilização indevida, acesso, divulgação, alteração ou destruição não autorizados.</p>
			<p className='terms-text'><b>6.5. Proteção de Senhas.</b> Vocês são igualmente responsáveis por tomar medidas cabíveis destinadas a proteger as suas senhas, nomes de usuário e outros recursos de acesso especiais ao cadastro pessoal no Di.Acordo.</p>
		
			<h2 className='terms-subtitle'>NOTIFICAÇÃO DE MODIFICAÇÕES NA POLÍTICA DE PRIVACIDADE</h2>
			<p className='terms-text'><b>7.1. Alterações na Política de Privacidade.</b> Nós podemos alterar a Política de Privacidade da Plataforma de tempos em tempos. O uso de informações coletadas agora está sujeito à Política de Privacidade em vigor. Se Nós realizarmos mudanças na forma como utiliza as informações pessoais, notificaremos Vocês através do envio de e-mail, do site ou do aplicativo. </p>
			<p className='terms-text'><b>7.2. Ajustes Não Notificados.</b> Pequenos ajustes nesta Política de Privacidade podem ocorrer sem afetar significativamente as formas em que Nós tratamos as informações coletadas, de forma que estes não precisarão ser informados.</p>
		
			<h2 className='terms-subtitle'>COMUNICAÇÃO</h2>
			<p className='terms-text'><b>8.1. Envio de Comunicações.</b> Vocês, ao se cadastrarem, concordam que Nós enviemos para o seu e-mail notificações, publicidades, novidades sobre os serviços e informações importantes sobre a utilização da Plataforma que necessitem da sua atenção.</p>
			<p className='terms-text terms-sublist-item'><b>8.1.1. Opção por Não Recebimento.</b> Ao receber um e-mail em nome da Plataforma, haverá a possibilidade de optar por não mais recebê-los, utilizando da opção opt-out ou através de solicitação por e-mail.</p>
			<p className='terms-text'><b>8.2. Política AntiSpam.</b> A Plataforma toma os cuidados necessários para evitar o envio não solicitado de e-mails. </p>
			<p className='terms-text'><b>8.3. Confidencialidade.</b> É assegurado o máximo de confidencialidade no tratamento da lista de telefones e e-mails durante as tarefas regulares de Nossa administração.</p>

			<h2 className='terms-subtitle'>SEGURANÇA DE DADOS PESSOAIS </h2>
			<p className='terms-text'><b>9.1. Armazenamento.</b> Os dados pessoais de sua conta e todas as informações da plataforma são armazenados e trafegam de forma segura em servidores da Amazon Web Services (AWS) nos Estados Unidos da América, sendo que apenas os colaboradores autorizados por Nós podem ter acesso às suas informações pessoais, estando obrigatoriamente sujeitos aos deveres de confidencialidade e rigoroso respeito à sua privacidade nos termos desta Política.</p>
			<p className='terms-text'><b>9.2. Segurança da Informação.</b> Todas as transações da Di.Acordo são criptografadas, sujeitas a backup de dados, ferramentas de monitoramento, políticas de segurança, controles de acesso para colaboradores, com softwares de segurança atualizados.</p>
			<p className='terms-text'><b>9.3. Notificação.</b> Se Nós tomarmos conhecimento de qualquer violação à segurança própria ou a empresas de hospedagem, incluindo invasões, vazamentos de dados ou qualquer outro incidente de segurança da informação, iremos notificar as autoridades nacionais, Vocês, se afetados, acerca dessa violação e iremos fornecer o máximo de detalhes referente à natureza, extensão da violação e os dados comprometidos, em prazo razoável, de acordo com o disposto nos artigos 48, § 1º, da LGPD.</p>
			<p className='terms-text'><b>9.4.  Prazo de Armazenamento.</b> Nós armazenaremos seus dados durante todo o período em que seu cadastro estiver ativo.</p>
			<p className='terms-text'><b>9.5. Encarregado de Proteção de Dados.</b> Fica definido que Daniel Morim será o Encarregado de Proteção de Dados da Di.Acordo e poderá ser contatado através do e-mail daniel@wiiglo.com, ou à Rua Fonseca Teles, 121, São Cristóvão, Rio de Janeiro/RJ, CEP 20940-200.</p>
		
			<h2 className='terms-subtitle'>DISPOSIÇÕES GERAIS </h2>
			<p className='terms-text'><b>10.1. Responsabilidade do Usuário.</b> Vocês serão responsáveis por Nos indenizar por todos os custos e danos que este possa incorrer, como resultado da violação desta Política de Privacidade por si provocados.</p>
			<p className='terms-text'><b>10.2. Cooperação com Autoridades.</b> Nós cooperamos plenamente com quaisquer autoridades ou tribunais que venham a solicitar a divulgação da identidade ou localização de qualquer pessoa que tenha inserido qualquer material na Plataforma que violem as Cláusulas dispostas nesta Política.</p>
			<p className='terms-text'><b>10.3. Limitação de Responsabilidade.</b> Esta Política de Privacidade aborda apenas o uso e divulgação de informações coletadas pela Nossa Plataforma. Se Vocês divulgarem suas informações a websites de terceiros, regras diferentes podem ser aplicadas à utilização das suas informações. </p>
		</div>
	)
}

export default PolicyText