import React, { useState } from "react";
import { useTranslation } from "react-i18next";

function AddressAutoComplete({ props }) {
  const { t } = useTranslation();
  //react component for adress fields auto complete using adress code
  const { field, values, handleBlur, pageType, disabled } = props;

  const [state, setState] = useState();
  const [cep, setCep] = useState();
  const [address, setAddress] = useState();
  const [district, setDistrict] = useState();
  const [city, setCity] = useState();

  const handleCEP = (event) => {
    let value = event.target.value;
    let name = event.target.id;
    if (name === "cep") {
      setCep(value);
    }
    if (value.length > 7 && name === "cep") {
      fetch(`https://viacep.com.br/ws/${value}/json/`)
        .then((response) => response.json())
        .then((data) => {
          setState(data.uf);
          setAddress(data.logradouro);
          setDistrict(data.bairro);
          setCity(data.localidade);
          console.log(data);
        });
    }
  };
  return (
    <>
      {field.inputs.map((item) => (
        <div key={item.id} className="adress-input-container">
          <p className="adress-input-title">
            {t(`${pageType}.fields.${item.id}.title`)}
          </p>
          <input
            className="wrapped-input"
            id={item.id}
            placeholder={t(`${pageType}.placeholder.${item.id}`)}
            value={values[item.id]}
            onChange={handleCEP}
            onBlur={handleBlur}
            disabled={disabled}
          ></input>
        </div>
      ))}
    </>
  );
}
export default AddressAutoComplete;
